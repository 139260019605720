import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { useEffect } from "react";
import SubClassroomService from "../../services/subClassroomService";
import ComponentLoader from "../sharedComponents/componentLoader";
import SubClassroomProgress from "./SubClassroomProgress";

export default function SubClassroomWidget() {
  const teacherContext = useContext(TeacherIdentityContext);
  const subClassroomService = new SubClassroomService();
  const dispatch = useDispatch();
  const [hover, setHover] = useState(null);
  const [subClassroomList, setSubClassroomList] = useState<
    Array<SubClassroomOutputModel>
  >([]);
  const [subClassroomModalVis, setSubClassroomModalVis] = useState<{
    vis: boolean;
    id: number;
  }>({ vis: false, id: null });
  const [subClassroomDetail, setSubClassroomDetail] = useState<
    Array<TeacherSubClassroomDetailOutputModal>
  >([]);
  const [classroomDetailLoading, setClassroomDetailLoading] =
    useState<boolean>(false);

  const [selectedProgress, setSelectedProgress] = useState(
    teacherContext?.majors[0]?.lessons
  );

  const getSubClassroom = async () => {
    dispatch(showLoader());
    const response = await subClassroomService.GetSubClassroomByTeacherId();
    setSubClassroomList(response);
    dispatch(hideLoader());
  };

  const getSubClassroomDetail = async (id: number) => {
    setClassroomDetailLoading(true);
    const response =
      await subClassroomService.GetSubClassroomByTeacherIdBySubClassroomId(id);
    setSubClassroomDetail(response);
    setClassroomDetailLoading(false);
  };

  useEffect(() => {
    getSubClassroom();
  }, []);

  useEffect(() => {
    if (subClassroomModalVis.id !== null) {
      getSubClassroomDetail(subClassroomModalVis.id);
    }
  }, [subClassroomModalVis.id]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4>Şubeler ({subClassroomList.length})</h4>
        </div>
        <div
          className="card-body "
          style={{ maxHeight: "250px", overflowY: "scroll" }}
        >
          {subClassroomList.map((t) => (
            <div
              className={`rounded recent-message d-flex px-4 py-3 table-hover br-2 ${
                hover === t.id && "bg-primary text-white"
              }`}
              onMouseEnter={() => setHover(t.id)}
              onMouseLeave={() => setHover(null)}
              onClick={() => {
                // getStudentById(t.id);
                setSubClassroomModalVis({ vis: true, id: t.id });
              }}
            >
              <div className="name ms-4">
                <h5 className="mb-1">
                  {t.classroom.name + " / " + t.name + " Şubesi"}
                </h5>
                <h6 className="text-muted mb-0">{""}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      {subClassroomModalVis.vis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              {classroomDetailLoading ? (
                <ComponentLoader />
              ) : (
                <>
                  <div className="modal-header">
                    <h4 className="modal-title" id="myModalLabel33">
                      {`${subClassroomDetail?.[0]?.classroom?.name} / ${subClassroomDetail?.[0]?.name} Şubesi `}
                    </h4>
                  </div>
                  <div className="modal-body ">
                    <>
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        {teacherContext.majors.map((item, index) => (
                          <li className="nav-item" role="presentation">
                            <button
                              className={`nav-link ${index === 0 && "active"}`}
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => {
                                setSelectedProgress(item.lessons);
                              }}
                            >
                              {item.lessonName}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <SubClassroomProgress
                            subClassRoomId={subClassroomDetail?.[0]?.id}
                            classRoomId={subClassroomDetail?.[0]?.classroom.id}
                            detail={subClassroomDetail}
                            major={selectedProgress}
                          />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="col-12 p-2">
                            <label>Şube: </label>
                          </div>
                          <div className="col-12 p-2">
                            <label className="font-weight-bold">Öğrenci:</label>
                          </div>
                        </div>
                        <div className="form-group col-12 p-2">
                          {/* <input
                            type="number"
                            placeholder="Sınav Süresi (Dakika)"
                            value={fromAssignDuration}
                            onInput={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (Number(e.target.value) <= 0) {
                                setFormAssignDuration("");
                              } else {
                                setFormAssignDuration(e.target.value);
                              }
                            }}
                            className={
                              "form-control" +
                              (fromAssignDuration !== null ? "" : " is-invalid")
                            }
                            required
                          /> */}
                        </div>

                        <div className="row d-flex justify-content-between">
                          {!subClassroomDetail?.[0]?.students.length ? (
                            <b>Şubede Öğrenci Kayıtlı Değil</b>
                          ) : (
                            subClassroomDetail?.[0]?.students.map(
                              (item, index) => (
                                <div className="col-lg-4">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    className="accordion-item card card-md card-body shadow"
                                  >
                                    <b>{item.name}</b>
                                  </div>
                                </div>
                              )
                            )
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setSubClassroomModalVis({ vis: false, id: null });
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
