import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { useEffect } from "react";
import SubClassroomService from "../../services/subClassroomService";
import ComponentLoader from "../sharedComponents/componentLoader";
import SubClassroomProgress from "./SubClassroomProgress";
import ConsentModal from "../consentModal";
import TaskService from "../../services/taskService";
import dayjs from "dayjs";

export default function TasksWidget() {
  const dispatch = useDispatch();
  const taskService = new TaskService();
  const [consentModalVisibility, setConsentModalVisibility] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [taskData, setTaskData] = useState<Array<TaskOutputModel>>([]);
  const [formText, setFormText] = useState<string>("");
  const [trig, setTrig] = useState<boolean>(false);
  const getTasks = async () => {
    dispatch(showLoader());
    const response = await taskService.GetUserTasks();
    setTaskData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getTasks();
  }, [trig]);

  const handleApprove = async () => {
    dispatch(showLoader());
    setConsentModalVisibility(false);
    const response = await taskService.TaskUserChecked(selectedId, formText);
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4>Görevlerim</h4>
        </div>
        <div
          className="card-body "
          style={{ maxHeight: "250px", overflowY: "scroll" }}
        >
          {taskData.map((t) => (
            <div
              className={"rounded recent-message d-flex px-4 table-hover br-2 "}
              onClick={() => {
                if (!t.checked) {
                  setSelectedId(t.id);
                  setConsentModalVisibility(true);
                }
              }}
            >
              <input
                type="checkbox"
                className="btn-check"
                id="btn-check-outlined"
                checked={t.checked}
                style={{ color: "red" }}
              />
              <label
                className="btn btn-outline-success"
                style={{ width: "100%" }}
              >
                <h5 className="mb-1" style={{ color: t.checked && "white" }}>
                  {t.task.length > 50 ? `${t.task.slice(0, 50)} ...` : t.task}
                </h5>
                <h6
                  className="text mb-0"
                  style={{ color: t.checked && "white" }}
                >
                  {`${dayjs(t.lastDate).format("DD.MM.YYYY")}`}
                </h6>
              </label>
            </div>
          ))}
        </div>
      </div>

      {consentModalVisibility && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            // style={{
            //   maxWidth: "1250px",
            // }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Görev tamamlandı mı ?
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setFormText("");
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <label className="font-weight-bold">Görev Tanımı:</label>
                  <h6>
                    {`${
                      taskData.find((task) => task.id === selectedId)?.task
                    } `}
                  </h6>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görev Notu:</label>

                    <input
                      type="text"
                      placeholder="Görev Notu"
                      value={formText}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormText(e.target.value)
                      }
                      className={"form-control is-valid"}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setFormText("");
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={handleApprove}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
