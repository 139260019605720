import dayjs from "dayjs";
import { useState, useEffect } from "react";

export const useCountdown = () => {
  const [secondsLeft, setSecondsLeft] = useState<number>(59);
  const [minutes, setMinutes] = useState<number>(0);
  const [timesUp, setTimesUp] = useState<boolean>(false);
  useEffect(() => {
    if (secondsLeft <= 0 && minutes <= 0) {
      setTimesUp(true);
      return;
    }

    const timeout = setTimeout(() => {
      if (secondsLeft <= 0) {
        setMinutes(minutes - 1);
        setSecondsLeft(59);
      } else {
        setSecondsLeft(secondsLeft - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [secondsLeft, minutes]);

  const start = (date: string) => {
    setMinutes(dayjs(date).diff(dayjs(), "minute", false));
    setSecondsLeft(dayjs(date).diff(dayjs(), "second", false) % 60);
  };

  return { minutes, secondsLeft, start, timesUp };
};
