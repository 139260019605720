import React, { useEffect, useState } from "react";
import TestService from "../../services/testService";
import Datatable from "../sharedComponents/datatable";
import ExamResultUpload from "./examResultUpload";
import ExamAnswerKeyUpload from "./examAnswerKeyUpload";
import TestPdf from "../sharedComponents/testpdf";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import { useRef } from "react";
import TestAssignModal from "../sharedComponents/TestAssignModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { start } from "repl";
import LessonService from "../../services/lessonService";
import UpdateTestAssignModal from "../sharedComponents/UpdateTestAssignModal";
import ClassroomService from "../../services/classroomService";
import SubClassroomService from "../../services/subClassroomService";
import TestAssignSubClassroomModal from "../sharedComponents/TestAssignSubClassroomModal";
import UpdateTestAssignSubClassModal from "../sharedComponents/UpdateTestAssignSubClassModal";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AdvancedTable from "./AdvancedTable";
import { ActionIcon, Tooltip } from "@mantine/core";
import {
  IconBookUpload,
  IconDownload,
  IconKey,
  IconPdf,
  IconUpload,
} from "@tabler/icons-react";
import Select from "react-select";
dayjs.extend(utc);

enum VisibleModalType {
  answerKey = "A",
  examResult = "B",
}

export default function ExamsPage() {
  const dispatch = useDispatch();
  const testService = new TestService();
  const subClassroomService = new SubClassroomService();
  const [examList, setExamList] = useState<Array<TestOutputModel>>([]);
  const [modalState, setModalState] = useState<{
    data: TestOutputModel;
    visibleModal: VisibleModalType;
    examId?: number;
  }>({ data: null, visibleModal: null, examId: null });
  const studentService = new StudentService();
  const lessonService = new LessonService();
  const classroomService = new ClassroomService();
  const [srcDoc, setSrcDoc] = useState(null);
  const [searchText, setSearchText] = useState("");
  let [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);
  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [testIdForDelete, setTestIdForDelete] = useState<number>(null);
  const [uploadModalVis, setUploadModalVis] = useState(false);
  const [assignModalVis, setAssignModalVis] = useState(false);
  const [uploadFile, setUploadFile] = useState<File>(null);
  const [uploadFileTestId, setUploadFileTestId] = useState<number>(null);
  const [editaModalVisibility, setEditaModalVisibility] = useState(false);
  const [editableExam, setEditableExam] = useState<TestOutputModel>(null);
  const [assignList, setAssignList] = useState([]);
  const [updateAssignList, setUpdateAssignList] = useState([]);
  const [updateSubClassAssignList, setUpdateSubClassAssignList] = useState([]);
  const [examName, setExamName] = useState<string>("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTestId, setSelectedTestId] = useState<number>(null);
  const [assigmentListById, setAssigmentListById] = useState<any>([]);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [classroomList, setClassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);
  const [subClassroomList, setSubClassroomList] = useState<
    Array<SubClassroomOutputModel>
  >([]);
  const [assignSubClassList, setAssignSubClassList] = useState([]);
  const [fromAssignDuration, setFormAssignDuration] = useState(null);
  const [assignedSubClassList, setAssignedSubClassList] = useState([]);
  const [filteredSubClassroomList, setFilteredSubClassroomList] = useState([]);
  const [assignType, setAssignType] = useState<string>("new");
  const [answerAlertModal, setAnswerAlertModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any>({});
  const [classList, setClassList] = useState<Array<ClassroomOutputModel>>([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedLesson, setSelectedLesson] = useState("");
  const [assignModalType, setAssignModalType] = useState<"single" | "multiple">(
    "single"
  );
  const getClasroomList = async () => {
    const response = await classroomService.GetClassroomList();
    setClassroomList(response);
  };

  const getStudentList = async () => {
    dispatch(showLoader());
    const response = await studentService.GetStudentList();

    setStudentList(response);
    dispatch(hideLoader());
  };

  const getTestList = async () => {
    const result = await testService.GetTestList();
    // if (result) setExamList(result.reverse());
    if (result) setExamList(result.reverse());
  };

  const getAssignmentListByID = async () => {
    const response: TestAssigmentByIdModel =
      await lessonService.GetTestAssigmentById(selectedTestId);
    const assigmentStudentList = response.studentId.map((item) => {
      const student = studentList.find((student) => student.id === item);
      return student === undefined
        ? []
        : {
            id: student.id,
            name: student.name,
            surname: student.surname,
          };
    });

    const filteredStudentArr = studentList.filter((student) => {
      return !response.studentId.some((item) => {
        return student.id === item;
      });
    });

    const filteredSubClassroomArr = subClassroomList.filter((subClassroom) => {
      return !response.subClassroom.some((item) => {
        return subClassroom.id === item.id;
      });
    });

    const assignedSubClassList = response.subClassroom.map((item) => {
      const subClassroom = subClassroomList.find(
        (subClassroom) => subClassroom.id === item.id
      );
      return {
        id: subClassroom.id,
        name: subClassroom.name,
        classroom: {
          id: subClassroom.classroom.id,
          name: subClassroom.classroom.name,
        },
      };
    });

    setFilteredSubClassroomList(filteredSubClassroomArr);
    setAssignedSubClassList(assignedSubClassList);
    setAssigmentListById(assigmentStudentList);
    setFilteredStudentList(filteredStudentArr);
  };

  useEffect(() => {
    if (selectedTestId !== null) {
      getAssignmentListByID();
    }
  }, [selectedTestId]);

  useEffect(() => {
    getStudentList();
    getTestList();
    getClasroomList();
  }, []);

  const createPdfOnClickHandler = async (id: number) => {
    dispatch(showLoader());
    const html = await TestPdf(id);
    setSrcDoc(html);
    dispatch(hideLoader());
  };

  const answerKeyEditClickHandler = async (examId: number) => {
    dispatch(showLoader());
    const test = await testService.GetTestDetails(examId);
    dispatch(hideLoader());
    setModalState({
      data: test,
      visibleModal: VisibleModalType.answerKey,
      examId: examId,
    });
  };

  const uploadExamResultClickHandler = async (examId: number) => {
    dispatch(showLoader());
    const test = await testService.GetTestDetails(examId);
    dispatch(hideLoader());
    setModalState({
      data: test,
      visibleModal: VisibleModalType.examResult,
      examId: examId,
    });
  };

  const updateData = async () => {
    removeModal();
    const result = await testService.GetTestList();
    if (result) {
      setExamList(result.reverse());
      // setExamList(result.reverse());

      // setModalState({
      //     data: examList.find(e => e.id == examId),
      //     visibleModal: null
      // });
    }
  };

  const removeModal = () => {
    setModalState({ data: null, visibleModal: null, examId: null });
  };

  const consentModalApproveHandler = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    const result = await testService.DeleteTest(testIdForDelete);
    //api call
    getTestList();
    dispatch(hideLoader());
  };

  const consentModalCancelHandler = () => setConsentModalVisibility(false);

  const selectTestForDelete = (testId: number) => {
    setTestIdForDelete(testId);
    setConsentModalVisibility(true);
  };

  const editSubmitHandler = async () => {
    setEditaModalVisibility(false);
    dispatch(showLoader());
    await testService.EditTestName(editableExam);
    await getTestList();
    dispatch(hideLoader());
  };

  const selectTestForEdit = (id: number) => {
    const editableExam = examList.find((e) => e.id == id);
    setEditableExam({ ...editableExam });
    setEditaModalVisibility(true);
  };

  const selectTestForAssign = async (id: number) => {
    const response = await testService.GetTestDetails(id);
    const isAnswered = response.testQuestions.some(
      (item) => item.correctAnswer === null
    );
    if (!isAnswered) {
      const name = examList.find((e) => e.id == id).name;
      setExamName(name);
      setAssignModalVis(true);
      setSelectedTestId(id);
    } else {
      setAnswerAlertModal(true);
    }
  };

  const filteredExamList = examList.filter((e) => {
    const queryText = searchText.toLocaleLowerCase();

    const testId = e.id.toString();
    const className = (e.className || "").toLocaleLowerCase();
    const lessonName = (e.lessonName || "").toLocaleLowerCase();
    const createDate = (e.issueDate || "").toLocaleLowerCase();
    const testName = (e.name || "").toLocaleLowerCase();

    return (
      testId.includes(queryText) ||
      className.includes(queryText) ||
      lessonName.includes(queryText) ||
      createDate.includes(queryText) ||
      testName.includes(queryText)
    );
  });

  const onFileInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let file = event.target.files[0];
    setUploadFile(file);
  };

  const uploadCsv = async () => {
    if (!uploadFile) return;
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("testResults", uploadFile);
    formData.append("testId", uploadFileTestId.toString());
    let result = await testService.UploadTestResultFile(formData);
    setUploadFile(null);
    dispatch(hideLoader());
    setUploadModalVis(false);
  };

  const showUploadForm = (testId: number) => {
    setUploadFileTestId(testId);
    setUploadModalVis(true);
  };

  const handleAssignTest = async () => {
    dispatch(showLoader());
    let response: boolean = false;
    if (assignType === "new") {
      response = await lessonService.TestAssigment({
        testId:
          assignModalType === "multiple"
            ? Object.keys(selectedRows).map((keys) => Number(keys))
            : [Number(selectedTestId)],
        studentId: assignList,
        subClassroomId: assignSubClassList,
        duration: Number(fromAssignDuration),
        // startDate,
        // endDate,
        startDate: dayjs(startDate).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        endDate: dayjs(endDate).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      });
    } else if (assignType === "update") {
      response = await lessonService.UpdateTestAssigment({
        testId: [Number(selectedTestId)],
        studentId: updateAssignList,
        subClassroomId: assignSubClassList,
        duration: Number(fromAssignDuration),
        startDate: dayjs(startDate).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        endDate: dayjs(endDate).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      });
    }
    setStartDate(new Date());
    setEndDate(new Date());
    setAssignList([]);
    setUpdateAssignList([]);
    setSelectedTestId(null);
    if (response) {
      setAssignModalVis(false);
      setAlertModal(true);
      setAssignType("new");
    }
    dispatch(hideLoader());
  };

  const handleDeleteAssigned = async () => {
    dispatch(showLoader());
    const response = await lessonService.DeleteTestAssigment({
      testId: [Number(selectedTestId)],
      studentId: updateAssignList,
      subClassroomId: assignSubClassList,
    });

    setStartDate(new Date());
    setEndDate(new Date());
    setAssignList([]);
    setUpdateAssignList([]);
    setSelectedTestId(null);
    response && setAssignModalVis(false);
    dispatch(hideLoader());
  };

  const getSubClassroomList = async () => {
    const response = await subClassroomService.GetSubClassroomList();
    setSubClassroomList(response);
  };

  const getLessonList = async () => {
    dispatch(showLoader());
    const classList = await lessonService.GetClassLessons();
    console.warn(classList);
    setClassList(classList);
    dispatch(hideLoader());
  };

  const classListOpt = [
    ...[{ value: "", label: "Sınıf Filtrele" }],
    ...classList.map((item) => ({
      value: item.name,
      label: item.name,
    })),
  ];

  const lessonListOPt =
    selectedClass !== ""
      ? [
          ...[{ value: "", label: "Ders Filtrele" }],
          ...classList
            .find((item) => item.name == selectedClass)
            ?.lessons.map((item) => ({
              value: item.name,
              label: item.name,
            })),
        ]
      : [{ value: "", label: " " }];

  useEffect(() => {
    getSubClassroomList();
    getLessonList();
  }, []);

  const customDataTable = [
    {
      accessorKey: "className",
      header: "Sınıf",
      maxSize: 100,
    },
    {
      accessorKey: "lessonName",
      header: "Ders Adı",
      maxSize: 100,
    },
    {
      accessorFn: (row) =>
        `${
          row.name.split("").length > 40
            ? `${row.name.slice(0, 40)}  ......`
            : row.name
        } `,
      accessorKey: "namee",
      header: "Test Adı",
      maxSize: 100,
    },
    {
      accessorKey: "questionCount",
      header: "Soru Sayısı",
      maxSize: 100,
    },
    {
      accessorFn: (row) => row.issueDate,
      accessorKey: "issueDate",
      header: "Oluşturma Tarihi",
      maxSize: 100,
    },
    // {
    //   accessorKey: "issueDate",
    //   header: "Oluşturma Tarihi",
    //   maxSize: 100,
    // },

    // {
    //   accessorKey: "questionCount",
    //   header: "Soru Sayısı",
    //   maxSize: 100,
    // },
  ];

  const data: TestOutputModel[] = examList.filter(
    (item) =>
      (selectedClass === "" || item.className === selectedClass) &&
      (selectedLesson === "" || item.lessonName === selectedLesson)
  );

  const handleSendSelected = async () => {
    setAssignModalType("multiple");
    setAssignModalVis(true);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start pt-2">
              <Select
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  container: (base) => ({
                    ...base,
                    width: "100%",
                  }),
                }}
                menuPosition={"fixed"}
                aria-label="Default select example"
                options={classListOpt}
                onChange={(selectedOption) => {
                  setSelectedLesson("");
                  setSelectedClass(selectedOption.value);
                }}
                placeholder="Sınıf Filtrele"
              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-start pt-2">
              <Select
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  container: (base) => ({
                    ...base,
                    width: "100%",
                  }),
                }}
                menuPosition={"fixed"}
                aria-label="Default select example"
                options={lessonListOPt}
                value={{
                  value: selectedLesson,
                  label:
                    selectedLesson === "" ? "Ders Filtrele" : selectedLesson,
                }}
                isDisabled={selectedClass === ""}
                onChange={(selectedOption) => {
                  setSelectedLesson(selectedOption.value);
                }}
                placeholder="Ders Filtrele"
              />
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-start pt-2">
              <button
                onClick={handleSendSelected}
                type="button"
                className="btn btn-primary ml-1 btn-l"
                disabled={!Object.keys(selectedRows).length}
              >
                Seçilenleri Gönder
              </button>
            </div>
          </div>
          <AdvancedTable
            data={data}
            dataTable={customDataTable}
            rowSelect={setSelectedRows}
            deleteButton={(row) => selectTestForDelete(row.id)}
            detailsButton={(row) => console.warn(row.id)}
            editButton={(row) => selectTestForEdit(row.id)}
            sendButton={(row) => {
              setAssignModalType("single");
              selectTestForAssign(row.id);
            }}
            extraButtons={(row) => {
              return (
                <>
                  <Tooltip label="İndir">
                    <ActionIcon
                      color="green"
                      onClick={() => createPdfOnClickHandler(row.id)}
                    >
                      <IconPdf />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Sonuç Yükle">
                    <ActionIcon
                      color="indigo"
                      onClick={() => uploadExamResultClickHandler(row.id)}
                    >
                      <IconBookUpload />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Cevap Anahtarı">
                    <ActionIcon
                      color="pink"
                      onClick={() => answerKeyEditClickHandler(row.id)}
                    >
                      <IconKey />
                    </ActionIcon>
                  </Tooltip>
                </>
              );
            }}
          />
        </div>
      </div>

      <iframe style={{ visibility: "hidden" }} srcDoc={srcDoc}></iframe>
      {modalState.visibleModal == VisibleModalType.examResult ? (
        <ExamResultUpload
          onClose={removeModal}
          testId={modalState.examId}
          show={true}
          data={modalState.data}
          callback={updateData}
        />
      ) : modalState.visibleModal == VisibleModalType.answerKey ? (
        <ExamAnswerKeyUpload
          onClose={removeModal}
          examId={modalState.examId}
          show={true}
          data={modalState.data}
          callback={updateData}
        />
      ) : null}
      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={consentModalApproveHandler}
        cancelCallback={consentModalCancelHandler}
      />
      {editableExam && (
        <div
          className="modal fade text-left show"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            overflow: "auto",
            display: editaModalVisibility ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Sınav Güncelle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x"></i>
                </button>
              </div>
              <div className="modal-body">
                <form action="#">
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Sınav Adı: </label>
                      <input
                        type="text"
                        placeholder="Ad"
                        value={editableExam.name}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEditableExam({
                            ...editableExam,
                            name: e.target.value,
                          })
                        }
                        className={
                          "form-control" +
                          (editableExam.name ? " is-valid" : " is-invalid")
                        }
                        required
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditaModalVisibility(false)}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  disabled={!editableExam.name}
                  type="button"
                  onClick={editSubmitHandler}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {uploadModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Sonuç Yükle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-12">
                    <label>Dosya </label>
                    <input
                      onChange={onFileInputChangeHandler}
                      className={
                        "form-control " + (uploadFile ? "" : "is-invalid")
                      }
                      type="file"
                      multiple={false}
                      accept="text/csv"
                    />
                    <div className="invalid-feedback">
                      <i className="bx bx-radio-circle"></i>
                      Dosya seçiniz
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setUploadModalVis(false)}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={uploadCsv}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {assignModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Sınav Gönder - ${examName} `}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <>
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => {
                          setAssignType("new");
                        }}
                      >
                        Yeni Gönder
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        disabled={assignModalType === "multiple"}
                        onClick={() => {
                          setAssignType("update");
                        }}
                      >
                        Güncelle / Sil
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-12 p-2">
                        <label>Şube: </label>
                        <TestAssignSubClassroomModal
                          setSelected={setAssignSubClassList}
                          list={filteredSubClassroomList}
                          // disabled={!!updateAssignList.length}
                        />
                      </div>
                      <div className="col-12 p-2">
                        <label className="font-weight-bold">Öğrenci:</label>
                        <TestAssignModal
                          setSelected={setAssignList}
                          list={filteredStudentList}
                          // disabled={!!updateAssignList.length}
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="col-12 p-2">
                        <label>Şube: </label>
                        <UpdateTestAssignSubClassModal
                          setSelected={setUpdateSubClassAssignList}
                          list={assignedSubClassList}
                        />
                      </div>
                      <div className="col-12 p-2">
                        <label className="font-weight-bold">Öğrenci:</label>
                        <UpdateTestAssignModal
                          setSelected={setUpdateAssignList}
                          list={assigmentListById}
                        />
                      </div>
                    </div>
                    <div className="form-group col-12 p-2">
                      <input
                        type="number"
                        placeholder="Sınav Süresi (Dakika)"
                        value={fromAssignDuration}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (Number(e.target.value) <= 0) {
                            setFormAssignDuration("");
                          } else {
                            setFormAssignDuration(e.target.value);
                          }
                        }}
                        className={
                          "form-control" +
                          (fromAssignDuration !== null ? "" : " is-invalid")
                        }
                        required
                      />
                    </div>
                    <div className="row p-2">
                      <div className="col-4 align-self-center">
                        <label className="font-weight-bold">
                          Başlangıç Tarihi:
                        </label>
                      </div>
                      <div className="col-4">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          minDate={new Date()}
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="row p-2">
                      <div className="col-4">
                        <label className="font-weight-bold">
                          Bitiş Tarihi:
                        </label>
                      </div>

                      <div className="col-4">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={
                            !updateAssignList.length ? startDate : null
                          }
                          endDate={endDate}
                          minDate={startDate}
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                </>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedTestId(null);
                    setAssignModalVis(false);
                    setAssignList([]);
                    setUpdateAssignList([]);
                    setFormAssignDuration(null);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                {assignType === "update" && (
                  <button
                    type="button"
                    onClick={handleDeleteAssigned}
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    disabled={
                      !updateAssignList.length &&
                      !updateSubClassAssignList.length
                    }
                  >
                    <i className="fas fa-trash"></i>
                    <span className="d-inline p-2">Sil</span>
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => {
                    handleAssignTest();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={
                    (assignType === "update"
                      ? !updateAssignList.length &&
                        !updateSubClassAssignList.length
                      : !assignList.length && !assignSubClassList.length) ||
                    fromAssignDuration === null
                  }
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertModal && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Başarılı`}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    <label className="font-weight-bold">
                      Test Gönderme İşlem Başarılı
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAlertModal(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {answerAlertModal && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Başarısız`}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    <label className="font-weight-bold">
                      Testi gönderebilmek için lütfen cevap anahtarını
                      yükleyiniz !
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAnswerAlertModal(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
