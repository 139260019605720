import React, { useEffect, useState } from 'react'
import TestService from '../../services/testService';

export default function StudentTests() {
    const testService = new TestService();

    const [studentTestList, setStudentTestList] = useState<Array<TestOutputModel>>([]);

    const getStudentTestList = async () => {
        const result = await testService.GetTestList();
        if (result) setStudentTestList(result);
    };

    useEffect(() => {
        getStudentTestList();
    }, []);


    return <div className="card">
        <div className="card-body">
            <div className="table-responsive">
                <table className="table table-striped" id="table1">
                    <thead>
                        <tr>
                            <th>Test No</th>
                            <th>Test Adı</th>
                            <th>Oluşturma Tarihi</th>
                            <th>Soru Sayısı</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentTestList.map(l =>
                            <tr>
                                <td>{l.id}</td>
                                <td>{l.name}</td>
                                <td>{l.issueDate}</td>
                                <td>{l.testQuestions.length}</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}