import BaseService from "./baseService";

declare global {
  interface TaskInputModel {
    AttendentIds: string;
    LastDate: string;
    Task: string;
  }

  interface TaskUserCheckInputModel {
    TaskId: number;
    CompleterNote: string;
  }

  interface TaskUserCheckOutputModel {
    id: number;
    task: string;
    lastDate: string;
    issueDate: string;
    checked: boolean;
    adminChecked: boolean;
    completerNote: string;
    completer: {
      name: string;
      surname: string;
      profilePhoto: any;
    };
  }

  interface TaskOutputModel {
    id: number;
    task: string;
    lastDate: string;
    issueDate: string;
    attendent: {
      name: string;
      surname: string;
      profilePhoto: any;
    }[];
    checked: boolean;
    adminChecked: boolean;
    completerNote: string;
    completer: {
      name: string;
      surname: string;
      profilePhoto: any;
    };
  }
}

export default class TaskService extends BaseService {
  constructor() {
    super("/Tasks");
  }

  CreateTask(task) {
    return this.Post<Array<TaskInputModel>, Array<TaskOutputModel>>(task);
  }

  async GetAllTasks(): Promise<Array<TaskOutputModel>> {
    return await this.Get<Array<TaskOutputModel>>();
  }

  DeleteTask(id: number) {
    return this.Delete<number>(id, "/Tasks");
  }

  TaskAdminChecked(id: number) {
    return this.GetById<any, any>(id, "/Tasks/AdminChecked/");
  }

  TaskUserChecked(TaskId: number, CompleterNote: string) {
    // return this.GetById<any, any>(id, "/Tasks/UserChecked/");
    return this.Post<TaskUserCheckInputModel, Array<TaskOutputModel>>(
      {
        TaskId,
        CompleterNote,
      },
      "/Tasks/UserChecked"
    );
  }

  async GetUserTasks(): Promise<Array<TaskOutputModel>> {
    return await this.Get<Array<TaskOutputModel>>(null, "/Tasks/UserTasks");
  }
}
