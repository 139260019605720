import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../reducers/messengerSlice";
import TestService from "../../services/testService";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useParams } from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import PdfViewer, { ViewerType } from "./pdfViewer";
import ConsentModal from "../consentModal";
import PdfViewerStatic from "../studentPanelComponents/PDFViewerStatic";

export default function PdfTestList() {
  const dispatch = useDispatch();
  let { lessonId } = useParams<UrlParam>();
  const testService = new TestService();

  const [pdfList, setPdfList] = useState<Array<Array<string>>>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState<FileList>(null);
  const [selectedPdfPageList, setSelectedPdfPageList] = useState<Array<string>>(
    []
  );
  const [pdfListModalStatus, setPdfListModalStatus] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const onFileInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let files = event.target.files;
    setFileList(files);
  };

  console.warn(selectedPdfPageList);

  const submitForm = async () => {
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++)
      formData.append(fileList[i].name, fileList[i]);

    formData.append("lessonId", lessonId);

    setShowModal(false);
    dispatch(showLoader());
    await testService.UploadPdf(formData);

    await getPdfList();
    dispatch(hideLoader());
  };

  const getPdfList = async () => {
    const result = await testService.GetPdfList(lessonId);
    if (result) setPdfList(result);
  };

  useEffect(() => {
    getPdfList();
  }, []);

  const selectPdf = (pdfPages: Array<string>) => {
    setSelectedPdfPageList([...pdfPages]);
    setPdfListModalStatus(true);
  };

  const submitTest = async (
    selectedQuestionList: Array<string>,
    testCreateName: string
  ) => {
    dispatch(showLoader());
    setPdfListModalStatus(false);

    const formData = new FormData();
    for (let index = 0; index < selectedQuestionList.length; index++) {
      const data = await fetch(selectedQuestionList[index]);
      const dataBlob = await data.blob();
      formData.append(index.toString(), dataBlob);
    }

    formData.append("testName", testCreateName);
    formData.append("lessonId", lessonId);

    const result = await testService.CreateTestFromPdf(formData);
    dispatch(hideLoader());
  };

  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [bookNameToDelete, setBookNameToDelete] = useState(null);

  const deleteBook = (bookName: string) => {
    setBookNameToDelete(bookName);
    setConsentModalVisibility(true);
  };

  const consentModalApproveHandler = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    await testService.DeletePdf(bookNameToDelete);
    await getPdfList();
    dispatch(hideLoader());
  };

  const consentModalCancelHandler = () => setConsentModalVisibility(false);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12">
                  <input
                    type="text"
                    placeholder="PDF Ara"
                    className="form-control"
                    value={searchText}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchText(e.target.value)
                    }
                    style={{ marginBottom: "25px" }}
                  />
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 pt-1">
                  <div className="buttons" style={{ float: "right" }}>
                    <button
                      onClick={() => setShowModal(true)}
                      className="btn btn-info"
                    >
                      Yeni PDF Ekle
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {pdfList
                  .filter((item) =>
                    item[0].toLocaleLowerCase().includes(searchText)
                  )
                  .map((p) => (
                    <div className="card col-md-6 p-4 text-center">
                      <div className="row">
                        <div className="col-3">
                          <img
                            src="/assets/img/pdf-icon.png"
                            width="70"
                            height="90"
                            style={{ cursor: "pointer" }}
                            onClick={() => selectPdf(p)}
                          />
                        </div>
                        <div
                          className="col-9"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <h6 style={{ textAlign: "left" }}>
                            {p[0].split(";;;")[0]}
                          </h6>
                          <button
                            onClick={() => deleteBook(p[0].split(";;;")[0])}
                            className="ml-2 btn btn-info"
                          >
                            Sil
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade text-left show"
        tabIndex={-1}
        aria-labelledby="myModalLabel33"
        style={{
          overflow: "auto",
          display: showModal ? "block" : "none",
          backgroundColor: "rgba(0,0,0,0.4)",
        }}
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel33">
                Yeni PDF Ekle
              </h4>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="row">
                  <div className="form-group col-12">
                    <label>Test Seç </label>
                    <input
                      onChange={onFileInputChangeHandler}
                      className="form-control"
                      type="file"
                      multiple={true}
                      accept="application/pdf"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="btn btn-default rounded pill"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times-circle"></i>
                <span className="d-inline p-2">Kapat</span>
              </button>
              <button
                disabled={!fileList}
                type="button"
                onClick={submitForm}
                className="btn btn-primary ml-1"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-check-circle"></i>
                <span className="d-inline p-2">Kaydet</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <PdfViewer
        viewerType={ViewerType.Static}
        selectedPdfPageList={selectedPdfPageList}
        visible={pdfListModalStatus}
        onCloseClick={() => setPdfListModalStatus(false)}
        onSubmit={() => {}}
      />
      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={consentModalApproveHandler}
        cancelCallback={consentModalCancelHandler}
      />
    </>
  );
}
