import { configureStore } from '@reduxjs/toolkit'
import { commonSlice } from './reducers/commonSlice';
import { counterSlice } from './reducers/counterSlice'
import { messengerSlice } from './reducers/messengerSlice';

const store = configureStore({
    reducer: {
        counter: counterSlice.reducer,
        messenger: messengerSlice.reducer,
        commons: commonSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;

export default store;