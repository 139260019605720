// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import LessonService from "../../services/lessonService";
import TestService from "../../services/testService";
import Accordion from "../sharedComponents/accordion";
import {
  Draggable,
  DragDropContext,
  Droppable,
  DragUpdate,
} from "react-beautiful-dnd";
import { Redirect, useParams } from "react-router-dom";
import SelectableAccordionItem from "../sharedComponents/selectableAccordionItem";
import Datatable from "../sharedComponents/datatable";
import Modal from "../sharedComponents/modal";
import { sendMessage } from "../../reducers/messengerSlice";
import TestPdf from "../sharedComponents/testpdf";
import BaseService from "../../services/baseService";

export default function CreateTestPage() {
  const baseService = new BaseService();
  const lessonService = new LessonService();
  const testService = new TestService();
  const dispatch = useDispatch();
  const [lesson, setlesson] = useState<LessonOutputModel>({
    id: 0,
    name: "",
    units: [],
    lessonGenericId: null,
  });
  const [questionList, setQuestionList] = useState<Array<QuestionOutputModel>>(
    []
  );
  const [modalVisibility, setModalVisibility] = useState(false);
  const [viewableQuestion, setViewableQuestion] = useState<QuestionOutputModel>(
    {
      checked: false,
      difficultyLevel: 0,
      id: 0,
      question: "",
      testQuestionAnswers: [],
    }
  );
  let { lessonId } = useParams<UrlParam>();
  const [srcDoc, setSrcDoc] = useState(null);
  const [testCreateName, setTestCreateName] = useState("");

  useEffect(() => {
    getLesson();
  }, []);

  const getLesson = async () => {
    dispatch(showLoader());
    var selectedLesson = await lessonService.GetLessonById(Number(lessonId));
    setlesson(selectedLesson);
    dispatch(hideLoader());
  };

  const populateUnits = () => {
    return lesson.units.map((u) => (
      <SelectableAccordionItem
        onCheckboxChangeHandler={(checked: boolean) =>
          onUnitCheckboxChangeCallbackHandler(u.id, checked)
        }
        id={"unit" + u.id}
        parentId="Units"
        title={u.name}
        checked={u.checked || false}
      >
        <Accordion
          id={"Topics"}
          insertable={false}
          populate={() => populateTopics(u.id, u.topics)}
        ></Accordion>
      </SelectableAccordionItem>
    ));
  };

  const onViewClickCallback = (id: number) => {
    const questionList: Array<QuestionOutputModel> = [];
    lesson.units.forEach((u) =>
      u.topics.forEach((t) =>
        t.testQuestions.forEach((q) => questionList.push(q))
      )
    );
    setViewableQuestion(questionList.find((q) => q.id == id));
    setModalVisibility(true);
  };

  const populateTopics = (unitId: number, topics: Array<TopicOutputModel>) =>
    topics.map((t) => {
      let data: DatatableDataModel = {
        header: ["Soru No", "Kazanım"],
        list: t.testQuestions.map((q) => ({
          id: q.id,
          checked: q.checked,
          row: [
            q.id.toString(),
            q.learningOutcome ? q.learningOutcome.toString() : "",
          ],
        })),
      };

      return (
        <SelectableAccordionItem
          onCheckboxChangeHandler={(checked: boolean) =>
            onTopicCheckboxChangeCallbackHandler(unitId, t.id, checked)
          }
          id={"topic" + t.id}
          parentId="Topics"
          title={t.name}
          checked={t.checked || false}
        >
          <Datatable
            onViewClickCallback={onViewClickCallback}
            viewable={true}
            editable={false}
            selectable={true}
            insertable={false}
            data={data}
            onCheckboxChangeCallback={(checked: boolean, id: number) =>
              onQuestionCheckboxChangeCallbackHandler(unitId, t.id, id, checked)
            }
          ></Datatable>
        </SelectableAccordionItem>
      );
    });

  const onUnitCheckboxChangeCallbackHandler = (
    unitId: number,
    checked: boolean
  ) => {
    let updatedLesson = { ...lesson };
    let updatedUnit = updatedLesson.units.find((u) => u.id == unitId);
    updatedUnit.checked = checked;
    let questionTempList: Array<QuestionOutputModel> = [];
    let questionIndexList: Array<number> = [];
    updatedUnit.topics.forEach((t) => {
      t.checked = checked;
      t.testQuestions.forEach((t) => {
        t.checked = checked;
        if (checked && questionList.indexOf(t) == -1) questionTempList.push(t);
        else questionIndexList.push(questionList.indexOf(t));
      });
    });
    setlesson(updatedLesson);

    // update test question list
    const updatedQuestionList = [...questionList];
    if (checked) updatedQuestionList.push(...questionTempList);
    else {
      questionIndexList
        .sort((x, y) => y - x)
        .forEach((val) => updatedQuestionList.splice(val, 1));
    }
    setQuestionList(updatedQuestionList);
  };

  const onTopicCheckboxChangeCallbackHandler = (
    unitId: number,
    topicId: number,
    checked: boolean
  ) => {
    let updatedLesson = { ...lesson };
    let updatedUnit = updatedLesson.units.find((u) => u.id == unitId);
    let updatedTopic = updatedUnit.topics.find((t) => t.id == topicId);
    let questionTempList: Array<QuestionOutputModel> = [];
    let questionIndexList: Array<number> = [];
    updatedTopic.checked = checked;
    updatedTopic.testQuestions.forEach((t) => {
      t.checked = checked;
      if (checked && questionList.indexOf(t) == -1) questionTempList.push(t);
      else questionIndexList.push(questionList.indexOf(t));
    });
    setlesson(updatedLesson);

    // update test question list
    const updatedQuestionList = [...questionList];
    if (checked) updatedQuestionList.push(...questionTempList);
    else {
      questionIndexList
        .sort((x, y) => y - x)
        .forEach((val) => updatedQuestionList.splice(val, 1));
    }
    setQuestionList(updatedQuestionList);
  };

  const onQuestionCheckboxChangeCallbackHandler = (
    unitId: number,
    topicId: number,
    questionId: number,
    checked: boolean
  ) => {
    let updatedLesson = { ...lesson };
    let updatedUnit = updatedLesson.units.find((u) => u.id == unitId);
    let updatedTopic = updatedUnit.topics.find((t) => t.id == topicId);
    let questionTempList: Array<QuestionOutputModel> = [];
    let questionIndexList: Array<number> = [];
    let updatedQuestion = updatedTopic.testQuestions.find(
      (q) => q.id == questionId
    );
    updatedQuestion.checked = checked;
    setlesson(updatedLesson);

    if (checked) questionTempList.push(updatedQuestion);
    else questionIndexList.push(questionList.indexOf(updatedQuestion));
    // update test question list
    const updatedQuestionList = [...questionList];
    if (checked) updatedQuestionList.push(...questionTempList);
    else {
      questionIndexList
        .sort((x, y) => y - x)
        .forEach((val) => updatedQuestionList.splice(val, 1));
    }
    setQuestionList(updatedQuestionList);
  };

  const onDragEnd = (result: DragUpdate) => {
    if (!result.destination) return;

    const items = reorder(
      questionList,
      result.source.index,
      result.destination.index
    );

    setQuestionList(items);
  };

  const createPdfOnClickHandler = async () => {
    const testId = await testService.CreateTest({
      testQuestionIds: questionList.map((q) => q.id),
      testName: testCreateName,
      lessonId: Number(lessonId),
    });

    if (!testId) {
      dispatch(
        sendMessage({ title: "İşlem Sonucu", message: "Test oluşturulamadı" })
      );
      return;
    }

    const html = await TestPdf(testId);
    setSrcDoc(html);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Üniteler</h4>
            </div>
            <div className="card-body">
              <Accordion
                id={"Units"}
                insertable={false}
                populate={populateUnits}
              ></Accordion>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div
            className="card"
            style={{ maxHeight: "100vh", overflow: "auto" }}
          >
            <div className="card-header">
              <h4 className="card-title">
                Seçilen Sorular ({questionList.length})
              </h4>
              {questionList.length > 0 ? (
                <>
                  <input
                    type="text"
                    className={
                      "form-control" + (testCreateName ? "" : " is-invalid")
                    }
                    value={testCreateName}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTestCreateName(e.target.value)
                    }
                    placeholder="Test Adı Giriniz"
                  />
                  <button
                    disabled={!testCreateName}
                    onClick={createPdfOnClickHandler}
                    className="btn btn-info mt-2"
                  >
                    Testi oluştur
                  </button>
                </>
              ) : null}
            </div>
            <div className="card-body">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {questionList.map((q, index) => (
                        <Draggable
                          key={q.id.toString()}
                          draggableId={q.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="accordion-item card card-md card-body shadow"
                              style={{ height: "150px" }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {q.id}{" "}
                              <i
                                onClick={() => onViewClickCallback(q.id)}
                                className="fas fa-eye"
                              ></i>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
      <Modal
        showButtonRight={false}
        title="Soru"
        modalVisibility={modalVisibility}
        onclose={() => setModalVisibility(false)}
      >
        {viewableQuestion ? (
          viewableQuestion.questionUrl ? (
            <img
              src={
                baseService.endPoints().pdfUrl +
                "/testquestionbank/" +
                viewableQuestion.questionUrl
              }
            />
          ) : (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: viewableQuestion.question }}
              ></div>
              <div>
                {(viewableQuestion.testQuestionAnswers || []).map((a) => (
                  <div>
                    <span
                      style={{ fontWeight: "bold" }}
                      dangerouslySetInnerHTML={{ __html: a.answerKey }}
                    ></span>
                    <span style={{ fontWeight: "bold" }}>)</span>
                    <span
                      dangerouslySetInnerHTML={{ __html: a.answerValue }}
                    ></span>
                  </div>
                ))}
              </div>
            </>
          )
        ) : null}
      </Modal>
      <iframe
        style={{ visibility: "hidden" }}
        srcDoc={srcDoc}
        id="test-frame"
      ></iframe>
    </>
  );
}

const reorder = (
  list: Array<QuestionOutputModel>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
