import BaseService from "./baseService";

declare global {
  interface LearningOutcomeOutputModel {
    learningOutcome: string;
    learningOutcomeId: number;
    lesson: {
      lessonName: string;
      shortLessonName: string;
      startQuestionPosition: number;
      questionLength: number;
    };
  }

  interface SyllabusInputModel {
    Name: string;
    StartDate: string;
    EndDate: string;
    StudentId: string;
    SyllabusProgress: {
      Date: string;
      LearningOutcomeId: number;
      LessonId: number;
      QuestionCount: number;
    }[];
  }

  interface SyllabusOutputModel {
    id: number;
    issue: string;
    name: string;
    startDate: string;
    endDate: string;
    student: any;
    syllabusProgresses: any;
    syllabusProgressesGroupedDay: {
      day: string;
      date: string;
      syllabusProgress: {
        lesson: {
          lessonName: string;
          shortLessonName: string;
          id: number;
        };
        learningOutcome: {
          learningOutcome: string;
          id: number;
        };
        questionCount: number;
      }[];
    }[];
  }
}
export default class SyllabusService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/syllabus");
  }

  GetLearningOutcomeByLessonId(id: number) {
    return this.GetById<number, Array<LearningOutcomeOutputModel>>(
      id,
      "/syllabus/GetLearningOutcomeByLessondId"
    );
  }

  AddSyllabusProgress(syllabus: SyllabusInputModel) {
    return this.Post<SyllabusInputModel, boolean>(
      syllabus,
      "/syllabus/AddSyllabusProgress"
    );
  }

  GetSyllabusProgressByStudentId(id: string) {
    return this.GetById<string, Array<SyllabusOutputModel>>(
      id,
      "/syllabus/GetSyllabusProgressByStudentId"
    );
  }

  GetSyllabusProgressForStudentId() {
    return this.Get<Array<SyllabusOutputModel>>(
      null,
      "/syllabus/GetSyllabusProgressForStudent"
    );
  }

  async DeleteSyllabusById(id: number) {
    return await this.Delete(id);
  }
}
