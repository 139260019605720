// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import ClassroomService from "../../services/classroomService";
import StudentService from "../../services/studentService";

export default function Register() {
  const studentService = new StudentService();
  const dispatch = useDispatch();

  const [formUserTel, setFormUserTel] = useState("");
  const [formUserEmail, setFormUserEmail] = useState("");
  const [formUserName, setFormUserName] = useState("");
  const [formUserSurname, setFormUserSurname] = useState("");
  const [formParentNameSurname, setFormParentNameSurname] = useState("");
  const [formUserSchool, setFormUserSchool] = useState("");
  const [formUserClassRoom, setFormUserClassRoom] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [agreeTermAndCondition, setAgreeTermAndCondition] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const [passwordInputType, setPasswordInputType] = useState("password");

  const [formParentTel, setFormParentTel] = useState("");
  const [formParentEmail, setFormParentEmail] = useState("");
  const [classroomList, setClassroomList] = useState<Array<string>>([
    "5.Sınıf",
    "6.Sınıf",
    "7.Sınıf",
    "8.Sınıf",
    "9.Sınıf",
    "10.Sınıf",
    "11.Sınıf",
    "12.Sınıf",
    "Mezun",
  ]);

  const formSubmitHandler = async () => {
    dispatch(showLoader());
    let result = await studentService.Register({
      schoolClass: formUserClassRoom,
      email: formUserEmail,
      name: formUserName,
      parentNameSurname: formParentNameSurname,
      password: password,
      school: formUserSchool,
      surname: formUserSurname,
      tel: formUserTel,
      parentTel: formParentTel,
      parentEmail: formParentEmail,
      gemClassroomId: null,
    });
    dispatch(hideLoader());
    let message = "";
    if (result != null) {
      setRedirectToLogin(true);
      message = "Kayıt işleminiz başarıyla tamamlanmıştır";
      dispatch(sendMessage({ title: "İşlem Sonucu", message: message }));
    } else {
      message = "Kayıt işlemi başarısız";
    }
  };

  const emailRegex = new RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );
  const isEmailValid = !!formUserEmail.match(emailRegex);
  const isParentEmailValid = !!formParentEmail.match(emailRegex);
  const isPasswordLengthValid = password.length >= 6;
  let isPasswordFormatValid = !!password.match(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
  );
  isPasswordFormatValid = true;

  const isFormValid = () => {
    return (
      formUserTel &&
      formUserEmail &&
      formUserName &&
      formParentNameSurname &&
      formUserSchool &&
      formUserClassRoom &&
      password &&
      repassword &&
      agreeTermAndCondition &&
      formParentTel &&
      formParentEmail
    );
  };

  const togglePasswordVisibility = () => {
    if (passwordInputType == "password") setPasswordInputType("text");
    else setPasswordInputType("password");
  };

  const filterPhoneInput = (value: string) => {
    const matches = (value || "").match(/^[0-9]*$/);
    if (matches) setFormUserTel(matches[0]);
  };

  const filterParentPhoneInput = (value: string) => {
    const matches = (value || "").match(/^[0-9]*$/);
    if (matches) setFormParentTel(matches[0]);
  };

  const getClassList = async () => {
    // const classService = new ClassroomService();
    // const result = await classService.GetClassroomList();
    // if (result) setClassroomList(result);
  };

  useEffect(() => {
    //getClassList();
  }, []);

  return redirectToLogin ? (
    <Redirect to="/login" />
  ) : (
    <section className="d-flex align-items-center overlay-soft bg-with-image uyeol">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-end mt-3 pt-5">
            <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h1 className="mb-0 h3">Üye ol</h1>
              </div>
              <form action="#" className="mt-4">
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user-graduate"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormUserName(e.target.value)
                      }
                      value={formUserName}
                      className={`form-control ${
                        formUserName ? "is-valid" : "is-invalid"
                      }`}
                      placeholder="Öğrenci Ad Soyad"
                      required
                    />
                  </div>
                </div>
                {/* <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend"><span className="input-group-text"><i className="fas fa-user-graduate"></i></span>
                                        </div><input type="text" onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFormUserSurname(e.target.value)} value={formUserSurname} className={`form-control ${formUserSurname ? "is-valid" : "is-invalid"}`} placeholder="Öğrenci Soyad"
                                            required />
                                    </div>
                                </div> */}
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-envelope"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormUserEmail(e.target.value)
                      }
                      value={formUserEmail}
                      className={`form-control ${
                        formUserEmail && isEmailValid
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      placeholder="Öğrenci Email Adresi"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </span>
                    </div>
                    <input
                      type="tel"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        filterPhoneInput(e.target.value)
                      }
                      value={formUserTel}
                      className={`form-control ${
                        formUserTel ? "is-valid" : "is-invalid"
                      }`}
                      placeholder="Öğrenci Telefon Numarası"
                      required
                    />
                  </div>
                </div>
                <div className="form-group pt-4">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormParentNameSurname(e.target.value)
                      }
                      value={formParentNameSurname}
                      className={`form-control ${
                        formParentNameSurname ? "is-valid" : "is-invalid"
                      }`}
                      placeholder="Veli Ad Soyad"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-envelope"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormParentEmail(e.target.value)
                      }
                      value={formParentEmail}
                      className={`form-control ${
                        formParentEmail && isParentEmailValid
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      placeholder="Veli Email Adresi"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-phone"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        filterParentPhoneInput(e.target.value)
                      }
                      value={formParentTel}
                      className={`form-control ${
                        formParentTel ? "is-valid" : "is-invalid"
                      }`}
                      placeholder="Veli Telefon Numarası"
                      required
                    />
                  </div>
                </div>
                <div className="form-group pt-4">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-school"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormUserSchool(e.target.value)
                      }
                      value={formUserSchool}
                      className={`form-control ${
                        formUserSchool ? "is-valid" : "is-invalid"
                      }`}
                      placeholder="Öğrenci Okulu"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-window-maximize"></i>
                      </span>
                    </div>
                    <select
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setFormUserClassRoom(e.target.value)
                      }
                      value={formUserClassRoom}
                      className={`form-control ${
                        formUserClassRoom ? "is-valid" : "is-invalid"
                      }`}
                    >
                      <option selected value="" disabled>
                        Öğrenci Sınıfı Seçiniz
                      </option>
                      {classroomList.map((c) => (
                        <option value={c}>{c}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group pt-4">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-unlock-alt"></i>
                      </span>
                    </div>
                    <input
                      className={`form-control ${
                        isPasswordFormatValid &&
                        isPasswordLengthValid &&
                        password
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      placeholder="Şifre"
                      type={passwordInputType}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                      }
                      value={password}
                      autoComplete="new-password"
                      required
                    />
                    <div
                      className="input-group-append"
                      style={{ cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    ></div>
                    <div className="invalid-feedback">
                      <i className="bx bx-radio-circle"></i>
                      {!isPasswordLengthValid
                        ? "Şifre en az 6 karakter uzunluğunda olmalıdır"
                        : !isPasswordFormatValid
                        ? "Şifre küçük, büyük harf ve rakam içermelidir"
                        : ""}
                    </div>
                  </div>
                  <div className="input-group mt-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-unlock-alt"></i>
                      </span>
                    </div>
                    <input
                      type="password"
                      className={`form-control ${
                        repassword && repassword == password
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      id="input-password-confirm"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRepassword(e.target.value)
                      }
                      value={repassword}
                      placeholder="Şifre tekrar"
                      required
                    />
                    <div className="invalid-feedback">
                      <i className="bx bx-radio-circle"></i>
                      Şifreler eşleşmiyor
                    </div>
                  </div>
                  <div className="d-block d-sm-flex justify-content-between align-items-center mt-2">
                    <div className="form-group form-check mt-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsandconditions"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setAgreeTermAndCondition(e.target.checked)
                        }
                        checked={agreeTermAndCondition}
                      />
                      <label
                        className="form-check-label form-check-sign-white"
                        htmlFor="termsandconditions"
                      >
                        <a target="_blank" href="./kullanimkosullari">
                          Kullanım Koşullarını
                        </a>{" "}
                        Kabul ediyorum.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={formSubmitHandler}
                    className="btn btn-block btn-primary"
                    disabled={!isFormValid()}
                  >
                    Üye ol
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
