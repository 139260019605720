import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../reducers/commonSlice';
import { sendMessage } from '../../reducers/messengerSlice';
import StudentService from '../../services/studentService';
import SurveyService from '../../services/surveyService';
import TeacherService from '../../services/teacherService';
import Accordion from '../sharedComponents/accordion';
import SelectableAccordionItem from '../sharedComponents/selectableAccordionItem';

export default function SurveyPage() {
    const dispatch = useDispatch();
    const surveyService = new SurveyService();
    const teacherService = new TeacherService();

    const [question1, setQuestion1] = useState("");
    const [question2, setQuestion2] = useState("");
    const [question3, setQuestion3] = useState("");
    const [question4, setQuestion4] = useState("");

    const onSurveySubmitClickHandler = async () => {
        dispatch(showLoader());
        const result = await surveyService.CreateSurvey({
            surveyQuestions: [
                { question: question1 },
                { question: question2 },
                { question: question3 },
                { question: question4 },
            ]
        });

        dispatch(hideLoader());
        if (result == null) dispatch(sendMessage({ title: "İşlem Sonucu", message: "İşlem başarısız" }));
        else {
            resetState();
            dispatch(sendMessage({ title: "İşlem Sonucu", message: "Anket başarıyla oluşturuldu" }));
        }
    };
    const resetState = () => {
        setQuestion1("");
        setQuestion2("");
        setQuestion3("");
        setQuestion4("");
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Anket Oluştur</h4>
                    </div>
                    <div className="card-body">
                        <form className="form form-vertical">
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className={"form-control" + (question1 ? " is-valid" : " is-invalid")} value={question1} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion1(e.target.value)} placeholder="Örnek: Öğretmen Eğitiminden memnun musunuz?" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className={"form-control" + (question2 ? " is-valid" : " is-invalid")} value={question2} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion2(e.target.value)} placeholder="Örnek: Öğretmen Geri Bildiriminden memnun musunuz?" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className={"form-control" + (question3 ? " is-valid" : " is-invalid")} value={question3} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion3(e.target.value)} placeholder="Örnek: İdareden memnun musunuz?" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className={"form-control" + (question4 ? " is-valid" : " is-invalid")} value={question4} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion4(e.target.value)} placeholder="Örnek:  Öğretmen Eğitiminden memnun musunuz?" />
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="button" disabled={!(question1 && question2 && question3 && question4)} className="btn btn-primary me-1 mb-1" onClick={onSurveySubmitClickHandler}>Kaydet</button>
                                        <button type="button"
                                            className="btn btn-light-secondary me-1 mb-1" onClick={resetState}>Sıfırla</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <div className="col-md-5">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Öğrenciler</h4>
                    </div>
                    <div className="card-body">
                        {
                            studentList.map(s => 
                            <div className="accordion-item card card-md card-body shadow">
                                <input type="checkbox" className="form-check-input"  />
                                <span>{s.name + " " + s.surname}</span>
                            </div>)
                        }
                    </div>
                </div>
            </div> */}
        </div>

    );
}