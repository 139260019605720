// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import StudentService from "../services/studentService";

declare global {
  interface ProfileWidgetAction {
    tag: string;
    callback: () => void;
  }

  interface ProfileLinkAction {
    tag: string;
    url: string;
  }

  interface ProfileWidgetModel {
    name: string;
    surname: string;
    photoUrl: string;
    actions?: Array<ProfileWidgetAction>;
    links?: Array<ProfileLinkAction>;
    subtext?: string;
    studentData?: StudentOutputModel;
    listUpdated?: () => void;
    isPassive?: boolean;
  }
}

export default function ProfileWidget(props: ProfileWidgetModel) {
  const studentService = new StudentService();

  const handleSwitch = async (isPassive: boolean) => {
    const result = await studentService.AddOrUpdateStudent({
      id: props.studentData.id,
      name: props.studentData.name,
      surname: props.studentData.surname,
      tel: props.studentData.telephone,
      email: props.studentData.email,
      lessons: props.studentData.lessons.map((l) => ({
        lessonId: l.lesson?.id,
        teacherId: l.teacher?.id,
      })),
      schoolClass: props.studentData.schoolClass,
      parentNameSurname: props.studentData.parentName,
      school: props.studentData.school,
      gemClassroomId: Number(props.studentData.classRoom),
      gemSubClassroomId: Number(props.studentData.subClassRoom),
      profilePhoto: props.studentData.profilePhoto,
      parentEmail: props.studentData.parentEmail,
      parentTel: props.studentData.parentTel,
      isPassive,
    });
    if (result !== null) {
      await props.listUpdated();
    }
  };

  return (
    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
      <div className="profile-widget">
        <div className="d-flex justify-content-start align-items-center ">
          {props.isPassive !== undefined && (
            <BootstrapSwitchButton
              checked={!props.isPassive}
              size={"sm"}
              onstyle="outline-success"
              offstyle="outline-secondary"
              onlabel={"A"}
              offlabel={"P"}
              onChange={() => {
                handleSwitch(!props.isPassive);
              }}
            />
          )}
        </div>
        <div className="profile-img">
          <a>
            <img
              className="avatar"
              src={
                props.photoUrl ||
                "/assets/img/Profile_avatar_placeholder_large.png"
              }
              alt=""
            />
          </a>
        </div>
        {props.actions || props.links ? (
          <div className="dropdown profile-action">
            <a
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-three-dots-vertical"></i>
            </a>
            <div className="dropdown-menu bg-light-secondary">
              {props.links
                ? (props.links || []).map((link) => (
                    <Link className="dropdown-item" to={link.url}>
                      {link.tag}
                    </Link>
                  ))
                : (props.actions || []).map((action) => (
                    <a className="dropdown-item" onClick={action.callback}>
                      <i className="fas fa-pencil-alt m-r-5"></i> {action.tag}
                    </a>
                  ))}
            </div>
          </div>
        ) : null}
        <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
          <a>{props.name + " " + props.surname}</a>
        </h4>
        <div className="small text-muted">{props.subtext}</div>
      </div>
    </div>
  );
}
