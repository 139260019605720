// @ts-nocheck
import React, { useEffect, useState } from "react";
import TestService from "../../services/testService";
import Datatable from "../sharedComponents/datatable";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import "react-datepicker/dist/react-datepicker.css";
import ClassroomService from "../../services/classroomService";
import { sendMessage } from "../../reducers/messengerSlice";
import { handleExcelToJsonParser, parser } from "../../utils/ExcelToJsonParser";
import OpticsService from "../../services/opticsService";
import Select from "react-select";
import StudentAnswerTable from "./StudentAnswerTable";
import ComponentLoader from "../sharedComponents/componentLoader";
import AdvancedTable from "./AdvancedTable";
import { StudentPracticeExamReportPDF } from "../../utils/Pdf";

export default function PracticeExamsPage() {
  const classroomService = new ClassroomService();
  const testService = new TestService();
  const opticService = new OpticsService();
  const studentService = new StudentService();
  const dispatch = useDispatch();

  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);
  const [classroomList, setClassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);
  const [selectedClassRoom, setSelecetedClassRoom] = useState("");
  const [selectedExamType, setSelectedExamType] = useState("");
  const [formPracticeExamName, setFormPracticeExamName] = useState<string>("");
  const [formPracticeExamPublisher, setFormPracticeExamPublisher] =
    useState<string>("");
  const [trig, setTrig] = useState<boolean>(false);
  const [practiceExamList, setPracticeExamList] = useState<
    Array<PracticeExamOutputModel>
  >([]);
  const [studentReportModalVis, setStudentReportModalVis] =
    useState<boolean>(false);
  const [consentModalVisibility, setConsentModalVisibility] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [mode, setMode] = useState<"create" | "update">("create");
  const [parsedData, setParsedData] = useState<any>([]);
  const [opticList, setOpticList] = useState<Array<OpticsOutputModel>>([]);
  const [formOptic, setFormOptic] = useState("");
  const [opticFile, setOpticFile] = useState(null);
  const [searchOptic, setSearchOptic] = useState("");
  const selectOpticOptions = opticList.map((item) => ({
    value: item.id,
    label: item.opticName.toLocaleLowerCase(),
  }));
  const [selectedPracticeExam, setSelectedPracticeExam] = useState(null);
  const [studentAnswer, setStudentAnswer] = useState<
    Array<OpticStudentAnswerOutputModel>
  >([]);
  const [dataTable, setDataTable] = useState<DatatableDataModel>([]);
  const [studentSelectModalVis, setStudentSelectModalVis] =
    useState<boolean>(false);
  const filteredSelectOpticOptions =
    searchOptic.length >= 3
      ? selectOpticOptions.filter((option) =>
          option.label
            .toLocaleLowerCase()
            .includes(searchOptic.toLocaleLowerCase())
        )
      : [];

  const [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedStudentIndex, setSelectedStudentIndex] =
    useState<number>(null);
  const [matchLessonModalVis, setMatchLessonModalVis] =
    useState<boolean>(false);
  const [practiceExamLessons, setPracticeExamLessons] = useState<Array<string>>(
    []
  );
  const [loaderAnswers, setLoaderAnswers] = useState<boolean>(false);
  const [matchedLessons, setMatchedLessons] = useState([]);
  const [practiceExamResult, setPracticeExamResult] = useState<
    Array<PracticeExamResultOutputModal>
  >([]);
  const [practiceExamResModalVis, setPracticeExamResModalVis] =
    useState<boolean>(false);
  const [practiceExamTypes, setPracticeExamTypes] = useState<
    Array<PracticeExamTypesOutputModel>
  >([]);
  const [optionalLesson, setOptionalLesson] = useState<boolean>(false);

  const [selectedExamForReport, setSelectedExamForReport] = useState<number[]>(
    []
  );
  const [studentPracticeExams, setStudentPracticeExams] = useState<
    Array<PracticeExamOutputModel>
  >([]);

  const [reportLoader, setReportLoader] = useState<boolean>(false);

  const getStudentReport = async () => {
    setReportLoader(true);
    const response = await testService.GetStudentPracticeExamsReport(
      selectedStudent,
      selectedExamForReport
    );
    StudentPracticeExamReportPDF(response);
    setReportLoader(false);
  };

  const getStudentPracticeExams = async (id: string) => {
    setReportLoader(true);
    setSelectedStudent(id);
    const response = await testService.GetStudentPracticeExams(id);
    if (response.length > 0) {
      setStudentPracticeExams(response);
      // response.map((item) =>
      //   setSelectedExamForReport((selectedExamForReport) => [
      //     ...selectedExamForReport,
      //     item.id,
      //   ])
      // );
    } else {
      setStudentPracticeExams([]);
    }
    setReportLoader(false);
  };

  const getStudentList = async () => {
    const response = await studentService.GetStudentList();
    setStudentList(response);
  };

  const getOptics = async () => {
    dispatch(showLoader());
    const response = await opticService.GetOptics();
    setOpticList(response);
    dispatch(hideLoader());
  };

  const getClassroomList = async () => {
    dispatch(showLoader());
    let result = await classroomService.GetClassroomList();
    dispatch(hideLoader());

    if (result != null) {
      setClassroomList(result);
    } else {
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    }
  };

  const getPracticeExamList = async () => {
    const response = await testService.GetPracticeExam();
    setPracticeExamList(response);
  };

  const createPracticeExam = async () => {
    dispatch(showLoader());
    if (mode === "create") {
      // const response = await testService.CreatePracticeExam({
      //   name: formPracticeExamName,
      //   classroomId: Number(selectedClassRoom),
      //   publisher: formPracticeExamPublisher,
      //   practiceExamLearningOutcome: parsedData,
      // });

      const updatedStudentAnswer = studentAnswer.map((student) => {
        let studentNewData = {
          isFind: student.isFind,
          opticParameters: student.opticParameters,
          studentName: student.studentName,
          tcNo: student.tcNo,
        };
        return {
          ...studentNewData,
          lessonParameters: student.lessonParameters.map((lessonParam) => {
            const matchedLesson = matchedLessons.find(
              (matched) => matched.oldKey === lessonParam.key
            );
            if (matchedLesson) {
              return { ...lessonParam, key: matchedLesson.newKey };
            } else {
              return lessonParam;
            }
          }),
        };
      });

      const response = await opticService.UploadStudentAnswer(
        updatedStudentAnswer,
        selectedPracticeExam
      );
    } else {
      const response = await testService.UpdatePracticeExam(
        {
          name: formPracticeExamName,
          publisher: formPracticeExamPublisher,
        },
        selectedId
      );
    }
    setFormPracticeExamName("");
    setFormPracticeExamPublisher("");
    setAddNewModalVis(false);
    setTrig((trig) => !trig);
    setParsedData([]);
    setStudentAnswer([]);
    setOpticFile(null);
    setFormOptic("");
    setMatchLessonModalVis(false);
    setStudentSelectModalVis(false);
    // dispatch(setSidebarStatusModal(true));
    dispatch(hideLoader());
    setSelectedStudent("");
  };

  const handleDeletePracticeExam = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    const response = await testService.DeletePracticeExam(selectedId);
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const onFileInputChangeHandler = async (event) => {
    const response = await handleExcelToJsonParser(event);
    const parsedData = await parser(response);
    setParsedData(parsedData);
  };

  const onFileInputOptic = (event) => {
    setOpticFile(event.target.files[0]);
  };

  const handleShowAnalyze = async () => {
    let selectedPracticeExamId;
    setLoaderAnswers(true);
    if (selectedPracticeExam === null) {
      const practiceExamResponse = await testService.CreatePracticeExam({
        name: formPracticeExamName,
        classroomId: Number(selectedClassRoom),
        practiceExamTypeId: selectedExamType,
        felsefe: optionalLesson,
        publisher: formPracticeExamPublisher,
        practiceExamLearningOutcome: parsedData,
      });
      const lessonsFromPracticeExamRewsponse =
        await testService.GetPracticeExamById(practiceExamResponse.id);

      const lessons = lessonsFromPracticeExamRewsponse.learningOutcomes.reduce(
        (acc, obj) => {
          if (!acc.includes(obj.lesson)) {
            acc = [...acc, obj.lesson];
          }
          return acc;
        },
        []
      );
      setPracticeExamLessons(lessons);
      setSelectedPracticeExam(practiceExamResponse.id);
      selectedPracticeExamId = practiceExamResponse.id;
    } else {
      selectedPracticeExamId = selectedPracticeExam;
    }
    const formData = new FormData();
    formData.append("", opticFile);
    formData.append("opticId", formOptic.toString());
    formData.append("practiceExamId", `${selectedPracticeExamId}`);
    formData.append("practiceExamType", `${selectedExamType}`);

    // formData.append("opticId", "2166");
    // formData.append("practiceExamId", "1044");

    const response = await opticService.InsertStudentAnswer(formData);
    if (response === null) {
      setStudentAnswer([]);
    } else {
      setStudentAnswer(response);
    }
    setSelectedStudent("");
    setLoaderAnswers(false);
  };

  const getPracticeExamTypes = async () => {
    const response = await opticService.GetPracticeExamTypes();
    setPracticeExamTypes(response);
  };

  useEffect(() => {
    getClassroomList();
    getPracticeExamList();
    getOptics();
    getStudentList();
    getPracticeExamTypes();
  }, [trig]);

  const datatableModel = (data: Array<OpticStudentAnswerOutputModel>) => {
    let headers = [];
    if (!!data.length) {
      headers = (
        [
          ...studentAnswer[0]?.opticParameters,
          ...studentAnswer[0]?.lessonParameters,
        ] || []
      ).map((params, index) => params.key);
    }
    return {
      header: [...headers],
      list: data.map((student, index) => ({
        id: index,
        row: [
          ...[...student.opticParameters, ...student.lessonParameters].map(
            (item, index) => {
              if (index === 0)
                return `(${student?.isFind ? "✓" : "X"}) ${item.value}`;
              else return item.value;
            }
          ),
        ],
      })),
    };
  };

  useEffect(() => {
    setDataTable(datatableModel(studentAnswer));
  }, [studentAnswer]);

  const selectStudent = (id: number) => {
    setSelectedStudentIndex(id);
    setStudentSelectModalVis(true);
  };

  const handleSelectStudent = () => {
    let tempArr = studentAnswer;
    tempArr[selectedStudentIndex].opticParameters.map((item, index) => {
      if (item.key === "TC Numarası") {
        tempArr[selectedStudentIndex].opticParameters[index] = {
          keyId: item.keyId,
          key: item.key,
          value: selectedStudent,
        };
      }
    });
    tempArr[selectedStudentIndex].isFind = true;
    tempArr[selectedStudentIndex].tcNo = selectedStudent;
    setStudentAnswer(tempArr);
    setDataTable(datatableModel(tempArr));
  };

  const getPracticeExamResult = async (id: number) => {
    dispatch(showLoader());
    const response = await testService.GetPracticeExamResultById(id);
    setPracticeExamResult(response);
    setPracticeExamResModalVis(true);
    dispatch(hideLoader());
  };

  const resultDatatableModel: DatatableDataModel = {
    header: ["Ders", "Kazanım", "Doğru Cevap", "Öğrenci Cevabı"],
    list: practiceExamResult
      .find((item) => item.id === selectedStudent)
      ?.practiceExamQuestionResult.map((s, index) => ({
        id: index,
        row: [
          s?.practiceExamLearningOutcomes.lesson.lessonName,
          s?.practiceExamLearningOutcomes.learningOutcome,
          s?.practiceExamLearningOutcomes.answer,
          s?.studentAnswer === null ? "-" : s?.studentAnswer,
        ],
      })),
  };

  const customDataTable = [
    {
      accessorKey: "name",
      header: "Deneme Adı",
    },
    {
      accessorKey: "publisher",
      header: "Yayın Evi",
    },
    {
      accessorKey: "classroom.name",
      header: "Sınıf",
    },
  ];

  const data: PracticeExamOutputModel[] = practiceExamList;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-start align-items-center  ">
              <button
                onClick={() => {
                  setAddNewModalVis(true);
                  setFormPracticeExamName("");
                  setFormPracticeExamPublisher("");
                  // dispatch(setSidebarStatusModal(false));
                }}
                type="button"
                className="btn btn-primary ml-1 btn-l"
              >
                Deneme Ekle
              </button>
            </div>
          </div>
          <AdvancedTable
            data={data}
            dataTable={customDataTable}
            deleteButton={(row) => {
              setSelectedId(row.id);
              setConsentModalVisibility(true);
            }}
            detailsButton={(row) => getPracticeExamResult(row.id)}
            detailsButtonTooltip="Sonuçlar"
          />
        </div>
      </div>

      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "1250px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Deneme Ekle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormPracticeExamName(e.target.value)
                      }
                      value={formPracticeExamName}
                      className={
                        "form-control" +
                        (formPracticeExamName !== ""
                          ? " is-valid"
                          : " is-invalid")
                      }
                      placeholder="Deneme Adı"
                      required
                    />
                  </div>
                  <div className="col-12 p-2">
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormPracticeExamPublisher(e.target.value)
                      }
                      value={formPracticeExamPublisher}
                      className={
                        "form-control" +
                        (formPracticeExamPublisher !== ""
                          ? " is-valid"
                          : " is-invalid")
                      }
                      placeholder="Yayın Evi"
                      required
                    />
                  </div>
                  <div className="col-12 p-2">
                    <label>Sınıf: </label>
                    <select
                      className={
                        "form-control" +
                        (selectedClassRoom !== "" ? " is-valid" : " is-invalid")
                      }
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSelecetedClassRoom(e.target.value);
                      }}
                    >
                      <option value={""} selected>
                        Sınıf
                      </option>
                      {classroomList.map((item) => (
                        <>
                          <option value={item.id}>{item.name}</option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 p-2">
                    <label>Sınav Türü: </label>
                    <select
                      className={
                        "form-control" +
                        (selectedExamType !== "" ? " is-valid" : " is-invalid")
                      }
                      aria-label="Default select example"
                      onChange={(e) => {
                        setSelectedExamType(e.target.value);
                      }}
                    >
                      <option value={""} selected>
                        Sınav Türü
                      </option>
                      {practiceExamTypes.map((item) => (
                        <>
                          <option value={item.id}>{item.name}</option>
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 p-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={optionalLesson}
                        onChange={() =>
                          setOptionalLesson((optionalLesson) => !optionalLesson)
                        }
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Denemede seçmeli Felsefe dersi var mı ?
                      </label>
                    </div>
                  </div>
                  <div className="col-12 p-2">
                    <label>Optik: </label>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                        }),
                      }}
                      aria-label="Default select example"
                      onInputChange={(e) => {
                        setSearchOptic(e);
                      }}
                      options={filteredSelectOpticOptions}
                      value={selectOpticOptions.find(
                        (option) => option.value === formOptic
                      )}
                      onChange={(selectedOption) => {
                        setFormOptic(selectedOption.value);
                      }}
                      placeholder="Lütfen aradığınız optiğin en az ilk 3 harfini yazınız"
                      // isSearchable={false}
                      noOptionsMessage={() =>
                        searchOptic.length <= 3
                          ? "Lütfen aradığınız optiğin en az ilk 3 harfini yazınız "
                          : "Optik Bulunamadı !"
                      }
                    />
                  </div>
                  <div className="col-12 p-2">
                    <label>Deneme Dosyası:</label>
                    <input
                      onChange={onFileInputChangeHandler}
                      className="form-control"
                      type="file"
                      multiple={true}
                      accept="application/excel"
                    />
                  </div>
                  <div className="col-12 p-2">
                    <label>Optik Dosyası:</label>
                    <input
                      onChange={onFileInputOptic}
                      className="form-control"
                      type="file"
                      multiple={false}
                      accept="application/excel"
                    />
                  </div>

                  {!!studentAnswer.length ? (
                    <div className="col-12 p-2">
                      <label>Öğrenci Sonuçları:</label>
                      <StudentAnswerTable
                        editable={true}
                        insertable={false}
                        data={dataTable}
                        onEditClickCustomHandler={selectStudent}
                      />
                    </div>
                  ) : (
                    loaderAnswers && <ComponentLoader />
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                    setParsedData([]);
                    setStudentAnswer([]);
                    setOpticFile(null);
                    setFormOptic("");
                    // dispatch(setSidebarStatusModal(true));
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={handleShowAnalyze}
                  className="btn btn-info ml-1"
                  data-bs-dismiss="modal"
                  disabled={
                    opticFile === null ||
                    formPracticeExamPublisher === "" ||
                    formPracticeExamPublisher === "" ||
                    !parsedData.length ||
                    formOptic === "" ||
                    !parsedData.length
                  }
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Göster</span>
                </button>
                <button
                  type="button"
                  // onClick={createPracticeExam}
                  onClick={() => {
                    // setMatchLessonModalVis(true);
                    createPracticeExam();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={!studentAnswer.length}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {studentSelectModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Öğrenci Seç
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <label>Öğrenciler: </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                      }),
                    }}
                    aria-label="Default select example"
                    options={studentList.map((item) => ({
                      value: item.tcNo,
                      label: item.name,
                    }))}
                    value={studentList
                      .map((item) => ({
                        value: item.tcNo,
                        label: item.name,
                      }))
                      .find((option) => option.value === selectedStudent?.tcNo)}
                    onChange={(selectedOption) => {
                      setSelectedStudent(selectedOption.value);
                    }}
                    placeholder="Öğrenci Seçiniz"
                    isSearchable
                    noOptionsMessage={() => "Öğrenci Bulunamadı !"}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setStudentSelectModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    handleSelectStudent(e);
                    setStudentSelectModalVis(false);
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={!selectedStudent}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {matchLessonModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Ders Eşleştirme
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row d-flex justify-content-center ">
                  {(studentAnswer[0]?.lessonParameters || []).map(
                    (item, index) => (
                      <>
                        {index === 0 && (
                          <div className="row-12 p-3">
                            <div className="row ">
                              <div className="col-4 p-2">
                                <h5>Optikteki Ders:</h5>
                              </div>
                              <div className="col-6 p-2">
                                <h5>Sistemde Kayıtlı Ders:</h5>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row-12 p-3">
                          <div className="row ">
                            <div className="col-3 pt-2">
                              <h6>{item.key}:</h6>
                            </div>
                            <div className="col-6">
                              <Select
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                  }),
                                }}
                                aria-label="Default select example"
                                options={practiceExamLessons.map((item) => ({
                                  label: item,
                                  value: item,
                                }))}
                                value={practiceExamLessons
                                  .map((item) => ({
                                    label: item,
                                    value: item,
                                  }))
                                  .find(
                                    (option) =>
                                      option.value ===
                                      matchedLessons.find(
                                        (lesson) => lesson.oldKey === item.key
                                      )
                                  )}
                                onChange={(selectedOption) => {
                                  const matchedLessonIndex =
                                    matchedLessons.findIndex(
                                      (lesson) => lesson.oldKey === item.key
                                    );

                                  if (matchedLessonIndex !== -1) {
                                    const updatedMatchedLessons = [
                                      ...matchedLessons,
                                    ];
                                    updatedMatchedLessons[matchedLessonIndex] =
                                      {
                                        oldKey: item.key,
                                        newKey: selectedOption.value,
                                      };
                                    setMatchedLessons(updatedMatchedLessons);
                                  } else {
                                    setMatchedLessons((prevMatchedLessons) => [
                                      ...prevMatchedLessons,
                                      {
                                        oldKey: item.key,
                                        newKey: selectedOption.value,
                                      },
                                    ]);
                                  }
                                }}
                                placeholder="Lütfen Ders Seçin"
                                isSearchable
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setMatchLessonModalVis(false);
                    setMatchedLessons([]);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    createPracticeExam();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={
                    studentAnswer[0]?.lessonParameters.length !==
                    matchedLessons.length
                  }
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {practiceExamResModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Deneme Sonuçları - {practiceExamResult[0]?.practiceExam.name}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 pb-4">
                    <label>Öğrenciler: </label>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                        }),
                      }}
                      aria-label="Default select example"
                      options={practiceExamResult.map((item) => ({
                        value: item?.id,
                        label: item?.student?.name,
                      }))}
                      onChange={(selectedOption) => {
                        setSelectedStudent(selectedOption.value);
                      }}
                      placeholder="Öğrenci Seçiniz"
                      isSearchable
                      noOptionsMessage={() => "Öğrenci Bulunamadı !"}
                    />
                  </div>
                  {selectedStudent !== "" && (
                    <div
                      className="row"
                      style={{
                        maxHeight: "600px",
                        minHeight: "200px",
                        overflowY: "scroll",
                      }}
                    >
                      <div className="col-3">
                        Topam Soru Sayısı:
                        {(practiceExamResult || []).find(
                          (item) => item?.id === selectedStudent
                        )?.correctAnswerCount +
                          (practiceExamResult || []).find(
                            (item) => item?.id === selectedStudent
                          )?.unAnsweredCount +
                          (practiceExamResult || []).find(
                            (item) => item?.id === selectedStudent
                          )?.inCorrectAnswerCount}
                      </div>
                      <div className="col-3">
                        Doğru Sayısı:
                        {
                          (practiceExamResult || []).find(
                            (item) => item.id === selectedStudent
                          )?.correctAnswerCount
                        }
                      </div>
                      <div className="col-3">
                        Yanlış Sayısı:
                        {
                          (practiceExamResult || []).find(
                            (item) => item.id === selectedStudent
                          )?.inCorrectAnswerCount
                        }
                      </div>
                      <div className="col-3">
                        Boş Bırakılan Sayısı:
                        {
                          (practiceExamResult || []).find(
                            (item) => item.id === selectedStudent
                          )?.unAnsweredCount
                        }
                      </div>
                      <div className="col-12">
                        <Datatable
                          data={resultDatatableModel}
                          editable={false}
                          insertable={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedStudent("");
                    setPracticeExamResModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {studentReportModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "800px",
              height: "800px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Öğrenci Rapor
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <label>Öğrenciler: </label>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                      }),
                    }}
                    aria-label="Default select example"
                    options={studentList.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    value={studentList
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                      .find((option) => option.value === selectedStudent?.tcNo)}
                    onChange={(selectedOption) => {
                      getStudentPracticeExams(selectedOption.value);
                    }}
                    placeholder="Öğrenci Seçiniz"
                    isSearchable
                    noOptionsMessage={() => "Öğrenci Bulunamadı !"}
                  />
                </div>
                {reportLoader ? (
                  <div className="pt-5">
                    <ComponentLoader />
                  </div>
                ) : (
                  <div className="form-group form-check pt-3 row">
                    {studentPracticeExams.map((item, index) => {
                      return (
                        <>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`rememberme-${item.id}`}
                            checked={selectedExamForReport.find(
                              (report) => report === item.id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedExamForReport(
                                  (selectedExamForReport) => [
                                    ...selectedExamForReport,
                                    item.id,
                                  ]
                                );
                              } else {
                                setSelectedExamForReport(
                                  (selectedExamForReport) =>
                                    selectedExamForReport.filter(
                                      (id) => id !== item.id
                                    )
                                );
                              }
                            }}
                          />
                          <label
                            htmlFor={`rememberme-${item.id}`}
                            className="form-check-label form-check-sign-white"
                          >
                            {item.name}
                          </label>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setStudentReportModalVis(false);
                    setSelectedExamForReport([]);
                    setStudentPracticeExams([]);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={(e) => {
                    getStudentReport();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={selectedExamForReport.length == 0}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Rapor Oluştur</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={
          "Var olan deneme ve öğrenci sonuç verileri silinecektir emin misiniz? Silme işlemini onaylıyor musunuz?"
        }
        approveCallback={handleDeletePracticeExam}
        cancelCallback={() => {
          setConsentModalVisibility(false);
          setSelectedPracticeExam(null);
        }}
      />
    </>
  );
}
