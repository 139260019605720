import React, { useEffect, useLayoutEffect } from 'react'

import '../style/summernote.css';

declare interface IProps {
    textEditorId: string;
    editorHeight?: number;
    placeholder?: string;
    onChangeCallback?: (text: string) => void,
    innerHtml?: string
}

export default class RichTextEditor extends React.Component<IProps> {
    /**
     *
     */
    constructor(props: IProps) {
        super(props);

        this.onChangeCallback = this.onChangeCallback.bind(this);
    }
    onChangeCallback() {
        var code = ($("#" + this.props.textEditorId || "") as any).summernote("code");
        this.props.onChangeCallback(code);
    }

    componentDidMount() {
        ($('#' + this.props.textEditorId) as any).summernote({
            placeholder: this.props.placeholder || 'Yukarıda ki araçları kullanarak sorularınızı ekleyebilirsiniz',
            fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Merriweather'],
            tabsize: 2,
            lang: "tr-TR",
            backgroundColor: "red",
            codeviewFilter: false,
            codeviewIframeFilter: true,
            height: this.props.editorHeight || 400,
            codemirror: { // codemirror options
                theme: 'flatly'
            },
            callbacks: {
                onChange: this.onChangeCallback
            },
            toolbar: [
                ['style', ['style', 'fontname']],
                ['font', ['bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['help']]
            ]
        });
        if (this.props.innerHtml) ($('#' + this.props.textEditorId) as any).summernote("code", this.props.innerHtml);
    }

    componentWillUnmount() {
        ($('#' + this.props.textEditorId) as any).summernote("destroy");
    }

    render() {
        if (this.props.innerHtml != null || this.props.innerHtml != undefined) ($('#' + this.props.textEditorId) as any).summernote("code", this.props.innerHtml);

        return (<div id={this.props.textEditorId}></div>);
    }
}