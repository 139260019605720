import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import useStylesheet from "../../hooks/useStylesheet";
import useScript from "../../hooks/useScript";
import Loading from "../loader";

export default function UserLayout(props: { children: React.ReactNode }) {
  useStylesheet("/assets/css/bootstrap.css");
  useStylesheet("/assets/css/accordion.css");
  useStylesheet("/assets/vendors/iconly/bold.css");
  // useStylesheet("/assets/vendors/perfect-scrollbar/perfect-scrollbar.css");
  useStylesheet("/assets/vendors/bootstrap-icons/bootstrap-icons.css");
  useStylesheet("/assets/css/app.css");
  useStylesheet("/assets/vendors/choices.js/choices.min.css");
  useStylesheet("/assets/css/preloader.css");

  // useScript("/assets/vendors/perfect-scrollbar/perfect-scrollbar.min.js");
  useScript("/assets/js/bootstrap.bundle.min.js");
  useScript("/assets/vendors/apexcharts/apexcharts.js");
  useScript("/assets/js/pages/dashboard.js");
  // useScript("/assets/vendors/choices.js/choices.min.js");

  return <>{props.children}</>;
}
