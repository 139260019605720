import React, { useEffect } from "react";
import useScript from "../../hooks/useScript";
import useStylesheet from "../../hooks/useStylesheet";

export default function WebLayout(props: { children: React.ReactNode }) {
  useStylesheet("/assets/css/nucleo/css/nucleo.css");
  useStylesheet("/assets/css/prismjs-themes/prism.css");
  useStylesheet("/assets/css/front-style.css");
  useStylesheet("/assets/css/preloader.css");

  useScript("/assets/js/vendor/jquery/dist/jquery.min.js");
  useScript("/assets/js/vendor/popper.js/popper.min.js");
  useScript("/assets/js/vendor/bootstrap/js/bootstrap.min.js");
  useScript("/assets/js/vendor/headroom.js/dist/headroom.min.js");
  useScript("/assets/js/vendor/onscreen/dist/on-screen.umd.min.js");
  useScript("/assets/js/vendor/waypoints/jquery.waypoints.min.js");
  useScript("/assets/js/vendor/jarallax/jarallax.min.js");
  useScript("/assets/js/vendor/smooth-scroll/smooth-scroll.polyfills.min.js");

  return <>{props.children}</>;
}
