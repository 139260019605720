// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import useAuthenticate from "../../hooks/useAuthenticate";
import AccountService from "../../services/accountService";
import Header from "../header";
import UserPanelFooter from "../sharedComponents/userPanelFooter";
import TeacherSideBar from "../sideBars/teacherSideBar";
import ProfilePage from "../teacherPanelComponents/profilePage";
import TeacherClassroom from "../teacherPanelComponents/teacherClassroom";
import TeacherMainpage from "../teacherPanelComponents/teacherMainpage";
import TeacherAddPdf from "../teacherPanelComponents/TeacherQuestionsBank";
import TeacherAddPdfTestList from "../teacherPanelComponents/TeacherAddPdfTestList";
import CreateTestPage from "../adminPanelComponents/createTestPage";
import TeacherQuestionsBank from "../teacherPanelComponents/TeacherQuestionsBank";
import LessonsPage from "../adminPanelComponents/lessonsPage";
import TeacherLessonsPage from "../teacherPanelComponents/TeacherLessonsPage";
import ExamsPage from "../adminPanelComponents/examsPage";
import MeetsPage from "../adminPanelComponents/MeetsPage";
import StudentsReportPage from "../adminPanelComponents/StudentsReportPage";
import SubClassroomReportPage from "../adminPanelComponents/SubClassroomReportPage";
import SubjectBook from "../adminPanelComponents/SubjectBook";
import CheckBook from "../adminPanelComponents/CheckBook";
import SubjectBookDetailsPage from "../adminPanelComponents/SubjectBookDetailsPage";
import CheckBookDetailsPage from "../adminPanelComponents/CheckBookDetailsPage";

export default function TeacherPanel() {
  const accountService = new AccountService();
  let { path, url } = useRouteMatch();
  const isAuthenticated = useAuthenticate("teacher");

  const [accountDetails, setAccountDetails] =
    useState<TeacherAccountModel>(teacherIdentity);

  const getTeacherAccountDetails = async () => {
    const result = await accountService.getAccountInfo<TeacherAccountModel>();
    if (result) setAccountDetails(result);
  };

  useEffect(() => {
    getTeacherAccountDetails();
  }, []);

  return isAuthenticated === null ? null : isAuthenticated === false ? (
    <Redirect to="/login" />
  ) : (
    <TeacherIdentityContext.Provider value={accountDetails}>
      <TeacherSideBar />
      <Switch>
        <Route path={`${path}/profile`}>
          <Header name="Profilim" />
          <ProfilePage />
        </Route>
        <Route path={`${path}/classroom/:classroomId`}>
          <TeacherClassroom />
        </Route>
        <Route exact path={path}>
          <Header name="Öğretmen Gösterge Paneli" />
          <TeacherMainpage />
        </Route>
        <Route exact path={`${path}/addPdf`}>
          <Header name="PDF Ekle" />
          <TeacherQuestionsBank />
        </Route>
        <Route path={`${path}/addPdf/lesson/:lessonId`}>
          <Header name="PDF Ekle Ders" />
          <TeacherAddPdfTestList />
        </Route>
        <Route exact path={`${path}/questionbank`}>
          <Header name="Soru Bankası Havuzu" />
          <TeacherQuestionsBank />
        </Route>
        <Route exact path={`${path}/createtest`}>
          <Header name="Test Oluştur" />
          {/* <CreateTestPage /> */}
          <TeacherLessonsPage />
        </Route>
        <Route exact path={`${path}/exams`}>
          <Header name="Sınavlar" />
          {/* <CreateTestPage /> */}
          <ExamsPage />
        </Route>
        <Route path={`${path}/questionbank/lesson/:lessonId`}>
          <Header name="Test Oluştur" />
          <CreateTestPage />
        </Route>
        <Route path={`${path}/meets`}>
          <Header name="Görüşmeler" />
          <MeetsPage />
        </Route>
        <Route path={`${path}/report/student`}>
          <Header name="Öğrenci Rapor" />
          <StudentsReportPage />
        </Route>
        <Route path={`${path}/report/subClassroom`}>
          <Header name="Şube Rapor" />
          <SubClassroomReportPage />
        </Route>

        <Route path={`${path}/classBook/subjectBook/lesson/:lessonId`}>
          <SubjectBookDetailsPage />
        </Route>
        <Route path={`${path}/classBook/checkBook/lesson/:lessonId`}>
          <CheckBookDetailsPage />
        </Route>

        <Route path={`${path}/classBook/subjectBook`}>
          <Header name="Sınıf Defteri" />
          <SubjectBook />
        </Route>
        <Route path={`${path}/classBook/checkBook`}>
          <Header name="Yoklama Defteri" />
          <CheckBook />
        </Route>
      </Switch>
      <UserPanelFooter />
    </TeacherIdentityContext.Provider>
  );
}

const teacherIdentity: TeacherAccountModel = {
  majors: [],
  classrooms: [],
  name: "",
  surname: "",
  profilePhoto: null,
  students: [],
};

export const TeacherIdentityContext = React.createContext(teacherIdentity);
