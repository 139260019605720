import { read, utils } from "xlsx";

export const handleExcelToJsonParser = (e) => {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = read(event.target.result, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const excelData = utils.sheet_to_json(worksheet, { header: 1 });
      resolve(excelData);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
};

const defineDataArr = (data: any) => {
  let mappedData = [];
  data.map((item, index) => {
    if (!!item.length) {
      mappedData = [...mappedData, item];
    }
  });
  return mappedData;
};

export const parser = (data: any) => {
  const keys = ["lesson", "question", "questionB", "answer", "learningOutcome"];
  let mappedData = [];
  let mappedDataNested = {};
  const definedData = defineDataArr(data);
  definedData.slice(1).map((arr) => {
    mappedDataNested = {};
    arr.map((item, index) => {
      mappedDataNested = { ...mappedDataNested, ...{ [keys[index]]: item } };
    });
    mappedData = [...mappedData, mappedDataNested];
  });
  return mappedData;
};
