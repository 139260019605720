// @ts-nocheck
import React, { useEffect, useState } from "react";
import TestService from "../../services/testService";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  setSidebarStatusModal,
  showLoader,
} from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import "react-datepicker/dist/react-datepicker.css";
import { sendMessage } from "../../reducers/messengerSlice";
import OpticsService from "../../services/opticsService";
import StudentAnswerTable from "./StudentAnswerTable";
import { lineDetection } from "../../utils/FmtParser";
import Select from "react-select";
import LessonService from "../../services/lessonService";
import dayjs from "dayjs";
import AdvancedTable from "./AdvancedTable";

enum VisibleModalType {
  answerKey = "A",
  examResult = "B",
}

export default function OpticsPage() {
  const testService = new TestService();
  const opticService = new OpticsService();
  const lessonService = new LessonService();
  const dispatch = useDispatch();
  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [formOpticName, setFormOpticName] = useState<string>("");
  const [formPracticeExamPublisher, setFormPracticeExamPublisher] =
    useState<string>("");
  const [trig, setTrig] = useState<boolean>(false);

  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [parsedData, setParsedData] = useState<any>([]);
  const [formOptic, setFormOptic] = useState("");
  const [opticFile, setOpticFile] = useState([]);
  const [opticFileRaw, setOpticFileRaw] = useState(null);
  const [lessonGeneric, setLessonGeneric] = useState<
    Array<OpticsLessonGroupOutputModel>
  >([]);
  const [studentAnswer, setStudentAnswer] = useState<
    Array<OpticStudentAnswerOutputModel>
  >([]);
  const [dataTable, setDataTable] = useState<DatatableDataModel>([]);
  const [opticsGenericParameteres, setOpitcsGenericParameters] = useState<
    Array<OpticsGenericParametersModel>
  >([]);
  const [opticList, setOpticList] = useState<Array<OpticsOutputModel>>([]);
  const [matchedParameters, setMatchedParameters] = useState([]);
  const [selectedId, setSelectedId] = useState<number>(null);

  const handleDeleteOptic = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    const response = await opticService.DeleteOptic(selectedId);
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const onFileInputOptic = async (event) => {
    const parsedOpticFile = await lineDetection(event.target.files[0]);
    await setOpticFileRaw(event.target.files[0]);
    await setOpticFile(parsedOpticFile);
  };

  const datatableModel = (data: Array<OpticStudentAnswerOutputModel>) => {
    let headers = [];
    if (!!data.length) {
      headers = (
        [
          ...studentAnswer[0]?.opticParameters,
          ...studentAnswer[0]?.lessonParameters,
        ] || []
      ).map((params, index) => params.key);
    }
    return {
      header: [...headers],
      list: data.map((student, index) => ({
        id: index,
        row: [
          ...[...student.opticParameters, ...student.lessonParameters].map(
            (item, index) => {
              if (index === 0)
                return `(${student?.isFind ? "✓" : "X"}) ${item.value}`;
              else return item.value;
            }
          ),
        ],
      })),
    };
  };

  useEffect(() => {
    setDataTable(datatableModel(studentAnswer));
  }, [studentAnswer]);

  const getOpitcsGenericParameters = async () => {
    dispatch(showLoader());
    const response = await opticService.GetOpticsGenericParameters();
    setOpitcsGenericParameters(response);
    dispatch(hideLoader());
  };

  const getOpticsLessonsGroup = async () => {
    dispatch(showLoader());
    const response = await opticService.GetOpticsLessonGroup();
    setLessonGeneric(response);
    dispatch(hideLoader());
  };

  const getOpticList = async () => {
    const response = await opticService.GetOptics();
    setOpticList(response);
  };

  useEffect(() => {
    getOpitcsGenericParameters();
    // getLessonGeneric();
    getOpticsLessonsGroup();
    getOpticList();
  }, [trig]);

  const handleSave = async () => {
    dispatch(showLoader());
    let GenericParameters = [];
    let LessonParameters = [];
    let GenericSubParameters = "";
    let LessonSubParameters = "";

    matchedParameters.map((item, index) => {
      if (((item.new[0] || []).value || []).includes("op")) {
        item.new.map((itemNew, indexNew) => {
          if (indexNew == 0) {
            GenericSubParameters = GenericSubParameters.concat(
              "",
              itemNew?.value.split("-")[1].toString()
            );
          } else {
            GenericSubParameters = GenericSubParameters.concat(
              ",",
              itemNew?.value.split("-")[1].toString()
            );
          }

          if (indexNew === item.new.length - 1) {
            GenericParameters = [
              ...GenericParameters,
              {
                Id: GenericSubParameters,
                ParameterStartPosition: item?.old?.start,
                ParameterLenght: item?.old?.length,
              },
            ];
            GenericSubParameters = "";
          }
        });
      } else {
        item.new.map((itemNew, indexNew) => {
          if (indexNew == 0) {
            LessonSubParameters = LessonSubParameters.concat(
              "",
              itemNew?.value.split("-")[1].toString()
            );
          } else {
            LessonSubParameters = LessonSubParameters.concat(
              ",",
              itemNew?.value.split("-")[1].toString()
            );
          }

          if (indexNew === item.new.length - 1) {
            LessonParameters = [
              ...LessonParameters,
              {
                LessonGroupId: LessonSubParameters,
                LessonGroupName: "",
                ParameterStartPosition: item?.old?.start,
                ParameterLenght: item?.old?.length,
              },
            ];
            LessonSubParameters = "";
          }
        });
      }
    });
    const formData = new FormData();
    formData.append("", opticFileRaw);

    const jsonData = JSON.stringify({
      OpticName: formOpticName,
      GenericParameters,
      LessonParameters,
    });
    formData.append("jsonData", jsonData);

    const response = await opticService.AddNewOptic(formData);
    if (response === "Başarılı") {
      setAddNewModalVis(false);
      setParsedData([]);
      setStudentAnswer([]);
      setOpticFile(null);
      setFormOptic("");
      setMatchedParameters([]);
      dispatch(setSidebarStatusModal(true));
      setTrig((trig) => !trig);
    }

    dispatch(hideLoader());
  };

  const lessonGenericOptions = (lessonGeneric || []).map((item) => ({
    label: `${item.groupName} (${item.practiceExamTypes.name})`,
    value: `ls-${item.id}`,
  }));
  const opticGenericOptions = (opticsGenericParameteres || []).map((item) => ({
    label: item.parameterName,
    value: `op-${item.id}`,
  }));

  const groupedOptions = [...opticGenericOptions, ...lessonGenericOptions];

  const customDataTable = [
    {
      accessorKey: "opticName",
      header: "Optik Adı",
    },
    {
      accessorFn: (row) => `${dayjs(row.issueDate).format("DD.MM.YYYY")}`,
      accessorKey: "issueDate",
      header: "Eklenme Tarihi",
    },
  ];

  const data: OpticsOutputModel[] = opticList;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-12 d-flex justify-content-start align-items-start ">
              <button
                onClick={() => {
                  setAddNewModalVis(true);
                  setFormOpticName("");
                  setFormPracticeExamPublisher("");
                  dispatch(setSidebarStatusModal(false));
                  setMatchedParameters([]);
                }}
                type="button"
                className="btn btn-primary ml-1 btn-l"
              >
                Optik Ekle
              </button>
            </div>
          </div>
          <AdvancedTable
            data={data}
            dataTable={customDataTable}
            deleteButton={(row) => {
              setSelectedId(row.id);
              setConsentModalVisibility(true);
            }}
            // detailsButton={(row) => getPracticeExamResult(row.id)}
            // detailsButtonTooltip="Sonuçlar"
          />
          {/* <div className="table-responsive">
            <table className="table table-striped" id="table1">
              <thead>
                <tr>
                  <th>Optik Adı</th>
                  <th>Eklenme Tarihi</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {(opticList || []).map((l) => (
                  <tr>
                    <td>{l.opticName}</td>
                    <td>{dayjs(l.issueDate).format("DD-MM-YYYY")}</td>
                    <td className="buttons">
                      <button
                        onClick={() => {
                          setSelectedId(l.id);
                          setConsentModalVisibility(true);
                        }}
                        className="btn btn-danger btn-sm"
                      >
                        Sil
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </div>
      </div>

      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            style={{
              maxWidth: "1250px",
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Optik Ekle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormOpticName(e.target.value)
                      }
                      value={formOpticName}
                      className={
                        "form-control" +
                        (formOpticName !== "" ? " is-valid" : " is-invalid")
                      }
                      placeholder="Optik Adı"
                      required
                    />
                  </div>
                  <div className="col-12 p-2">
                    <label>Optik Dosyası ( .fmt, .bldm, vb. ):</label>
                    <input
                      onChange={onFileInputOptic}
                      className="form-control"
                      type="file"
                      multiple={false}
                      accept="application/excel"
                    />
                  </div>

                  {!!opticFile?.length && (
                    <div className="row d-flex justify-content-center ">
                      {(opticFile || []).map((item, index) => (
                        <>
                          <div className="row-12 p-3">
                            <div className="row ">
                              <div className="col-3 pt-2">
                                <h6>{item.parameter}:</h6>
                              </div>
                              <div className="col-6">
                                <Select
                                  isMulti
                                  // isOptionDisabled={}
                                  closeMenuOnSelect={false}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                    }),
                                  }}
                                  aria-label="Default select example"
                                  options={groupedOptions}
                                  // value={groupedOptions.find(
                                  //   (option) =>
                                  //     option.value ===
                                  //     matchedParameters.find(
                                  //       (lesson) => lesson.old === item
                                  //     )
                                  // )}
                                  onChange={(selectedOption) => {
                                    const matchedParamIndex =
                                      matchedParameters.findIndex(
                                        (lesson) => lesson.old === item
                                      );
                                    if (matchedParamIndex !== -1) {
                                      const updatedMatchedParams = [
                                        ...matchedParameters,
                                      ];
                                      updatedMatchedParams[matchedParamIndex] =
                                        {
                                          old: item,
                                          new: selectedOption,
                                        };
                                      setMatchedParameters(
                                        updatedMatchedParams
                                      );
                                    } else {
                                      setMatchedParameters(
                                        (prevMatchedLessons) => [
                                          ...prevMatchedLessons,
                                          {
                                            old: item,
                                            new: selectedOption,
                                          },
                                        ]
                                      );
                                    }
                                  }}
                                  placeholder="Lütfen Parametre Seçin"
                                  isSearchable
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                    //   <div className="modal-footer">
                    //     <button
                    //       type="button"
                    //       onClick={() => {
                    //         setMatchLessonModalVis(false);
                    //         setMatchedLessons([]);
                    //       }}
                    //       className="btn btn-default rounded pill"
                    //       data-bs-dismiss="modal"
                    //     >
                    //       <i className="fas fa-times-circle"></i>
                    //       <span className="d-inline p-2">Kapat</span>
                    //     </button>
                    //     {/*
                    //     <button
                    //       type="button"
                    //       onClick={(e) => {
                    //         createPracticeExam();
                    //       }}
                    //       className="btn btn-primary ml-1"
                    //       data-bs-dismiss="modal"
                    //       disabled={
                    //         studentAnswer[0]?.lessonParameters.length !==
                    //         matchedLessons.length
                    //       }
                    //     >
                    //       <i className="fas fa-check-circle"></i>
                    //       <span className="d-inline p-2">Kaydet</span>
                    //     </button> */}
                    //   </div>
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                    setParsedData([]);
                    setStudentAnswer([]);
                    setOpticFile(null);
                    setFormOptic("");
                    setMatchedParameters([]);
                    dispatch(setSidebarStatusModal(true));
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={formOpticName === "" || !opticFile?.length}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={handleDeleteOptic}
        cancelCallback={() => {
          setConsentModalVisibility(false);
        }}
      />
    </>
  );
}
