import BaseService from "./baseService";

declare global {
  interface LoginInputModel {
    username: string;
    password: string;
  }

  interface LoginOutputModel {
    access_token: string;
    redirect: string;
    userName: string;
  }
}

const baseService = new BaseService();

export default class AuthorizationService extends BaseService {
  constructor() {
    super("");
  }

  async Login(loginModel: LoginInputModel): Promise<LoginOutputModel> {
    let response = await fetch(this.tokenEndpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic Z3VtdXNlZ2l0aW13ZWJhcHA6MDNhMmE5MjgtZDMwZi00MjU2LThkNTktYmNmNTU3N2Y3YTYx",
      },
      body: `grant_type=password&username=${loginModel.username}&password=${loginModel.password}`,
    });

    if (response.status != 200) {
      return null;
    }
    var result = await response.json();
    console.warn(result);
    return <LoginOutputModel>result;
  }

  async Logout() {
    this.GetRefreshTokenIfNotExist(true);
    let response = await fetch(`${baseService.endPoints().baseUrl}logout`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
    response.status == 200 && (window.location.href = "/login");
  }

  ResetPassword(email: string) {
    return this.Post<string, boolean>(
      email,
      "/user/resetpassword",
      false,
      false
    );
  }
}
