import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useScript from "../../hooks/useScript";
import {
  hideLoader,
  setSidebarStatus,
  showLoader,
} from "../../reducers/commonSlice";
import AccountService from "../../services/accountService";
import AuthorizationService from "../../services/authorizationService";
import RoleService from "../../services/roleService";
import { RootState } from "../../store";
import { StudentIdentityContext } from "../panels/studentPanel";

export default function StudentSideBar() {
  const roleService = new RoleService();
  const accountService = new AccountService();

  const sidebarStatus = useSelector(
    (state: RootState) => state.commons.sidebarStatus
  );
  const studentContext = useContext(StudentIdentityContext);
  let history = useHistory();
  const dispatch = useDispatch();
  const activeTab = history.location.pathname
    .split("/")
    .pop()
    .toLocaleLowerCase();
  const activeTabArray = history.location.pathname.split("/");

  const [isAdminUser, setIsAdminUser] = useState(false);

  const logout = async () => {
    let authorizationService = new AuthorizationService();
    dispatch(showLoader());
    await authorizationService.Logout();
  };

  const getUserRoles = async () => {
    const result = await roleService.GetUserRoles();
    setIsAdminUser(result.includes("admin") || result.includes("manager"));
  };

  const exitHostAccount = () => {
    dispatch(showLoader());
    accountService.exitHostAccount("/students");
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    dispatch(setSidebarStatus(false));
  }, [history.location]);

  useScript("/assets/js/main.js");
  return (
    <div id="sidebar">
      <div
        className="sidebar-wrapper"
        style={{ left: sidebarStatus ? 0 : null }}
      >
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <Link to="/student">
                <img
                  src="/logo.png"
                  width="100%"
                  height="100%"
                  alt="Logo"
                  srcSet=""
                />
              </Link>
            </div>
            <div className="toggler">
              <a
                onClick={() => dispatch(setSidebarStatus(false))}
                className="sidebar-hide d-xl-none d-block"
              >
                <i className="bi bi-x bi-middle"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-title">Menuler</li>

            <li
              className={
                "sidebar-item " + (activeTab == "student" ? "active" : "")
              }
            >
              <Link to="/student" className="sidebar-link">
                <i className="bi bi-grid-fill"></i>
                <span>Gösterge Paneli</span>
              </Link>
            </li>

            <li
              className={
                "sidebar-item has-sub " +
                (history.location.pathname.includes("results") ||
                history.location.pathname.includes("failedQuestions")
                  ? "active"
                  : "")
              }
            >
              <a className="sidebar-link">
                <i className="bi bi-stack"></i>
                <span>Sınavlar</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " +
                    (activeTab == "examresults" ? "active" : "")
                  }
                >
                  <Link to="/student/examresults">Sınav Sonuçları</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTab == "uploadexamresults" ? "active" : "")
                  }
                >
                  <Link to="/student/uploadExamresults">Sınav Sonuç Yükle</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (history.location.pathname.includes("failedQuestions")
                      ? "active"
                      : "")
                  }
                >
                  <Link to="/student/failedQuestions/lessons">
                    Yapılamayan Sorular
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                "sidebar-item has-sub " +
                (activeTabArray.includes("classBook") ? "active" : "")
              }
            >
              <a className="sidebar-link">
                <i className="fa fa-book"></i>
                <span>Sınıf Defteri</span>
              </a>
              <ul className="submenu ">
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("subjectBook") ? "active" : "")
                  }
                >
                  <Link to="/student/classBook/subjectBook">Sınıf Defteri</Link>
                </li>
                <li
                  className={
                    "submenu-item " +
                    (activeTabArray.includes("checkBook") ? "active" : "")
                  }
                >
                  <Link to="/student/classBook/checkBook">Yoklama Defteri</Link>
                </li>
              </ul>
            </li>

            {/* Raporlama */}
            <li
              className={
                "sidebar-item " + (activeTab == "report" ? "active" : "")
              }
            >
              <Link to="/student/report" className="sidebar-link">
                <i className="fa fa-line-chart"></i>
                <span>Raporlarım</span>
              </Link>
            </li>

            <li
              className={
                "sidebar-item has-sub " +
                (history.location.pathname.includes("courses") ? "active" : "")
              }
            >
              <a className="sidebar-link">
                <i className="fas fa-book"></i>
                <span>Tamamlanan Konular</span>
              </a>
              <ul className="submenu ">
                {studentContext.lessons == null ||
                studentContext.lessons.length == 0 ? (
                  <li className="submenu-item ">
                    <div className="sidebar-link">
                      <span></span>
                    </div>
                  </li>
                ) : (
                  studentContext.lessons.map((l) => (
                    <li
                      className={
                        "submenu-item " +
                        (activeTab == "lessons" ? "active" : "")
                      }
                    >
                      <Link
                        to={`/student/lesson/${l.id}`}
                        className="sidebar-link"
                      >
                        <i className="bi bi-circle-fill"></i>
                        <span>{l.name}</span>
                      </Link>
                    </li>
                  ))
                )}
              </ul>
            </li>

            <li
              className={
                "sidebar-item " + (activeTab == "teachers" ? "active" : "")
              }
            >
              <Link to="/student/teachers" className="sidebar-link">
                <i className="bi bi-people-fill"></i>
                <span>Öğretmenlerim</span>
              </Link>
            </li>

            <li
              className={
                "sidebar-item " + (activeTab == "survey" ? "active" : "")
              }
            >
              <Link to="/student/survey" className="sidebar-link">
                <i className="bi bi-collection-fill"></i>
                <span>Veli Anketi</span>
              </Link>
            </li>

            <li className="sidebar-item">
              <a
                onClick={logout}
                style={{ cursor: "pointer" }}
                className="sidebar-link"
              >
                <i className="bi bi-plug-fill"></i>
                <span>Çıkış Yap</span>
              </a>
            </li>
            <li className="sidebar-title">&nbsp;</li>
            {isAdminUser ? (
              <li className={"sidebar-item"}>
                <a
                  onClick={exitHostAccount}
                  style={{ cursor: "pointer" }}
                  className="sidebar-link btn-facebook"
                >
                  <i className="fas fa-directions text-white"></i>
                  <span className="text-white">Admin Panel'e Dön</span>
                </a>
              </li>
            ) : null}
            <li className="sidebar-item">
              <a
                className="sidebar-link btn-facebook"
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-long-arrow-alt-left text-white"></i>
                <Link to="/">
                  <span className="text-white">Web'e Dön</span>
                </Link>
              </a>
            </li>
          </ul>
        </div>
        <button className="sidebar-toggler btn x">
          <i data-feather="x"></i>
        </button>
      </div>
    </div>
  );
}
