import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideClassroomCreateOrUpdateModal,
  hideLoader,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import ClassroomService from "../../services/classroomService";
import TeacherService from "../../services/teacherService";
import { RootState } from "../../store";

export default function ClassroomCreateOrUpdateModal(props: {
  callback: () => void;
  data?: ClassroomOutputModel;
}) {
  const dispatch = useDispatch();
  const showClassroomCreateOrUpdateModal = useSelector(
    (state: RootState) => state.commons.showClassroomCreateOrUpdateModal
  );

  useEffect(() => {
    if (props.data) setClassroomName(props.data.name);
    else setClassroomName("");
  }, [props.data]);

  const classroomService = new ClassroomService();

  const formSubmitHandler = async () => {
    dispatch(showLoader());

    let result = props?.data?.id
      ? await classroomService.updateClassroom({
          name: classroomName,
          id: props.data.id,
        })
      : await classroomService.AddClassroom({
          name: classroomName,
        });

    dispatch(hideClassroomCreateOrUpdateModal());
    dispatch(hideLoader());

    if (result != null) {
      props.callback();
    } else {
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "Ders ekleme işlemi başarısız",
        })
      );
    }
  };

  //form data
  const [classroomName, setClassroomName] = useState("");

  return (
    <div
      className="modal fade text-left show"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: showClassroomCreateOrUpdateModal ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {(props.data || {}).id ? "Sınıf Güncelle" : "Yeni Sınıf Ekle"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">
            <form action="#">
              <div className="row">
                <div className="form-group col-12">
                  <label>Ders Adı: </label>
                  <input
                    type="text"
                    placeholder="Ad"
                    value={classroomName}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setClassroomName(e.target.value)
                    }
                    className={
                      "form-control" +
                      (classroomName ? " is-valid" : " is-invalid")
                    }
                    required
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => dispatch(hideClassroomCreateOrUpdateModal())}
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              disabled={!classroomName}
              type="button"
              onClick={formSubmitHandler}
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
