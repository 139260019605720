import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSidebarStatus } from "../reducers/commonSlice";

export default function Header(props: { name: string }) {
  const dispatch = useDispatch();

  return (
    <>
      <header className="mb-3">
        <a
          onClick={() => dispatch(setSidebarStatus(true))}
          className="react-burger-btn d-block d-xl-none"
        >
          <i className="bi bi-justify fs-3"></i>
        </a>
      </header>
      <div className="page-heading">
        <h3>{props.name}</h3>
      </div>
    </>
  );
}
