import BaseService from "./baseService";

declare global {
  interface ManagerModel {
    id?: string;
    name: string;
    surname: string;
    email: string;
    telephone: string;
    city: string;
    profilePhoto: string;
    password: string;
    branch?: {
      id: number;
      name: string;
    };
  }
}

export default class ManagerService extends BaseService {
  /**
   *
   */
  constructor() {
    super();
  }

  GetManagerList() {
    return this.Get<Array<ManagerModel>>(null, "/manager");
  }

  CreateNewManager(managerDetails: ManagerModel) {
    if (managerDetails.id) return this.UpdateManagerDetails(managerDetails);
    return this.Post<ManagerModel, boolean>(managerDetails, "/manager");
  }

  UpdateManagerDetails(manager: ManagerModel) {
    return this.Put<string, ManagerModel, boolean>(
      manager.id,
      manager,
      "/manager"
    );
  }

  DeleteManager(managerId: string) {
    return this.Delete<string>(managerId, "/manager");
  }
}
