import BaseService from "./baseService";
import { Roles } from "./roleService";

declare global {
  interface AccountModel {
    name: string;
    surname: string;
    profilePhoto?: string;
  }

  interface StudentAccountModel extends AccountModel {
    gumusEgitimClassroom: string;
    lessons: Array<LessonOutputModel>;
    teachers: Array<TeacherOutputModel>;
    subclassroom: null | {
      branches: {
        id: 19;
        name: "Samsun";
      };
      id: number;
      name: string;
      classroom: {
        id: number;
        name: string;
      };
      teachers: {
        teacher: {
          majors: {
            id: number;
            lessonName: string;
            lessons: any[];
          }[];
          branch: {
            id: number;
            name: string;
          };
          id: string;
          name: string;
          surname: string;
          telephone: string;
          city: null | string;
          profilePhoto: null | string;
          university: null | string;
          aboutMe: null | string;
          reviewScore: null | string;
          specialty: null | string;
          email: null | string;
          commentCount: null | string;
          facebookUrl: null | string;
          twitterUrl: null | string;
          instagramUrl: null | string;
        };
      }[];
    };
  }

  interface TeacherAccountModel extends AccountModel {
    majors: Array<GenericLessonModel>;
    classrooms: Array<ClassroomOutputModel>;
    students: Array<StudentOutputModel>;
  }
}

export default class AccountService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/account");
  }

  async changeAccount(userId: string): Promise<boolean | void> {
    let response = await fetch(this.tokenEndpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic Z3VtdXNlZ2l0aW13ZWJhcHA6MDNhMmE5MjgtZDMwZi00MjU2LThkNTktYmNmNTU3N2Y3YTYx",
      },
      body: `grant_type=refresh_token&operation_type=visitor&user_id=${userId}&refresh_token=`,
    });
    if (response.status == 200) {
      const result = await response.json();
      window.location.href =
        window.location.origin + "/" + (result.redirect as string);
    } else return false;
  }

  async exitHostAccount(returnPage: string) {
    let response = await fetch(this.tokenEndpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic Z3VtdXNlZ2l0aW13ZWJhcHA6MDNhMmE5MjgtZDMwZi00MjU2LThkNTktYmNmNTU3N2Y3YTYx",
      },
      body: `grant_type=refresh_token&operation_type=exit_host&refresh_token=`,
    });
    if (response.status == 200) {
      const result = await response.json();
      var redirectPage = "";
      switch (result.redirect) {
        case Roles.Admin:
        case Roles.Manager:
          redirectPage = "/admin";
          break;
        case Roles.Student:
          redirectPage = "/student";
          break;
        case Roles.Teacher:
          redirectPage = "/teacher";
          break;
      }

      window.location.href = window.location.origin + redirectPage + returnPage;
    }
  }

  getAccountInfo<T>() {
    return this.Get<T>();
  }
}
