import React, { useContext, useEffect } from "react";
import EditableAccordion, { AccordionItemType } from "./editableAccordionItem";

export enum ActionButtonType {
  Blue = "primary",
  Yellow = "warning",
  Red = "danger",
  Skyblue = "info",
}

declare global {
  interface ProgressBarData {
    valueNow: number;
    valueMin: number;
    valueMax: number;
  }
}

export default function AccordionItem(props: {
  id: string;
  parentId: string;
  title: string;
  children: React.ReactNode;
  headerExtra?: React.ReactNode;
  progressBar?: ProgressBarData;
  collapsable?: boolean;
}) {
  return (
    <div className="accordion-item card card-md card-body shadow">
      <div
        className="accordion-header"
        style={{ display: "flex", alignItems: "center" }}
        id={`heading${props.id}`}
      >
        {props.headerExtra}
        {props.collapsable != false ? (
          <button
            className="accordion-button collapsed"
            style={{ border: "none" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${props.id}`}
            aria-expanded="true"
            aria-controls={`collapse${props.id}`}
          >
            {props.title}
          </button>
        ) : (
          <div>{props.title}</div>
        )}
      </div>

      {props.progressBar ? (
        <div className="progress progress-info mt-4 mb-4">
          <div
            className="progress-bar progress-bar-striped progress-label"
            role="progressbar"
            style={{ width: props.progressBar.valueNow + "%", height: "100%" }}
            aria-valuenow={props.progressBar.valueNow}
            aria-valuemin={props.progressBar.valueMin}
            aria-valuemax={props.progressBar.valueMax}
          ></div>
        </div>
      ) : null}

      <div
        className="accordion-collapse collapse"
        id={`collapse${props.id}`}
        aria-labelledby={`heading${props.id}`}
        data-bs-parent={`#accordion${props.parentId}`}
      >
        <div className="accordion-body pt-3">{props.children}</div>
      </div>
    </div>
  );
}
