import BaseService from "./baseService";

declare global {
  interface OpticsOutputModel {
    id: number;
    opticName: string;
    issueDate: string;
  }

  interface OpticDetailOutputModel {
    opticLessonParameters: {
      id: number;
      parameterStartPosition: number;
      parameterLenght: number;
      opticLesson: {
        lessonName: string;
        id: number;
      };
      opticLessonGroup: {
        id: number;
        groupName: string;
      };
    }[];
    opticParameters: Array<{
      id: number;
      parameter: any;
      parameterStartPosition: number;
      parameterLenght: number;
    }>;
    lessonParamaters: Array<{
      keyId: number;
      key: string;
      value: string;
    }>;

    id: number;
    opticName: string;
  }

  interface OpticStudentAnswerOutputModel {
    tcNo: string;
    studentName: string;
    opticParameters: Array<{
      keyId: number;
      key: string;
      value: string;
    }>;
    lessonParameters: Array<{
      keyId: number;
      key: string;
      value: string;
    }>;
    isFind: true;
  }

  interface OpticsGenericParametersModel {
    id: number;
    parameterName: string;
  }
  interface CreateOpitcInputModel {
    OpticName: string;
    GenericParameters: Array<{
      Id: number;
      ParameterName: string;
      ParameterStartPosition: number;
      ParameterLenght: number;
    }>;
    LessonParameters: Array<{
      LessonId: number;
      LessonName: string;
      ParameterStartPosition: number;
      ParameterLenght: number;
    }>;
  }

  interface OpticsLessonGroupOutputModel {
    id: number;
    practiceExamTypes: {
      id: number;
      name: string;
    };
    groupName: string;
    questionCount: number;
  }

  interface PracticeExamTypesOutputModel {
    id: number;
    name: string;
  }

  interface LessonByExamTypeOutputModel {
    lessonName: string;
    shortLessonName: string;
    id: number;
  }
}

export default class OpticsService extends BaseService {
  constructor() {
    super("/Optics");
  }

  async GetOptics() {
    return await this.Get<Array<OpticsOutputModel>>(null, "/Optics", false);
  }

  GetOpticById(id: number) {
    this.endPoint = "/Optics";
    return this.GetById<number, OpticDetailOutputModel>(id);
  }

  InsertStudentAnswer(data: FormData) {
    return this.Post<FormData, Array<OpticStudentAnswerOutputModel>>(
      data,
      "/Optics/insertStudentAnswer",
      true,
      true,
      {},
      false
    );
  }

  UploadStudentAnswer(data: any, id: number) {
    return this.Post<Array<OpticStudentAnswerOutputModel>, any>(
      data,
      `/Optics/uploadStudentAnswer?practiceExamId=${id}`
    );
  }

  GetOpticsGenericParameters() {
    this.endPoint = "/Optics/GenericParameters";
    return this.Get<Array<OpticsGenericParametersModel>>();
  }

  AddNewOptic(data: FormData) {
    return this.Post<FormData, Array<CreateOpitcInputModel>>(
      data,
      "/Optics/AddNewOptic",
      true,
      true,
      {},
      false
    );
  }

  async GetOpticsLessonGroup() {
    return await this.Get<Array<OpticsLessonGroupOutputModel>>(
      null,
      "/Optics/LessonGroups",
      false
    );
  }

  async GetPracticeExamTypes() {
    return await this.Get<Array<PracticeExamTypesOutputModel>>(
      null,
      "/Optics/ExamTypes",
      false
    );
  }

  DeleteOptic(id: number) {
    return this.Delete<number>(id, "/Optics");
  }

  GetLessonByExamType(id) {
    return this.GetById<number, Array<LessonByExamTypeOutputModel>>(
      id,
      "/Optics/LessonByExamType"
    );
  }
}
