import BaseService from "./baseService";

declare global {
  interface SubClassroomInputModel {
    name: string;
    classroomId?: number;
  }

  interface SubClassroomOutputModel {
    id: number;
    name: string;
    classroom: {
      id: number;
      name: string;
    };
  }

  interface StudentSubClassroomOutputModel {
    students: Array<{
      id: string;
      parentName: string;
      email: string;
      school: string;
      schoolClass: string;
      name: string;
      surname: string;
      telephone: string;
      city: any;
      profilePhoto: any;
      parentEmail: string;
      parentTel: string;
      isPassive: boolean;
      tcNo: any;
    }>;
    teachers: Array<{
      teacher: {
        majors: Array<{
          id: number;
          lessonName: string;
          lessons: any;
        }>;
        branch: {
          id: number;
          name: string;
        };
        id: string;
        name: string;
        surname: string;
        telephone: string;
        city: any;
        profilePhoto: any;
        university: any;
        aboutMe: any;
        reviewScore: any;
        specialty: any;
        email: string;
        commentCount: any;
        facebookUrl: any;
        twitterUrl: any;
        instagramUrl: any;
      };
    }>;
    id: number;
    name: string;
    classroom: {
      id: number;
      name: string;
    };
    branches: {
      id: number;
      name: string;
    };
  }
  // {
  //   students: {
  //     id: string;
  //     parentName: string;
  //     email: string;
  //     school: string;
  //     schoolClass: string;
  //     name: string;
  //     surname: string;
  //     telephone: string;
  //     city: string;
  //     profilePhoto: any;
  //     parentEmail: string;
  //     parentTel: string;
  //     isPassive: boolean;
  //   }[];
  //   teachers: {
  //     teacher: {
  //       id: string;
  //       name: string;
  //       surname: string;
  //       branch: any;
  //     };
  //   }[];
  //   id: number;
  //   name: string;
  //   classroom: {
  //     id: number;
  //     name: string;
  //   };
  // }

  interface TeacherSubClassroomDetailOutputModal {
    students: Array<{
      id: string;
      parentName: string;
      email: string;
      school: string;
      schoolClass: string;
      name: string;
      surname: string;
      telephone: string;
      city: any;
      profilePhoto: any;
      parentEmail: string;
      parentTel: string;
      isPassive: boolean;
      tcNo: any;
    }>;
    id: number;
    name: string;
    classroom: {
      id: number;
      name: string;
    };
  }
}

export default class SubClassroomService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/SubClassrooms");
  }

  async GetSubClassroomList(): Promise<Array<SubClassroomOutputModel>> {
    return await this.Get(null, "/SubClassrooms", false);
  }

  async GetSubClassroomById(id: number): Promise<any> {
    return await this.Get(null, `/SubClassrooms/${id}`);
  }

  async AddSubClassroom(
    classroom: SubClassroomInputModel
  ): Promise<SubClassroomOutputModel> {
    return this.Post(classroom);
  }

  async GetTeacherClassroomList(id: number) {
    this.endPoint = "/teacherclassroom";
    return this.GetById<number, SubClassroomOutputModel>(id);
  }

  DeleteSubClassroom(id: number) {
    return this.Delete<number>(null, `/SubClassrooms/${id}`);
  }

  updateSubClassroom(name: any, classId: number) {
    return this.Post(name, `/SubClassrooms/${classId}`);
  }

  async GetStudentSubClassroomList(
    id: number
  ): Promise<StudentSubClassroomOutputModel> {
    return await this.Get(null, `/SubClassrooms/student/${id}`);
  }

  SubClassRoomAddUser(data: string[], id: number) {
    return this.Post({ users: data }, `/SubClassrooms/AddUser/${id}`);
  }

  GetSubClassroomByTeacherId() {
    return this.Get<Array<SubClassroomOutputModel>>(
      null,
      "/SubClassrooms/ByTeacherId",
      false
    );
  }

  DeleteSubClassroomUser(data: string[], id: number) {
    return this.Post({ users: data }, `/SubClassrooms/DeleteUser/${id}`);
  }

  GetSubClassroomByTeacherIdBySubClassroomId(id: number) {
    return this.Get<Array<TeacherSubClassroomDetailOutputModal>>(
      null,
      `/SubClassrooms/ByTeacherId/${id}`,
      false
    );
  }

  GetSubClassroomForAdmin(data: { classroomId: number; branchId: number }) {
    return this.Post<
      { classroomId: number; branchId: number },
      Array<SubClassroomOutputModel>
    >(data, `/SubClassrooms/foradmin`);
  }
}
