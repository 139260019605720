import React, { useEffect, useRef, useState } from "react";
import ResultQuestions from "../sharedComponents/ResultQuestions";
import { useCountdown } from "../../hooks/useCountdown";
import AnswerKey from "./AnswerKey";

export default function PdfViewerStatic(props: {
  visible: boolean;
  pdf?: any;
  questionCount: any;
  onCloseClick: () => void;
  onSubmit: (answer) => void;
  time: TestStartTimeOutputModel;
  testId: number;
  // lastResult: TestResultModel[];
}) {
  const previewCanvasRef = useRef(null);
  const [answer, setAnswer] = useState<TestQuestionResultModel[]>([]);
  // const [lastAnswer, setLastAnswer] = useState<TestQuestionResultModel[]>([]);
  const { minutes, secondsLeft, start, timesUp } = useCountdown();

  useEffect(() => {
    setAnswer([]);
  }, [props.testId]);

  useEffect(() => {
    start(props.time.testEndDate);
  }, [props.time]);

  // useEffect(() => {
  //   if (props.lastResult) {
  //     setLastAnswer(
  //       props.lastResult[props.lastResult.length - 1].testQuestionResults
  //     );
  //   }
  // }, [props.lastResult]);
  return (
    <>
      {props.visible ? (
        <div
          className="doc-container container-fluid"
          style={{
            height: "100vh",
            overflowY: "scroll",
            position: "fixed",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.7)",
            left: 0,
            top: 0,
            zIndex: 9999999,
          }}
        >
          <div className="row">
            <div
              className="col-9"
              style={{ height: "10%", textAlign: "right" }}
            >
              <i
                onClick={props.onCloseClick}
                className="fas fa-times"
                style={{
                  color: "white",
                  fontSize: "2rem",
                  padding: "0.5rem",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row display">
                <div className="col-12">
                  {timesUp ? (
                    <h2 style={{ color: "white" }}>Süre Doldu !</h2>
                  ) : (
                    <h2 style={{ color: "white" }}>
                      Kalan Süre: {minutes < 10 ? "0" + minutes : minutes}:
                      {secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}
                    </h2>
                  )}
                </div>
              </div>
              <div className="row">
                <h4 style={{ color: "white" }}>Cevaplar</h4>
                {props.questionCount.map((item, index) => (
                  <div className="col-12 d-flex justify-content-center align-self-center bg-light p-3 ">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{index + 1}.</span>
                    </div>
                    <AnswerKey
                      index={index}
                      answer={answer}
                      setAnswer={setAnswer}
                      learningOutcome={false}
                      // lastAnswer={lastAnswer}
                      questionId={item.id}
                    />
                  </div>
                ))}
                <div className="col-12 d-flex justify-content-center pt-2">
                  <button
                    type="button"
                    onClick={() => props.onSubmit(answer)}
                    className="btn btn-primary ml-1 w-100"
                    data-bs-dismiss="modal"
                    disabled={timesUp}
                  >
                    <i className="fas fa-check-circle"></i>
                    <span className="d-inline p-2">Kaydet</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12 pt-2"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <iframe
                style={{
                  width: `80%`,
                  height: 1000,
                  justifyContent: "center",
                  display: "flex",
                }}
                srcDoc={props.pdf}
              ></iframe>
            </div>
          </div>
        </div>
      ) : null}
      <canvas
        ref={previewCanvasRef}
        style={{
          display: "none",
          width: Math.round(200),
          height: Math.round(200),
        }}
      />
    </>
  );
}
