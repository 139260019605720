import BaseService from "./baseService";

declare global {
    interface SurveyInputModel {
        surveyQuestions: Array<SurveyQuestionInputModel>
    }

    interface SurveyQuestionInputModel {
        question: string;
    }

    interface SurveyOutputModel {
        issueDate: string;
        id: number;
        surveyResults: Array<SurveyResultOuputModel>;
        surveyQuestions?: Array<SurveyQuestionOutputModel>;
    }

    interface SurveyQuestionOutputModel extends SurveyQuestionInputModel {
        id: number;
        surveyId: number;
    }

    interface SurveyResultOuputModel {
        fillDate: string;
        student: StudentOutputModel;
        surveyQuestionResults: Array<SurveyQuestionResultOutputModel>;
        teacher: TeacherOutputModel;
    }

    interface SurveyQuestionResultOutputModel {
        surveyQuestion: SurveyQuestionOutputModel;
        value: string;
        questionId?: number;
    }

    interface SurveyResultInputModel {
        answers: Array<SurveyQuestionResultInputModel>;
        surveyId: number;
        teacherId: string;
    }

    interface SurveyQuestionResultInputModel {
        value: string;
        questionId: number;
    }
}

export default class SurveyService extends BaseService {
    /**
     *
     */
    constructor() {
        super("/survey");
    }

    CreateSurvey(survey: SurveyInputModel) {
        return this.Post<SurveyInputModel, SurveyOutputModel>(survey);
    }

    GetSurveyList() {
        return this.Get<Array<SurveyOutputModel>>();
    }

    GetSurveyQuestions() {
        return this.Get<Array<SurveyQuestionOutputModel>>(null, "/surveyquestions");
    }

    SendSurveyAnswers(surveyResult: SurveyResultInputModel) {
        return this.Post<SurveyResultInputModel, boolean>(surveyResult, "/surveyquestions");
    }

    GetStudentLastSurveyResult() {
        return this.Get<Array<SurveyResultOuputModel>>(null, "/studentSurveyResult");
    }
}