// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import useAuthenticate from "../../hooks/useAuthenticate";
import AccountService from "../../services/accountService";
import RoleService, { Roles } from "../../services/roleService";
import { RootState } from "../../store";
import AdminMainPage from "../adminPanelComponents/adminMainPage";
import ClassroomPage from "../adminPanelComponents/classroomsPage";
import CreateTestPage from "../adminPanelComponents/createTestPage";
import EmailPage from "../adminPanelComponents/emailPage";
import ExamsPage from "../adminPanelComponents/examsPage";
import LessonListPage from "../adminPanelComponents/lessonListPage";
import LessonsPage from "../adminPanelComponents/lessonsPage";
import ManagersPage from "../adminPanelComponents/managersPage";
import PdfTestList from "../adminPanelComponents/pdfTestList";
import QuestionBankPage from "../adminPanelComponents/questionBankPage";
import StudentsPage from "../adminPanelComponents/studentsPage";
import SurveyPage from "../adminPanelComponents/surveyPage";
import SurveyResultPage from "../adminPanelComponents/surveyResultPage";
import TeachersPage from "../adminPanelComponents/teachersPage";
import Header from "../header";
import Loader from "../loader";
import Preloader from "../sharedComponents/preloader";
import UserPanelFooter from "../sharedComponents/userPanelFooter";
import AdminSideBar from "../sideBars/adminSideBar";
import SubClassroomPage from "../adminPanelComponents/SubClassroomPage";
import PracticeExamsPage from "../adminPanelComponents/PracticeExamsPage";
import BranchesListPage from "../adminPanelComponents/BranchesListPage";
import BranchePage from "../adminPanelComponents/BranchePage";
import OpticsPage from "../adminPanelComponents/OpticsPage";
import TasksPage from "../adminPanelComponents/TasksPage";
import MeetsPage from "../adminPanelComponents/MeetsPage";
import StudentsReportPage from "../adminPanelComponents/StudentsReportPage";
import SubClassroomReportPage from "../adminPanelComponents/SubClassroomReportPage";
import GuidancePage from "../adminPanelComponents/GuidancePage";
import SubLessonsPage from "../adminPanelComponents/SubLessonsPage";
import SubjectBook from "../adminPanelComponents/SubjectBook";
import CheckBook from "../adminPanelComponents/CheckBook";
import SubClassroomOutReportPage from "../adminPanelComponents/SubClassroomOutReportPage";

export default function AdminPanel() {
  const accountService = new AccountService();

  let { path, url } = useRouteMatch();
  const [accountDetails, setAccountDetails] =
    useState<AccountModel>(adminIdentity);
  const [roles, setRoles] = useState<Array<string>>(null);

  const isAuthenticated = useAuthenticate("admin,manager");

  const getAdminAccountInfo = async () => {
    const result = await accountService.getAccountInfo<AccountModel>();
    if (result) setAccountDetails(result);
  };
  const getUserRoles = async () => {
    const roleService = new RoleService();
    const result = await roleService.GetUserRoles();
    setRoles(result);
  };

  useEffect(() => {
    getAdminAccountInfo();
    getUserRoles();
  }, []);
  console.warn(path);
  return isAuthenticated === null ? null : isAuthenticated === false ? (
    <Redirect to="/login" />
  ) : (
    <AdminIdentityContext.Provider value={accountDetails}>
      <AdminSideBar />
      <Switch>
        <Route path={`${path}/exams`}>
          <Header name="Sınavlar" />
          <ExamsPage />
        </Route>
        <Route path={`${path}/report/student`}>
          <Header name="Öğrenci Raporları" />
          <StudentsReportPage />
        </Route>
        <Route path={`${path}/report/inClassrooms`}>
          <Header name="Şube İçi Rapor" />
          <SubClassroomReportPage />
        </Route>
        <Route path={`${path}/report/outClassrooms`}>
          <Header name="Şubeler Arası Rapor" />
          <SubClassroomOutReportPage />
        </Route>
        <Route path={`${path}/practiceExams`}>
          <Header name="Denemeler" />
          <PracticeExamsPage />
        </Route>
        <Route path={`${path}/optics`}>
          <Header name="Optikler" />
          {/* <PracticeExamsPage /> */}
          <OpticsPage />
        </Route>
        <Route path={`${path}/examresults`}>
          <Header name="Sınav Sonuçları" />
        </Route>
        <Route path={`${path}/survey`}>
          <Header name="Anket Oluştur" />
          <SurveyPage />
        </Route>
        <Route path={`${path}/surveyresults`}>
          <Header name="Anket Sonuçları" />
          <SurveyResultPage />
        </Route>
        <Route path={`${path}/lessons`}>
          <Header name="Bütün Dersler" />
          <LessonListPage />
        </Route>

        <Route path={`${path}/classlessons/lesson/:lessonId`}>
          <Header name="Sınıf Dersleri" />
          <SubLessonsPage />
        </Route>
        <Route path={`${path}/classlessons`}>
          <Header name="Sınıf Dersleri" />
          <LessonsPage />
        </Route>

        <Route path={`${path}/questionbank/lesson/:lessonId`}>
          <Header name="Test Oluştur" />
          <CreateTestPage />
        </Route>
        <Route path={`${path}/questionbank`}>
          <Header name="Soru Bankası" />
          <QuestionBankPage />
        </Route>
        <Route path={`${path}/createtest/lesson/:lessonId`}>
          <Header name="PDF Ekle" />
          <PdfTestList />
        </Route>
        <Route path={`${path}/createtest`}>
          <Header name="PDF Ekle" />
          <QuestionBankPage />
        </Route>
        <Route path={`${path}/students`}>
          <Header name="Öğrenciler" />
          <StudentsPage />
        </Route>
        <Route path={`${path}/teachers`}>
          <Header name="Öğretmenler" />
          <TeachersPage />
        </Route>
        <Route path={`${path}/classrooms`}>
          <ClassroomPage />
        </Route>
        <Route path={`${path}/guidance`}>
          <Header name="Rehberlik" />
          <GuidancePage />
        </Route>
        <Route path={`${path}/classroom/:classId/subClassroom/:subClassId`}>
          <SubClassroomPage />
        </Route>
        <Route path={`${path}/reports`}>
          <Header name="Raporlar" />
        </Route>
        <Route path={`${path}/email`}>
          <Header name="E-mail" />
          <EmailPage />
        </Route>
        <Route path={`${path}/tasks`}>
          <Header name="Görevler" />
          <TasksPage />
        </Route>
        <Route path={`${path}/meets`}>
          <Header name="Görüşmeler" />
          <MeetsPage />
        </Route>
        <Route path={`${path}/managers`}>
          <Header name="Yöneticiler" />
          <ManagersPage />
        </Route>
        <Route exact path={path}>
          <Header name="Yönetim Gösterge Paneli" />
          <AdminMainPage />
        </Route>

        <Route path={`${path}/classBook/subjectBook`}>
          <Header name="Sınıf Defteri" />
          <SubjectBook />
        </Route>
        <Route path={`${path}/classBook/checkBook`}>
          <Header name="Yoklama Defteri" />
          <CheckBook />
        </Route>

        {(roles || []).includes(Roles.Admin) ? (
          <>
            <Route path={`${path}/branches`}>
              <BranchesListPage />
            </Route>
            <Route path={`${path}/branche/:brancheId`}>
              <BranchePage />
            </Route>
            <Route path={`${path}/branchesClassrooms`}>
              <BranchesListPage />
            </Route>
            <Route path={`${path}/report/outbranchesClassrooms`}>
              <BranchesListPage />
            </Route>
            <Route path={`${path}/report/branchesClassrooms`}>
              <BranchesListPage />
            </Route>
          </>
        ) : (
          <Redirect to={path} />
        )}
      </Switch>
      <UserPanelFooter />
    </AdminIdentityContext.Provider>
  );
}

const adminIdentity: AccountModel = {
  name: "",
  surname: "",
  profilePhoto: "",
};

export const AdminIdentityContext = React.createContext(adminIdentity);
