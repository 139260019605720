import BaseService from "./baseService";
declare global {
  interface TeacherInputModel {
    name: string;
    surname: string;
    telephone: string;
    email: string;
    id: string;
    majors: Array<number>;
    profilePhoto?: string;
    password?: string;
  }

  interface TeacherOutputModel {
    name: string;
    surname: string;
    telephone: string;
    email: string;
    id: string;
    majors: Array<GenericLessonModel>;
    profilePhoto?: string;
    branch?: {
      id: number;
      name: string;
    };
  }

  interface TeacherProfileInputModel {
    profilePhoto: string;
    name: string;
    surname: string;
    city: string;
    university: string;
    aboutMe: string;
    specialty: string;
    facebookUrl?: string;
    twitterUrl?: string;
    instagramUrl?: string;
  }

  interface TeacherProfileOutputModel {
    id?: string;
    profilePhoto: string;
    name: string;
    surname: string;
    city: string;
    university: string;
    aboutMe: string;
    reviewScore: number;
    commentCount: number;
    specialty: string;
    facebookUrl?: string;
    twitterUrl?: string;
    instagramUrl?: string;
    majors?: Array<LessonOutputModel>;
    branch?: {
      id: number;
      name: string;
    };
  }

  interface TeacherReviewInputOutputModel {
    title: string;
    reviewText: string;
    teacherId: string;
    evaluationScore: number;
    reviewOwner: string;
  }

  interface TeacherStudentLessonProgressInputModel {
    lessonId: number;
    studentId: string;
    progressList: Array<TeacherProgressInputModel>;
  }

  interface TeacherProgressInputModel {
    progressId?: number;
    lessonId?: number;
    unitId?: number;
    topicId?: number;
  }
}

export default class TeacherService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/teacher");
  }

  async GetTeacherList(): Promise<Array<TeacherOutputModel>> {
    return await this.Get<Array<TeacherOutputModel>>();
  }

  async DeleteTeacherById(teacherId: string) {
    return await this.Delete(teacherId);
  }

  async AddOrUpdateTeacher(
    teacherInfo: TeacherInputModel
  ): Promise<TeacherOutputModel> {
    if (teacherInfo.id) return await this.Put(teacherInfo.id, teacherInfo);
    return await this.Post(teacherInfo);
  }

  async EditTeacherInfo(
    updatedTeacher: TeacherInputModel
  ): Promise<TeacherOutputModel> {
    return await this.Put(updatedTeacher.id, updatedTeacher);
  }

  GetTeacherProfile() {
    this.endPoint = "/teacherProfile";
    return this.Get<TeacherProfileOutputModel>();
  }

  GetTeacherProfileById(id: string) {
    this.endPoint = "/teacherProfile";
    return this.GetById<string, TeacherProfileOutputModel>(
      id,
      "/teacherProfile",
      false
    );
  }

  GetTeacherProfileList() {
    return this.Get<Array<TeacherProfileOutputModel>>(null, "/teacher", false);
  }

  UpdateTeacherProfile(teacher: TeacherProfileInputModel) {
    this.endPoint = "/teacherProfile";
    return this.Put<
      string,
      TeacherProfileInputModel,
      TeacherProfileOutputModel
    >("0", teacher);
  }

  AddTeacherReview(review: TeacherReviewInputOutputModel) {
    return this.Post<
      TeacherReviewInputOutputModel,
      TeacherReviewInputOutputModel
    >(review, "/teacherreview");
  }

  ListTeacherReviews(id: string) {
    return this.GetById<string, Array<TeacherReviewInputOutputModel>>(
      id,
      "/teacherreview",
      false
    );
  }

  RemoveTeacherReview() {
    this.endPoint = "/teacherReview";
  }

  UpdateTeacherReview() {
    this.endPoint = "/teacherReview";
  }

  GetTeacherStudentLessonProgress(studentId: string, lessonId: number) {
    return this.Get<LessonOutputModel>(
      { studentId, lessonId: lessonId.toString() },
      "/teacherprogress"
    );
  }

  UpdateTeacherStudentLessonProgress(
    model: TeacherStudentLessonProgressInputModel
  ) {
    return this.Post<TeacherStudentLessonProgressInputModel, boolean>(
      model,
      "/teacherprogress"
    );
  }

  GetBestRatedTeacherReviews() {
    return this.Get<Array<TeacherReviewInputOutputModel>>(
      null,
      "/teacherreview",
      false
    );
  }

  GetSubClassroomProgress(subClassroomId: number, lessonId: number) {
    return this.Get<any>(
      null,
      `/teacherprogress?&subClassroomId=${subClassroomId}&lessonId=${lessonId}`,
      false
    );
  }

  UpdateSubClassroomProgress(data: any) {
    return this.Post<any, any>(data, "/teacherprogress/postSubClassroom");
  }

  StudentProgress(lessonId: number) {
    return this.Get<LessonOutputModel>(
      null,
      `/teacherprogress?lessonId=${lessonId}`
    );
  }
}
