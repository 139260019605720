import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLessonCreateOrUpdateModal,
  hideLoader,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import LessonService from "../../services/lessonService";
import { RootState } from "../../store";

export default function LessonCreateOrUpdateModal(props: {
  callback: () => void;
}) {
  const dispatch = useDispatch();
  const showLessonCreateOrUpdateModal = useSelector(
    (state: RootState) => state.commons.showLessonCreateOrUpdateModal
  );

  const lessonService = new LessonService();

  const formSubmitHandler = async () => {
    dispatch(showLoader());
    let result = await lessonService.CreateGenericLesson({
      lessonName: lessonName,
    });

    dispatch(hideLessonCreateOrUpdateModal());
    dispatch(hideLoader());

    if (result != null) {
      props.callback();
    } else {
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "Ders ekleme işlemi başarısız",
        })
      );
    }
  };

  //form data
  let [lessonName, setLessonName] = useState("");

  return (
    <div
      className="modal fade text-left show"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: showLessonCreateOrUpdateModal ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {"Ders Formu"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">
            <form action="#">
              <div className="row">
                <div className="form-group col-12">
                  <label>Ders Adı: </label>
                  <input
                    type="text"
                    placeholder="Ad"
                    value={lessonName}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLessonName(e.target.value)
                    }
                    className={
                      "form-control" +
                      (lessonName ? " is-valid" : " is-invalid")
                    }
                    required
                  />
                </div>
                {/* <div className="form-group col-12">
                                    <label>Ders Simgesi: </label>
                                    <input type="file" placeholder="Soyad" className="form-control" required />
                                </div> */}
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => dispatch(hideLessonCreateOrUpdateModal())}
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              type="button"
              disabled={!lessonName}
              onClick={formSubmitHandler}
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
