import { createSlice, PayloadAction } from "@reduxjs/toolkit";
declare global {
  interface AuthenticationContext {
    isAuthenticated: boolean;
    userName: string;
  }

  interface CommonData {
    showLoader: boolean;
    showTeacherCreateOrUpdateModal: boolean;
    showLessonCreateOrUpdateModal: boolean;
    showStudentCreateOrUpdateModal: boolean;
    accessToken: string;
    showClassroomCreateOrUpdateModal: boolean;
    showSubClassroomCreateOrUpdateModal: boolean;
    showQuestionCreateOrUpdateModal: boolean;
    authenticationContext: AuthenticationContext;
    sidebarStatus: boolean;
  }
}

const initialState: CommonData = {
  showLoader: false,
  showTeacherCreateOrUpdateModal: false,
  showLessonCreateOrUpdateModal: false,
  showStudentCreateOrUpdateModal: false,
  showClassroomCreateOrUpdateModal: false,
  showSubClassroomCreateOrUpdateModal: false,
  showQuestionCreateOrUpdateModal: false,
  accessToken: "",
  authenticationContext: null,
  sidebarStatus: false,
};

export const commonSlice = createSlice({
  name: "commons",
  initialState: initialState,
  reducers: {
    showLoader: (state) => {
      state.showLoader = true;

      return state;
    },
    hideLoader: (state) => {
      state.showLoader = false;

      return state;
    },
    showTeacherCreateOrUpdateModal: (state) => {
      state.showTeacherCreateOrUpdateModal = true;
    },
    hideTeacherCreateOrUpdateModal: (state) => {
      state.showTeacherCreateOrUpdateModal = false;
    },
    showLessonCreateOrUpdateModal: (state) => {
      state.showLessonCreateOrUpdateModal = true;
    },
    hideLessonCreateOrUpdateModal: (state) => {
      state.showLessonCreateOrUpdateModal = false;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    showStudentCreateOrUpdateModal: (state) => {
      state.showStudentCreateOrUpdateModal = true;
    },
    hideStudentCreateOrUpdateModal: (state) => {
      state.showStudentCreateOrUpdateModal = false;
    },
    showClassroomCreateOrUpdateModal: (state) => {
      state.showClassroomCreateOrUpdateModal = true;
    },
    hideClassroomCreateOrUpdateModal: (state) => {
      state.showClassroomCreateOrUpdateModal = false;
    },
    showQuestionCreateOrUpdateModal: (state) => {
      state.showQuestionCreateOrUpdateModal = true;
    },
    hideQuestionCreateOrUpdateModal: (state) => {
      state.showQuestionCreateOrUpdateModal = false;
    },
    setAuthenticationContext: (
      state,
      action: PayloadAction<AuthenticationContext>
    ) => {
      state.authenticationContext = action.payload;
    },
    setSidebarStatus: (state, action: PayloadAction<boolean>) => {
      if (window.innerWidth < 1200) state.sidebarStatus = action.payload;
      else state.sidebarStatus = true;
    },
    setSidebarStatusModal: (state, action: PayloadAction<boolean>) => {
      state.sidebarStatus = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showLoader,
  hideLoader,
  showTeacherCreateOrUpdateModal,
  hideTeacherCreateOrUpdateModal,
  showLessonCreateOrUpdateModal,
  hideLessonCreateOrUpdateModal,
  setAccessToken,
  showStudentCreateOrUpdateModal,
  hideStudentCreateOrUpdateModal,
  showClassroomCreateOrUpdateModal,
  hideClassroomCreateOrUpdateModal,
  hideQuestionCreateOrUpdateModal,
  showQuestionCreateOrUpdateModal,
  setAuthenticationContext,
  setSidebarStatus,
  setSidebarStatusModal,
} = commonSlice.actions;

export default commonSlice.reducer;
