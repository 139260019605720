import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../reducers/messengerSlice";
import ConsentModal from "../consentModal";
import AccordionItem, { ActionButtonType } from "./accordionItem";

export enum AccordionItemType {
  AddNew = "add_new",
  Edit = "edit",
}

export default function EditableAccordionItem(props: {
  children?: React.ReactNode;
  id?: string;
  parentId?: string;
  //
  title?: string;
  //
  editableInputLabel?: string;
  editableInputPlaceholder?: string;
  //
  onDelete?: () => void;
  onUpdate?: (name: string) => Promise<boolean>;

  isSelectBox?: boolean;
  isEditable?: boolean;
  selectBoxList?: Array<{ text: string; value: string }>;
}) {
  const [title, setTitle] = useState(props.title);
  const [editableTitle, seteditableTitle] = useState(props.title);
  const [isDeleted, setIsDeleted] = useState(false);
  const [IsInEditMode, setIsInEditMode] = useState(false);
  const [consentModalVisibility, setConsentModalVisibility] = useState(false);

  useEffect(() => {
    setTitle(props.title);
    seteditableTitle(props.title);
  }, [props.title]);

  const editSubmitHandler = async () => {
    let result = await props.onUpdate(editableTitle);
    if (!result) return;
    setIsInEditMode(false);
    setTitle(editableTitle);
  };

  const deleteSubmitHandler = async () => {
    setConsentModalVisibility(true);
  };

  const exitEditMode = () => {
    seteditableTitle(title);
    setIsInEditMode(false);
  };

  const consentModalApproveHandler = async () => {
    setConsentModalVisibility(false);
    let result = await props.onDelete();
  };

  const consentModalCancelHandler = () => setConsentModalVisibility(false);

  return isDeleted ? (
    <div />
  ) : (
    <>
      {IsInEditMode ? (
        <div className="accordion-item card card-md card-body shadow align-items-center">
          <div className="form-group col-12">
            <label>{props.editableInputLabel}: </label>
            {props.isSelectBox ? (
              <select
                className="form-select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  seteditableTitle(e.target.value)
                }
                value={editableTitle}
              >
                <option selected value="" disabled>
                  Seçiniz
                </option>
                {(props.selectBoxList || []).map((sbl) => (
                  <option value={sbl.value}>{sbl.text}</option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                placeholder={props.editableInputLabel + " Giriniz"}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  seteditableTitle(e.target.value)
                }
                value={editableTitle}
                className={
                  "form-control" + (editableTitle ? " is-valid" : " is-invalid")
                }
                required
              />
            )}
            <div className="mt-1 buttons">
              <button
                className="btn btn-danger rounded-pill"
                onClick={exitEditMode}
              >
                İptal
              </button>
              <button
                className="btn btn-success rounded-pill"
                disabled={!editableTitle}
                onClick={editSubmitHandler}
              >
                Kaydet
              </button>
            </div>
          </div>
        </div>
      ) : (
        <AccordionItem
          id={props.id}
          parentId={props.parentId}
          title={
            props.isSelectBox
              ? props.selectBoxList.find((src) => src.value == title)?.text
              : title
          }
          headerExtra={
            <>
              {props.isEditable === undefined && (
                <>
                  <i
                    className="fas fa-times"
                    title="Sil"
                    onClick={deleteSubmitHandler}
                    style={deleteIconStyle}
                  ></i>
                  <i
                    className="fas fa-edit"
                    title="Değiştir"
                    onClick={() => setIsInEditMode(true)}
                    style={editIconStyle}
                  ></i>
                </>
              )}
            </>
          }
        >
          {props.children}
        </AccordionItem>
      )}
      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={consentModalApproveHandler}
        cancelCallback={consentModalCancelHandler}
      />
    </>
  );
}

const deleteIconStyle: React.CSSProperties = {
  color: "red",
  marginLeft: ".5rem",
};

const editIconStyle: React.CSSProperties = {
  color: "yello",
  marginLeft: ".5rem",
};
