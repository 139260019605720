import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(): null {
    const { pathname } = useLocation();

    useEffect(() => {
        // window.scrollTo(0, 0);
        document.querySelector("html").scrollTop = 0;
        document.querySelector("body").scrollTop = 0;
    }, [pathname]);

    return null;
}