// @ts-nocheck
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, useRouteMatch } from "react-router-dom";
// layouts
import Header from "../header";
import WebFooter from "../webFooter";
import WebHeader from "../webHeader";
// pages
import Login from "../webPanelComponents/login";
import OgretmenProfil from "../webPanelComponents/ogretmenProfil";
import OzelDers from "../webPanelComponents/ozelders";
import TumOgretmenler from "../webPanelComponents/tumogretmenler";
import Kesfet from "../webPanelComponents/kesfet";
import TypeFormTemplate from "../webPanelComponents/typeformTemplate";
import OgretmenIsBasvurusu from "../webPanelComponents/ogretmenIsBasvurusu";
import Register from "../webPanelComponents/register";
import Hakkimizda from "../webPanelComponents/hakkimizda";
import Iletisim from "../webPanelComponents/iletişim";
import SSS from "../webPanelComponents/sss";
import GizlilikPolitikasi from "../webPanelComponents/gizlilikPolitikasi";
import KullanimKosullari from "../webPanelComponents/kullanimkosullari";
import MainPage from "../webPanelComponents/mainpage";
import Preloader from "../sharedComponents/preloader";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import useLoader from "../../hooks/useLoader";
import TumYorumlar from "../webPanelComponents/tumyorumlar";
import useAuthenticate from "../../hooks/useAuthenticate";
import AfterLoginMenu from "../webPanelComponents/afterloginmenu";
import AdminLoginPage from "../webPanelComponents/adminLoginPage";

export default function WebPanel() {
  let { path, url } = useRouteMatch();

  const loaded = useLoader();

  return loaded ? (
    <>
      <WebHeader />
      <Switch>
        <Route path={`${path}login`}>
          <Header name="Giriş Yapın" />
          <Login />
        </Route>
        <Route path={`${path}admin-login-page`}>
          <Header name="Giriş Yapın" />
          <AdminLoginPage />
        </Route>
        <Route path={`${path}register`}>
          <Header name="Kayıt Olun" />
          <Register />
        </Route>
        {/* <Route path={`${path}ozeldersal`}>
                <Header name="Özel Ders Al" />
                <TypeFormTemplate />
            </Route>
            <Route path={`${path}ozelders`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route> */}
        <Route path={`${path}tumogretmenler`}>
          <Header name="Tüm Öğretmenler" />
          <OzelDers />
        </Route>
        {/* <Route path={`${path}kesfet`}>
                <Header name="Keşfet" />
                <Kesfet />
            </Route>
            <Route path={`${path}onlinebirebirders`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route>
            <Route path={`${path}lgs`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route>
            <Route path={`${path}yks`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route>
            <Route path={`${path}oaho`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route>
            <Route path={`${path}arasinifyazilicalismasi`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route>
            <Route path={`${path}grupdersleri`}>
                <Header name="Giriş Yapın" />
                <OzelDers />
            </Route> */}
        <Route path={`${path}ogretmenprofil/:teacherId`}>
          <Header name="Giriş Yapın" />
          <OgretmenProfil />
        </Route>
        {/* <Route path={`${path}hakkimizda`}>
                <Header name="Giriş Yapın" />
                <Hakkimizda />
            </Route>
            <Route path={`${path}iletisim`}>
                <Header name="Giriş Yapın" />
                <Iletisim />
            </Route>
            <Route path={`${path}sss`}>
                <Header name="Giriş Yapın" />
                <SSS />
            </Route>
            <Route path={`${path}gizlilikpolitikasi`}>
                <Header name="Giriş Yapın" />
                <GizlilikPolitikasi />
            </Route>
            <Route path={`${path}kullanimkosullari`}>
                <Header name="Giriş Yapın" />
                <KullanimKosullari />
            </Route> */}
        <Route path={`${path}tumyorumlar`}>
          <TumYorumlar />
        </Route>
        {/* <Route path={`${path}isbasvurusuyap`}>
                <OgretmenIsBasvurusu />
            </Route>
            <Route exact path={path}>
                <Header name="Gümüş Eğitim" />
                <MainPage />
            </Route> */}
        <Route exact path={path}>
          <Header name="Gümüş Eğitim" />
          <AfterLoginMenu />
        </Route>
      </Switch>
      <WebFooter />
    </>
  ) : null;
}
