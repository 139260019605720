// @ts-nocheck
import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import Header from "../header";
import { useState } from "react";
import SubClassroomService from "../../services/subClassroomService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { response } from "express";
import SubjectBookService from "../../services/subjectBookService";
import ConsentModal from "../consentModal";
import AdvancedTable from "../adminPanelComponents/AdvancedTable";
import { StudentIdentityContext } from "../panels/studentPanel";

export default function StudentSubjectBookPage() {
  const dispatch = useDispatch();
  const studentContext = useContext(StudentIdentityContext);
  const lessonName = `${studentContext?.subclassroom?.classroom?.name}/${studentContext?.subclassroom?.name}`;
  const subjectBookService = new SubjectBookService();
  const [subjectBookData, setSubjectBookData] = useState<
    GetSubcjectBookOutputModel[]
  >([]);
  const [detailsModalVis, setDetailsModalVis] = useState<boolean>(false);
  const [detailsData, setDetailsData] =
    useState<GetSubcjectBookOutputModel>("");

  const getSubjectBook = async (lessonId: number) => {
    dispatch(showLoader());
    const response = await subjectBookService.GetSubjectBookForStudent();
    setSubjectBookData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getSubjectBook();
  }, []);

  const tableData = subjectBookData?.map((item, index) => {
    const lesson = item.teacher.majors
      .map((item) => item.lessonName)
      .join(", ");
    return {
      issueDate: dayjs(item.issueDate).format("DD-MM-YYYY"),
      lesson: lesson,
      id: item.id,
    };
  });

  const customDataTable = [
    {
      accessorKey: "lesson",
      header: "Ders",
      maxSize: 100,
    },
    {
      accessorKey: "issueDate",
      header: "Tarih",
      maxSize: 100,
    },
  ];

  const getSubjectBookById = async (id: number) => {
    dispatch(showLoader());
    const response = await subjectBookService.GetSubjectById(id);
    setDetailsData(response);
    dispatch(hideLoader());
    setDetailsModalVis(true);
  };

  return (
    <div className="page-content">
      <Header name={`Sınıf Defteri - ${lessonName}`} />
      <div className="card">
        <div className="card-body">
          <div className="row  d-flex justify-content-end align-items-center">
            <AdvancedTable
              data={tableData}
              dataTable={customDataTable}
              filters={false}
              detailsButton={(row) => {
                getSubjectBookById(row.id);
              }}
            />
          </div>
        </div>
      </div>

      {detailsModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Sınıf Defteri Detay
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="form-group col-4">
                    <label className="font-weight-bold">Tarih:</label>
                    <h6>{dayjs(detailsData.issueDate).format("DD.MM.YYYY")}</h6>
                  </div>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">İşlenen Konu:</label>
                    <h6>{detailsData.topic}</h6>
                  </div>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">Ödev:</label>
                    <h6>{detailsData.homework}</h6>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setDetailsModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
