import BaseService from "./baseService";

declare global {
  interface TeacherLessonPair {
    teacherId: string;
    lessonId: number;
  }

  interface RegistrationInputModel {
    id?: string;
    name: string;
    surname: string;
    email: string;
    tel: string;
    parentNameSurname: string;
    school: string;
    schoolClass: string;
    password: string;
    lessons?: Array<TeacherLessonPair>;
    gemClassroomId?: number;
    profilePhoto?: string;
    parentTel: string;
    parentEmail: string;
    tcNo: string;
  }

  interface RegistrationOutputModel {
    username: string;
  }

  interface StudentOutputModel {
    branch?: {
      id: number;
      name: string;
    };
    id: string;
    name: string;
    surname: string;
    email: string;
    telephone: string;
    school: string;
    schoolClass: string;
    parentName: string;
    lessons: Array<TeacherLessonPairOutputModel>;
    classRoom: ClassroomOutputModel;
    subClassroom: any;
    profilePhoto?: string;
    parentEmail: string;
    parentTel: string;
    tcNo: string;
    isPassive: boolean;
  }

  interface TeacherLessonPairOutputModel {
    id: number;
    teacher: TeacherOutputModel;
    lesson: LessonOutputModel;
  }

  interface StudentFailedQuestionOutputModel {
    test: {
      id: number;
      name: string;
      issueDate: string;
      lessonName: string;
      className: string;
      questionCount: number;
    };
    testQuestionWithStudentAnswerOutputModel: Array<{
      testQuestion: {
        id: number;
        question: any;
        correctAnswer: string;
        difficultyLevel: number;
        questionUrl: string;
        unitId: number;
        unitName: string;
        learningOutcome: any;
      };
      studentAnswer: string;
      order: number;
    }>;
  }
}

export default class StudentService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/student");
  }

  async Register(
    account: RegistrationInputModel
  ): Promise<RegistrationOutputModel> {
    return await this.Post<RegistrationInputModel, RegistrationOutputModel>(
      account,
      null,
      false,
      false
    );
  }

  async GetStudentList(): Promise<Array<StudentOutputModel>> {
    return await this.Get<Array<StudentOutputModel>>();
  }

  async DeleteStudentById(studentId: string) {
    return await this.Delete(studentId);
  }

  async AddOrUpdateStudent(
    studentInfo: RegistrationInputModel
  ): Promise<StudentOutputModel> {
    if (studentInfo.id) return await this.Put(studentInfo.id, studentInfo);
    return await this.Post(studentInfo);
  }

  DownloadStudentListAsCSV() {
    return this.Get<Response>(
      null,
      "/student/csvstudentlist",
      true,
      true,
      true,
      false
    );
  }

  GetStudentFailedQuestion(id: number) {
    return this.Get<Array<StudentFailedQuestionOutputModel>>(
      null,
      `/failedquestion?&lessonId=${id}`
    );
  }

  GetStudentFailedQuestionByLessonGenericId(id: number) {
    return this.Get<Array<StudentFailedQuestionOutputModel>>(
      null,
      `/failedquestion?&lessonGenericId=${id}`
    );
  }
}
