import { createSlice, PayloadAction } from '@reduxjs/toolkit'

declare global {
    interface MessengerData {
        title: string,
        message: string,
        show?: boolean
    }
}

const initialState: MessengerData = {
    title: "",
    message: "",
    show: false
};

export const messengerSlice = createSlice({
    name: 'messenger',
    initialState: initialState,
    reducers: {
        sendMessage: (state, action: PayloadAction<MessengerData>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state = action.payload

            return state;
        }
    },
});


// Action creators are generated for each case reducer function
export const { sendMessage } = messengerSlice.actions

export default messengerSlice.reducer