// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  showStudentCreateOrUpdateModal,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import AccountService from "../../services/accountService";
import ClassroomService from "../../services/classroomService";
import LessonService from "../../services/lessonService";
import StudentService from "../../services/studentService";
import ProfileWidget from "../profileWidget";
import ComponentLoader from "../sharedComponents/componentLoader";
import Modal from "../sharedComponents/modal";
import TestPdf, {
  OgretmenRaporuOlustur,
  exportStudentList,
} from "../sharedComponents/testpdf";
import StudentCreateOrEditModal from "./studentCreateOrEditModal";
import { exportAsExcel } from "../../utils/Excel";
import PDF, { StudentList } from "../../utils/Pdf";
import RoleService, { Roles } from "../../services/roleService";
import BranchesService from "../../services/branchesService";
import { PDFViewer } from "@react-pdf/renderer";

export default function StudentsPage() {
  //services
  var studentService = new StudentService();
  const lessonService = new LessonService();
  const classroomService = new ClassroomService();
  const accountService = new AccountService();
  const roleService = new RoleService();
  const branchesService = new BranchesService();
  const dispatch = useDispatch();
  //states
  let [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);
  const [lessonList, setLessonList] = useState<Array<GenericLessonModel>>([]);
  const [classroomList, setclassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);
  let [editData, setEditData] = useState<StudentOutputModel>({
    parentEmail: "",
    parentTel: "",
    email: "",
    id: "",
    name: "",
    surname: "",
    telephone: "",
    lessons: [],
    parentName: "",
    schoolClass: "",
    school: "",
    classRoom: null,
    subClassroom: null,
    tcNo: "",
    isPassive: false,
  });
  let [deleteModelState, setdeleteModelState] = useState({
    id: null,
    visibility: false,
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [srcDoc, setSrcDoc] = useState(null);

  const [searchBarStudentName, setSearchBarStudentName] = useState("");
  const [searchBarStudentLessonId, setSearchBarStudentLessonId] =
    useState<number>(null);
  const [searchBarStudentClassId, setSearchBarStudentClassId] =
    useState<number>(null);
  const [searchBarStudenActivePassive, setSearchBarStudenActivePassive] =
    useState<string>(null);
  const [roles, setRoles] = useState<Array<string>>(null);
  const [brancheList, setBranchesList] = useState<Array<BranchesOutputModel>>();
  const [filterBranche, setFilterBranche] = useState<number>(0);

  //definitions
  var teacherWidgetActions: (id: string) => Array<ProfileWidgetAction> = (
    id: string
  ) => [
    { tag: "Düzenle", callback: () => editCallback(id) },
    { tag: "Sil", callback: () => deleteCallback(id) },
    { tag: "Hesaba Geçiş Yap", callback: () => changeAccountCallback(id) },
    { tag: "Rapor Al", callback: () => ogrenciRaporuAl(id) },
  ];

  const getUserRoles = async () => {
    const result = await roleService.GetUserRoles();
    setRoles(result);
  };

  const ogrenciRaporuAl = async (studentId: string) => {
    dispatch(showLoader());
    // const html = await OgrenciRaporuOlustur(studentId);
    dispatch(hideLoader());
    setSrcDoc(html);
  };

  const changeAccountCallback = async (id: string) => {
    dispatch(showLoader());
    const result = await accountService.changeAccount(id);
    if (result === false) {
      dispatch(hideLoader());
      dispatch(
        sendMessage({
          title: "İşelm Başarısız",
          message: "Beklenmeyen Bir Hata Oluştu",
        })
      );
    }
  };

  const deleteCallback = (id: string) =>
    setdeleteModelState({ id: id, visibility: true });
  const editCallback = (id: string) => {
    var student = studentList.find((t) => t.id == id);
    setEditData({
      email: student.email,
      id: student.id,
      name: student.name,
      surname: student.surname,
      telephone: student.telephone,
      lessons: student.lessons,
      schoolClass: student.schoolClass,
      parentName: student.parentName,
      school: student.school,
      classRoom: student.classRoom,
      subClassroom: student.subClassroom,
      profilePhoto: student.profilePhoto,
      parentEmail: student.parentEmail,
      parentTel: student.parentTel,
      isPassive: student.isPassive,
      tcNo: student.tcNo,
    });
    dispatch(showStudentCreateOrUpdateModal());
  };

  const createHandler = () => {
    setEditData({
      parentEmail: "",
      parentTel: "",
      email: "",
      id: "",
      name: "",
      surname: "",
      telephone: "",
      lessons: [],
      school: "",
      schoolClass: "",
      parentName: "",
      tcNo: "",
      classRoom: null,
      subClassroom: null,
      isPassive: true,
    });
    dispatch(showStudentCreateOrUpdateModal());
  };

  const getClassroomList = async () => {
    dispatch(showLoader());
    let result = await classroomService.GetClassroomList();
    setclassroomList(result);
    dispatch(hideLoader());
  };

  const getStudentList = async () => {
    dispatch(showLoader());
    var studentList = await studentService.GetStudentList();

    setDataLoaded(true);
    // console.log(studentList);
    setStudentList(studentList);
    dispatch(hideLoader());
  };

  const getLessonList = async () => {
    dispatch(showLoader());
    var lessonList = await lessonService.GetGenericLessonList();
    // console.log(lessonList);
    setLessonList(lessonList);
    dispatch(hideLoader());
  };

  const getBranchesList = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranches();
    setBranchesList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getLessonList();
    getStudentList();
    getClassroomList();
    getUserRoles();
  }, []);

  useEffect(() => {
    if ((roles || []).includes(Roles.Admin)) {
      getBranchesList();
    }
  }, [roles]);

  const deleteHandler = async () => {
    dispatch(showLoader());
    var result = await studentService.DeleteStudentById(deleteModelState.id);

    setdeleteModelState({ id: null, visibility: false });
    dispatch(hideLoader());
    if (result === true) {
      getStudentList();
    } else {
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "Silme işlemi başarısız",
        })
      );
    }
  };

  const filterStudentList = () => {
    setSearchBarStudenActivePassive(
      (
        document.querySelector(
          "#student-active-passive-search"
        ) as HTMLInputElement
      ).value
    );

    setSearchBarStudentName(
      (document.querySelector("#student-name-search") as HTMLInputElement).value
    );

    setSearchBarStudentLessonId(
      Number(
        (document.querySelector("#student-lesson-search") as HTMLSelectElement)
          .value
      )
    );
    setSearchBarStudentClassId(
      Number(
        (
          document.querySelector(
            "#student-classroom-search"
          ) as HTMLSelectElement
        ).value
      )
    );
  };
  const [studentListt, setStudentListt] = useState([]);

  const downloadStudentListAsCsv = async () => {
    let txt: string =
      "First Name, Last Name, Class Name, Student Tel, Parent Tel\n";

    studentList.forEach(
      (s) =>
        (txt +=
          (s?.name || "") +
          "," +
          (s?.surname ?? "") +
          "," +
          (s.classRoom?.name ?? "") +
          "," +
          (s.telephone || "") +
          "," +
          (s?.parentTel || "") +
          "\n")
    );
    let data = [];
    studentList.forEach(
      (s) =>
        (data = [
          ...data,
          {
            "Öğrenci İsim Soyisim": s?.name || "",
            "Öğrenci Sınıfı": s.classRoom?.name ?? "",
            "Öğrenci Telefonu": s.telephone || "",
            "Veli İsim Soyisim": s?.parentName || "",
            "Veli Telefonu": s?.parentTel || "",
          },
        ])
    );
    PDF(data);
    // setStudentListt(data);
    // setSrcDoc(blob);
    // exportAsExcel(data, ["A", "B", "C", "D", "E"], "TümÖğrenciler");
  };

  const createCsvStudentListFile = async () => {
    let txt: string =
      "First Name, Last Name, Class Name, Student Tel, Parent Tel\n";

    filteredStudentList.forEach(
      (s) =>
        (txt +=
          (s?.name || "") +
          "," +
          (s?.surname ?? "") +
          "," +
          (s.classRoom?.name ?? "") +
          "," +
          (s.telephone || "") +
          "," +
          (s?.parentTel || "") +
          "\n")
    );
    let data = [];
    filteredStudentList.forEach(
      (s) =>
        (data = [
          ...data,
          {
            "Öğrenci İsim Soyisim": s?.name || "",
            "Öğrenci Sınıfı": s.classRoom?.name ?? "",
            "Öğrenci Telefonu": s.telephone || "",
            "Veli İsim Soyisim": s?.parentName || "",
            "Veli Telefonu": s?.parentTel || "",
          },
        ])
    );
    PDF(data);

    // const html = await exportStudentList(data);
    // setSrcDoc(html);
  };

  const filterByActivePassive = (value: string) => {
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return "";
    }
  };

  const filteredStudentList = studentList.filter((student) => {
    const studentFullName = (student.name + " " + student.surname)
      .toLocaleLowerCase()
      .trim();

    const studentLessonIds = student.lessons.map(
      (lesson) => lesson.lesson.lessonGenericId
    );

    const nameFilter =
      !searchBarStudentName ||
      studentFullName.includes(searchBarStudentName.toLocaleLowerCase());

    const lessonFilter =
      !searchBarStudentLessonId ||
      studentLessonIds.includes(searchBarStudentLessonId);

    const classFilter =
      !searchBarStudentClassId ||
      (student.classRoom && student.classRoom.id === searchBarStudentClassId);

    const branchFilter =
      filterBranche === 0 ? true : student.branch?.id === filterBranche;

    const activePassiveFilter =
      !searchBarStudenActivePassive ||
      (typeof searchBarStudenActivePassive !== "undefined" &&
        student.isPassive ===
          filterByActivePassive(searchBarStudenActivePassive));

    return (
      nameFilter &&
      lessonFilter &&
      classFilter &&
      branchFilter &&
      activePassiveFilter
    );
  });

  return (
    <>
      <div className="page-content">
        <section className="row">
          <div className="content-page">
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">Öğrenci Adı</label>
                  <i className="bar"></i>
                  <input
                    id="student-name-search"
                    onInput={filterStudentList}
                    type="text"
                    className="form-control"
                    placeholder="Öğrenci Adı Gir"
                  />
                </div>
              </div>
              {(roles || []).includes(Roles.Admin) && (
                <div className="col-sm-6 col-md-3">
                  <label className="control-label">Kurum</label>
                  <i className="bar"></i>
                  <div className="form-group custom-mt-form-group">
                    <select
                      id="student-branche-search"
                      onChange={(e) => setFilterBranche(Number(e.target.value))}
                      className="form-select"
                    >
                      <option selected value={0}>
                        Hepsi
                      </option>
                      <option value={null}>Atanmamış</option>
                      {brancheList?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-md-3">
                <label className="control-label">Aktif / Pasif</label>
                <i className="bar"></i>
                <div className="form-group custom-mt-form-group">
                  <select
                    id="student-active-passive-search"
                    onChange={filterStudentList}
                    className="form-select"
                  >
                    <option value={""}>Tüm Öğrenciler</option>
                    <option value={"false"}>Aktif Öğrenciler</option>
                    <option value={"true"}>Pasif Öğrenciler</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <label className="control-label">Ders</label>
                <i className="bar"></i>
                <div className="form-group custom-mt-form-group">
                  <select
                    id="student-lesson-search"
                    onChange={filterStudentList}
                    className="form-select"
                  >
                    <option selected value="0">
                      Seçiniz
                    </option>
                    {lessonList.map((l, index) => (
                      <option value={l.id}>{l.lessonName || index}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <label className="control-label">Sınıf</label>
                <i className="bar"></i>
                <div className="form-group custom-mt-form-group">
                  <select
                    id="student-classroom-search"
                    onChange={filterStudentList}
                    className="form-select"
                  >
                    <option selected value="0">
                      Seçiniz
                    </option>
                    {classroomList.map((l, index) => (
                      <option value={l.id}>{l.name || index}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">&nbsp;</label>
                  <i className="bar"></i>
                  <button
                    onClick={createHandler}
                    className="btn btn-info btn-block form-control"
                  >
                    Yeni Öğrenci Kaydı
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">
                    <span className="h4">{filteredStudentList.length}</span>
                    <span className="h5"> öğrenci listelendi</span>
                  </label>
                  <i className="bar"></i>
                  {/* <PDF data={studentData}> */}
                  <button
                    onClick={downloadStudentListAsCsv}
                    className="btn btn-info btn-block form-control"
                  >
                    Tüm Öğrenci Listesini İndir
                  </button>
                  {/* </PDF> */}
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">
                    <span className="h4">&nbsp;</span>
                  </label>
                  <i className="bar"></i>
                  <button
                    onClick={createCsvStudentListFile}
                    className="btn btn-info btn-block form-control"
                  >
                    Listelenen Öğrenci Listesini İndir
                  </button>
                </div>
              </div>
            </div>
            <div className="row staff-grid-row mt-3">
              {!dataLoaded ? (
                <ComponentLoader />
              ) : (
                filteredStudentList.map((t) => (
                  <ProfileWidget
                    photoUrl={t.profilePhoto}
                    key={t.id}
                    actions={teacherWidgetActions(t.id)}
                    name={t.name}
                    surname={t.surname}
                    studentData={t}
                    listUpdated={getStudentList}
                    isPassive={t.isPassive}
                  />
                ))
              )}
            </div>
          </div>
        </section>
      </div>
      <StudentCreateOrEditModal
        listUpdated={getStudentList}
        editData={editData}
        classroomList={classroomList}
      />
      <Modal
        onclose={() => setdeleteModelState({ id: null, visibility: false })}
        onsubmit={deleteHandler}
        modalVisibility={deleteModelState.visibility}
        title="Öğrenci Kaydı Silme Onayı"
        buttonRight={"Evet"}
      >
        <div>Silme işlemini onaylıyor musunuz?</div>
      </Modal>
      <iframe style={{ visibility: "hidden" }} srcDoc={srcDoc}></iframe>
      <a id="download-link" href="#" style={{ display: "none" }}>
        Download
      </a>
    </>
  );
}
