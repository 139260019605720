import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../reducers/commonSlice";
import AuthorizationService from "../services/authorizationService";
import RoleService, { Roles } from "../services/roleService";

export default function WebHeader() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const roleService = new RoleService();
  const authorizationService = new AuthorizationService();

  const checkUserLoginStatus = async () => {
    const result = await roleService.GetUserRoles();
    setIsLoggedIn(result != null);
    setUserRoles(result);
  };

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  const logout = () => {
    authorizationService.Logout();
  };

  const getUserPage = (userRole: string) => {
    switch (userRole) {
      case Roles.Admin:
      case Roles.Manager:
        return "admin";
      case Roles.Student:
        return "student";
      case Roles.Teacher:
        return "teacher";
    }
  };

  return (
    <header className="header-global">
      <nav
        id="navbar-main"
        className="navbar shadow-sm position-fixed navbar-main bg-soft navbar-expand-lg headroom py-lg-3 px-lg-6"
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              className="navbar-brand-light common"
              src="../logo.png"
              height="35"
              alt="Logo dark"
            />
          </Link>
          <div className="navbar-collapse collapse" id="navbar_global">
            <div className="navbar-collapse-header">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <Link to="/">
                    <img src="../logo.png" height="35" alt="Logo Impact" />
                  </Link>
                </div>
                <div className="col-6 collapse-close">
                  <a
                    href="#navbar_global"
                    role="button"
                    className="fas fa-times"
                    data-toggle="collapse"
                    data-target="#navbar_global"
                    aria-controls="navbar_global"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  ></a>
                </div>
              </div>
            </div>
            {isLoggedIn === null ? null : isLoggedIn ? (
              <ul className="navbar-nav navbar-nav-hover justify-content-center ml-lg-auto">
                <li className="nav-item text-center pl-1 pr-1 pb-1">
                  <button
                    onClick={logout}
                    className="btn btn-md btn-docs btn-warning animate-up-2 mr-1"
                  >
                    <i className="fas fa-sign-out-alt mr-2"></i> Çıkış Yap
                  </button>
                </li>
                <li className="nav-item text-center pl-1 pr-1">
                  <a
                    href={"/" + getUserPage(userRoles[0])}
                    className="btn btn-md btn-success animate-up-2"
                  >
                    <i className="fas fa-directions mr-2"></i> Panele Dön
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav navbar-nav-hover justify-content-center ml-lg-auto">
                <li
                  className="nav-item text-center pl-1 pr-1 pb-1"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link
                    to="/login"
                    className="btn btn-md btn-docs btn-outline-default animate-up-2 mr-1"
                  >
                    <i className="fas fa-sign-in-alt mr-2"></i> Giriş Yap
                  </Link>
                </li>
                <li
                  className="nav-item text-center pl-1 pr-1"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link
                    to="/register"
                    className="btn btn-md btn-secondary animate-up-2"
                  >
                    <i className="fas fa-user-plus mr-2"></i> Üye ol
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="d-flex d-lg-none align-items-center">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar_global"
              aria-controls="navbar_global"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="mr-2">
                <i className="fas fa-user text-default"></i>
              </span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}
