import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { Link, useRouteMatch } from "react-router-dom";
import SubClassroomService from "../../services/subClassroomService";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { useEffect } from "react";
import AdvancedTable from "./AdvancedTable";
import dayjs from "dayjs";

export default function CheckBook() {
  const { path } = useRouteMatch();
  const subClassroomService = new SubClassroomService();
  const dispatch = useDispatch();
  const [subClassroomList, setSubClassroomList] = useState<
    Array<SubClassroomOutputModel>
  >([]);

  const getSubClassroom = async () => {
    dispatch(showLoader());
    const response = await subClassroomService.GetSubClassroomByTeacherId();
    setSubClassroomList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getSubClassroom();
  }, []);

  return (
    <div className="page-content">
      <section className="row">
        <div className="row staff-grid-row">
          {subClassroomList.map((l) => (
            <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
              <div className="profile-widget" style={{ borderRadius: "100px" }}>
                <div className="profile-img">
                  <Link to={`${path}/lesson/${l.id}`}>
                    <a>
                      <img
                        className="avatar"
                        src="/assets/img/class.png"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>
                <h5 className="user-name m-t-10 m-b-0 text-ellipsis">
                  <a>{`${l.classroom.name} - ${l.name}` || "Default"}</a>
                </h5>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
