// @ts-nocheck
import React, { useEffect, useState } from "react";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import "react-datepicker/dist/react-datepicker.css";
import OpticsService from "../../services/opticsService";
import "react-datepicker/dist/react-datepicker.css";
import AdvancedTable from "./AdvancedTable";
import dayjs from "dayjs";
import MeetService from "../../services/meetService";
import ComponentLoader from "../sharedComponents/componentLoader";
import {
  TextInput,
  Switch,
  Group,
  ActionIcon,
  Box,
  Text,
  Button,
  Code,
  Tooltip,
} from "@mantine/core";
import { randomId } from "@mantine/hooks";
import { IconPdf, IconTrash } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import Select from "react-select";
import SyllabusService from "../../services/syllabusService";
import { symlink } from "fs";

export default function GuidancePage() {
  const opticService = new OpticsService();
  const studentService = new StudentService();
  const meetService = new MeetService();
  const syllabusService = new SyllabusService();

  const dispatch = useDispatch();

  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);
  const [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);
  const [detailsModalVis, setDetailsModalVis] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<string>("");
  const [selectedId, setSelectedId] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [formText, setFormText] = useState<string>("");
  const [formInterviewer, setFormInterviewer] = useState<string>("");
  const [trig, setTrig] = useState<boolean>(false);
  const [lessonList, setLessonList] = useState<
    Array<LessonByExamTypeOutputModel>
  >([]);
  const [examtypes, setExamTypes] = useState<
    Array<PracticeExamTypesOutputModel>
  >([]);
  const [learningOutComeList, setLearningOutcomeList] = useState<
    Array<LearningOutcomeOutputModel>
  >([]);
  const [fromSyllabusName, setFromSyllabusName] = useState<string>("");
  const [syllabusData, setSyllabusData] = useState<Array<SyllabusOutputModel>>(
    []
  );

  const days = [
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
    "Pazar",
  ];

  const daysToDate: { day: string; date: string }[] = [
    { day: "Pazartesi", date: "01.01.2024" },
    { day: "Salı", date: "02.01.2024" },
    { day: "Çarşamba", date: "03.01.2024" },
    { day: "Perşembe", date: "04.01.2024" },
    { day: "Cuma", date: "05.01.2024" },
    { day: "Cumartesi", date: "06.01.2024" },
    { day: "Pazar", date: "07.01.2024" },
  ];

  const form = useForm({
    initialValues: {
      schedules: [
        // {
        //   id: [],
        //   day: "",
        //   questionCount: null,
        //   lessonName: "",
        //   learningOutcomes: [],
        //   lessonId: null,
        // },
      ],
    },
  });

  const getStudentList = async () => {
    dispatch(showLoader());
    const response = await studentService.GetStudentList();
    setStudentList(response);
    dispatch(hideLoader());
  };

  const getExamsType = async () => {
    dispatch(showLoader());
    const response = await opticService.GetPracticeExamTypes();
    setExamTypes(response);
    dispatch(hideLoader());
  };

  const getLessonByExamType = async (id) => {
    const response = await opticService.GetLessonByExamType(id);
    setLessonList(response);
  };

  const getLearningOutcomeList = async (id) => {
    const response = await syllabusService.GetLearningOutcomeByLessonId(id);
    setLearningOutcomeList(response);
  };

  const getSyllabusList = async () => {
    dispatch(showLoader());
    const response = await syllabusService.GetSyllabusProgressByStudentId(
      selectedStudent.value
    );
    setSyllabusData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getStudentList();
    getExamsType();
  }, []);

  useEffect(() => {
    getSyllabusList();
  }, [trig, selectedStudent]);

  const handleSave = async () => {
    dispatch(showLoader());

    const SyllabusProgress = form.values.schedules.map((item) => ({
      Date: daysToDate.find((day) => day.day === item.day).date,
      // Date: "2024-01-04",
      LearningOutcomeId: item.id[0].value,
      LessonId: item.lessonId,
      QuestionCount: Number(item.questionCount),
    }));

    const syllabusObj: SyllabusInputModel = {
      Name: fromSyllabusName,
      StartDate: new Date(),
      EndDate: new Date(),
      StudentId: selectedStudent.value,
      SyllabusProgress,
    };

    const response = await syllabusService.AddSyllabusProgress(syllabusObj);
    setFromSyllabusName("");
    form.reset();
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const handleDelete = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    const response = await syllabusService.DeleteSyllabusById(selectedId);
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const selectStudentOptions = studentList.map((item) => ({
    value: item.id,
    label: item.name + " " + item.surname,
  }));

  const selectDayOpt = days.map((item) => ({
    value: item,
    label: item,
  }));

  const examTypesOpt = examtypes.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const selectLessonOpt = lessonList.map((item) => ({
    value: item.id,
    label: item.lessonName,
  }));

  const selectLearningOutcomeOpt = learningOutComeList.map((item) => ({
    value: item.learningOutcomeId,
    label: item.learningOutcome,
  }));
  const fields = form.values.schedules.map((item, index) => (
    <div className="row d-flex justify-content-center align-items-center">
      <div className="form-group col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
        <Select
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            container: (base) => ({
              ...base,
              width: "100%",
            }),
          }}
          menuPosition={"fixed"}
          aria-label="Default select example"
          options={examTypesOpt}
          onChange={(selectedOption) => {
            form.setFieldValue(
              `schedules.${index}.lessonName`,
              selectedOption.label
            );
            form.setFieldValue(
              `schedules.${index}.lessonName`,
              selectedOption.label
            );
            getLessonByExamType(selectedOption.value);
          }}
          placeholder="Sınav Türü Seçiniz"
          // isMulti
          // closeMenuOnSelect={false}
        />
      </div>
      <div className="form-group col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
        <Select
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            container: (base) => ({
              ...base,
              width: "100%",
            }),
          }}
          menuPosition={"fixed"}
          aria-label="Default select example"
          options={selectLessonOpt}
          onChange={(selectedOption) => {
            form.setFieldValue(
              `schedules.${index}.lessonName`,
              selectedOption.label
            );
            form.setFieldValue(
              `schedules.${index}.lessonId`,
              selectedOption.value
            );
            getLearningOutcomeList(selectedOption.value);
          }}
          placeholder="Ders Seçiniz"
          isDisabled={form.values.schedules[index].lessonName === null}
        />
      </div>
      <div className="form-group col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
        <Select
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            container: (base) => ({
              ...base,
              width: "100%",
            }),
          }}
          menuPosition={"fixed"}
          aria-label="Default select example"
          options={selectLearningOutcomeOpt}
          onChange={(selectedOption) => {
            form.setFieldValue(`schedules.${index}.id`, [selectedOption]);
          }}
          placeholder="Kazanım Seçiniz"
          // isMulti
          // closeMenuOnSelect={false}
          isDisabled={form.values.schedules[index].lessonId === null}
        />
      </div>
      <div className="form-group col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
        <Select
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            container: (base) => ({
              ...base,
              width: "100%",
            }),
          }}
          menuPosition={"fixed"}
          aria-label="Default select example"
          options={selectDayOpt}
          onChange={(selectedOption) => {
            form.setFieldValue(`schedules.${index}.day`, selectedOption.value);
          }}
          placeholder="Gün Seçiniz"
          isDisabled={!form.values.schedules[index].id.length}
        />
      </div>
      <div className="form-group col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
        <input
          type="number"
          min={0}
          placeholder="Soru Sayısı"
          value={form.values.schedules[index].questionCount}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            form.setFieldValue(
              `schedules.${index}.questionCount`,
              e.target.value < 0 ? "" : e.target.value
            )
          }
          className={
            "form-control" +
            (form.values.schedules[index].questionCount
              ? " is-valid"
              : " is-invalid")
          }
          required
          disabled={
            !form.values.schedules[index].id.length ||
            form.values.schedules[index].day === ""
          }
        />
      </div>
      <div className="form-group col-xl-1 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
        <ActionIcon
          color="red"
          onClick={() => form.removeListItem("schedules", index)}
        >
          <IconTrash size="1rem" />
        </ActionIcon>
      </div>
      <h5 className="border-bottom"></h5>
    </div>
  ));

  const customDataTable = [
    {
      accessorKey: "name",
      header: "İsim",
      maxSize: 100,
    },
    {
      accessorFn: (row) => dayjs(row.endDate).format("DD.MM.YYYY"),
      accessorKey: "endDate",
      header: "Tarih",
      maxSize: 100,
    },
  ];

  const detailData = syllabusData.find((schedule) => {
    return schedule.id === selectedId;
  });

  const validate = form.values.schedules.filter(
    (item) => item.questionCount === ""
  );

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div
              className={
                "col-12 justify-content-start align-items-center pb-3 "
              }
            >
              <Select
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  container: (base) => ({
                    ...base,
                    width: "100%",
                  }),
                }}
                menuPosition={"fixed"}
                aria-label="Default select example"
                options={selectStudentOptions}
                onChange={(selectedOption) => {
                  setSelectedStudent(selectedOption);
                }}
                placeholder="Lütfen Öğrenci Seçiniz"
              />
            </div>

            <AdvancedTable
              data={syllabusData}
              dataTable={customDataTable}
              filters={false}
              deleteButton={(row) => {
                setSelectedId(row.id);
                setConsentModalVisibility(true);
              }}
              detailsButton={(row) => {
                setSelectedId(row.id);
                setDetailsModalVis(true);
              }}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="form-group col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-start align-items-center">
              <input
                type="text"
                min={0}
                placeholder="Porgram Adı"
                value={fromSyllabusName}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFromSyllabusName(e.target.value);
                }}
                className={
                  "form-control" +
                  (fromSyllabusName !== "" ? " is-valid" : " is-invalid")
                }
                required
              />
            </div>

            <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-start align-items-center pt-3">
              <div className="row col-12">{fields}</div>
            </div>
            <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              <button
                onClick={() =>
                  form.insertListItem("schedules", {
                    id: [],
                    day: null,
                    questionCount: "",
                    lessonName: null,
                    learningOutcomes: null,
                    lessonId: null,
                  })
                }
                type="button"
                className="btn btn-primary ml-1 btn-l"
              >
                Ders ve Kazanım Ekle
              </button>
            </div>
          </div>

          <div className="row">
            {days.map((day) => (
              <div className="border col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                <h5 className="border-bottom">{day}</h5>
                {form.values.schedules.map((schedule) => {
                  return (
                    schedule.day === day &&
                    schedule?.id.map((item) => {
                      return (
                        <>
                          <h6>
                            -{"> "}
                            {`${schedule.lessonName} - ${item.label} (${schedule.questionCount} Soru)`}
                          </h6>
                        </>
                      );
                    })
                  );
                })}
              </div>
            ))}
          </div>
          <div className="col-12 d-flex justify-content-end  align-items-center pt-3">
            <button
              onClick={handleSave}
              type="button"
              className="btn btn-success ml-1 btn-l"
              disabled={!!validate.length || fromSyllabusName === ""}
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>

      {detailsModalVis && (
        <div
          className="modal text-left show"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Ders Programı
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>

              <div className="modal-body" id="detailModal">
                <div className="d-flex justify-content-center align-items-center">
                  <h4>{`${detailData.name} - ${dayjs(detailData.endDate).format(
                    "DD.MM.YYYY"
                  )}`}</h4>
                </div>
                <div className="row">
                  <div className="border col-12 p-3">
                    {detailData.syllabusProgressesGroupedDay.map((schedule) => (
                      <>
                        <h5 className="border-bottom">{schedule.day}</h5>
                        {schedule.syllabusProgress.map((item) => (
                          <h6 className="text-muted mb-0">
                            -{"> "}
                            {`${item.lesson.lessonName} - ${item.learningOutcome.learningOutcome} - (${item.questionCount} Soru)`}
                          </h6>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setDetailsModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    window.print();
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check"></i>
                  <span className="d-inline p-2">Yazdır</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Görüşme Ekle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görüşme Notu:</label>
                    <textarea
                      class="form-control"
                      aria-label="With textarea"
                      value={formText}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormText(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formText ? " is-valid" : " is-invalid")
                      }
                    />
                  </div>
                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görüşülen Kişi:</label>
                    <input
                      type="text"
                      placeholder="Görüşülen Kişi"
                      value={formInterviewer}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormInterviewer(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formInterviewer ? " is-valid" : " is-invalid")
                      }
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                    setFormText("");
                    setFormInterviewer("");
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={formText === "" || formInterviewer === ""}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={handleDelete}
        cancelCallback={() => {
          setConsentModalVisibility(false);
        }}
      />
    </>
  );
}
