// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  hideStudentCreateOrUpdateModal,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import LessonService from "../../services/lessonService";
import StudentService from "../../services/studentService";
import { RootState } from "../../store";
import Choices from "choices.js";
import {
  filterNonNumerics,
  validateEmail,
} from "../../extensions/commonFunctions";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import SubClassroomService from "../../services/subClassroomService";
import BranchesService from "../../services/branchesService";
import ClassroomService from "../../services/classroomService";
import ComponentLoader from "../sharedComponents/componentLoader";
import Select from "react-select";
import TeacherService from "../../services/teacherService";

var choices: Choices;
var choices2: Choices;

export default function StudentCreateOrEditModal(props: {
  listUpdated: () => void;
  editData?: StudentOutputModel;
  classroomList: Array<ClassroomOutputModel>;
}) {
  const showStudentCreateOrUpdateModal = useSelector(
    (state: RootState) => state.commons.showStudentCreateOrUpdateModal
  );
  const ref = useRef(null);
  const branchesService = new BranchesService();
  const [createMode, setCreateMode] = useState(false);

  const dispatch = useDispatch();
  const subClassService = new SubClassroomService();
  const serviceService = new StudentService();
  const classService = new ClassroomService();
  const teacherService = new TeacherService();
  const formSubmitHandler = async () => {
    dispatch(hideStudentCreateOrUpdateModal());
    dispatch(showLoader());
    const sa = formStudentLessons.map((l) => ({
      teacherId: l.value.split(":")[1],
      lessonId: Number(l.value.split(":")[0]),
    }));
    var result = await serviceService.AddOrUpdateStudent({
      id: studentId,
      name: formStudentName,
      surname: formStudentSurname,
      tel: formStudentTel,
      email: formStudentEmail,
      lessons: formStudentLessons.map((l) => ({
        teacherId: l.value.split(":")[1],
        lessonId: Number(l.value.split(":")[0]),
      })),
      schoolClass: formStudentSchoolClass,
      parentNameSurname: formParentNameSurname,
      password: formStudentPassword,
      school: formStudentSchool,
      gemClassroomId: Number(formStudentClassroom),
      gemSubClassroomId: Number(formStudentSubClassroom),
      profilePhoto: studentProfilePhoto,
      parentEmail: formParentEmail,
      parentTel: formParentTel,
      tcNo: formStudentTC.toString(),
      isPassive,
    });

    if (result != null) {
      const response = await branchesService.AddBranchForUser(result.id);
      await props.listUpdated();
    } else {
      let message = studentId
        ? "Güncelleme işlemi başarısız"
        : "Kayıt işlemi başarısız";
      //dispatch(sendMessage({ title: "İşlem Sonucu", message: message }));
    }

    dispatch(hideLoader());
  };

  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormStudentLessons(
      Array.from(event.target.selectedOptions, (option) => option.value)
    );
  };
  // form data
  const [studentId, setStudentId] = useState("");
  const [formStudentName, setFormStudentName] = useState("");
  const [formStudentTC, setFormStudentTc] = useState("");
  const [formStudentSurname, setFormStudentSurname] = useState("");
  const [formStudentEmail, setFormStudentEmail] = useState("");
  const [formStudentTel, setFormStudentTel] = useState("");
  const [formParentNameSurname, setParentNameSurname] = useState("");
  const [formStudentSchool, setStudentSchool] = useState("");
  const [formStudentSchoolClass, setStudentSchoolClass] = useState("");
  const [formStudentPassword, setformStudentPassword] = useState("");
  const [formStudentLessons, setFormStudentLessons] = useState<Array<string>>(
    []
  );
  const [formStudentClassroom, setFormStudentClassroom] = useState("");
  const [studentProfilePhoto, setStudentProfilePhoto] = useState("");
  const [formParentTel, setFormParentTel] = useState("");
  const [formParentEmail, setFormParentEmail] = useState("");
  const [isPassive, setIsPassive] = useState<boolean>(false);
  const [formStudentSubClassroom, setFormStudentSubClassroom] = useState("");
  const [subClassroomList, setSubClassroomList] = useState<
    SubClassroomOutputModel[]
  >([]);
  const [teacherList, setTeacherList] = useState<ClassroomOutputModel>([]);
  const getSubClassroomList = async () => {
    const response = await subClassService.GetSubClassroomById(
      formStudentClassroom
    );
    setSubClassroomList(response);
  };

  const getTecaherList = async (id: number) => {
    const response = await classService.GetClassroomById(id);
    setTeacherList(response?.[0]);
  };

  useEffect(() => {
    getSubClassroomList();
  }, [formStudentClassroom]);

  useEffect(() => {
    if (!props.editData) return;

    const studentLessons = props.editData.lessons.map((m) => ({
      value: m.lesson.id + ":" + (m.teacher || {}).id,
      label:
        m.teacher?.name + " " + m.teacher?.surname + " -- " + m.lesson?.name,
    }));

    setStudentId(props.editData.id);
    setFormStudentName(props.editData.name || "");
    setFormStudentSurname(props.editData.surname || "");
    setFormStudentEmail(props.editData.email || "");
    setFormStudentTel(props.editData.telephone || "");
    setParentNameSurname(props.editData.parentName || "");
    setStudentSchool(props.editData.school || "");
    setStudentSchoolClass(props.editData.schoolClass || "");
    setFormStudentLessons(studentLessons);
    setFormStudentClassroom(
      props.editData.classRoom ? props.editData.classRoom.id.toString() : ""
    );
    setFormStudentSubClassroom(
      props.editData.subClassroom
        ? props.editData.subClassroom.id.toString()
        : ""
    );
    setStudentProfilePhoto(props.editData.profilePhoto || "");
    setformStudentPassword("");
    setCreateMode(!props.editData.id);
    setFormParentTel(props.editData.parentTel || "");
    setFormParentEmail(props.editData.parentEmail || "");
    setFormStudentTc(props.editData.tcNo || "");
  }, [props.editData]);

  // useEffect(() => {
  //   updateLessonSelectbox();
  // }, [teacherList, formStudentClassroom]);

  useEffect(() => {
    if (formStudentClassroom !== "") {
      getTecaherList(formStudentClassroom);
    }
  }, [props.editData, formStudentClassroom]);

  const getTest = async () => {
    const response = await teacherService.GetTeacherList();
    // setTeacherList(response);
  };

  useEffect(() => {
    getTest();
  }, []);

  const options = [].concat(
    ...(teacherList.lessons || []).map((lesson) =>
      (lesson.teachers || []).map((teacher) => ({
        value: lesson.id + ":" + teacher.id,
        label: teacher.name + " " + teacher.surname + " -- " + lesson.name,
      }))
    )
  );

  const openFileUpload = () => {
    (document.querySelector("#profile-photo-input") as HTMLInputElement).value =
      "";
    (
      document.querySelector("#profile-photo-input") as HTMLInputElement
    ).click();
  };
  const profileImageOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setStudentProfilePhoto(reader.result as string);
      //(document.querySelector("#preview-profile-img") as HTMLImageElement).src = reader.result as string;
    };

    reader.readAsDataURL(selectedFile);
  };

  const isEmailValid = validateEmail(formStudentEmail);
  const isParentEmailValid = formParentEmail
    ? validateEmail(formParentEmail)
    : true;
  const isPasswordLengthValid = formStudentPassword.length >= 5;
  const isPasswordFormatValid = !!formStudentPassword.match(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
  );

  const filterPhoneInput = (value: string) => {
    const matches = (value || "").match(/^[0-9]*$/);
    if (matches) setFormStudentTel(matches[0]);
  };

  const filterParentPhoneInput = (value: string) => {
    const matches = (value || "").match(/^[0-9]*$/);
    if (matches) setFormParentTel(matches[0]);
  };

  const checPasswordValidity = () => {
    if (createMode) {
      return isPasswordFormatValid && isPasswordLengthValid;
    } else
      return (
        !formStudentPassword || (isPasswordFormatValid && isPasswordLengthValid)
      );
  };

  return (
    <div
      className="modal fade text-left show"
      id="yeniOgrenciEkleModal"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: showStudentCreateOrUpdateModal ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {"Öğrenci Formu"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                data-feather="x"
                onClick={() => dispatch(hideStudentCreateOrUpdateModal())}
              ></i>
            </button>
          </div>
          <div className="modal-body">
            <form action="#">
              <div className="row justify-content-center">
                <div className="col-6">
                  <div className="card-profile-image">
                    <div
                      className="pic-holder"
                      onClick={openFileUpload}
                      style={{ width: "97%", height: "215px" }}
                    >
                      <a href="#">
                        <img
                          id="preview-profile-img"
                          src={
                            studentProfilePhoto ||
                            "/assets/img/Profile_avatar_placeholder_large.png"
                          }
                          height="225"
                          style={{ width: "100%", objectFit: "contain" }}
                          className="profile-teacher-photo rounded-circle"
                        />
                      </a>
                      <input
                        id="profile-photo-input"
                        type="file"
                        onChange={profileImageOnChangeHandler}
                        hidden
                      />
                      <label className="upload-file-block">
                        <div className="text-center">
                          <div className="mb-2">
                            <i className="fa fa-camera fa-2x"></i>
                          </div>
                          <div className="text-uppercase">
                            Fotoğraf <br /> Ekle
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12">
                  <label>Öğrenci Ad Soyad: </label>
                  <input
                    type="text"
                    placeholder="Ad"
                    value={formStudentName}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormStudentName(e.target.value)
                    }
                    className={
                      "form-control" + (formStudentName ? "" : " is-invalid")
                    }
                    required
                  />
                </div>
                <div className="form-group col-12">
                  <label>Öğrenci TC No: </label>
                  <input
                    type="text"
                    placeholder="TC"
                    value={formStudentTC}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormStudentTc(e.target.value)
                    }
                    className={
                      "form-control" +
                      (formStudentTC.length === 11 ? "" : " is-invalid")
                    }
                    required
                  />
                </div>
                {/* <div className="form-group col-md-6">
                                    <label>Öğrenci Soyadı: </label>
                                    <input type="text" placeholder="Soyad" value={formStudentSurname} onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFormStudentSurname(e.target.value)} className={"form-control" + (formStudentSurname ? "" : " is-invalid")} required />
                                </div> */}
                <div className="form-group col-12">
                  <label>Email: </label>
                  <input
                    type="text"
                    placeholder="Email giriniz"
                    value={formStudentEmail}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormStudentEmail(e.target.value)
                    }
                    className={
                      "form-control" +
                      (formStudentEmail && isEmailValid ? "" : " is-invalid")
                    }
                    required
                  />
                  <div className="invalid-feedback">
                    <i className="bx bx-radio-circle"></i>
                    {!isEmailValid ? "Email formatında giriş yapınız" : ""}
                  </div>
                </div>
                <div className="form-group col-12">
                  <label>Telefon: </label>
                  <input
                    type="text"
                    placeholder="Telefon giriniz"
                    value={formStudentTel}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      filterPhoneInput(e.target.value)
                    }
                    className="form-control"
                  />
                </div>
                <div className="form-group col-12">
                  <label>Veli Ad Soyad: </label>
                  <input
                    type="text"
                    placeholder="Veli Ad Soyad giriniz"
                    value={formParentNameSurname}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setParentNameSurname(e.target.value)
                    }
                    className="form-control"
                  />
                </div>
                <div className="form-group col-12">
                  <label>Veli Email: </label>
                  <input
                    type="text"
                    placeholder="Veli Email giriniz"
                    value={formParentEmail}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormParentEmail(e.target.value)
                    }
                    className={
                      "form-control" + (isParentEmailValid ? "" : " is-invalid")
                    }
                  />
                </div>
                <div className="form-group col-12">
                  <label>Veli Tel: </label>
                  <input
                    type="text"
                    placeholder="Veli Telefon giriniz"
                    value={formParentTel}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      filterParentPhoneInput(e.target.value)
                    }
                    className="form-control"
                  />
                </div>
                <div className="form-group col-12">
                  <label>Öğrenci Okulu: </label>
                  <input
                    type="text"
                    placeholder="Öğrenci Okulu giriniz"
                    value={formStudentSchool}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setStudentSchool(e.target.value)
                    }
                    className="form-control"
                  />
                </div>
                <div className="form-group col-12">
                  <label>Öğrenci Sınıfı: </label>
                  <input
                    type="text"
                    placeholder="Öğrenci Sınıfı giriniz"
                    value={formStudentSchoolClass}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setStudentSchoolClass(e.target.value)
                    }
                    className="form-control"
                  />
                </div>
                <div className="form-group col-12">
                  <label>Öğrenci Giriş Şifresi: </label>
                  <input
                    type="text"
                    placeholder="Öğrenci Giriş Şifresi giriniz"
                    value={formStudentPassword}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setformStudentPassword(e.target.value)
                    }
                    className={
                      "form-control" +
                      (checPasswordValidity() ? "" : " is-invalid")
                    }
                  />
                  <div className="invalid-feedback">
                    <i className="bx bx-radio-circle"></i>
                    {!isPasswordLengthValid
                      ? "Şifre en az 5 karakter uzunluğunda olmalıdır"
                      : !isPasswordFormatValid
                      ? "Şifre küçük, büyük harf ve rakam içermelidir"
                      : ""}
                  </div>
                </div>
                <div className="form-group col-12">
                  <label>Sınıf: </label>
                  <select
                    className="form-select"
                    value={formStudentClassroom}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setFormStudentClassroom(e.target.value)
                    }
                  >
                    <option value="" disabled>
                      Seçim Yapınız
                    </option>
                    {props.classroomList.map((c) => (
                      <option value={c.id}>{c.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-12">
                  <label>Şube: </label>
                  <select
                    className="form-select"
                    value={formStudentSubClassroom}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      setFormStudentSubClassroom(e.target.value)
                    }
                  >
                    <option value="">-</option>
                    {subClassroomList.map((c) => (
                      <option value={c.id}>{c.name}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-12">
                  <label>Ders: </label>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={options}
                    value={formStudentLessons}
                    onChange={setFormStudentLessons}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                dispatch(hideStudentCreateOrUpdateModal());
                setFormStudentLessons([]);
              }}
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              type="button"
              disabled={
                !(
                  isParentEmailValid &&
                  isEmailValid &&
                  formStudentName &&
                  formStudentEmail &&
                  (createMode
                    ? isPasswordLengthValid && isPasswordFormatValid
                    : true) &&
                  formStudentTC.length === 11
                )
              }
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              {/* <i className="fas fa-check-circle"></i> */}
              <span className="d-inline p-2" onClick={formSubmitHandler}>
                Kaydet
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
