import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { serviceStaticData } from "../../extensions/servicesStaticData";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import LessonService from "../../services/lessonService";
import TeacherService from "../../services/teacherService";
import { v4 as uuidv4 } from "uuid";

export default function OzelDers() {
  let { path, url } = useRouteMatch();
  const staticData = serviceStaticData;
  const currentPageData = staticData[path] || {
    longDesc: "",
    longDescTitle: "",
    shortDesc: "",
    shortDescTitle: "",
  };
  const dispatch = useDispatch();
  const teacherService = new TeacherService();
  const lessonService = new LessonService();
  const [teacherList, setTeacherList] = useState<
    Array<TeacherProfileOutputModel>
  >([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filterBadges, setFilterBadges] = useState<
    Array<{ value: number; text: string }>
  >([]);
  const [lessonList, setLessonList] = useState<Array<GenericLessonModel>>([]);
  const [carouselChunkSize, setCarouselChunkSize] = useState(
    window.innerWidth > 400 ? 3 : 1
  );

  const getLessonList = async () => {
    const result = await lessonService.GetGenericLessonList();
    if (result) setLessonList(result);
  };

  const getTeacherList = async () => {
    dispatch(showLoader());
    var teacherList = await teacherService.GetTeacherProfileList();
    setDataLoaded(true);
    // console.log(teacherList);
    setTeacherList(teacherList);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getTeacherList();
    getLessonList();
  }, []);

  const filterTeacherList = () => {
    const lessonNameFilter = (
      document.querySelector("#lesson-name-filter") as HTMLSelectElement
    ).value;
    if (!lessonNameFilter) {
      setFilterBadges([]);
      return;
    }
    const targetLesson = lessonList.find(
      (l) => l.id.toString() == lessonNameFilter
    );
    setFilterBadges([
      { text: targetLesson.lessonName, value: targetLesson.id },
    ]);
  };

  const filteredTeacherList =
    filterBadges.length > 0
      ? teacherList.filter((t) =>
          t.majors.some((m) => m.id == filterBadges[0].value)
        )
      : teacherList;
  var i, j, temporary;
  const carouselGroup = [];
  for (i = 0, j = filteredTeacherList.length; i < j; i += carouselChunkSize) {
    temporary = filteredTeacherList.slice(i, i + carouselChunkSize);
    // do whatever
    carouselGroup.push(temporary);
  }

  return !dataLoaded ? null : (
    <>
      <section className="section-header pb-9 pb-md-11 pb-lg-13 mb-4 mb-lg-6 bg-danger text-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <h1
                className="display-2 mb-3"
                dangerouslySetInnerHTML={{
                  __html: currentPageData.shortDescTitle,
                }}
              ></h1>
              <p
                className="lead"
                dangerouslySetInnerHTML={{ __html: currentPageData.shortDesc }}
              ></p>
            </div>
          </div>
          <div className="card">
            <div className="card-header border-0">
              <div className="row">
                <div className="col-3">
                  <button
                    className="btn btn-icon btn-3 btn-primary"
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-service-filter"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-filter"></i>
                    </span>
                    <span className="btn-inner--text">Filter</span>
                  </button>
                </div>
                <div className="col-6 text-center pt-2">
                  {filterBadges.map((s) => (
                    <span className="badge badge-pill badge-secondary">
                      {s.text}
                    </span>
                  ))}
                </div>
                <div className="col-3 text-right pt-1">
                  <a
                    onClick={() => setFilterBadges([])}
                    className="btn btn-sm btn-danger btn-round btn-icon"
                    data-toggle="tooltip"
                    data-original-title="Temizle"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-trash"></i>
                    </span>
                    <span className="btn-inner--text">Temizle</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section section-lg pt-0">
        <div className="container mt-n8 mt-lg-n12 z-2">
          <div className="row mt-6 mt-md-n7">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  {carouselGroup.length > 0 ? (
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <ol className="carousel-indicators">
                        {carouselGroup.map((g, index) => (
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index.toString()}
                            className={index == 0 ? "active" : ""}
                            key={uuidv4()}
                          ></li>
                        ))}
                      </ol>
                      <div className="carousel-inner">
                        {carouselGroup.map((g, index) => (
                          <div
                            className={
                              "carousel-item" + (index == 0 ? " active" : "")
                            }
                            key={uuidv4()}
                          >
                            <div className="col-12 col-lg-12">
                              <div className="customer-testimonial d-flex mb-5">
                                {g.map((t) => (
                                  <div
                                    className="col-md-6 col-lg-4"
                                    style={{
                                      placeSelf: "center",
                                      top: "100px",
                                      bottom: "100px",
                                    }}
                                  >
                                    <div className="profile-card mb-7">
                                      <div className="card shadow-soft border-light text-center">
                                        <div className="profile-image">
                                          <img
                                            style={{ height: "100%" }}
                                            src={
                                              t.profilePhoto ||
                                              "/assets/img/Profile_avatar_placeholder_large.png"
                                            }
                                            className="card-img-top"
                                            alt="image"
                                          />
                                        </div>
                                        <div className="card-body mt-n5">
                                          <h5 className="card-title">
                                            {t.name + " " + t.surname}
                                          </h5>
                                          <h6 className="card-subtitle">
                                            <i className="fas fa-map-marker-alt text-info"></i>{" "}
                                            {t.city}
                                          </h6>
                                          <p className="card-text my-2">
                                            <Link
                                              to={`/ogretmenprofil/${t.id}`}
                                            >
                                              <mark>
                                                HAKKINDA DAHA FAZLA BILGI AL
                                              </mark>
                                            </Link>
                                          </p>
                                          <div
                                            className="stats"
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div className="stars star-color">
                                              <i
                                                className={`${
                                                  t.reviewScore > 0
                                                    ? "fas"
                                                    : "far"
                                                } fa-star`}
                                              ></i>
                                              <i
                                                className={`${
                                                  t.reviewScore > 1
                                                    ? "fas"
                                                    : "far"
                                                } fa-star`}
                                              ></i>
                                              <i
                                                className={`${
                                                  t.reviewScore > 2
                                                    ? "fas"
                                                    : "far"
                                                } fa-star`}
                                              ></i>
                                              <i
                                                className={`${
                                                  t.reviewScore > 3
                                                    ? "fas"
                                                    : "far"
                                                } fa-star`}
                                              ></i>
                                              <i
                                                className={`${
                                                  t.reviewScore > 4
                                                    ? "fas"
                                                    : "far"
                                                } fa-star`}
                                              ></i>
                                              <span className="star-color-bol ">
                                                {" "}
                                                |{" "}
                                              </span>
                                              <p className="d-inline text-default">
                                                <Link
                                                  to={`/ogretmenprofil/${t.id}`}
                                                >
                                                  {t.commentCount
                                                    ? t.commentCount +
                                                      " Yorum Var"
                                                    : "İlk Yorumu Siz Yapın"}
                                                </Link>{" "}
                                              </p>
                                            </div>
                                          </div>

                                          <ul className="social-buttons py-3">
                                            <li>
                                              <a
                                                href={t.facebookUrl}
                                                target="_blank"
                                                className="btn btn-link btn-facebook"
                                              >
                                                <i className="fab fa-facebook-f"></i>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href={t.twitterUrl}
                                                target="_blank"
                                                className="btn btn-link btn-twitter"
                                              >
                                                <i className="fab fa-twitter"></i>
                                              </a>
                                            </li>
                                            {/* <li>
                                                <a href="#" target="_blank" className="btn btn-link btn-twitch">
                                                    <i className="fab fa-twitch"></i>
                                                </a>
                                            </li> */}
                                            <li>
                                              <a
                                                href={t.instagramUrl}
                                                target="_blank"
                                                className="btn btn-link btn-instagram"
                                              >
                                                <i className="fab fa-instagram"></i>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  ) : (
                    <div className="text-center">
                      <h2 className=" text-white">
                        Kriterlere Uygun Eğitmen Bulunamadı
                      </h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentPageData.longDescTitle ? (
        <div className="section section-lg pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-5">
                <div className="card bg-white border-light shadow-soft flex-md-row no-gutters p-4">
                  <div className="card-body d-flex flex-column justify-content-between col-auto py-4 p-lg-5">
                    <h1
                      className="display-2 mb-4 text-center"
                      dangerouslySetInnerHTML={{
                        __html: currentPageData.longDescTitle,
                      }}
                    ></h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: currentPageData.longDesc,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div
        className="modal fade"
        id="modal-service-filter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modal-service-filter"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal- modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="card">
                <div className="card-body">
                  <div className="text-muted mb-2">
                    <h6 className="card-title text-uppercase text-muted mb-0">
                      Branş
                    </h6>
                  </div>
                  <div className="form-group mb-3 focused">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-tags"></i>
                        </span>
                      </div>
                      <select
                        className="form-control"
                        name="categoryid"
                        id="lesson-name-filter"
                      >
                        <option selected value="">
                          Seçiniz
                        </option>
                        {lessonList.map((l) => (
                          <option value={l.id}>{l.lessonName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="text-center">
                    <input
                      type="submit"
                      className="btn btn-info my-4"
                      data-dismiss="modal"
                      data-target="#modal-service-filter"
                      value="Filtrele"
                      onClick={filterTeacherList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
