import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { validateEmail } from "../../extensions/commonFunctions";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ManagerService from "../../services/managerService";
import ConsentModal from "../consentModal";
import ProfileWidget from "../profileWidget";
import ComponentLoader from "../sharedComponents/componentLoader";
import BranchesService from "../../services/branchesService";

export default function ManagersPage() {
  const dispatch = useDispatch();
  const branchesService = new BranchesService();
  const [managerList, setManagerList] = useState<Array<ManagerModel>>([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [deleteApproveModalVisiblity, setDeleteApproveModalVisiblity] =
    useState(false);

  const [searchBarManagerName, setSearchBarManagerName] = useState("");

  const [managerId, setManagerId] = useState("");
  const [formManagerName, setFormManagerName] = useState("");
  const [formManagerSurname, setFormManagerSurname] = useState("");
  const [formManagerCity, setFormManagerCity] = useState("");
  const [formManagerPhone, setFormManagerPhone] = useState("");
  const [formManagerEmail, setFormManagerEmail] = useState("");
  const [formManagerPassword, setFormManagerPassword] = useState("");
  const [formManagerProfilePhoto, setFormManagerProfilePhoto] = useState("");
  const [brancheList, setBranchesList] = useState<Array<BranchesOutputModel>>();
  const [filterBranche, setFilterBranche] = useState<number>(null);

  const managerService = new ManagerService();

  var managerWidgetActions: (id: string) => Array<ProfileWidgetAction> = (
    id: string
  ) => [
    { tag: "Düzenle", callback: () => editCallback(id) },
    { tag: "Sil", callback: () => deleteCallback(id) },
  ];

  const editCallback = (id: string) => {
    const selectedManager = managerList.find((m) => m.id == id);

    setManagerId(id);
    setFormManagerName(selectedManager.name);
    setFormManagerSurname(selectedManager.surname);
    setFormManagerCity(selectedManager.city);
    setFormManagerPhone(selectedManager.telephone);
    setFormManagerEmail(selectedManager.email);
    setFormManagerProfilePhoto(selectedManager.profilePhoto);
    setFormManagerPassword("");
    setModalVisibility(true);
  };

  const deleteCallback = (id: string) => {
    setManagerId(id);
    setDeleteApproveModalVisiblity(true);
  };

  const getManagerList = async () => {
    const result = await managerService.GetManagerList();

    if (result) setManagerList(result);
  };

  const getBranchesList = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranches();
    setBranchesList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getManagerList();
    getBranchesList();
  }, []);

  const openFileUpload = () => {
    (document.querySelector("#profile-photo-input") as HTMLInputElement).value =
      "";
    (
      document.querySelector("#profile-photo-input") as HTMLInputElement
    ).click();
  };

  const profileImageOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setFormManagerProfilePhoto(reader.result as string);
      //(document.querySelector("#preview-profile-img") as HTMLImageElement).src = reader.result as string;
    };

    reader.readAsDataURL(selectedFile);
  };

  const isEmailValid = validateEmail(formManagerEmail);
  const isPasswordLengthValid = formManagerPassword.length >= 5;
  const isPasswordFormatValid = !!formManagerPassword.match(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
  );

  const filterPhoneInput = (value: string) => {
    const matches = (value || "").match(/^[0-9]*$/);
    if (matches) setFormManagerPhone(matches[0]);
  };

  const checkPasswordValidity = () => {
    if (!managerId) {
      return isPasswordFormatValid && isPasswordLengthValid;
    } else
      return (
        !formManagerPassword || (isPasswordFormatValid && isPasswordLengthValid)
      );
  };

  const submitNewManagerForm = async () => {
    setModalVisibility(false);
    dispatch(showLoader());
    const result = await managerService.CreateNewManager({
      city: formManagerCity,
      email: formManagerEmail,
      name: formManagerName,
      surname: formManagerSurname,
      password: formManagerPassword,
      profilePhoto: formManagerProfilePhoto,
      telephone: formManagerPhone,
      id: managerId,
    });
    if (result) await getManagerList();
    dispatch(hideLoader());
  };

  const deleteApproveHandler = async () => {
    setDeleteApproveModalVisiblity(false);
    dispatch(showLoader());
    const result = await managerService.DeleteManager(managerId);
    if (result) await getManagerList();
    dispatch(hideLoader());
  };

  const openCreateNewManagerModal = () => {
    setManagerId("");
    setFormManagerName("");
    setFormManagerSurname("");
    setFormManagerCity("");
    setFormManagerPhone("");
    setFormManagerEmail("");
    setFormManagerProfilePhoto("");
    setModalVisibility(true);
  };

  const filterManagerList = () => {
    setSearchBarManagerName(
      (document.querySelector("#manager-name-search") as HTMLInputElement).value
    );
  };

  const filteredManagerList = managerList.filter((t) => {
    return (
      (t.name + " " + t.surname)
        .toLocaleLowerCase()
        .includes(searchBarManagerName.toLocaleLowerCase()) &&
      (!Number.isInteger(filterBranche) || t.branch?.id === filterBranche)
    );
  });

  return (
    <>
      <div className="page-content">
        <section className="row">
          <div className="content-page">
            <div className="row filter-row">
              <div className="col-sm-12 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">Yönetici Adı</label>
                  <i className="bar"></i>
                  <input
                    type="text"
                    id="manager-name-search"
                    className="form-control"
                    onChange={(e) => setSearchBarManagerName(e.target.value)}
                    placeholder="Yönetici Adı Gir"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <label className="control-label">Kurum</label>
                <i className="bar"></i>
                <div className="form-group custom-mt-form-group">
                  <select
                    id="student-active-passive-search"
                    onChange={(e) => setFilterBranche(Number(e.target.value))}
                    className="form-select"
                  >
                    <option selected value={null}>
                      Tüm Kurumlar
                    </option>
                    {brancheList?.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">Arama Yap</label>
                  <i className="bar"></i>
                  <a
                    onClick={filterManagerList}
                    className="btn btn-success btn-block form-control text-white"
                  >
                    Ara
                  </a>
                </div>
              </div> */}
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">&nbsp;</label>
                  <i className="bar"></i>
                  <button
                    onClick={openCreateNewManagerModal}
                    className="btn btn-info btn-block form-control"
                  >
                    {" "}
                    Yeni Yönetici Kaydı
                  </button>
                </div>
              </div>
            </div>
            <div className="row staff-grid-row mt-3">
              {!true ? (
                <ComponentLoader />
              ) : (
                filteredManagerList.map((t) => (
                  <ProfileWidget
                    subtext={t.city}
                    photoUrl={t.profilePhoto}
                    key={t.id}
                    actions={managerWidgetActions(t.id)}
                    name={t.name}
                    surname={t.surname}
                  />
                ))
              )}
            </div>
          </div>
        </section>
      </div>
      <div
        className="modal fade text-left show"
        id="yeniOgrenciEkleModal"
        tabIndex={-1}
        aria-labelledby="myModalLabel33"
        style={{
          overflow: "auto",
          display: modalVisibility ? "block" : "none",
          backgroundColor: "rgba(0,0,0,0.4)",
        }}
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel33">
                {"Yönetici Formu"}
              </h4>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i
                  data-feather="x"
                  onClick={() => setModalVisibility(false)}
                ></i>
              </button>
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="row justify-content-center">
                  <div className="col-6">
                    <div className="card-profile-image">
                      <div
                        className="pic-holder"
                        onClick={openFileUpload}
                        style={{ width: "97%", height: "215px" }}
                      >
                        <a href="#">
                          <img
                            id="preview-profile-img"
                            src={
                              formManagerProfilePhoto ||
                              "/assets/img/Profile_avatar_placeholder_large.png"
                            }
                            height="225"
                            style={{ width: "100%", objectFit: "contain" }}
                            className="profile-teacher-photo rounded-circle"
                          />
                        </a>
                        <input
                          id="profile-photo-input"
                          type="file"
                          onChange={profileImageOnChangeHandler}
                          hidden
                        />
                        <label className="upload-file-block">
                          <div className="text-center">
                            <div className="mb-2">
                              <i className="fa fa-camera fa-2x"></i>
                            </div>
                            <div className="text-uppercase">
                              Fotoğraf <br /> Ekle
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Yönetici Adı: </label>
                    <input
                      type="text"
                      placeholder="Ad"
                      value={formManagerName}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormManagerName(e.target.value)
                      }
                      className={
                        "form-control" + (formManagerName ? "" : " is-invalid")
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Yönetici Soyadı: </label>
                    <input
                      type="text"
                      placeholder="Soyad"
                      value={formManagerSurname}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormManagerSurname(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formManagerSurname ? "" : " is-invalid")
                      }
                      required
                    />
                  </div>

                  <div className="form-group col-12">
                    <label>Telefon: </label>
                    <input
                      type="text"
                      placeholder="Telefon giriniz"
                      value={formManagerPhone}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        filterPhoneInput(e.target.value)
                      }
                      className={
                        "form-control" + (formManagerPhone ? "" : " is-invalid")
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Email: </label>
                    <input
                      type="text"
                      placeholder="Email giriniz"
                      value={formManagerEmail}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormManagerEmail(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formManagerEmail && isEmailValid ? "" : " is-invalid")
                      }
                      required
                    />
                    <div className="invalid-feedback">
                      <i className="bx bx-radio-circle"></i>
                      {!isEmailValid ? "Email formatında giriş yapınız" : ""}
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <label>Yönetici Giriş Şifresi: </label>
                    <input
                      type="text"
                      placeholder="Yönetici Giriş Şifresi giriniz"
                      value={formManagerPassword}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormManagerPassword(e.target.value)
                      }
                      className={
                        "form-control" +
                        (checkPasswordValidity() ? "" : " is-invalid")
                      }
                    />
                    <div className="invalid-feedback">
                      <i className="bx bx-radio-circle"></i>
                      {!isPasswordLengthValid
                        ? "Şifre en az 5 karakter uzunluğunda olmalıdır"
                        : !isPasswordFormatValid
                        ? "Şifre küçük, büyük harf ve rakam içermelidir"
                        : ""}
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setModalVisibility(false)}
                className="btn btn-default rounded pill"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times-circle"></i>
                <span className="d-inline p-2">Kapat</span>
              </button>
              <button
                type="button"
                disabled={
                  !(
                    isEmailValid &&
                    setFormManagerName &&
                    formManagerSurname &&
                    formManagerPhone &&
                    setFormManagerEmail &&
                    (!managerId
                      ? isPasswordLengthValid && isPasswordFormatValid
                      : true)
                  )
                }
                className="btn btn-primary ml-1"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-check-circle"></i>
                <span className="d-inline p-2" onClick={submitNewManagerForm}>
                  Kaydet
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConsentModal
        title="İşlem Onayı"
        message="Yönetici hesabını silmek istediğinize emin misiniz?"
        visible={deleteApproveModalVisiblity}
        approveCallback={deleteApproveHandler}
        cancelCallback={() => setDeleteApproveModalVisiblity(false)}
      />
    </>
  );
}
