import React, { useEffect } from 'react'

export default function TumYorumlar() {
    return (
        <section className="section section-lg mt-6 mt-md-6">
            <div className="container">
                <div className="row justify-content-center mb-4 mb-lg-5">
                    <div className="col-12 col-md-8 text-center">
                        <h1 className="h1 font-weight-bolder mb-4">Gümüş Eğitim ile ilgili tüm Yorumlar</h1>
                        <p className="lead">Aileler ve Öğrenciler neden Gümüş Eğitim'i tercih ediyor?</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mx-auto">
                        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="col-12 col-lg-12">
                                        <div className="customer-testimonial d-flex mb-5">
                                            <img src="../assets/img/humandoesnotexist/M1.jpg"
                                                className="image image-sm mr-3 rounded-circle shadow" alt="" />
                                            <div className="content bg-soft shadow-soft border border-light rounded position-relative p-4">
                                                <div className="d-flex mb-4">
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                </div>
                                                <p className="mt-2">"Mollit proident do et elit. Ea deserunt mollit excepteur excepteur
                                                    consectetur excepteur irure magna anim tempor dolore. Officia aute do commodo
                                                    deserunt incididunt fugiat. Dolor reprehenderit proident dolore aliqua consequat."
                                                </p>
                                                <span className="h6">- Mehmet Yılmaz <small className="ml-0 ml-md-2">Öğrenci</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="col-12 col-lg-12">
                                        <div className="customer-testimonial d-flex mb-5">
                                            <img src="../assets/img/humandoesnotexist/F4.jpg"
                                                className="image image-sm mr-3 rounded-circle shadow" alt="" />
                                            <div className="content bg-soft shadow-soft border border-light rounded position-relative p-4">
                                                <div className="d-flex mb-4">
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                </div>
                                                <p className="mt-2">"Mollit proident do et elit. Ea deserunt mollit excepteur excepteur
                                                    consectetur excepteur irure magna anim tempor dolore. Officia aute do commodo
                                                    deserunt incididunt fugiat. Dolor reprehenderit proident dolore aliqua consequat."
                                                </p>
                                                <span className="h6">- Ayşe Yıldırım <small className="ml-0 ml-md-2">Öğrenci</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="col-12 col-lg-12">
                                        <div className="customer-testimonial d-flex mb-5">
                                            <img src="../assets/img/humandoesnotexist/M3.jpg"
                                                className="image image-sm mr-3 rounded-circle shadow" alt="" />
                                            <div className="content bg-soft shadow-soft border border-light rounded position-relative p-4">
                                                <div className="d-flex mb-4">
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                    <span className="text-warning mr-2"><i className="star fas fa-star"></i></span>
                                                </div>
                                                <p className="mt-2">"Mollit proident do et elit. Ea deserunt mollit excepteur excepteur
                                                    consectetur excepteur irure magna anim tempor dolore. Officia aute do commodo
                                                    deserunt incididunt fugiat. Dolor reprehenderit proident dolore aliqua consequat."
                                                </p>
                                                <span className="h6">- Ahmet Yılmaz <small className="ml-0 ml-md-2">Öğrenci</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}