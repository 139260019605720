import "../style/examresultupload.css";

import React, { useEffect, useRef, useState } from "react";
import StudentService from "../../services/studentService";
import TestService from "../../services/testService";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../reducers/messengerSlice";
import Choices from "choices.js";
import { hideLoader, showLoader } from "../../reducers/commonSlice";

var choices: Choices;

enum InputType {
  radioButtonList = "R",
  textInput = "T",
}

declare global {
  interface ExamResultQuestionModel extends QuestionOutputModel {
    inputType: InputType;
    userAnswer?: string;
  }
}

export default function ExamResultUpload(props: {
  show: boolean;
  onClose: () => void;
  data: TestOutputModel;
  testId: number;
  callback: () => Promise<void>;
  studentId?: string;
}) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const radioOptions = ["A", "B", "C", "D", "E"];
  const [questionList, setQuestionList] = useState<
    Array<ExamResultQuestionModel>
  >([]);
  const [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const studentService = new StudentService();
  const testService = new TestService();
  console.warn("comp", props.studentId);
  useEffect(() => {
    setQuestionList(
      props.data.testQuestions.map((v) => ({
        ...v,
        inputType: InputType.radioButtonList,
      }))
    );
  }, [props]);

  useEffect(() => {
    choices && choices.destroy();
    const choicesList = [];
    setSelectedStudentId(props.studentId);
    studentList.forEach((l) =>
      choicesList.push({ value: l.id, label: l.name + " " + l.surname })
    );

    choicesList.unshift({
      value: "",
      label: "Seçim Yapınız",
      selected: true,
      disabled: true,
    });

    choices = new Choices(ref.current, {
      itemSelectText: "Seçmek için tıklayınız",
      removeItemButton: true,
      choices: choicesList,
      noResultsText: "Öğrenci bulunamadı",
      shouldSort: false,
    });
  }, [studentList]);

  useEffect(() => {
    getStudentList();
  }, []);

  const uploadTestResult = async () => {
    dispatch(showLoader());
    const result = await testService.UploadTestResult({
      questionResults: questionList.map((q) => ({
        value: q.userAnswer,
        questionId: q.id,
      })),
      studentId: selectedStudentId,
      testId: props.testId,
    });

    props.onClose();

    if (!result)
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message:
            "İşlem başarısız, cevap anahtarının yüklü olduğundan emin olunuz",
        })
      );
    else await props.callback();
    dispatch(hideLoader());
  };

  const getStudentList = async () => {
    const result = await studentService.GetStudentList();

    if (result != null) setStudentList(result);
  };

  const swapInput = (index: number) => {
    const tempQuestionList = [...questionList];
    const q = tempQuestionList[index];
    if (q.inputType == InputType.radioButtonList)
      q.inputType = InputType.textInput;
    else q.inputType = InputType.radioButtonList;

    setQuestionList(tempQuestionList);
  };

  const selectQuestionAnswer = (questionId: number, answer: string) => {
    const tempQuestionList = [...questionList];
    const questionToUpdate = tempQuestionList.find((q) => q.id == questionId);
    questionToUpdate.userAnswer = answer;
    setQuestionList(tempQuestionList);
  };

  useEffect(() => {
    const studentResults = props.data.testResults.filter(
      (r) => r.student.id == selectedStudentId
    );
    if (studentResults && studentResults.length > 0) {
      const studentResult = studentResults.sort((a, b) => b.id - a.id)[0];
      const nextQuestionList: Array<ExamResultQuestionModel> =
        props.data.testQuestions.map((q) => ({
          ...q,
          inputType: InputType.radioButtonList,
        }));
      nextQuestionList.forEach((q) => {
        const qAnswer = studentResult.testQuestionResults.find(
          (r) => r.questionId == q.id
        );
        if (qAnswer) {
          q.userAnswer = qAnswer.value;
          q.inputType =
            !q.userAnswer || radioOptions.includes(q.userAnswer)
              ? InputType.radioButtonList
              : InputType.textInput;
        }
      });

      setQuestionList(nextQuestionList);
    } else
      setQuestionList(
        props.data.testQuestions.map((q) => ({
          ...q,
          inputType: InputType.radioButtonList,
        }))
      );
  }, [selectedStudentId]);

  return (
    <div
      className="modal fade text-left show"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        overflow: "auto",
        paddingRight: "16px",
        display: "block",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel17">
              Sonuç Yükle
            </h4>
            <button
              onClick={props.onClose}
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group col-12 pb-4">
                <label>Öğrenci: </label>
                <select
                  style={{ zIndex: 1 }}
                  className={"choices form-select"}
                  ref={ref}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setSelectedStudentId(e.target.value)
                  }
                  disabled={props.studentId === undefined ? false : true}
                ></select>
              </div>
              {questionList.map((item, index) => (
                <div className="col-md-6 pb-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{index + 1}.</span>
                    </div>
                    <div
                      style={{
                        padding: "0 10px 0 10px",
                      }}
                    >
                      <div
                        style={{
                          display:
                            item.inputType == InputType.radioButtonList
                              ? "flex"
                              : "none",
                          justifyContent: "space-between",
                        }}
                        className="resultRadio"
                      >
                        <input
                          type="radio"
                          id={`A-${index}`}
                          name={`skills-${index}`}
                          value="A"
                          checked={item.userAnswer == "A"}
                          onChange={() => selectQuestionAnswer(item.id, "A")}
                        />
                        <label htmlFor={`A-${index}`}>A</label>
                        <input
                          type="radio"
                          id={`B-${index}`}
                          name={`skills-${index}`}
                          value="B"
                          checked={item.userAnswer == "B"}
                          onChange={() => selectQuestionAnswer(item.id, "B")}
                        />
                        <label htmlFor={`B-${index}`}>B</label>
                        <input
                          type="radio"
                          id={`C-${index}`}
                          name={`skills-${index}`}
                          value="C"
                          checked={item.userAnswer == "C"}
                          onChange={() => selectQuestionAnswer(item.id, "C")}
                        />
                        <label htmlFor={`C-${index}`}>C</label>
                        <input
                          type="radio"
                          id={`D-${index}`}
                          name={`skills-${index}`}
                          value="D"
                          checked={item.userAnswer == "D"}
                          onChange={() => selectQuestionAnswer(item.id, "D")}
                        />
                        <label htmlFor={`D-${index}`}>D</label>
                        <input
                          type="radio"
                          id={`E-${index}`}
                          name={`skills-${index}`}
                          value="E"
                          checked={item.userAnswer == "E"}
                          onChange={() => selectQuestionAnswer(item.id, "E")}
                        />
                        <label htmlFor={`E-${index}`}>E</label>
                      </div>
                      <div
                        style={{
                          display:
                            item.inputType == InputType.textInput
                              ? "block"
                              : "none",
                        }}
                      >
                        <input
                          className="form-control"
                          value={
                            radioOptions.includes(item.userAnswer)
                              ? ""
                              : item.userAnswer
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            selectQuestionAnswer(item.id, e.target.value)
                          }
                          name={`skills-${index}`}
                        />
                      </div>
                    </div>

                    <div className="input-group-append">
                      <i
                        onClick={() => swapInput(index)}
                        style={{ cursor: "pointer" }}
                        className="fas fa-sync-alt"
                      ></i>
                    </div>
                    {/* <button onClick={() => swapInput(index)} className="btn btn-warning resultButton"><i className="fas fa-sync-alt"></i></button> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={props.onClose}
              type="button"
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              disabled={!selectedStudentId}
              type="button"
              onClick={uploadTestResult}
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Yükle</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
