import BaseService from "./baseService";

declare global {
  interface BranchesInputModel {
    name: string;
  }

  interface BranchesOutputModel {
    id: number;
    name: string;
  }

  interface BrancheOutputModel {
    teachers: Array<{
      majors: Array<{
        id: number;
        lessonName: string;
        lessons: any;
      }>;
      classrooms: Array<{
        id: number;
        name: string;
      }>;
      id: string;
      students: any;
      name: string;
      surname: string;
      profilePhoto: string;
    }>;
    students: Array<{
      id: string;
      name: string;
      surname: string;
    }>;
    managers: Array<{
      id: string;
      name: string;
      surname: string;
    }>;
    id: number;
    name: string;
  }

  interface BrancheUserInputModel {
    users: Array<string>;
  }

  interface BrancheUserOutputModel {}
}

export default class BranchesService extends BaseService {
  constructor() {
    super("/Branches");
  }

  GetBranches() {
    return this.Get<Array<BranchesOutputModel>>();
  }

  CreateBranche(data: BranchesInputModel) {
    return this.Post<BranchesInputModel, BranchesOutputModel>(data);
  }

  UpdateBranche(id: number, data: BranchesInputModel) {
    return this.Post<BranchesInputModel, BranchesOutputModel>(
      data,
      `/Branches/${id}`
    );
  }

  DeleteBranche(id: number) {
    return this.Delete<number>(id, "/Branches");
  }

  GetBranche(id: number) {
    this.endPoint = "/Branches";
    return this.GetById<number, BrancheOutputModel>(id);
  }

  BrancheAddUser(id: number, data: BrancheUserInputModel) {
    return this.Post<BrancheUserInputModel, BranchesOutputModel>(
      data,
      `/Branches/AddStudent/${id}`
    );
  }

  BrancheDeleteUser(id: number, data: BrancheUserInputModel) {
    return this.Post<BrancheUserInputModel, BranchesOutputModel>(
      data,
      `/Branches/DeleteStudent/${id}`
    );
  }

  AddBranchForUser(id: string) {
    return this.Post<any, any>({}, `/Branches/AddBranchForUser/${id}`);
  }
}
