import BaseService from "./baseService";

declare global {
  interface ClassroomInputModel {
    name: string;
    id?: number;
  }

  interface ClassroomOutputModel {
    students: Array<StudentOutputModel>;
    name: string;
    id: number;
    lessons?: Array<LessonOutputModel>;
  }

  interface TeacherClassroomByStudentIdOutputModel {
    id: number;
    lessons?: Array<any>;
    name: string;
    students: Array<StudentOutputModel>;
  }
}

export default class ClassroomService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/classroom");
  }

  async GetClassroomList(): Promise<Array<ClassroomOutputModel>> {
    return await this.Get(null, "/classroom", false);
  }

  async AddClassroom(
    classroom: ClassroomInputModel
  ): Promise<ClassroomOutputModel> {
    return this.Post(classroom);
  }

  async GetTeacherClassroomList(id: number) {
    this.endPoint = "/teacherclassroom";
    return this.GetById<number, ClassroomOutputModel>(id);
  }

  GetTeacherClassroomByStudentId(studentId?: string) {
    return this.Get<TeacherClassroomByStudentIdOutputModel>(
      null,
      `/teacherclassroom?studentId=${studentId}`
    );
  }

  DeleteClassroom(id: number) {
    return this.Delete<number>(id, "/classroom");
  }

  updateClassroom(model: ClassroomInputModel) {
    return this.Put<number, ClassroomInputModel, boolean>(
      model.id,
      model,
      "/classroom"
    );
  }

  GetClassroomById(id: number) {
    this.endPoint = "/classroom";
    return this.GetById<number, Array<ClassroomOutputModel>>(id);
  }

  GetClassroomForAdmin(data: { classroomId: number; branchId: number }) {
    return this.Post(data, `/classroom/foradmin`);
  }
}
