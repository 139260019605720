import { useEffect } from 'react';

const useStylesheet = (url: string) => {
    useEffect(() => {
        const link = document.createElement('link');

        link.rel = "stylesheet"
        link.href = url;
        //script.async = true;

        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        }
    }, [url]);
};

export default useStylesheet;