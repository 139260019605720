// @ts-nocheck

// import iconv from "iconv-lite";

export const lineDetection = async (opticFile: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(opticFile);

    let convertedParameters = [];

    reader.onload = function (e) {
      const content = reader.result;

      // const file = iconv.decode(content, "ISO-8859-9");
      const file = content;

      const lines = file.split("\r\n");
      const lastElement = [...lines[1]][lines[1].length - 1];

      let parameters = [];

      lines.map((item) => {
        const splittedItem = item.split("=");
        let name = "";
        if (lastElement == "=") {
          name = splittedItem[splittedItem.length - 2];
        } else {
          name = splittedItem[splittedItem.length - 1];
        }
        const direction = splittedItem[5];
        if (direction == "D") {
          parameters = [
            ...parameters,
            {
              parameter: name,
              length: Math.abs(splittedItem[2] - splittedItem[3]) + 1,
            },
          ];
        } else if (direction == "Y") {
          parameters = [
            ...parameters,
            {
              parameter: name,
              length: Math.abs(splittedItem[0] - splittedItem[1]) + 1,
            },
          ];
        }
      });

      parameters.map((item, index) => {
        if (index == 0) {
          parameters[index] = { ...item, ...{ start: 0 } };
        } else {
          parameters[index] = {
            ...item,
            ...{
              start: parameters[index - 1].length + parameters[index - 1].start,
            },
          };
        }
      });

      parameters.map((item, index) => {
        if (index != 0) {
          if (parameters[index].parameter === parameters[index - 1].parameter) {
            const mergedParameters = {
              parameter: parameters[index].parameter,
              length: parameters[index].length + parameters[index - 1].length,
              start: parameters[index - 1].start,
            };
            parameters[index - 1] = mergedParameters;
            parameters.splice(index, 1);
            convertedParameters = parameters;
          } else if (parameters.length - 1 === index) {
            if (!convertedParameters.length) {
              convertedParameters = parameters;
            }
          }
        }
      });
      resolve(convertedParameters);
    };
    reader.onerror = function (error) {
      reject(error); // Hata durumunda Promise'i reject et
    };
  });
};
