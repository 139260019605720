// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import AuthorizationService from "../../services/authorizationService";
import { AdminIdentityContext } from "../panels/adminPanel";

import "react-datepicker/dist/react-datepicker.css";
import TasksWidget from "../teacherPanelComponents/TasksWidget";
import { useState } from "react";
import RoleService from "../../services/roleService";
import { PDFViewer } from "@react-pdf/renderer";
import {
  PracticeExamCompareReport,
  StudentExamCompareReport,
  StudentExamReport,
  StudentReport2,
  SubClassroomExamCompareReport,
} from "../../utils/Pdf";

export default function AdminMainPage() {
  const dispatch = useDispatch();
  const roleService = new RoleService();
  const adminContext = useContext(AdminIdentityContext);
  const [userRole, setUserRole] = useState<string[]>([]);

  const getUserRoles = async () => {
    dispatch(showLoader());
    const response = await roleService.GetUserRoles();
    setUserRole(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  const logout = async () => {
    let authorizationService = new AuthorizationService();
    dispatch(showLoader());
    await authorizationService.Logout();
  };

  const mergedData: TestReportForSubClassroomOutputModel = {
    info: {
      testName: "testName",
      classroomInfo: {
        id: 1,
        name: "classroomInfoName",
        classroom: {
          id: 1,
          name: "classroomName",
        },
        branches: {
          id: 1,
          name: "branchesName",
        },
      },
      date: new Date(),
    },
    exams: {
      testResults: [
        {
          questionCount: 2,
          correctAnswerCount: 1,
          incorrectAnswerCount: 1,
          unansweredCount: 0,
          student: {
            name: "Berk",
            surname: "Duman",
          },
          testId: 12,
          testName: "testName",
          fillDate: "FillDate",
          lessonName: "lessonName",
          lessonId: 2,
          className: "className",
          testAssignmentId: 3,
          id: 3,
          net: 10,
          successRate: 80,
        },
      ],
      testName: "testName",
      average: 15,
    },
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-md-12">
        <div className="card">
          <div className="card-header">
            <h4>Merhaba!</h4>
          </div>
          <div className="card-content pb-4">
            <div className="recent-message d-flex px-4 py-3">
              <div className="avatar avatar-lg">
                <img
                  src={
                    adminContext.profilePhoto ||
                    "/assets/img/Profile_avatar_placeholder_large.png"
                  }
                />
              </div>
              <div className="name ms-4">
                <h6 className="text-muted mb-0">Hoş geldiniz</h6>
                <h5 className="mb-1">
                  {adminContext.name + " " + adminContext.surname}
                </h5>
              </div>
            </div>
            <div className="px-4">
              <button
                onClick={logout}
                className="btn btn-block btn-xl btn-light-primary font-bold mt-3"
              >
                Çıkış Yap
              </button>
            </div>
          </div>
        </div>
      </div>
      {!userRole.includes("admin") && (
        <div className="col-lg-6 col-md-12">
          <TasksWidget />
        </div>
      )}

      {/* <PDFViewer style={{ width: "100%", height: 600 }}>
        <PracticeExamCompareReport />
      </PDFViewer> */}
    </div>
  );
}
