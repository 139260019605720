import React, { useEffect, useState } from "react";
import LessonService from "../../services/lessonService";
import AccordionItem from "./accordionItem";
import EditableAccordionItem from "./editableAccordionItem";

function Accordion(props: {
  id: string;
  insertable: boolean;
  insertTitle?: string;
  insertInputLabel?: string;
  isSelectBox?: boolean;
  selectBoxList?: Array<{ text: string; value: string }>;
  populate: () => React.ReactNode;
  onInsert?: (name: string) => Promise<boolean>;
  onInserClickCustomHandler?: () => void;
}) {
  const [newTitle, setNewTitle] = useState("");
  const [IsInInsertMode, setIsInInsertMode] = useState(false);
  const createSubmitHandler = async () => {
    let result = await props.onInsert(newTitle);
    result && setIsInInsertMode(false);
  };

  const onInsertClickHandler =
    props.onInserClickCustomHandler || (() => setIsInInsertMode(true));
  return (
    <div className="accordion" id={`accordion${props.id}`}>
      {props.insertable ? (
        IsInInsertMode ? (
          <div className="accordion-item card card-md card-body shadow align-items-center">
            <div className="form-group col-12">
              <label>{props.insertInputLabel}: </label>
              {props.isSelectBox ? (
                <select
                  className="form-select"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setNewTitle(e.target.value)
                  }
                  value={newTitle}
                >
                  <option selected value="" disabled>
                    Seçiniz
                  </option>
                  {(props.selectBoxList || []).map((sbl) => (
                    <option value={sbl.value}>{sbl.text}</option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  placeholder={props.insertInputLabel + " Giriniz"}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewTitle(e.target.value)
                  }
                  value={newTitle}
                  className={
                    "form-control" + (newTitle ? " is-valid" : " is-invalid")
                  }
                  required
                />
              )}
              <div className="mt-1 buttons">
                <button
                  className="btn btn-danger rounded-pill"
                  onClick={() => setIsInInsertMode(false)}
                >
                  İptal
                </button>
                <button
                  className="btn btn-success rounded-pill"
                  disabled={!newTitle}
                  onClick={createSubmitHandler}
                >
                  Kaydet
                </button>
              </div>
            </div>
          </div>
        ) : (
          // yeni ders ekle
          <div className="accordion-item card card-md card-body shadow align-items-center">
            {props.insertable && (
              <div style={{ cursor: "pointer" }} onClick={onInsertClickHandler}>
                <i className="fas fa-plus"></i> {props.insertTitle}
              </div>
            )}
          </div>
        )
      ) : (
        <div />
      )}
      {props.populate()}
    </div>
  );
}

export default React.memo(Accordion);
