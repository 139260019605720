import React, { useEffect, useState } from "react";
import RichTextEditor from "../sharedComponents/richTextEditor";
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MailingService from "../../services/mailingService";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../reducers/messengerSlice";
import { hideLoader, showLoader } from "../../reducers/commonSlice";

export default function EmailPage() {
  const dispatch = useDispatch();
  const mailingService = new MailingService();

  const [subscriberList, setSubscriberList] = useState<Array<SubscriberModel>>(
    []
  );
  const [newSubscriber, setNewSubscriber] = useState<SubscriberModel>({
    email: "",
    surname: "",
    name: "",
  });
  const [mailToSend, setMailToSend] = useState<MailInputModel>({
    to: "",
    subject: "",
    body: "",
  });
  const [selectboxOptions, setSelectboxOptions] = useState([]);
  const [selectedSelectboxOptions, setSelectedSelectboxOptions] = useState([]);

  const addNewSubscriber = async () => {
    dispatch(showLoader());
    const result = await mailingService.AddNewSubscriber(newSubscriber);
    dispatch(hideLoader());
    if (result) {
      setNewSubscriber(null);
      dispatch(
        sendMessage({
          title: "Bilgilendirme",
          message: "Kişi başarıyla eklendi",
        })
      );
      getAllSubscribers();
    } else dispatch(sendMessage({ title: "Hata", message: "Kişi eklenemedi" }));
  };

  const sendEmail = async () => {
    let result = true;
    dispatch(showLoader());
    for (var mailTo of selectedSelectboxOptions) {
      result &&= await mailingService.SendMailTo({
        to: mailTo.value,
        subject: mailToSend.subject,
        body: mailToSend.body,
      });
    }
    dispatch(hideLoader());

    if (result)
      dispatch(
        sendMessage({
          title: "Bilgilendirme",
          message: "Mail başarıyla iletildi",
        })
      );
    else
      dispatch(
        sendMessage({
          title: "Hata",
          message: "Mail gönderme esnasında bir hata oluştu",
        })
      );
  };

  const getAllSubscribers = async () => {
    const result = await mailingService.GetAllSubscribers();
    if (result) {
      setSubscriberList(result);
      const options = result.map((s) => ({
        label: `${s.email} (${s.name} ${s.surname})`,
        value: s.email,
      }));
      options.unshift({ label: "Hepsini Seç", value: "*" });
      setSelectboxOptions(options);
    }
  };

  useEffect(() => {
    getAllSubscribers();
  }, []);

  const selecboxOnChangeHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedSelectboxOptions(selectboxOptions);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedSelectboxOptions([]);
    } else if (event.action === "deselect-option") {
      setSelectedSelectboxOptions(value.filter((o) => o.value !== "*"));
    } else if (value.length === selectboxOptions.length - 1) {
      setSelectedSelectboxOptions(selectboxOptions);
    } else setSelectedSelectboxOptions(value);
  };

  const textEditorOnChangeHandler = (text: string) => {
    mailToSend.body = text;
  };

  const isNewEmailValid =
    newSubscriber &&
    !!newSubscriber.email.match(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Mail Düzenle</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-12">
                <label>Kime: </label>
                {/* <ReactMultiSelectCheckboxes value={selectedSelectboxOptions} onChange={selecboxOnChangeHandler} options={selectboxOptions} placeholderButtonLabel={"Seçiniz"} /> */}
              </div>
              <div className="form-group col-12">
                <label>Konu: </label>
                <input
                  type="text"
                  value={mailToSend ? mailToSend.subject : ""}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setMailToSend({ ...mailToSend, subject: e.target.value })
                  }
                  className="form-control"
                  placeholder="Konu Ekleyin"
                />
              </div>
            </div>
            <RichTextEditor
              placeholder=" "
              textEditorId="summernote-email"
              innerHtml={mailToSend.body}
              onChangeCallback={textEditorOnChangeHandler}
            />
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary"
              onClick={sendEmail}
              disabled={!(selectedSelectboxOptions.length > 0)}
            >
              Gönder
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Yeni Kişi Ekle</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-12">
                <label>İsim: </label>
                <input
                  type="text"
                  value={newSubscriber ? newSubscriber.name : ""}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewSubscriber({ ...newSubscriber, name: e.target.value })
                  }
                  className="form-control"
                  placeholder="İsim Giriniz"
                />
              </div>
              <div className="form-group col-12">
                <label>Soyisim: </label>
                <input
                  type="text"
                  value={newSubscriber ? newSubscriber.surname : ""}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewSubscriber({
                      ...newSubscriber,
                      surname: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Soyisim Giriniz"
                />
              </div>
              <div className="form-group col-12">
                <label>Email: </label>
                <input
                  type="text"
                  value={newSubscriber ? newSubscriber.email : ""}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewSubscriber({
                      ...newSubscriber,
                      email: e.target.value,
                    })
                  }
                  className={
                    "form-control" + (isNewEmailValid ? "" : " is-invalid")
                  }
                  placeholder="Mail Adresi Giriniz"
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary"
              onClick={addNewSubscriber}
              disabled={
                !(newSubscriber && newSubscriber.email && isNewEmailValid)
              }
            >
              Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
