import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import AuthorizationService from "../../services/authorizationService";
import RoleService, { Roles } from "../../services/roleService";

export default function AfterLoginMenu() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const roleService = new RoleService();
  const authorizationService = new AuthorizationService();

  const checkUserLoginStatus = async () => {
    const result = await roleService.GetUserRoles();
    setIsLoggedIn(result != null);
    setUserRoles(result);
  };

  useEffect(() => {
    checkUserLoginStatus();
  }, []);

  const getUserPage = (userRole: string) => {
    switch (userRole) {
      case Roles.Admin:
      case Roles.Manager:
        return "admin";
      case Roles.Student:
        return "student";
      case Roles.Teacher:
        return "teacher";
    }
  };

  const logout = () => {
    authorizationService.Logout();
  };

  return (
    <section className="vh-100 d-flex align-items-center overlay-soft giris">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-end">
            <div className="container-fluid mt--6">
              <div className="row">
                <div className="col-xl-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <a href="https://gumusegitim.com" target="_blank">
                        <button
                          style={{
                            backgroundColor: "rgb(71 114 166)",
                            color: "white",
                          }}
                          className="btn btn-lg btn-block"
                        >
                          Anasayfa
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <Link to="/tumogretmenler">
                        <button
                          style={{
                            backgroundColor: "rgb(71 114 166)",
                            color: "white",
                          }}
                          className="btn btn-lg btn-block"
                        >
                          Tüm Öğretmenler
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      {isLoggedIn === null ? null : isLoggedIn ? (
                        <a href={"/" + getUserPage(userRoles[0])}>
                          <button
                            style={{
                              color: "white",
                            }}
                            className="btn btn-lg btn-block btn-success"
                          >
                            Panele Dön
                          </button>
                        </a>
                      ) : (
                        <Link to="/login">
                          <button
                            style={{
                              backgroundColor: "rgb(71 114 166)",
                              color: "white",
                            }}
                            className="btn btn-lg btn-block"
                          >
                            Giriş Yap
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3">
                  <div className="card">
                    <div className="card-body">
                      {isLoggedIn === null ? null : isLoggedIn ? (
                        <button
                          style={{
                            color: "white",
                          }}
                          className="btn btn-lg btn-block btn-warning"
                          onClick={logout}
                        >
                          Çıkış Yap
                        </button>
                      ) : (
                        <Link to="/register">
                          <button
                            style={{
                              backgroundColor: "rgb(71 114 166)",
                              color: "white",
                            }}
                            className="btn btn-lg btn-block"
                          >
                            Kayıt Ol
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
