import React, { useEffect, useState } from "react";
import RoleService from "../../services/roleService";
import TestService from "../../services/testService";
import ExamResultUpload from "../adminPanelComponents/examResultUpload";
import ConsentModal from "../consentModal";
import LessonService from "../../services/lessonService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import AdvancedTable from "../adminPanelComponents/AdvancedTable";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconBookUpload, IconTrash } from "@tabler/icons-react";

enum VisibleModalType {
  answerKey = "A",
  examResult = "B",
}
export default function StudentTestResults() {
  const dispatch = useDispatch();
  const testService = new TestService();
  const lessonService = new LessonService();
  const [testResultList, setTestResultList] = useState<Array<TestResultModel>>(
    []
  );
  const [modalState, setModalState] = useState<{
    data: TestOutputModel;
    visibleModal: VisibleModalType;
    examId?: number;
  }>({ data: null, visibleModal: null, examId: null });
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [showStudentAnswers, setShowStudentAnswers] = useState<boolean>(false);
  const [trig, setTrig] = useState<boolean>(false);
  const getTestResultList = async () => {
    const result = await testService.GetTestResults();
    if (result) setTestResultList(result);
  };
  const [uploadModalVis, setUploadModalVis] = useState(false);

  const studentId = testResultList[0]?.studentId;

  const getUserRoles = async () => {
    const roleService = new RoleService();
    const userRoles = await roleService.GetUserRoles();
    setUserRoles(userRoles);
  };

  useEffect(() => {
    dispatch(showLoader());
    getTestResultList();
    getUserRoles();
    dispatch(hideLoader());
  }, [trig]);

  const editExamResult = (testId: number) => {};

  const deleteExamResult = async (testId: number) => {
    await testService.DeleteStudentTestResult(testId);
    await setTrig((trig) => !trig);
  };

  const uploadExamResultClickHandler = async (examId: number) => {
    dispatch(showLoader());
    const test = await testService.GetTestDetails(examId);
    setModalState({
      data: test,
      visibleModal: VisibleModalType.examResult,
      examId: examId,
    });
    dispatch(hideLoader());
  };

  const removeModal = () => {
    setModalState({ data: null, visibleModal: null, examId: null });
  };

  const updateData = async () => {
    dispatch(showLoader());
    await getTestResultList();
    dispatch(hideLoader());

    // removeModal();
    // const result = await testService.GetTestList();
    // if (result) {
    //   // setExamList(result);
    //   // setModalState({
    //   //     data: examList.find(e => e.id == examId),
    //   //     visibleModal: null
    //   // });
    // }
  };

  const customDataTable = [
    {
      accessorKey: "className",
      header: "Sınıf",
      maxSize: 100,
    },
    {
      accessorKey: "lessonName",
      header: "Ders Adı",
      size: 40,
    },
    {
      accessorKey: "fillDate",
      header: "Tarih",
      size: 40,
    },
    {
      accessorKey: "testName",
      header: "Test Adı",
      // size: 40,
      maxSize: 150,
    },
    {
      accessorKey: "questionCount",
      header: "Soru Sayısı",
      size: 40,
    },
    {
      accessorKey: "correctAnswerCount",
      header: "Doğru ",
      size: 40,
    },
    {
      accessorKey: "incorrectAnswerCount",
      header: "Yanlış ",
      size: 40,
    },
    {
      accessorKey: "unansweredCount",
      header: "Boş",
      size: 10,
    },
    {
      accessorKey: "net",
      header: "Net",
      size: 10,
    },
    {
      accessorFn: (row) => `% ${Number(row.successRate).toFixed(2)}`,
      accessorKey: "successRate",
      header: "Başarı",
      size: 10,
    },
    // {
    //   accessorFn: (row) =>
    //     `% ${Math.round((row.correctAnswerCount / row.questionCount) * 100)}`,
    //   accessorKey: "successPercent",
    //   header: "Başarı ",
    //   size: 10,
    // },
  ];

  const data: TestResultModel[] = testResultList;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <AdvancedTable
            data={data}
            dataTable={customDataTable}
            filters={false}
            // deleteButton={(row) => {
            //   if (
            //     userRoles.includes("admin") ||
            //     userRoles.includes("manager")
            //   ) {
            //     deleteExamResult(row.id);
            //   }
            // }}
            extraButtons={(row) => {
              if (
                userRoles.includes("admin") ||
                userRoles.includes("manager")
              ) {
                return (
                  <>
                    <Tooltip label="Sil">
                      <ActionIcon
                        color="red"
                        onClick={() => deleteExamResult(row.id)}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Sonuç Yükle">
                      <ActionIcon
                        color="indigo"
                        onClick={() => uploadExamResultClickHandler(row.testId)}
                      >
                        <IconBookUpload />
                      </ActionIcon>
                    </Tooltip>
                  </>
                );
              }
            }}
          />
        </div>
      </div>
      {modalState.visibleModal == VisibleModalType.examResult && (
        <ExamResultUpload
          onClose={removeModal}
          testId={modalState.examId}
          show={true}
          data={modalState.data}
          callback={updateData}
          studentId={studentId}
        />
      )}
    </>
  );
}
