import React, { useEffect, useState } from "react";

declare global {
  interface ModalModel {
    children: React.ReactNode;
    title: string;
    onclose: () => void;
    onsubmit?: () => void;
    modalVisibility: boolean;
    buttonLeft?: string;
    buttonRight?: string;
    showButtonLeft?: boolean;
    showButtonRight?: boolean;
  }
}

export default function Modal(props: ModalModel) {
  let [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    setModalVisibility(props.modalVisibility);
  }, [props.modalVisibility]);

  const closeHandler = () => {
    props.onclose();
  };

  // console.log(props.modalVisibility);

  return (
    <div
      className="modal fade text-left show"
      id="yeniOgrenciEkleModal"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: modalVisibility ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {props.title}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x" onClick={closeHandler}></i>
            </button>
          </div>
          <div className="modal-body">{props.children}</div>

          <div className="modal-footer">
            {props.showButtonLeft !== false ? (
              <button
                type="button"
                onClick={closeHandler}
                className="btn btn-default rounded pill"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-times-circle"></i>
                <span className="d-inline p-2">
                  {props.buttonLeft || "Kapat"}
                </span>
              </button>
            ) : null}
            {props.showButtonRight !== false ? (
              <button
                type="button"
                onClick={props.onsubmit}
                className="btn btn-primary ml-1"
                data-bs-dismiss="modal"
              >
                <i className="fas fa-check-circle"></i>
                <span className="d-inline p-2">
                  {props.buttonRight || "Kaydet"}
                </span>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
