import BaseService from "./baseService";

declare global {
  interface AddSubjectBookInputModel {
    IssueDate: string;
    Topic: string;
    Homework: string;
    SubclassroomId: number;
  }

  interface AddSubjectBookOutpuModel {}

  interface GetSubcjectBookOutputModel {
    id: number;
    homework: string;
    topic: string;
    issueDate: string;
    teacher: TeacherAccountModel;
    subClassRoom: {
      id: number;
      name: string;
      classroom: {
        id: number;
        name: string;
      };
    };
  }
}

export default class SubjectBookService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/subjectbook");
  }

  AddSubjectBook(subject: AddSubjectBookInputModel) {
    return this.Post<AddSubjectBookInputModel, AddSubjectBookOutpuModel>(
      subject
    );
  }

  GetSubjectById(id: number) {
    return this.GetById<number, GetSubcjectBookOutputModel>(
      id,
      "/subjectbook/"
    );
  }

  DeleteSubject(id: number) {
    return this.Delete(id, "/subjectbook");
  }

  GetSubjectBookForStudent() {
    return this.Get<Array<GetSubcjectBookOutputModel>>(
      null,
      "/subjectbook/forstudent"
    );
  }

  GetSubcjectBookBySubclassroomId(id: number) {
    return this.GetById<number, GetSubcjectBookOutputModel[]>(
      id,
      "/subjectbook/BySubclassroomId/"
    );
  }
}
