import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ConsentModal from "../consentModal";
import BranchesService from "../../services/branchesService";
import BrancheCreateOrUpdateModal from "./BrancheCreateOrUpdateModal";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import BranchePage from "./BranchePage";
import Header from "../header";
import ClassroomPage from "./classroomsPage";

export default function BranchesListPage() {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const branchesService = new BranchesService();
  const [branchesList, setBranchesList] = useState<Array<BranchesOutputModel>>(
    []
  );
  const [trig, setTrig] = useState<boolean>(false);
  const [createOrUpdateModalVis, setCreateOrUpdateModalVis] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [modalMode, setModalMode] = useState<any>("update");

  const getBranchesList = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranches();
    setBranchesList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getBranchesList();
  }, [trig]);

  const consentModalApproveHandler = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    const response = await branchesService.DeleteBranche(selectedId);
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const consentModalCancelHandler = () => setConsentModalVisibility(false);
  return (
    <Switch>
      <Route path={`${path}/:brancheId`}>
        {path.includes("branchesClassrooms") ? (
          <ClassroomPage />
        ) : (
          <BranchePage />
        )}
      </Route>
      <Route path={path}>
        <>
          <Header
            name={
              path.includes("branchesClassrooms")
                ? path.includes("outbranchesClassrooms")
                  ? "Şubeler Arası Rapor"
                  : path.includes("branchesClassrooms")
                  ? "Şube Raporları"
                  : "Kurum Sınıfları"
                : "Kurum Şubeleri"
            }
          />
          <div className="page-content">
            <section className="row">
              <div className="content-page">
                <div className="row staff-grid-row">
                  {branchesList.map((l) => (
                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                      <div
                        className={`profile-widget ${
                          path.includes("report") && "pt-5"
                        }`}
                        style={{ borderRadius: "100px" }}
                      >
                        {!path.includes("report") && (
                          <>
                            <div className="buttons">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setModalMode("update");
                                  setSelectedId(l.id);
                                  setCreateOrUpdateModalVis(true);
                                }}
                              >
                                Düzenle
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  setSelectedId(l.id);
                                  setConsentModalVisibility(true);
                                }}
                              >
                                Sil
                              </button>
                            </div>
                          </>
                        )}

                        <div className="profile-img">
                          <Link to={`${path}/${l.id}`}>
                            <img
                              className="avatar"
                              src="/assets/img/school.png"
                              alt=""
                            />
                          </Link>
                        </div>
                        <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                          <a>{l.name || "Default"}</a>
                        </h4>
                      </div>
                    </div>
                  ))}
                  {!path.includes("report") && (
                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                      <div
                        className="profile-widget"
                        style={{ borderRadius: "100px" }}
                      >
                        <div
                          className="profile-img"
                          onClick={() => {
                            setModalMode("create");
                            setCreateOrUpdateModalVis(true);
                          }}
                        >
                          <i
                            className="fas fa-plus fa-4x"
                            style={{ opacity: "0.5" }}
                          ></i>
                        </div>
                        <h5 className="user-name m-t-10 m-b-0 text-ellipsis ">
                          <a data-bs-toggle="modal" data-bs-target="#small">
                            Yeni Şube Ekle
                          </a>
                        </h5>
                        <div className="small text-muted"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          <BrancheCreateOrUpdateModal
            trig={trig}
            setTrig={setTrig}
            data={branchesList}
            vis={createOrUpdateModalVis}
            onClose={(vis) => {
              setCreateOrUpdateModalVis(vis);
            }}
            mode={modalMode}
            id={selectedId}
          />
          <ConsentModal
            title="Uyarı"
            visible={consentModalVisibility}
            message={
              "Kurum şubesini sildiğinizde bu şubeye ait bütün veriler silinecektir. Silme işlemini onaylıyor musunuz?"
            }
            approveCallback={consentModalApproveHandler}
            cancelCallback={consentModalCancelHandler}
          />
        </>
      </Route>
    </Switch>
  );
}
