import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../extensions/commonFunctions";
import {
  hideLoader,
  setAccessToken,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import AccountService from "../../services/accountService";
import AuthorizationService from "../../services/authorizationService";
import { Roles } from "../../services/roleService";

export default function AdminLoginPage() {
  const dispatch = useDispatch();
  const [showPassReset, setShowPassReset] = useState(false);
  const authorizationService = new AuthorizationService();

  const formSubmitHandler = async () => {
    dispatch(showLoader());
    let result = await authorizationService.Login({
      password: formUserPassword,
      username: formUserEmail,
    });

    if (result != null) {
      dispatch(setAccessToken(result.access_token));

      switch (result.redirect) {
        case Roles.Admin:
        case Roles.Manager:
          window.location.href = "/admin";
          break;
        case Roles.Student:
          window.location.href = "/student";
          break;
        case Roles.Teacher:
          window.location.href = "/teacher";
          break;
      }
    } else {
      dispatch(hideLoader());
      dispatch(sendMessage({ title: "Giriş", message: "İşlem Başarısız" }));
    }
  };

  const [formUserEmail, setFormUserEmail] = useState("");
  const [formUserPassword, setFormUserPassword] = useState("");
  const [formEmailForPasswordReset, setFormEmailForPasswordReset] =
    useState("");

  const [passwordInputType, setPasswordInputType] = useState("password");

  const togglePasswordVisibility = () => {
    if (passwordInputType == "password") setPasswordInputType("text");
    else setPasswordInputType("password");
  };

  const formResetSubmitHandler = async () => {
    dispatch(showLoader());
    const result = await authorizationService.ResetPassword(
      formEmailForPasswordReset
    );

    if (result) {
      setFormEmailForPasswordReset("");
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message:
            "İşleminiz başarıyla tamamlanmıştır, mail adresinize şifre sıfırlama için bir mail gönderdik.",
        })
      );
    }

    dispatch(hideLoader());
  };

  const isEmailValid = validateEmail(formEmailForPasswordReset);

  return (
    <section className="vh-100 d-flex align-items-center overlay-soft bg-admin-login giris">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            {showPassReset ? (
              <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <i
                  style={{ cursor: "pointer", fontSize: "1.5rem" }}
                  onClick={() => setShowPassReset(false)}
                  className="fas fa-arrow-left"
                ></i>
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h1 className="mb-0 h3">Şifre Sıfırla</h1>
                </div>
                <div className="mt-4">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        className={`form-control ${
                          isEmailValid ? "" : "is-invalid"
                        }`}
                        id="input-passreset-email"
                        placeholder="Email Adresiniz"
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormEmailForPasswordReset(e.target.value)
                        }
                        value={formEmailForPasswordReset}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      onClick={formResetSubmitHandler}
                      className="btn btn-block btn-primary"
                      disabled={!isEmailValid}
                    >
                      Sıfırla
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h1 className="mb-0 h3">Yönetici Paneline Giriş Yap</h1>
                </div>
                <form action="#" className="mt-4">
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="far fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        className={`form-control ${
                          formUserEmail ? "" : "is-invalid"
                        }`}
                        id="input-email"
                        placeholder="Email Adresiniz"
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormUserEmail(e.target.value)
                        }
                        value={formUserEmail}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-unlock-alt"></i>
                        </span>
                      </div>
                      <input
                        className={`form-control ${
                          formUserPassword ? "" : "is-invalid"
                        }`}
                        placeholder="Şifreniz"
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormUserPassword(e.target.value)
                        }
                        value={formUserPassword}
                        type={passwordInputType}
                        required
                      />
                      <div className="input-group-append">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={togglePasswordVisibility}
                          className="input-group-text"
                        >
                          <i className="far fa-eye"></i>
                        </span>
                      </div>
                    </div>
                    <div className="d-block d-sm-flex justify-content-between align-items-center mt-2">
                      <div className="form-group form-check mt-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="rememberme"
                        />
                        <label
                          htmlFor="rememberme"
                          className="form-check-label form-check-sign-white"
                        >
                          Beni Hatırla
                        </label>
                      </div>
                      <div>
                        <a
                          onClick={() => setShowPassReset(true)}
                          className="small text-right"
                        >
                          Şifreni mi Unuttun?
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      onClick={formSubmitHandler}
                      className="btn btn-block btn-primary"
                      disabled={!formUserPassword || !formUserEmail}
                    >
                      Giriş Yap
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
