import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../reducers/commonSlice";

const useLoader = () => {
    const [show, setShow] = useState<boolean>(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(showLoader())

        setTimeout(() => {
            dispatch(hideLoader());
            setShow(true);
        }, 500);
    }, []);

    return show;
};

export default useLoader;