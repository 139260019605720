import BaseService from "./baseService";

declare global {
    interface SubscriberModel {
        name: string,
        surname: string,
        email: string
    }
    interface MailInputModel {
        to: string,
        subject: string,
        body: string
    }
}

export default class MailingService extends BaseService {
    constructor() {
        super();
    }

    AddNewSubscriber(subscriber: SubscriberModel) {
        return this.Post<SubscriberModel, boolean>(subscriber, "/subscriber");
    }

    GetAllSubscribers() {
        return this.Get<Array<SubscriberModel>>(null, "/subscriber");
    }

    SendMailTo(mail: MailInputModel) {
        return this.Post<MailInputModel, boolean>(mail, "/mail")
    }
}