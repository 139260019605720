import React from "react";
import {
  setAccessToken,
  setAuthenticationContext,
} from "../reducers/commonSlice";
import { sendMessage } from "../reducers/messengerSlice";
import store from "../store";

declare global {
  interface KeyValuePair<TValue> {
    [key: string]: TValue;
  }
}

declare interface Payload {
  url: RequestInfo;
  request: RequestInit;
}

export default class BaseService {
  /**
   *
   */
  protected baseUrl: string;
  protected pdfUrl: string;
  protected endPoint: string;
  protected tokenEndpoint: string;
  protected accessToken: string;

  constructor(endpoint?: string) {
    if (process.env.NODE_ENV == "production") {
      this.baseUrl = `${window.location.origin}/api`;
      this.pdfUrl = `${window.location.origin}`;
      this.tokenEndpoint = `${window.location.origin}/token`;
    } else {
      this.baseUrl = "http://localhost:3001/api";
      this.tokenEndpoint = "http://localhost:3001/token";
      this.pdfUrl = `http://localhost:3001`;
      // this.baseUrl = "https://panel.gumusegitim.com/api";
      // this.tokenEndpoint = "https://panel.gumusegitim.com/token";
    }

    this.endPoint = endpoint;
    this.accessToken = store.getState().commons.accessToken;
  }

  async RefreshToken(redirect: boolean): Promise<LoginOutputModel> {
    let response = await fetch(this.tokenEndpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic Z3VtdXNlZ2l0aW13ZWJhcHA6MDNhMmE5MjgtZDMwZi00MjU2LThkNTktYmNmNTU3N2Y3YTYx",
      },
      body: `grant_type=refresh_token&refresh_token=`,
    });
    if (response.status != 200 && redirect) {
      window.location.href = "/login";
    }

    if (response.status != 200) return null;

    var result = await response.json();

    return <LoginOutputModel>result;
  }

  async GetRefreshTokenIfNotExist(redirect: boolean): Promise<boolean> {
    // console.log("no token, trying to refresh");
    if (!this.accessToken) {
      let result = await this.RefreshToken(redirect);

      if (result != null) {
        store.dispatch(setAccessToken(result.access_token));
        store.dispatch(
          setAuthenticationContext({
            userName: result.userName,
            isAuthenticated: true,
          })
        );
        this.accessToken = store.getState().commons.accessToken;
        // console.log("token received");

        return true;
      }

      // console.log("token couldnt get !! ");
    } else return true;
  }

  async RepeatRequestWithNewAccessToken<TResponse>(
    payload: Payload,
    redirect: boolean
  ): Promise<TResponse> {
    this.accessToken = null;

    const hasToken = await this.GetRefreshTokenIfNotExist(redirect);
    if (!hasToken) return null;

    (payload.request.headers as any)["Authorization"] =
      "Bearer " + this.accessToken;
    let response = await fetch(payload.url, payload.request);

    if (response.status != 200) {
      return null;
    }

    var result = await response.json();

    return <TResponse>result;
  }

  async Post<TInput, TResponse>(
    input: TInput,
    endpoint?: string,
    authenticate: boolean = true,
    redirect: boolean = true,
    header?: HeadersInit,
    stringifyBody: boolean = true
  ): Promise<TResponse> {
    if (authenticate) {
      const hasToken = await this.GetRefreshTokenIfNotExist(redirect);
      if (!hasToken) return null;
    }

    var payload: Payload = {
      url: this.baseUrl + (endpoint || this.endPoint),
      request: {
        method: "POST",
        credentials: "include",
        headers: header || {
          "Content-Type": "application/json",
        },
        body: stringifyBody ? JSON.stringify(input) : (input as any),
      },
    };
    authenticate &&
      ((payload.request.headers as any)["Authorization"] =
        "Bearer " + this.accessToken);
    var response = await fetch(payload.url, payload.request);

    if (response.status == 401)
      return this.RepeatRequestWithNewAccessToken<TResponse>(payload, redirect);

    if (response.status != 200) {
      const message = await response.text();
      store.dispatch(sendMessage({ title: "Hata", message: message }));
      return null;
    }

    var result = await response.json();

    return <TResponse>result;
  }

  async Get<TResponse>(
    params?: KeyValuePair<string | number[]>,
    endpoint?: string,
    authenticate: boolean = true,
    redirect: boolean = true,
    showErrorMessage?: boolean,
    readResultInJson: boolean = true
  ): Promise<TResponse> {
    // console.log(
    //   "GET request to: " + endpoint + ", authenticate: " + authenticate
    // );
    if (authenticate) {
      const hasToken = await this.GetRefreshTokenIfNotExist(redirect);
      // console.log("has token:" + hasToken);
      if (!hasToken) return null;
    }

    const getQueryString = () => {
      if (!params) return "";
      let queryString = "?";

      for (let key in params) {
        queryString += "&" + key + "=" + params[key];
      }

      return queryString;
    };

    var payload: Payload = {
      url: this.baseUrl + (endpoint || this.endPoint) + getQueryString(),
      request: {
        credentials: "include",
        headers: {},
      },
    };
    authenticate &&
      ((payload.request.headers as any)["Authorization"] =
        "Bearer " + this.accessToken);
    var response = await fetch(payload.url, payload.request);

    if (response.status == 401) {
      // console.log("resending same reqeust");
      return this.RepeatRequestWithNewAccessToken<TResponse>(payload, redirect);
    }

    if (response.status != 200) {
      if (showErrorMessage) {
        const message = await response.text();
        store.dispatch(sendMessage({ title: "Hata", message: message }));
      }

      return null;
    }

    if (readResultInJson) {
      let result = await response.json();
      return <TResponse>result;
    } else return response as any;
  }

  async GetById<TId, TResponse>(
    id: TId,
    endpoint?: string,
    authenticate: boolean = true,
    redirect: boolean = true
  ): Promise<TResponse> {
    if (authenticate) {
      const hasToken = await this.GetRefreshTokenIfNotExist(redirect);
      if (!hasToken) return null;
    }

    var payload: Payload = {
      url: this.baseUrl + (endpoint || this.endPoint) + "/" + id,
      request: {
        credentials: "include",
        headers: {},
      },
    };
    authenticate &&
      ((payload.request.headers as any)["Authorization"] =
        "Bearer " + this.accessToken);
    var response = await fetch(payload.url, payload.request);

    if (response.status == 401)
      return this.RepeatRequestWithNewAccessToken<TResponse>(payload, redirect);

    if (response.status != 200) {
      return null;
    }

    var result = await response.json();

    return <TResponse>result;
  }

  async Put<TId, TInput, TResponse>(
    id: TId,
    input: TInput,
    endpoint?: string,
    authenticate: boolean = true,
    redirect: boolean = true
  ): Promise<TResponse> {
    if (authenticate) {
      const hasToken = await this.GetRefreshTokenIfNotExist(redirect);
      if (!hasToken) return null;
    }

    var payload: Payload = {
      url: this.baseUrl + (endpoint || this.endPoint) + "/" + id,
      request: {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      },
    };
    authenticate &&
      ((payload.request.headers as any)["Authorization"] =
        "Bearer " + this.accessToken);
    var response = await fetch(payload.url, payload.request);

    if (response.status == 401)
      return this.RepeatRequestWithNewAccessToken<TResponse>(payload, redirect);

    if (response.status != 200) {
      const message = await response.text();
      store.dispatch(sendMessage({ title: "Hata", message: message }));
      return null;
    }

    var result = await response.json();

    return <TResponse>result;
  }

  async Delete<TId>(
    id?: TId,
    endpoint?: string,
    authenticate: boolean = true,
    redirect: boolean = true
  ): Promise<boolean> {
    if (authenticate) {
      const hasToken = await this.GetRefreshTokenIfNotExist(redirect);
      if (!hasToken) return null;
    }

    var payload: Payload = {
      url: this.baseUrl + (endpoint || this.endPoint) + (id ? "/" + id : ""),
      request: {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      },
    };
    authenticate &&
      ((payload.request.headers as any)["Authorization"] =
        "Bearer " + this.accessToken);
    var response = await fetch(payload.url, payload.request);

    if (response.status == 401) {
      this.GetRefreshTokenIfNotExist(redirect);
      (payload.request.headers as any)["Authorization"] =
        "Bearer " + this.accessToken;
      response = await fetch(payload.url, payload.request);
    }

    if (response.status != 200) {
      const message = await response.text();
      store.dispatch(sendMessage({ title: "Hata", message: message }));
    }

    return response.status == 200;
  }

  endPoints() {
    return {
      baseUrl: this.baseUrl,
      tokenEndpoint: this.tokenEndpoint,
      pdfUrl: this.pdfUrl,
    };
  }
}
