// @ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ConsentModal from "../consentModal";
import BranchesService from "../../services/branchesService";
import BrancheCreateOrUpdateModal from "./BrancheCreateOrUpdateModal";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import BranchePage from "./BranchePage";
import Header from "../header";
import ClassroomPage from "./classroomsPage";
import RoleService from "../../services/roleService";
import SubClassroomService from "../../services/subClassroomService";
import Select from "react-select";
import TestService from "../../services/testService";
import ComponentLoader from "../sharedComponents/componentLoader";
import CheckboxTree from "react-checkbox-tree";
import {
  SubClassroomCompareTestReportPDF,
  SubClassroomTestReportPDF,
} from "../../utils/Pdf";
import SubClassRoomPage from "./SubClassroomPage";
import AdminClassStudentsPage from "./AdminClassStudentsPage";
import ClassroomService from "../../services/classroomService";

const ReportClassroomPage = () => {};

export default function SubClassroomOutReportPage() {
  const dispatch = useDispatch();
  const roleService = new RoleService();
  const subClassroomService = new SubClassroomService();
  const testService = new TestService();
  const classroomService = new ClassroomService();

  const { path, url } = useRouteMatch();
  const branchesService = new BranchesService();
  const [branchesList, setBranchesList] = useState<Array<BranchesOutputModel>>(
    []
  );
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [subClassroomList, setSubClassroomList] = useState<
    Array<SubClassroomOutputModel>
  >([]);
  const [selectedSubClassroom, setSelectedSubClassroom] =
    useState<number>(null);
  const [subClassroomTestList, setSubClassroomTestList] = useState([]);
  const [selectedTestExamValues, setSelectedTestExamValues] = useState([]);
  const [expandedTestExam, setExpandedTestExam] = useState([]);
  const [subClassroomTestReportModalVis, setSubClassroomTestReportModalVis] =
    useState<boolean>(false);
  const [reportLoader, setReportLoader] = useState<boolean>(false);
  const [classroomList, setClassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);

  const [subClassroomListModalVis, setSubClassroomListModalVis] =
    useState<boolean>(false);

  const getClassroomList = async () => {
    dispatch(showLoader());
    let result = await classroomService.GetClassroomList();
    dispatch(hideLoader());

    if (result != null) {
      setClassroomList(result);
    } else {
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    }
  };

  useEffect(() => {
    getClassroomList();
  }, []);

  const getSubClassroom = async () => {
    dispatch(showLoader());
    const response = await subClassroomService.GetSubClassroomByTeacherId();
    setSubClassroomList(response);
    dispatch(hideLoader());
  };

  const getUserRole = async () => {
    dispatch(showLoader());
    const response = await roleService.GetUserRoles();
    setUserRoles(response);
    dispatch(hideLoader());
  };

  const getBranchesList = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranches();
    setBranchesList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getBranchesList();
    getUserRole();
    getSubClassroom();
  }, []);

  const selectSubClassroomOpt = subClassroomList?.map((item) => ({
    value: item?.id,
    label: `${item?.name} Şubesi`,
  }));

  console.warn(selectedTestExamValues, selectedSubClassroom);
  const getSubClassroomTestReport = async () => {
    setReportLoader(true);
    const SubclassroomIds = selectedSubClassroom?.map((item) => item.value);
    const TestIds = selectedTestExamValues?.map((item) => Number(item));
    // console.warn(SubclassroomIds, TestIds);
    const response = await testService.GetReportByCompareSubclassroom({
      SubclassroomIds,
      TestIds,
    });
    SubClassroomCompareTestReportPDF(response);
    setReportLoader(false);
  };

  const getSubClassroomList = async (id: number) => {
    dispatch(showLoader());
    const response = await subClassroomService.GetSubClassroomById(id);
    setSubClassroomList(response);
    dispatch(hideLoader());
    setSubClassroomListModalVis(true);
  };

  const getSubClassroomTestList = async () => {
    getSubClassroomTestExamList();
  };

  const getSubClassroomTestExamList = async () => {
    setReportLoader(true);
    const SubclassroomIds = selectedSubClassroom?.map((item) => item?.value);
    const response = await testService.GetCompareSubclassrooms(SubclassroomIds);
    const transformedData = response.map((lessonTests, lessonIndex) => {
      const lessonName = lessonTests.lessonName;
      setExpandedTestExam((expandedTestExam) => [
        ...expandedTestExam,
        lessonName,
      ]);
      const transformedLesson = {
        label: lessonName,
        value: lessonName,
        children: lessonTests.tests.map((test, index) => {
          setSelectedTestExamValues((selectedTestExamValues) => [
            ...selectedTestExamValues,
            `${test.id}`,
          ]);
          return {
            value: `${test.id}`,
            label: `${test.name} - ${test.issueDate}`,
          };
        }),
      };

      return transformedLesson;
    });
    setSubClassroomTestList(transformedData);
    setReportLoader(false);
  };

  return (
    <>
      <div className="page-content">
        <section className="row">
          <div className="content-page">
            <div className="row staff-grid-row">
              {classroomList.map((l) => (
                <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                  <div
                    className={`profile-widget ${
                      path.includes("report") && "pt-5"
                    }`}
                    style={{ borderRadius: "100px" }}
                  >
                    <div
                      className="profile-img"
                      onClick={() => getSubClassroomList(l.id)}
                    >
                      <img
                        className="avatar"
                        src="/assets/img/class.png"
                        alt=""
                      />
                    </div>
                    <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                      <a>{l.name || "Default"}</a>
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {subClassroomListModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Şube Seçimi`}
                </h4>
              </div>
              <div className="modal-body">
                <div className=" row d-flex justify-content-start align-items-center pt-2">
                  <div className="col-8">
                    <Select
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        container: (base) => ({
                          ...base,
                          width: "100%",
                        }),
                      }}
                      menuPosition={"fixed"}
                      isMulti
                      closeMenuOnSelect={false}
                      aria-label="Default select example"
                      options={selectSubClassroomOpt}
                      onChange={(selectedOption) => {
                        setSelectedSubClassroom(selectedOption);
                      }}
                      placeholder="Lütfen Şube Seçiniz"
                    />
                  </div>
                  <div className="col-4">
                    <button
                      onClick={() => {
                        // dispatch(setSidebarStatusModal(false));
                        getSubClassroomTestList();
                      }}
                      type="button"
                      className="btn btn-primary ml-1 btn-l"
                      disabled={!selectedSubClassroom?.length}
                    >
                      Ortak Testleri Getir
                    </button>
                  </div>
                </div>
                <div className=" row d-flex justify-content-start align-items-center pt-2">
                  {reportLoader ? (
                    <div className="d-flex justify-content-center align-items-center pt-2">
                      <ComponentLoader />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-start align-items-center pt-2">
                      <CheckboxTree
                        nativeCheckboxes
                        nodes={subClassroomTestList}
                        checked={selectedTestExamValues}
                        expanded={expandedTestExam}
                        onCheck={setSelectedTestExamValues}
                        onExpand={setExpandedTestExam}
                        showNodeIcon={false}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={(e) => {
                    getSubClassroomTestReport();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={selectedTestExamValues.length == 0}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Rapor Oluştur</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setSubClassroomListModalVis(false);
                    setSubClassroomTestList([]);
                    setSelectedTestExamValues([]);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
