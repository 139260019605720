import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import SurveyService from "../../services/surveyService";
import TeacherService from "../../services/teacherService";
import Loader from "../loader";
import { StudentIdentityContext } from "../panels/studentPanel";
import StudentBasicInfo from "./studentBasicInfo";

export default function ParentSurvey() {
  const surveyService = new SurveyService();
  const teacherService = new TeacherService();

  const dispatch = useDispatch();
  const studentContext = useContext(StudentIdentityContext);
  const [surveyQuestions, setSurveyQuestions] = useState<
    Array<SurveyQuestionOutputModel>
  >([]);
  const [question1Answer, setQuestion1Answer] = useState(0);
  const [question2Answer, setQuestion2Answer] = useState(0);
  const [question3Answer, setQuestion3Answer] = useState(0);
  const [question4Answer, setQuestion4Answer] = useState("");
  const [isFilledOutForm, setIsFilledOutForm] = useState(false);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [teacherResultList, setTeacherResultList] = useState<
    Array<SurveyResultOuputModel>
  >([]);

  const getSurveyQuestions = async () => {
    const result = await surveyService.GetSurveyQuestions();

    if (result != null) {
      setSurveyQuestions(result);
      setQuestionsLoaded(true);
    }
  };
  const onSurveySubmitHandler = async () => {
    dispatch(showLoader());
    const result = await surveyService.SendSurveyAnswers({
      surveyId: surveyQuestions[0].surveyId,
      teacherId: selectedTeacherId,
      answers: [
        {
          value: question1Answer.toString(),
          questionId: surveyQuestions[0].id,
        },
        {
          value: question2Answer.toString(),
          questionId: surveyQuestions[1].id,
        },
        {
          value: question3Answer.toString(),
          questionId: surveyQuestions[2].id,
        },
        { value: question4Answer, questionId: surveyQuestions[3].id },
      ],
    });
    dispatch(hideLoader());
    if (!result)
      dispatch(
        sendMessage({ title: "İşlem Sonucu", message: "Anket tamamlanamadı" })
      );
    else {
      await getStudentSurveyResult();
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "Anket başarıyla tamamlandı",
        })
      );
    }
  };

  const onSurveyResetHandler = () => {
    setQuestion4Answer("");
  };

  const getStudentSurveyResult = async () => {
    dispatch(showLoader());
    const result = await surveyService.GetStudentLastSurveyResult();
    dispatch(hideLoader());
    if (result != null) {
      setTeacherResultList(result);
      setAnswersLoaded(true);
    }
  };

  useEffect(() => {
    // console.log("teacher changed");
    setTeacherResult();
  }, [selectedTeacherId, teacherResultList]);

  const setTeacherResult = () => {
    const teacherSurveyResult = teacherResultList.find(
      (r) => (r.teacher || {}).id == selectedTeacherId
    );
    if (teacherSurveyResult) {
      setQuestion1Answer(
        Number(teacherSurveyResult.surveyQuestionResults[0].value)
      );
      setQuestion2Answer(
        Number(teacherSurveyResult.surveyQuestionResults[1].value)
      );
      setQuestion3Answer(
        Number(teacherSurveyResult.surveyQuestionResults[2].value)
      );
      setQuestion4Answer(teacherSurveyResult.surveyQuestionResults[3].value);
      setIsFilledOutForm(true);
    } else {
      setQuestion1Answer(0);
      setQuestion2Answer(0);
      setQuestion3Answer(0);
      setQuestion4Answer("");
      setIsFilledOutForm(false);
    }
  };

  const isFormValid =
    question1Answer && question2Answer && question3Answer && question4Answer;

  useEffect(() => {
    getSurveyQuestions();
    getStudentSurveyResult();
  }, []);

  return !(questionsLoaded && answersLoaded) ? null : (
    <section className="row">
      <StudentBasicInfo />
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <b>Anket Formu</b>
          </div>
          <div className="card-content">
            <div className="card-body">
              <form className="form form-vertical">
                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Öğretmen</label>
                        <select
                          className={
                            "form-select" +
                            (selectedTeacherId ? " is-valid" : " is-invalid")
                          }
                          value={selectedTeacherId}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            setSelectedTeacherId(e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Öğretmen seçiniz
                          </option>
                          {studentContext.teachers.map((t, index) => (
                            <option value={t.id}>
                              {t.name + " " + t.surname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="first-name-vertical">
                          {surveyQuestions &&
                            surveyQuestions.length > 0 &&
                            surveyQuestions[0].question}
                        </label>
                        <div className="stars">
                          <input
                            className="star star-5"
                            disabled={isFilledOutForm}
                            checked={question1Answer == 5}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => e.target.checked && setQuestion1Answer(5)}
                            id="star-15"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-5"
                            htmlFor="star-15"
                          ></label>
                          <input
                            className="star star-4"
                            disabled={isFilledOutForm}
                            checked={question1Answer == 4}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => e.target.checked && setQuestion1Answer(4)}
                            id="star-14"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-4"
                            htmlFor="star-14"
                          ></label>
                          <input
                            className="star star-3"
                            disabled={isFilledOutForm}
                            checked={question1Answer == 3}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => e.target.checked && setQuestion1Answer(3)}
                            id="star-13"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-3"
                            htmlFor="star-13"
                          ></label>
                          <input
                            className="star star-2"
                            disabled={isFilledOutForm}
                            checked={question1Answer == 2}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => e.target.checked && setQuestion1Answer(2)}
                            id="star-12"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-2"
                            htmlFor="star-12"
                          ></label>
                          <input
                            className="star star-1"
                            disabled={isFilledOutForm}
                            checked={question1Answer == 1}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => e.target.checked && setQuestion1Answer(1)}
                            id="star-11"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-1"
                            htmlFor="star-11"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="email-id-vertical">
                          {surveyQuestions &&
                            surveyQuestions.length > 1 &&
                            surveyQuestions[1].question}
                        </label>
                        <div className="stars">
                          <form action="">
                            <input
                              className="star star-5"
                              disabled={isFilledOutForm}
                              checked={question2Answer == 5}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion2Answer(5)}
                              id="star-25"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-5"
                              htmlFor="star-25"
                            ></label>
                            <input
                              className="star star-4"
                              disabled={isFilledOutForm}
                              checked={question2Answer == 4}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion2Answer(4)}
                              id="star-24"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-4"
                              htmlFor="star-24"
                            ></label>
                            <input
                              className="star star-3"
                              disabled={isFilledOutForm}
                              checked={question2Answer == 3}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion2Answer(3)}
                              id="star-23"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-3"
                              htmlFor="star-23"
                            ></label>
                            <input
                              className="star star-2"
                              disabled={isFilledOutForm}
                              checked={question2Answer == 2}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion2Answer(2)}
                              id="star-22"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-2"
                              htmlFor="star-22"
                            ></label>
                            <input
                              className="star star-1"
                              disabled={isFilledOutForm}
                              checked={question2Answer == 1}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion2Answer(1)}
                              id="star-21"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-1"
                              htmlFor="star-21"
                            ></label>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="contact-info-vertical">
                          {surveyQuestions &&
                            surveyQuestions.length > 2 &&
                            surveyQuestions[2].question}
                        </label>
                        <div className="stars">
                          <form action="">
                            <input
                              className="star star-5"
                              disabled={isFilledOutForm}
                              checked={question3Answer == 5}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion3Answer(5)}
                              id="star-35"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-5"
                              htmlFor="star-35"
                            ></label>
                            <input
                              className="star star-4"
                              disabled={isFilledOutForm}
                              checked={question3Answer == 4}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion3Answer(4)}
                              id="star-34"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-4"
                              htmlFor="star-34"
                            ></label>
                            <input
                              className="star star-3"
                              disabled={isFilledOutForm}
                              checked={question3Answer == 3}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion3Answer(3)}
                              id="star-33"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-3"
                              htmlFor="star-33"
                            ></label>
                            <input
                              className="star star-2"
                              disabled={isFilledOutForm}
                              checked={question3Answer == 2}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion3Answer(2)}
                              id="star-32"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-2"
                              htmlFor="star-32"
                            ></label>
                            <input
                              className="star star-1"
                              disabled={isFilledOutForm}
                              checked={question3Answer == 1}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => e.target.checked && setQuestion3Answer(1)}
                              id="star-31"
                              type="radio"
                              name="star"
                            />
                            <label
                              className="star star-1"
                              htmlFor="star-31"
                            ></label>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="#">
                          {surveyQuestions &&
                            surveyQuestions.length > 3 &&
                            surveyQuestions[3].question}
                        </label>

                        <textarea
                          className="form-control"
                          id="#"
                          disabled={isFilledOutForm}
                          placeholder="Açıklayın"
                          rows={3}
                          value={question4Answer}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => setQuestion4Answer(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {!isFormValid && selectedTeacherId && !isFilledOutForm && (
                      <span style={{ color: "red" }}>
                        Lütfen kaydetmeden önce formu tamamlayınız
                      </span>
                    )}
                    {isFilledOutForm || !selectedTeacherId ? null : (
                      <div className="col-12 d-flex justify-content-end">
                        <button
                          type="button"
                          disabled={!isFormValid}
                          className="btn btn-primary me-1 mb-1"
                          onClick={onSurveySubmitHandler}
                        >
                          Kaydet
                        </button>
                        <button
                          type="button"
                          className="btn btn-light-secondary me-1 mb-1"
                          onClick={onSurveyResetHandler}
                        >
                          Sıfırla
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <br />
              <div className="alert alert-light-warning color-warning">
                <i
                  className="bi bi-exclamation-triangle"
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "2px",
                  }}
                ></i>
                <strong>Dikkat: </strong>Her Anket tek seferlik cevaplanır,{" "}
                <strong>Kaydet </strong> butonuna tıkladıktan sonra tekrar
                cevaplayamaz veya değişiklik yapamazsınız.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
