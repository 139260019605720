// @ts-nocheck
import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import Header from "../header";
import { useState } from "react";
import SubClassroomService from "../../services/subClassroomService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { response } from "express";
import SubjectBookService from "../../services/subjectBookService";
import AdvancedTable from "./AdvancedTable";
import ConsentModal from "../consentModal";

export default function SubjectBookDetailsPage() {
  const { lessonId } = useParams();
  const dispatch = useDispatch();
  const subClassroomService = new SubClassroomService();
  const subjectBookService = new SubjectBookService();
  const [subClassroomDetail, setSubClassroomDetail] = useState<
    Array<TeacherSubClassroomDetailOutputModal>
  >([]);

  const [assignment, setAssignment] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [selectedDate, setSelectedData] = useState(new Date());
  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);
  const [subjectBookData, setSubjectBookData] = useState<
    GetSubcjectBookOutputModel[]
  >([]);
  const [detailsModalVis, setDetailsModalVis] = useState<boolean>(false);
  const [detailsData, setDetailsData] =
    useState<GetSubcjectBookOutputModel>("");
  const [constModalVisibility, setConstModalVisibility] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const lessonName = `${subClassroomDetail?.[0]?.classroom?.name}/${subClassroomDetail?.[0]?.name}`;

  const getSubClassroomDetail = async (id: number) => {
    dispatch(showLoader());
    const response =
      await subClassroomService.GetSubClassroomByTeacherIdBySubClassroomId(id);
    setSubClassroomDetail(response);
    dispatch(hideLoader());
  };

  const getSubjectBook = async (lessonId: number) => {
    dispatch(showLoader());
    const response = await subjectBookService.GetSubcjectBookBySubclassroomId(
      lessonId
    );
    setSubjectBookData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getSubClassroomDetail(lessonId);
    getSubjectBook(lessonId);
  }, [lessonId]);

  const formSubmitHandler = async () => {
    const subjectData = {
      IssueDate: dayjs(selectedDate).format("YYYY-MM-DD"),
      Topic: subject,
      Homework: assignment,
      SubclassroomId: Number(lessonId),
    };
    const response = await subjectBookService.AddSubjectBook(subjectData);
    if (response) {
      setAddNewModalVis(false);
      getSubjectBook(lessonId);
    }
  };

  const tableData = subjectBookData?.map((item, index) => {
    return {
      issueDate: dayjs(item.issueDate).format("DD-MM-YYYY"),
      id: item.id,
    };
  });

  const customDataTable = [
    {
      accessorKey: "issueDate",
      header: "Tarih",
      maxSize: 100,
    },
  ];

  const getSubjectBookById = async (id: number) => {
    dispatch(showLoader());
    const response = await subjectBookService.GetSubjectById(id);
    setDetailsData(response);
    dispatch(hideLoader());
    setDetailsModalVis(true);
  };

  const deleteSubject = async () => {
    dispatch(showLoader());
    const response = await subjectBookService.DeleteSubject(selectedId);
    if (response) {
      setConstModalVisibility(false);
      getSubjectBook(lessonId);
    }
    dispatch(hideLoader());
  };

  return (
    <div className="page-content">
      <Header name={`Sınıf Defteri - ${lessonName}`} />
      <div className="card">
        <div className="card-body">
          <div className="row  d-flex justify-content-end align-items-center">
            <div
              className={
                "col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end align-items-center pb-3"
              }
            >
              <button
                onClick={() => {
                  setAddNewModalVis(true);
                }}
                type="button"
                className="btn btn-primary ml-1 btn-l"
              >
                Ödev ve Konu Ekle
              </button>
            </div>
            <AdvancedTable
              data={tableData}
              dataTable={customDataTable}
              filters={false}
              detailsButton={(row) => {
                getSubjectBookById(row.id);
              }}
              deleteButton={(row) => {
                setSelectedId(row.id);
                setConstModalVisibility(true);
              }}
            />
          </div>
        </div>
      </div>
      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog modal-xl"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Ödev ve Konu Ekle `}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    {/* <div
                      className={
                        "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end align-items-center"
                      }
                    >
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedData(date)}
                        selectsStart
                        startDate={new Date()}
                        endDate={new Date()}
                        minDate={new Date()}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div> */}
                    <section className="row">
                      <div className="row staff-grid-row">
                        <div className="row ">
                          <div className="form-group col-12">
                            <label className="font-weight-bold">
                              İşlenen Konu:
                            </label>
                            <textarea
                              class="form-control"
                              aria-label="With textarea"
                              value={subject}
                              onInput={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setSubject(e.target.value)}
                              className={
                                "form-control" +
                                (subject ? " is-valid" : " is-invalid")
                              }
                            />
                          </div>
                          <div className="form-group col-12">
                            <label className="font-weight-bold">Ödev:</label>
                            <textarea
                              class="form-control"
                              aria-label="With textarea"
                              value={assignment}
                              onInput={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => setAssignment(e.target.value)}
                              className={"form-control"}
                            />
                          </div>
                          <div className="form-group col-12 ">
                            <div className="row">
                              <div className="col-6">
                                <label className="font-weight-bold pr-2">
                                  Tarih:
                                </label>
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={(date) => setSelectedData(date)}
                                  selectsStart
                                  startDate={new Date()}
                                  endDate={new Date()}
                                  minDate={new Date()}
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={formSubmitHandler}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {detailsModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Sınıf Defteri Detay
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="form-group col-4">
                    <label className="font-weight-bold">Tarih:</label>
                    <h6>{dayjs(detailsData.issueDate).format("DD.MM.YYYY")}</h6>
                  </div>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">İşlenen Konu:</label>
                    <h6>{detailsData.topic}</h6>
                  </div>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">Ödev:</label>
                    <h6>{detailsData.homework}</h6>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setDetailsModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConsentModal
        visible={constModalVisibility}
        message="Silme işlemini onaylıyor musunuz?"
        title="Uyarı"
        cancelCallback={() => setConstModalVisibility(false)}
        approveCallback={deleteSubject}
      />
    </div>
  );
}
