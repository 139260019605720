import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLessonCreateOrUpdateModal,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import LessonService from "../../services/lessonService";
import ConsentModal from "../consentModal";
import LessonCreateOrUpdateModal from "./lessonCreateOrUpdateModal";

export default function LessonListPage() {
  const dispatch = useDispatch();
  const [lessonList, setLessonList] = useState<Array<GenericLessonModel>>([]);

  const getGenericLessonList = async () => {
    const lessonService = new LessonService();
    const result = await lessonService.GetGenericLessonList();
    if (result) setLessonList(result);
  };

  useEffect(() => {
    getGenericLessonList();
  }, []);

  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [lessonIdToDelete, setLessonIdToDelete] = useState<number>(null);

  const deleteGenericLesson = async (id: number) => {
    setLessonIdToDelete(id);
    setConsentModalVisibility(true);
  };

  const consentModalApproveHandler = async () => {
    dispatch(showLoader());
    setConsentModalVisibility(false);
    const lessonService = new LessonService();
    const result = await lessonService.DeleteGenericLesson(lessonIdToDelete);

    dispatch(hideLoader());
    if (result) {
      await getGenericLessonList();
    } else {
      dispatch(sendMessage({ title: "Hata", message: "Ders silinemedi." }));
    }
  };

  const consentModalCancelHandler = () => setConsentModalVisibility(false);

  const [showLessonEditModal, setShowLessonEditModal] = useState(false);
  const [editableGenericLesson, setEditableGenericLesson] =
    useState<GenericLessonModel>(null);

  const editGenericLesson = (id: number) => {
    const editableLesson = lessonList.find((l) => l.id == id);
    setEditableGenericLesson({
      id: editableLesson.id,
      lessonName: editableLesson.lessonName,
    });
    setShowLessonEditModal(true);
  };

  const editSubmitHandler = async () => {
    setShowLessonEditModal(false);
    dispatch(showLoader());
    const lessonService = new LessonService();
    await lessonService.UpdateGenericLesson(editableGenericLesson);
    await getGenericLessonList();
    dispatch(hideLoader());
  };

  return (
    <>
      <div className="page-content">
        <section className="row">
          <div className="content-page">
            <div className="row staff-grid-row">
              {lessonList.map((l) => (
                <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                  <div
                    className="profile-widget"
                    style={{ borderRadius: "100px" }}
                  >
                    <div className="buttons">
                      <button
                        className="btn btn-primary"
                        onClick={() => editGenericLesson(l.id)}
                      >
                        Düzenle
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => deleteGenericLesson(l.id)}
                      >
                        Sil
                      </button>
                    </div>
                    <div className="profile-img">
                      <a>
                        <img
                          className="avatar"
                          src="/assets/img/class.png"
                          alt=""
                        />
                      </a>
                    </div>
                    <h5 className="user-name m-t-10 m-b-0 text-ellipsis">
                      <a>{l.lessonName || "Default"}</a>
                    </h5>
                  </div>
                </div>
              ))}
              <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                <div
                  className="profile-widget"
                  style={{ borderRadius: "100px" }}
                >
                  {/*  */}
                  <div
                    className="profile-img"
                    onClick={() => dispatch(showLessonCreateOrUpdateModal())}
                  >
                    <i
                      className="fas fa-plus fa-4x"
                      style={{ opacity: "0.5" }}
                    ></i>
                  </div>
                  <h5 className="user-name m-t-10 m-b-0 text-ellipsis ">
                    <a data-bs-toggle="modal" data-bs-target="#small">
                      Yeni Ders Ekle
                    </a>
                  </h5>
                  <div className="small text-muted"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <LessonCreateOrUpdateModal callback={getGenericLessonList} />
      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={
          "Dersi sildiğinizde bu derse ait bütün sınıf dersleri ve sınavlar da silinecektir. Silme işlemini onaylıyor musunuz?"
        }
        approveCallback={consentModalApproveHandler}
        cancelCallback={consentModalCancelHandler}
      />
      {editableGenericLesson ? (
        <div
          className="modal fade text-left show"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            overflow: "auto",
            display: showLessonEditModal ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Ders Güncelle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x"></i>
                </button>
              </div>
              <div className="modal-body">
                <form action="#">
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Ders Adı: </label>
                      <input
                        type="text"
                        placeholder="Ad"
                        value={editableGenericLesson.lessonName}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEditableGenericLesson({
                            ...editableGenericLesson,
                            lessonName: e.target.value,
                          })
                        }
                        className={
                          "form-control" +
                          (editableGenericLesson.lessonName
                            ? " is-valid"
                            : " is-invalid")
                        }
                        required
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setShowLessonEditModal(false)}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  disabled={!editableGenericLesson.lessonName}
                  type="button"
                  onClick={editSubmitHandler}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
