import React, { useEffect, useState } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import ClassroomService from "../../services/classroomService";
import Header from "../header";
import Datatable from "../sharedComponents/datatable";
import SubClassroomListPage from "./SubClassroomListPage";
import SubClassroomService from "../../services/subClassroomService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ComponentLoader from "../sharedComponents/componentLoader";
import AdminSubClassroomListPage from "./AdminSubClassroomListPage";
import SubClassRoomPage from "./SubClassroomPage";

export default function AdminClassStudentsPage(props: {
  classroomList: Array<ClassroomOutputModel>;
}) {
  const { path } = useRouteMatch();
  const params = useParams<any>();
  const { classId, brancheId } = params;
  const classService = new ClassroomService();
  const subClassroomService = new SubClassroomService();
  const dispatch = useDispatch();
  const [classroom, setClassroom] = useState<ClassroomOutputModel>({
    students: [],
    name: "",
    lessons: [],
    id: 0,
  });
  const [subClassroomList, setSubClassroomList] = useState<
    SubClassroomOutputModel[]
  >([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState<boolean>(true);
  const getSubClassroomById = async () => {
    dispatch(showLoader());

    const response = await subClassroomService.GetSubClassroomForAdmin({
      classroomId: Number(classId),
      branchId: Number(brancheId),
    });
    await setSubClassroomList(response);
    dispatch(hideLoader());
  };

  const getClassroomById = async () => {
    setLoader(true);
    const response = await classService.GetClassroomForAdmin({
      classroomId: Number(classId),
      branchId: Number(brancheId),
    });
    await setClassroom(response?.[0]);

    setLoader(false);
  };

  useEffect(() => {
    getClassroomById();
    getSubClassroomById();
  }, [classId, props]);

  const filteredStudentList = classroom?.students.filter((e) => {
    const queryText = searchText.toLocaleLowerCase();

    const testId = e.id.toString();
    const name = (e.name || "").toLocaleLowerCase();
    const surname = (e.surname || "").toLocaleLowerCase();
    const parentName = (e.parentName || "").toLocaleLowerCase();
    // const testName = (e.name || "").toLocaleLowerCase();
    const isPassive = e.isPassive === false;
    return (
      (testId.includes(queryText) ||
        name.includes(queryText) ||
        surname.includes(queryText) ||
        parentName.includes(queryText)) &&
      isPassive
    );
  });

  const datatableModel: DatatableDataModel = {
    header: [
      "Öğrenci Adı Soyadı",
      "Veli Adı Soyadı",
      "Öğrenci Tel",
      "Veli Tel",
    ],
    list: (filteredStudentList || []).map((s, index) => ({
      id: index,
      row: [s?.name, s?.parentName, s?.telephone, s?.parentTel],
    })),
  };

  useEffect(() => {
    datatableModel.list.length && dispatch(hideLoader());
  }, [datatableModel]);
  return (
    <Switch>
      <Route
        path={`/admin/branchesClassrooms/:brancheId/classroom/:classId/subClassroom/:subClassId`}
      >
        <SubClassRoomPage />
      </Route>
      <Route path={path}>
        <>
          <AdminSubClassroomListPage
            classroomName={classroom?.name}
            subClassroomList={subClassroomList}
            classId={classId}
          />
          {!path.includes("subClassroom") && !path.includes("report") && (
            <>
              <div className="page-heading">
                <h3>{`Şubesi Belirlenmemiş Öğrenciler (${classroom?.students?.length} Öğrenci)`}</h3>
              </div>
              <div className="form-group row">
                <div className="col-12 d-flex justify-content-center align-items-center ">
                  <input
                    type="text"
                    placeholder="Öğrenci Ara"
                    className="form-control"
                    value={searchText}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchText(e.target.value)
                    }
                    style={{ marginBottom: "25px" }}
                  />
                </div>
              </div>
              {loader ? (
                <ComponentLoader />
              ) : (
                <Datatable
                  editable={false}
                  insertable={false}
                  data={datatableModel}
                />
              )}
            </>
          )}
        </>
      </Route>
    </Switch>
  );
}
