import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import Choices from "choices.js";
import { options } from "sanitize-html";

export default function SelectFromList(props: {
  onSelectClick: (value: string) => void;
  buttonTag?: string;
  options?: Array<{ text: string; value: string }>;
  bookList?: any;
  lessonId?: number;
  lessonBookList?: any;
}) {
  const [selectValue, setSelectValue] = useState("");
  const ref = useRef(null);
  const choicesRef: MutableRefObject<Choices> = useRef(null);
  const [filteredBooks, setFilteredBooks] = useState<[]>([]);

  const getListOptions = () => {
    let data = [];
    (props.lessonBookList || []).map((item, index) => {
      if (Number(item[0].split(";;;")[1]) === Number(props.lessonId)) {
        data = [
          ...data,
          { value: index.toString(), text: item[0].split(";;;")[0] },
        ];
      }
    });
    return data;
  };

  useEffect(() => {
    choicesRef.current && choicesRef.current.destroy();
    const choicesList = [];
    getListOptions().forEach((l) =>
      choicesList.push({ value: l.value, label: l.text })
    );
    choicesList.unshift({
      value: "",
      label: "Seçim Yapınız",
      selected: true,
      disabled: true,
    });
    choicesRef.current = new Choices(ref.current, {
      itemSelectText: "Seçmek için tıklayınız",
      removeItemButton: true,
      choices: choicesList,
      noResultsText: "Kitap bulunamadı",
      shouldSort: false,
    });
  }, [props.options, props.lessonBookList]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <select
                className="choices form-select"
                ref={ref}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectValue(e.target.value)
                }
                style={{ zIndex: 1 }}
              ></select>
              <button
                disabled={!selectValue}
                onClick={() => props.onSelectClick(selectValue)}
                className="mt-2 btn btn-info rounded-pill"
              >
                {props.buttonTag || "Seç"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
