import React, { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { RootState } from "../../store";

export default function MessageModal() {
  const message = useSelector((state: RootState) => state.messenger);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    setModalVisibility(message.show !== false);
  }, [message]);

  return (
    <div
      className="modal fade text-left show"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: modalVisibility ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {message.title}
            </h4>
            <button
              type="button"
              onClick={() => setModalVisibility(false)}
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">{message.message}</div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => setModalVisibility(false)}
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Tamam</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
