//@ts-nocheck
import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import Header from "../header";
import { useState } from "react";
import SubClassroomService from "../../services/subClassroomService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import AdvancedTable from "./AdvancedTable";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CheckBookService from "../../services/checkBookService";

export default function CheckBookDetailsPage() {
  const { lessonId } = useParams();
  const dispatch = useDispatch();
  const checkBookService = new CheckBookService();
  const subClassroomService = new SubClassroomService();
  const [subClassroomDetail, setSubClassroomDetail] = useState<
    Array<TeacherSubClassroomDetailOutputModal>
  >([]);
  const lessonName = `${subClassroomDetail?.[0]?.classroom?.name}/${subClassroomDetail?.[0]?.name}`;
  const [selectedStudent, setSelectedStudent] = useState<string[]>([]);
  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);
  const [selectedDate, setSelectedData] = useState("");
  const [checkBookData, setCheckBookData] = useState<GetCheckBookOutputModel[]>(
    []
  );
  const [selectedId, setSelectedId] = useState<number>();
  const [detailsModalVis, setDetailsModalVis] = useState<boolean>(false);

  const handleStudentClick = (id: string) => {
    if (selectedStudent.includes(id)) {
      setSelectedStudent(
        selectedStudent.filter((studentId) => studentId !== id)
      );
    } else {
      setSelectedStudent([...selectedStudent, id]);
    }
  };

  const getSubClassroomDetail = async (id: number) => {
    dispatch(showLoader());
    const response =
      await subClassroomService.GetSubClassroomByTeacherIdBySubClassroomId(id);
    setSubClassroomDetail(response);
    dispatch(hideLoader());
  };

  const getCheckBook = async (id: number) => {
    dispatch(showLoader());
    const response = await checkBookService.GetCheckBook(id);
    setCheckBookData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getSubClassroomDetail(lessonId);
    getCheckBook(lessonId);
  }, [lessonId]);

  useEffect(() => {
    checkBookData.map((item) => {
      if (dayjs(item.issueDate).isSame(dayjs(), "d")) {
        // console.warn(dayjs(item.issueDate), dayjs());
        // console.warn(item.issueDate);
        const students = item.students.map((student) => {
          if (student.check) {
            return student.student.id;
          }
        });
        setSelectedStudent(students);
      }
    });
  }, [checkBookData, addNewModalVis]);

  const formSubmitHandler = async () => {
    const data = {
      IssueDate: dayjs().format("YYYY-MM-DD"),
      SubclassroomId: Number(lessonId),
      StudentId: selectedStudent,
    };
    const response = await checkBookService.AddCheckBook(data);
    if (response) {
      setAddNewModalVis(false);
    }
    getCheckBook(lessonId);
  };

  const tableData = checkBookData?.map((item, index) => {
    return {
      issueDate: dayjs(item.issueDate).format("DD-MM-YYYY"),
      id: index,
    };
  });

  const customDataTable = [
    {
      accessorKey: "issueDate",
      header: "Tarih",
      maxSize: 100,
    },
  ];

  const detailsData: GetCheckBookOutputModel = checkBookData?.[selectedId];

  const selectedDetailsData: GetCheckBookOutputModel =
    detailsData?.students?.map((item) => ({
      check: item.check ? "Var" : "Yok",
      id: item.student.id,
      nameSurname: `${item.student.name} ${item.student.surname}`,
    }));

  const tableDetailsData = selectedDetailsData;

  const customDetailsDataTable = [
    {
      accessorKey: "nameSurname",
      header: "İsim Soyisim",
      maxSize: 100,
    },
    {
      accessorKey: "check",
      header: "Yoklama",
      maxSize: 100,
    },
  ];

  return (
    <div className="page-content">
      <Header name={`Yoklama Defteri - ${lessonName}`} />
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="row  d-flex justify-content-end align-items-center">
              <div
                className={
                  "col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end align-items-center pb-3"
                }
              >
                <button
                  onClick={() => {
                    setAddNewModalVis(true);
                  }}
                  type="button"
                  className="btn btn-primary ml-1 btn-l"
                  disabled={selectedStudent === ""}
                >
                  Yoklama Ekle
                </button>
              </div>
            </div>
            <AdvancedTable
              data={tableData}
              dataTable={customDataTable}
              filters={false}
              detailsButton={(row) => {
                setSelectedId(row.id);
                setDetailsModalVis(true);
              }}
            />
          </div>
        </div>
      </div>
      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog modal-xl"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Yoklama Ekle - ${dayjs().format("DD-MM-YYYY")}`}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    {/* <div
                      className={
                        "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end align-items-center"
                      }
                    >
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedData(date)}
                        selectsStart
                        startDate={new Date()}
                        endDate={new Date()}
                        minDate={new Date()}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div> */}
                    <section className="row">
                      <div className="row staff-grid-row">
                        <div className="row d-flex justify-content">
                          {!subClassroomDetail?.[0]?.students?.length ? (
                            <b>Şubede Öğrenci Kayıtlı Değil</b>
                          ) : (
                            subClassroomDetail?.[0]?.students.map(
                              (item, index) => (
                                <div
                                  className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"
                                  onClick={() => {
                                    handleStudentClick(item.id);
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    className={`accordion-item card card-md card-body shadow  ${
                                      selectedStudent.includes(item.id)
                                        ? "bg-success"
                                        : "bg-grey"
                                    } `}
                                  >
                                    <b
                                      style={{
                                        color:
                                          selectedStudent.includes(item.id) &&
                                          "white",
                                      }}
                                    >
                                      {item.name}
                                    </b>
                                  </div>
                                </div>
                              )
                            )
                          )}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={formSubmitHandler}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {detailsModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog modal-xl"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Yoklama Detay - ${dayjs(
                    selectedDetailsData.issueDate
                  ).format("DD-MM-YYYY")}`}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <AdvancedTable
                    data={tableDetailsData}
                    dataTable={customDetailsDataTable}
                    filters={false}
                  />
                  <div className="col-12 p-2">
                    <section className="row">
                      <div className="row staff-grid-row">
                        <div className="row d-flex justify-content">
                          {detailsData[0]?.students[0]?.student?.name}
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setDetailsModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
