// @ts-nocheck
import { useEffect, useState } from "react";

const ResultQuestions = (props: {
  index: number;
  setAnswer: any;
  answer:
    | {
        id: number;
        answer: string;
        learningOutCome: string;
      }[]
    | TestQuestionResultModel[];
  learningOutcome?: boolean;
}) => {
  const [correctAnswer, setCorrectAnswer] = useState<{
    answer: string;
    learningOutCome: string;
  }>({ answer: "", learningOutCome: "" });
  const [inputType, setInputType] = useState<string>("R");
  const selectQuestionAnswer = (answer: string) => {
    setCorrectAnswer((correctAnswer) => ({
      ...correctAnswer,
      ...{ answer },
    }));
  };
  const swapInput = () => {
    setInputType(inputType === "R" ? "T" : "R");
  };

  useEffect(() => {
    let newData = [...props.answer];
    newData[props.index] = {
      id: props.index,
      answer: correctAnswer.answer,
      learningOutCome: correctAnswer.learningOutCome,
    };

    props.setAnswer(newData);
  }, [correctAnswer]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12 pb-2">
          <div className="input-group">
            <div className="input-group-prepend"></div>
            {inputType === "R" ? (
              <div
                style={{
                  padding: "0 10px 0 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="resultRadio"
                >
                  <input
                    type="radio"
                    id={`A-${props.index}`}
                    name={`skills-${props.index}`}
                    value="A"
                    checked={props.answer[props.index]?.answer == "A"}
                    onChange={() => {
                      selectQuestionAnswer("A");
                    }}
                  />
                  <label htmlFor={`A-${props.index}`}>A</label>
                  <input
                    type="radio"
                    id={`B-${props.index}`}
                    name={`skills-${props.index}`}
                    value="B"
                    checked={props.answer[props.index]?.answer == "B"}
                    onChange={() => selectQuestionAnswer("B")}
                  />
                  <label htmlFor={`B-${props.index}`}>B</label>
                  <input
                    type="radio"
                    id={`C-${props.index}`}
                    name={`skills-${props.index}`}
                    value="C"
                    checked={props.answer[props.index]?.answer == "C"}
                    onChange={() => selectQuestionAnswer("C")}
                  />
                  <label htmlFor={`C-${props.index}`}>C</label>
                  <input
                    type="radio"
                    id={`D-${props.index}`}
                    name={`skills-${props.index}`}
                    value="D"
                    checked={props.answer[props.index]?.answer == "D"}
                    onChange={() => selectQuestionAnswer("D")}
                  />
                  <label htmlFor={`D-${props.index}`}>D</label>
                  <input
                    type="radio"
                    id={`E-${props.index}`}
                    name={`skills-${props.index}`}
                    value="E"
                    checked={props.answer[props.index]?.answer == "E"}
                    onChange={() => selectQuestionAnswer("E")}
                  />
                  <label htmlFor={`E-${props.index}`}>E</label>
                </div>
              </div>
            ) : (
              <div
                style={{
                  padding: "0 10px 0 10px",
                }}
              >
                <input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    selectQuestionAnswer(e.target.value)
                  }
                  value={props.answer.learningOutCome}
                  placeholder="Cevap"
                  className="form-control"
                  name={`skills`}
                />
              </div>
            )}
            <div className="input-group-append">
              <i
                onClick={() => swapInput(props.index)}
                style={{ cursor: "pointer" }}
                className="fas fa-sync-alt"
              ></i>
            </div>
            {props.learningOutcome && (
              <div
                style={{
                  display: "block",
                  padding: "0 10px 0 10px",
                }}
              >
                <input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    // setLearningOutcome(e.target.value)
                    setCorrectAnswer((correctAnswer) => ({
                      ...correctAnswer,
                      ...{ learningOutCome: e.target.value },
                    }))
                  }
                  value={props.answer.learningOutCome}
                  placeholder="Soru Kazanımı"
                  className="form-control"
                  name={`skills`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultQuestions;
