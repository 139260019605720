import BaseService from "./baseService";

declare global {
  interface TestInputModel {
    testQuestionIds: Array<number>;
    testName: string;
    lessonId: number;
  }

  interface TestOutputModel {
    id: number;
    name: string;
    issueDate: string;
    testQuestions: Array<QuestionOutputModel>;
    lessonName: string;
    className: string;
    testResults: Array<TestResultModel>;
    questionCount: number;
  }

  interface TestQuestionSorted {
    order: number;
    question: QuestionOutputModel;
  }

  interface TestQuestionAnswerInputModel {
    id: number;
    correctAnswer: string;
  }

  interface TestResultModel {
    questionCount?: number;
    correctAnswerCount?: number;
    incorrectAnswerCount?: number;
    unansweredCount?: number;
    questionResults: Array<TestQuestionResultModel>; // output
    testQuestionResults?: Array<TestQuestionResultModel>; // input
    studentId: string;
    testId: number;
    testName?: string;

    fillDate?: string;
    lessonName?: string;
    lessonId?: number;
    className?: string;

    student?: StudentOutputModel;
    id?: number;
  }

  interface TestQuestionResultModel {
    value: string;
    questionId: number;
    id?: number;
  }

  interface FailedQuestionWithStudentsOutputModel {
    testQuestion: QuestionOutputModel;
    students: Array<StudentOutputModel>;
  }

  interface TestQuestionWithStudentAnswerOutputModel {
    testQuestion: QuestionOutputModel;
    studentAnswer: Array<StudentOutputModel>;
    order: number;
  }

  interface LessonTestUploadModel {
    id: number;
    lessonName: string;
    bookList: Array<Array<string>>;
  }

  interface TestAssignmentModel {
    startDate?: string;
    endDate?: string;
    testId: number[];
    studentId: string[];
    subClassroomId: string[];
    duration?: number;
  }

  interface TestAssigmentByIdModel {
    id: number;
    studentId: string[];
    subClassroom: SubClassroomOutputModel[];
    testId: number;
    startDate: string;
    endDate: string;
    isSolved: boolean;
    isDeleted: boolean;
    test: {
      id: number;
      name: string;
      issueDate: string;
      lessonName: string;
      className: string;
      questionCount: number;
    };
  }

  interface PracticeExamInputModel {
    name: string;
    classroomId: number;
    publisher: string;
    practiceExamTypeId: number;
    felsefe: boolean;
    practiceExamLearningOutcome: Array<{
      lesson: string;
      question: number;
      questionB: number;
      answer: string;
      learningOutcome: string;
    }>;
  }

  interface PracticeExamOutputModel {
    id: number;
    name: string;
    classroom: {
      id: number;
      name: string;
    };
    publisher: string;
  }

  interface StudentPracticeExamListOutputModel {
    practiceExam: {
      id: number;
      name: string;
      classroom: any;
      publisher: string;
      practiceExamType: {
        id: number;
        name: string;
      };
    };
    lesson: string[];
    practiceExamResultId: number;
  }

  interface PracticeExamDetailOutputModel {
    learningOutcomes: Array<{
      lesson: string;
      question: number;
      questionB: number;
      answer: string;
      learningOutcome: string;
    }>;
    id: number;
    name: string;
    classroom: {
      id: number;
      name: string;
    };
    publisher: string;
  }

  interface PracticeExamResultOutputModal {
    id: number;
    fillDate: string;
    student: {
      id: string;
      parentName: string;
      email: string;
      school: string;
      schoolClass: string;
      name: string;
      surname: string;
      telephone: string;
      city: string;
      profilePhoto: string;
      parentEmail: string;
      parentTel: string;
      isPassive: boolean;
      tcNo: string;
    };
    practiceExam: {
      id: number;
      name: string;
      classroom: string;
      publisher: string;
    };
    correctAnswerCount: number;
    inCorrectAnswerCount: number;
    unAnsweredCount: number;
    practiceExamQuestionResult: Array<{
      id: number;
      studentAnswer: string;
      practiceExamLearningOutcomes: {
        lesson: {
          practiceExamLessonGroups: any;
          lessonName: string;
          startQuestionPosition: number;
          questionLength: number;
        };
        question: number;
        questionB: number;
        answer: string;
        learningOutcome: string;
      };
    }>;
  }

  interface StudentPracticeExamsReportInputModel {
    studentId: string;
    practiceExamIds: number[];
  }

  interface StudentPracticeExamsReportOutputModel {
    student: {
      name: string;
      classroom: string;
      date: string;
      examType: string;
    };
    headers: string[];
    exams: {
      examName: string;
      examDate: string;
      results: {
        lessonName: string;
        questionCount: string;
        totalNet: string;
      }[];
    }[];
    average: {
      lessonName: string;
      averageCount: string;
      questionCount: string;
    }[];
    learningOutcomes: {
      lessonName: string;
      learningOutcome: string;
      questionCount: string;
      correctAnswerCount: string;
      inCorrectAnswerCount: string;
      unAnsweredCount: string;
      successRate: string;
    }[];
    orderByDescLearningOutcomes: {
      correctAnswerCount: string;
      inCorrectAnswerCount: string;
      learningOutcome: string;
      lessonName: string;
      questionCount: string;
      successRate: string;
      unAnsweredCount: string;
    }[];
  }

  interface ReportForSubclassroomInputModel {
    SubclassroomId: number;
    TestIds: number[];
  }

  interface ReportForSubclassroomOutputModel {
    student: StudentOutputModel;
    exams: {
      testResults: {
        questionCount: number;
        correctAnswerCount: number;
        incorrectAnswerCount: number;
        unansweredCount: number;
        student: StudentOutputModel;
        testId: number;
        testName: string;
        fillDate: string;
        lessonName: string;
        lessonId: number;
        className: string;
        testAssignmentId: any;
        id: number;
        net: number;
        successRate: number;
      }[];
      lessonName: string;
    }[];
  }

  interface ReportForStudentInputModel {
    studentId: number;
    TestIds: number[];
  }

  interface ReportForStudentOutputModel {
    student: StudentOutputModel;
    exams: {
      testResults: {
        questionCount: number;
        correctAnswerCount: number;
        incorrectAnswerCount: number;
        unansweredCount: number;
        student: StudentOutputModel;
        testId: number;
        testName: string;
        fillDate: string;
        lessonName: string;
        lessonId: number;
        className: string;
        testAssignmentId: any;
        id: number;
        net: number;
        successRate: number;
        failedQuestions: {
          questionIndex: number;
          studentAnswer: string;
          correctAnswer: string;
        }[];
      }[];
      lessonName: string;
    }[];
  }

  interface TestListByStudentOutputModel {
    tests: {
      id: number;
      name: string;
      issueDate: string;
      lessonName: string;
      className: string;
      questionCount: number;
    }[];
    lessonName: string;
  }

  interface TestListBySubClassroomOutputModel {
    tests: {
      id: number;
      name: string;
      issueDate: string;
      lessonName: string;
      className: string;
      questionCount: number;
    }[];
    lessonName: string;
  }

  interface TestReportForSubClassroomOutputModel {
    info: {
      classroomInfo: {
        id: number;
        name: string;
        classroom: {
          id: number;
          name: string;
        };
        branches: {
          id: number;
          name: string;
        };
      };
      date: string;
    };
    exams: {
      testResults: {
        questionCount: number;
        correctAnswerCount: number;
        incorrectAnswerCount: number;
        unansweredCount: number;
        student: StudentOutputModel;
        testId: number;
        testName: string;
        fillDate: string;
        lessonName: string;
        lessonId: number;
        className: string;
        testAssignmentId: any;
        id: number;
        net: number;
        successRate: number;
      }[];
      testName: string;
      average: number;
    }[];
  }
}

export default class TestService extends BaseService {
  constructor() {
    super("/test");
  }

  CreateTest(test: TestInputModel) {
    return this.Post<TestInputModel, number>(test);
  }

  GetTestDetails(id: number) {
    return this.GetById<number, TestOutputModel>(id);
  }

  GetTestList() {
    return this.Get<Array<TestOutputModel>>();
  }

  UploadAnswerKey(questionList: Array<TestQuestionAnswerInputModel>) {
    return this.Post<
      Array<TestQuestionAnswerInputModel>,
      Array<QuestionOutputModel>
    >(questionList, "/answerkey");
  }

  UploadTestResult(testResult: any) {
    return this.Post<TestResultModel, number>(testResult, "/testresult");
  }

  GetTestResults() {
    return this.Get<Array<TestResultModel>>(null, "/testresult");
  }

  GetStudentsFailedQuestionList(lessonId: number, studentId: string) {
    return this.Get<Array<StudentFailedQuestionOutputModel>>(
      { lessonId: lessonId.toString(), studentId },
      "/failedquestion"
    );
  }

  UploadPdf(data: FormData) {
    return this.Post<FormData, boolean>(
      data,
      "/testupload",
      true,
      true,
      {},
      false
    );
  }

  GetPdfList(lessonId: string) {
    return this.Get<Array<Array<string>>>(
      { lessonId: lessonId },
      "/testupload"
    );
  }

  CreateTestFromPdf(questionList: FormData) {
    return this.Post<FormData, boolean>(
      questionList,
      "/testcreate",
      true,
      true,
      {},
      false
    );
  }

  GetAllPdfList() {
    return this.Get<Array<LessonTestUploadModel>>(null, "/testupload");
  }

  DeleteTest(testId: number) {
    return this.Delete<number>(testId, "/test");
  }

  DeletePdf(bookName: string) {
    return this.Delete(null, "/testupload?fileName=" + bookName);
  }

  EditTestName(test: TestOutputModel) {
    return this.Put<number, TestOutputModel, TestOutputModel>(test.id, test);
  }

  UploadTestResultFile(data: FormData) {
    return this.Post<FormData, boolean>(
      data,
      "/testresult/upload",
      true,
      true,
      {},
      false
    );
  }

  CreatePracticeExam(data: PracticeExamInputModel) {
    return this.Post(data, `/practiceExams`);
  }

  GetPracticeExam() {
    return this.Get<Array<PracticeExamOutputModel>>(null, "/practiceExams");
  }

  DeletePracticeExam(id: number) {
    return this.Delete<number>(null, `/practiceExams/${id}`);
  }

  UpdatePracticeExam(data: { name?: string; publisher?: string }, id: number) {
    return this.Post(data, `/practiceExams/${id}`);
  }

  GetPracticeExamById(id: number) {
    return this.Get<PracticeExamDetailOutputModel>(
      null,
      `/practiceExams/${id}`
    );
  }

  GetPracticeExamResultById(id: number) {
    return this.Get<Array<PracticeExamResultOutputModal>>(
      null,
      `/practiceExams/result/${id}`
    );
  }

  TestFirstUpload(data) {
    return this.Post<TestResultModel, number>(data, `/testresult/firstUpload`);
  }

  DeleteStudentTestResult(id: number) {
    return this.Delete(id, `/TestResult`);
  }

  CheckTestAssignment() {
    return this.Get<any>(null, `/TestAssignment/CheckTestAssignment`);
  }

  GetStudentPracticeExamsReport(studentId: string, practiceExamIds: number[]) {
    return this.Post<
      StudentPracticeExamsReportInputModel,
      StudentPracticeExamsReportOutputModel
    >(
      {
        studentId,
        practiceExamIds,
      },
      `/PracticeExams/report`
    );
  }

  GetStudentPracticeExams(id: string) {
    return this.Get<Array<StudentPracticeExamListOutputModel>>(
      null,
      `/PracticeExams/forStudent/${id}`
    );
  }

  GetReportForSubclassroom({
    SubclassroomId,
    TestIds,
  }: ReportForSubclassroomInputModel) {
    return this.Post<
      ReportForSubclassroomInputModel,
      ReportForSubclassroomOutputModel
    >(
      {
        SubclassroomId,
        TestIds,
      },
      ` /TestResult/getReportBySubclassroom`
    );
  }

  GetTestReportForStudent(studentId, TestIds) {
    return this.Post<ReportForStudentInputModel, ReportForStudentOutputModel>(
      {
        studentId,
        TestIds,
      },
      `/TestResult/getReportByStudent`
    );
  }

  GetTestListByStudent(studentId: string) {
    return this.Get<Array<TestListByStudentOutputModel>>(
      null,
      `/TestResult?studentId=${studentId}`
    );
  }

  GetTestListBySubClassroom(subClassroomId: number) {
    return this.Get<Array<TestListBySubClassroomOutputModel>>(
      null,
      `/TestResult?subclassroomId=${subClassroomId}`
    );
  }

  GetTestReportForSubClassroom(SubclassroomId: number, TestIds: number[]) {
    return this.Post<any, TestReportForSubClassroomOutputModel>(
      {
        SubclassroomId,
        TestIds,
      },
      `/TestResult/getReportBySubclassroom`
    );
  }

  GetTestResultReportForstudent(TestIds: number[]) {
    return this.Post<any, any>(
      {
        TestIds,
      },
      `/TestResult/getReportForStudent`
    );
  }

  GetTestResultReportForSubclassroom(TestIds: number[]) {
    return this.Post<any, any>(
      {
        TestIds,
      },
      `/TestResult/getReportForSubclassroom`
    );
  }

  GetTestForSubclassroomByTestResult() {
    return this.Get(null, "/TestResult/GetForSubclassroom");
  }

  GetTestForStudentByTestResult() {
    return this.Get(null, "/TestResult/GetForStudent");
  }

  GetPracticeExamReportForStudent(practiceExamIds: number[]) {
    return this.Post<any, any>(
      {
        practiceExamIds,
      },
      `/PracticeExams/reportForStudent`
    );
  }

  GetReportByCompareSubclassroom(data) {
    return this.Post<any, any>(
      data,
      `/TestResult/getReportByCompareSubclassroom`
    );
  }

  GetCompareSubclassrooms(data: any) {
    return this.Post<any, any>(
      {
        subclassroomIds: data,
      },
      `/TestResult/TestListForCompareSubclassrooms`
    );
  }
}
