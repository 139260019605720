import { ReactElement } from "react";

declare interface ServiceStaticDataDictionary {
    [key: string]: ServiceStaticData;
}

declare interface ServiceStaticData {
    shortDescTitle: string;
    shortDesc: string;
    longDescTitle: string;
    longDesc: string;
}

export const serviceStaticData: ServiceStaticDataDictionary = {
    "/ozelders": {
        shortDesc: "Özel Ders ile ilgili en aşağıda ki makaleyi okuyup, Özel Ders kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "Özel Ders",
        longDesc: "Özel ders programı gümüşeğitim 2009 yılından itibaren en önem verdiği ve sistemin bu yapı üzerine kurulu olduğu en çok hizmet verdiği alandır.  Özel ders programı öğrencinin Merkezi Sınava mı yoksa Okul dersi için mi olduğuna bakılarak aldığı paket programlardır. Özel ders isteğe bağlı bir dersten yada o dersin bazı ünitelerinden alınabilir.Her koşulda öğrenci gümüştakip sistemine kaydolur ve burdan takip edilir.Özel ders alan öğrenciye sabit bir gün ve saat belirlenerek dersin devamlılığı sağlanır.Özel ders öğrencinin bir eğitim danışmanı ve çalışma programı alabileceği bir rehber öğretmeni vardır. Yapılan her ders sonrası bitirilen ünitelerden sonra öğrenci Temel Seviye ve İleri Seviye Ünite değerlendirme sınavlarına Tabi tutulur ve yüzdelik olarak ne kadar öğrenme sağlamış izlenir.Yapılamayan soruların biriktiği soru havuzundan gelen sorular öğrencinin sürekli karşısına çıkar ve öğretmen her defasında yapamadığı soruyu tekrar tekrar öğretir. Özellikle bir konuda uzmanlaşmak isteyen veya altyapı eksiği olan öğrenciler kesinlikle özel ders almak zorundadır.Geçmiş dönemden eksik gelen bir öğrencinin konularının tamamlanacağı alan özel ders programıdır. ",
        longDescTitle: "Özel Ders Nedir?"
    },
    "/onlinebirebirders": {
        shortDesc: "ONLİNE EĞİTİM ile ilgili en aşağıda ki makaleyi okuyup, ONLİNE EĞİTİM kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "ONLİNE EĞİTİM",
        longDesc: "Program sadece Bire-Bir derslerimiz için geçerlidir. Özel Ders online eğitimi veren eğitmenlerimiz alanında uzman öğretmenlerimizdir ve iki senedir sürekli online dersler vermektedirler. Teknolojik alt yapı olarak öğretmenlerin yazı Tabletleri öğrenci Takip sistemi sayesinde öğrenciyi ödevlendirmek ve kontrol edebilme imkanlarına sahiptir. Yüz yüze yapılan özel derslerden hiç bir farkı yoktur yapılan derslerin videoları sitede mevcuttur. Dersler 40 dakika yapılır Online için gayet yeterli bir süredir çünkü online derste çok daha fazla soru çözülmektedir. Öğrenci dersin tekrarını kaydederek sürekli tekrar etme olağanına sahiptir derste kullanılar materyarller Yazı tableti dersin hızlı ve akıcı yapılmasına olanak sağlar ve öğrencinin ekstra not tutmasına gerek kalmaz. Öğrenci yüzyüze eğitimde alsa online eğitimde alsa gümüşeğitim öğrenci takip sistemine kaydı olur bu sistem sayesinde öğrencinin ünite değerlendirme yapamadığı soru havuzu performans grafiği oluşturulur. Ödevler PDF olarak atılır yada istenilen kaynaktan takip edilir Tüm kaynakların PDF sistemimizde mevcuttur.",
        longDescTitle: "Online Bire-bir Nedir?"
    },
    "/lgs": {
        shortDesc: "LGS ile ilgili en aşağıda ki makaleyi okuyup, LGS kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "LGS",
        longDesc: `
        LGS Programı Sınıf ve Özel ders programı olarak ikiye ayrılmaktadır. 
LGS programında başarılı olmanın yolu öğrencinin yeni nesil soruları çözebilme kabiliyetiyle alakalıdır. 
<br/>
Yeni nesil soruları çözen öğretmenlerin kesinlikle alanlarında iyi ve yıllardır yeni nesil sorulara alışık öğretmenlerden oluşması gerekir. 
Gümüşeğitim LGS programına dahil olan öğrenci hafta içi veya hafta sonu sınıf programlarından birine katılabilir. Dersler seans şeklindedir ve her bir seans 90dak. yapılır. Matematik ağırlık bir programdır. 
Haftalık verilen ders sayısı Etüt Hariç 20 saat olmaktadır ve ayrıca haftanın belli bir günü ünite değerlendirme sınavları yapılır. 
LGS programında öğrencinin ihtiyaçına bağlı öğrenciye eğitim danışmaları özel ders almasını tavsiye edebilir veya veli isterse tüm dersleri özel ders şeklinde alabilir bu konuda yapılan ünite değerlendirme sınavları ve öğretmen yorumları önemlidir. 
<br />
LGS sınavında başarılı olmak için öğrencinin belli bir Hazır Bulunuşluk Seviyesinde olması gerekir eksik olduğu konular için kesinlikle profesyonel özel ders almalıdır. 
LGS özel bir sınavdır ve her öğrencinin başarılı olması zor bir sınavdır kontenjan sayıları azdır yüzdelik dilim olarak %7 giren öğrencilerin kazanabileceği bir sınavdır.
<br />
Gümüşeğitim sistemi içinde kullanılan gümüştakip sistemi sayesinde öğrencinin her ünite sonrası başarı durumunu yapamadığı soruları yapamadığı soru havuzunu başarı yüzdelerini ve öğretmen geri bildirimlerini size verilen şifreyle evinizden kolay bir şekilde takip edebilirsiniz. 
<strong>*LGS de başarı: </strong>
<br/>
1) Tecrübeli ve enerjisi yüksek Öğretmenler.
<br/>
2) Kavrama ve Niteliğe bağlı Doğru yayınların kullanılması.
<br/>

3) İyi bir takip sistemiyle mümkündür.
    `,
        longDescTitle: "LGS Nedir?"
    },
    "/yks": {
        shortDesc: "YKS ile ilgili en aşağıda ki makaleyi okuyup, YKS kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "YKS",
        longDesc: "Üniversite Hazırlık Programımız İki ayrı programdan oluşmaktadır. Birincisi Özel ders programı diğeri ise grup dersidir. Özel ders programımız öğrencinin bölümüne bağlı olarak senelik paket programlardır ve bire-bir yapılan derslerdir. Öğrenci Özel ders programın tüm derslerinden alabileceği gibi sadece belli derslerden de özel ders paket programından faydalana bilir ister haftalık 10 ders isterse 1 ders alsın gümüşeğitim takip sistemi ve şubenin diğer tüm hizmetlerinden faydalanabilir. Grup dersleri 10-12 kişilik sınıflarda yapılan belli bir müfredat üzerinden giden ve saat esansına göre değil SEAN esansına göre yapılan derslerdir. Dersler 90dak. Yapılır ve işlenilen her konunun yapılması gereken ödevlerin ilk kısmı öğrenci öğretmen eşliğinde konu anlatıldıktan sonra derste yapılır bu çalışma şekli ilk öğrenme için çok önemlidir ve öğrencinin daha sonra yapacağı ödevlerin yapabilme,çözümleye bilmesine olanak sağlar. ",
        longDescTitle: "YKS Nedir?"
    },
    "/oaho": {
        shortDesc: "Okudugunu Anlama ve Hızlı Okuma ile ilgili en aşağıda ki makaleyi okuyup, Okudugunu Anlama ve Hızlı Okuma kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "Okudugunu Anlama ve Hızlı Okuma",
        longDesc: `
        Dünyamız hızla ilerlemekte ve gelişmektedir. Bu gelişim ve ilerlemeye ayak
        uydurabilmek için bizler de hayatımızın her alanında daha hızlı hareket etmeli, daha
        pratik olabilmeliyiz. Bu amaç doğrultusunda hazırlanan "HIZLI OKUMA VE ANLAMA"
        Programı okuma hızınınzı artırarak zamanı daha verimli kullanmanızda, okuduğunuzu
        daha iyi anlayıp sonuca ulaşmanızda size yardımcı olacak; hem öğrenim hayatınızın
        her basamağında hem de sonraki meslek hayatınızda size daha önemli işler için vakit
        kazandıracaktır.
        <br>
        <br>
        <strong>HIZLI OKUMA VE ANLAMA BİZE NE KAZANDIRIR?</strong>
        <br>

        1. Yapılan araştırmalarda,okumayı hızlandırıcı çalışmalar yapan öğrencilerin okuma
        ve anlama hızlarının 4 - 5 kat arttığı görülmüştür.
        <br>

        2. Hızlı okuyan öğrenciler,karşılaştıkları problemlere daha hızlı çözüm bulurlar.
        Hızlı okumayan bir öğrenci 60 dakikada 60- 70 soru çözebiliyorsa aynı öğrenci, okuma
        hızını artırdığında aynı sürede 180-200 soru çözebilir.
        <br>

        3. Hızlı okuyan bir öğrenci ödevlerini daha kısa sürede bitirir. Kısa sürede
        ödevlerini bitirdiği için kalan zamanını farklı çalışmalara ayırabilir, zevk aldığı
        işlerle uğraşabilir.
        <br>

        4. Hızlı okuya bilen bir öğrenci eskiden haftada 2 kitap okuyorsa artık 4 kitap
        okuyacaktır. Okuduğu kitap sayısı artıkça kelime hazneside gelişecektir.
        <br>

        5. Okuması hızlı olan öğrenci,öğretmenini daha hızlı takip edecek,dersleri daha iyi
        anlayacak,dersi daha iyi anlayan öğrencinin derse yoğunluğu artacak,başardığını
        hissedecektir.
        <br>

        Beynimizin kapasitesi aslında bizim tahmin ettiğimizden cok daha fazladır. Maalesef
        bunun farkında değiliz. İnsanoğlu eğer isterse beynini daha verimli kullanıp daha
        iyi işler başarabilir. Bunun anahtarı ise çok ve hızlı okumaktır.
    `,
        longDescTitle: "Okudugunu Anlama ve Hızlı Okuma Nedir?"
    },
    "/arasinifyazilicalismasi": {
        shortDesc: "Ara Sınıf Yazılı Çalışması ile ilgili en aşağıda ki makaleyi okuyup, Ara Sınıf Yazılı Çalışması kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "Ara Sınıf Yazılı Çalışması",
        longDesc: "gümüşeğitim Yazılı Çalışması özel ders programı şeklinde yapılır. Her ders değil ihtiyaç duyulan derslerden program yapılır ve öğrencinin sınavlara hazırlanması sağlanır. İstenirse sene başından itibaren bir özel ders programı yapılır takip edilir. Bu daha verimli bir çalışma şeklidir. Yazılı öncesi yapılan özel ders programı sürekli devam edilen programlar kadar verimli olmaya bilir. Genel tekrar diye bir özel ders programı sağlıklı değildir. Öğrencinin Sınıfına bağlı olarak o sene kesinlikle tam olarak öğrenmesi gereken dersler ve konular vardır bu konuların en doğru şekilde öğrenilmesi öğrencinin sonraki eğitim hayatı için önemlidir.",
        longDescTitle: "Ara Sınıf Yazılı Çalışması Nedir?"
    },
    "/grupdersleri": {
        shortDesc: "Grup Dersleri ile ilgili en aşağıda ki makaleyi okuyup, Grup Dersleri kategorisinde bulunan Eğitimcilerimizin profillerini inceleyebilirsiniz veya Isteğinize göre filtreleyip sonuçları inceleyebilirsiniz.",
        shortDescTitle: "Grup Dersleri",
        longDesc: `
        Sınıflar minimum 9 max 12 kişiliktir ve etüt programı programın içine yerleştirilmiştir. 
        Ders saati şeklinde değil sean şeklinde ilerler ve ders süreleri 90 dakikadır. 
        
        Derslerin başlama zamanları ve günleri okullara bağlı değişlikler göstere bilir. 
        
        Sınıflar arası seviyeye kesinlikle bakılır aynı seviye öğrencileri aynı sınıfta olur. Bu sıralama girilen deneme sınavlarıyla belirlenir ve sene sonuna kadar bu değişim yapılır. (Her deneme Sınıf değiştirme sınavı değildir ve sınıf değişim sınavları çocuklara ve velilere bildirilir)

        <br>
        <br>
        Her seviye grubuna giren öğretmen aynıdır. Sınıfların farklı olması yapılan eğitimin farklı olması anlamına gelmez tüm eğitim aynıdır ama bir şekilde sınıfları adil bir şekilde bölmek gerekmektedir.  Seviye sınıfları oluşturmak çocukların hem motivasyonu geliştirir hemde yapılan eğitimin kalitesini artırır Seviye belirleme sınavlarında oluşan sınıflar noktasında kesinlikle taviz verilemez.

        Yapılan eğitim sean şeklinde devam ettiğinden her ders sonrası ünite değerlendirme soruları derste yapılır veriler sisteme yüklenir yapılamayan sorular çözülür
        <br>

        Veliye geri bildirimle bilgi verilir bu sistemde sorun yaşayan öğrenci için özel ders programı takviye edilir yada gerekirse sadece özel ders alması sağlanır. 

        Ara Sınıf Yazılı Çalışması Programı
         gümüşeğitim Yazılı Çalışması özel ders programı şeklinde yapılır. Her ders değil ihtiyaç duyulan derslerden program yapılır ve öğrencinin sınavlara hazırlanması sağlanır. 

        <br>
        İstenirse sene başından itibaren bir özel ders programı yapılır takip edilir. Bu daha verimli bir çalışma şeklidir. 

        Yazılı öncesi yapılan özel ders programı sürekli devam edilen programlar kadar verimli olmaya bilir. Genel tekrar diye bir özel ders programı sağlıklı değildir. 
        
        Öğrencinin Sınıfına bağlı olarak o sene kesinlikle tam olarak öğrenmesi gereken dersler ve konular vardır bu konuların en doğru şekilde öğrenilmesi öğrencinin sonraki eğitim hayatı için önemlidir.
    `,
        longDescTitle: "Grup Dersleri Nedir?"
    },
};