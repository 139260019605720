import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { StudentIdentityContext } from "../panels/studentPanel";
import StudentBasicInfo from "./studentBasicInfo";
import LessonService from "../../services/lessonService";
import { useState } from "react";
import TestService from "../../services/testService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import SyllabusWidget from "./SyllabusWidget";

export default function StudentMainPage() {
  const studentContext = useContext(StudentIdentityContext);
  const dispatch = useDispatch();
  const testService = new TestService();
  const lessonService = new LessonService();

  const [testAssignment, setTestAssigment] = useState<
    Array<TestAssigmentOutputModel>
  >([]);

  const checkTestAssignment = async () => {
    dispatch(showLoader());
    const response = await testService.CheckTestAssignment();
    dispatch(hideLoader());
  };

  const getTestAssigment = async () => {
    dispatch(showLoader());
    const response = await lessonService.GetTestAssigment();
    setTestAssigment(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getTestAssigment();
    checkTestAssignment();
  }, []);

  return (
    <div className="page-content">
      <section className="row">
        <StudentBasicInfo />

        <div className="col-12 col-lg-12">
          <div className="row">
            <div className="col-12 col-lg-4 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>Sınıf Öğretmenlerim</h4>
                </div>
                <div
                  className="card-content pb-4"
                  style={{
                    maxHeight: "200px",
                    minHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {studentContext?.subclassroom?.teachers.map((t) => (
                    <div className="recent-message d-flex px-4 py-3">
                      <div className="col-3 col-md-3 avatar avatar-lg">
                        <img
                          src={
                            t.teacher.profilePhoto ||
                            "/assets/img/Profile_avatar_placeholder_large.png"
                          }
                        />
                      </div>
                      <div className="name ms-4">
                        <h5 className="mb-1">
                          {t.teacher.name + " " + t.teacher.surname}
                        </h5>
                        <h6 className="text-muted mb-0">
                          {t.teacher.majors.map((m) => m.lessonName).join(", ")}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-12 ">
              <div className="card">
                <div className="card-header">
                  <h4>Özel Ders Öğretmenlerim</h4>
                </div>
                <div
                  className="card-content pb-4"
                  style={{
                    maxHeight: "200px",
                    minHeight: "200px",
                    overflowY: "scroll",
                  }}
                >
                  {studentContext.teachers.map((t) => (
                    <div className="recent-message d-flex px-4 py-3">
                      <div className="col-3 col-md-3 avatar avatar-lg">
                        <img
                          src={
                            t.profilePhoto ||
                            "/assets/img/Profile_avatar_placeholder_large.png"
                          }
                        />
                      </div>
                      <div className="name ms-4">
                        <h5 className="mb-1">{t.name + " " + t.surname}</h5>
                        <h6 className="text-muted mb-0">
                          {t.majors.map((m) => m.lessonName).join(", ")}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>Ödevlerim</h4>
                </div>
                <div className="card-content">
                  <div
                    style={{
                      maxHeight: "200px",
                      minHeight: "200px",
                      overflowY: "scroll",
                    }}
                  >
                    {testAssignment.map((item, index) => (
                      <div className="name ms-4">
                        <h6 className="mb-1">
                          {index + 1}. {item.test.name}
                        </h6>
                      </div>
                    ))}
                  </div>

                  {testAssignment.length > 0 && (
                    <div className="px-4 pb-3">
                      <Link
                        to="/student/uploadExamresults"
                        className="btn btn-block btn-xl btn-light-primary font-bold mt-3"
                      >
                        Hepsini Gör
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 ">
          <SyllabusWidget />
        </div>
      </section>
    </div>
  );
}
