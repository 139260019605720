// // @ts-nocheck
// import "../style/lessons.css";

// import React, { useCallback, useEffect, useState } from "react";
// import Accordion from "../sharedComponents/accordion";
// import EditableAccordionItem, {
//   AccordionItemType,
// } from "../sharedComponents/editableAccordionItem";
// import LessonService from "../../services/lessonService";
// import { useDispatch } from "react-redux";
// import {
//   hideLoader,
//   showLoader,
//   showQuestionCreateOrUpdateModal,
// } from "../../reducers/commonSlice";
// import { sendMessage } from "../../reducers/messengerSlice";
// import QuestionCreateOrUpdateModal from "./questionCreateOrUpdateModal";
// import Datatable from "../sharedComponents/datatable";
// import Draggable, { DraggableCore } from "react-draggable";
// import TestService from "../../services/testService";
// import SelectFromList from "./selectFormList";
// import PdfViewer, { ViewerType } from "./pdfViewer";
// import Modal from "../sharedComponents/modal";
// import ConsentModal from "../consentModal";
// import BaseService from "../../services/baseService";
// import { useParams } from "react-router-dom";

// declare global {
//   interface QuestionIdentityInfo {
//     classId?: number;
//     lessonId?: number;
//     unitId?: number;
//     topicId?: number;
//     question?: QuestionOutputModel;
//   }
// }

// export default function SubLessonsPage() {
//   const baseService = new BaseService();
//   const { lessonId } = useParams();
//   const [deleteModalState, setdeleteModalState] = useState({
//     id: null,
//     visibility: false,
//   });
//   const [formLearningOutcome, setFormLearningOutcome] = useState<string>("");
//   const [classList, setClassList] = useState<Array<ClassroomOutputModel>>([]);
//   const [editableQuestionIndentityData, seteditableQuestionIndentityData] =
//     useState<QuestionIdentityInfo>({});
//   const [lessonBookList, setLessonBookList] = useState<Array<Array<string>>>(
//     []
//   );
//   const [pdfListModalStatus, setPdfListModalStatus] = useState(false);
//   const [selectedBookIndex, setSelectedBookIndex] = useState<number>(null);
//   const [
//     selectedTopicIdForQuestionInsert,
//     setSelectedTopicIdForQuestionInsert,
//   ] = useState(null);
//   const [modalVisibility, setModalVisibility] = useState(false);
//   const [viewableQuestion, setViewableQuestion] =
//     useState<QuestionOutputModel>(null);
//   const lessonService = new LessonService();
//   const dispatch = useDispatch();
//   useEffect(() => {
//     getAllBooks();
//     getGenericLessonList();
//     getLessonList();
//   }, []);
//   const [editModalVis, setEditModalVis] = useState<boolean>(false);

//   const testService = new TestService();
//   const getAllBooks = async () => {
//     const result = await testService.GetAllPdfList();
//     if (result) {
//       const data = result.reduce((acc, curr) => {
//         acc.push(...curr.bookList);
//         return acc;
//       }, [] as Array<Array<string>>);
//       setLessonBookList(data);
//     }
//   };

//   const getBooksById = async (lessonId: number) => {
//     const result = await testService.GetPdfList(29);
//     let data = [];
//     if (result) {
//       data = result.reduce((acc, curr) => {
//         acc.push(...curr);
//         return acc;
//       }, [] as Array<Array<string>>);
//     }
//     return data;
//   };

//   const getLessonList = async () => {
//     dispatch(showLoader());
//     var classList = await lessonService.GetClassroomWithLessons();
//     setClassList(classList);
//     dispatch(hideLoader());
//   };

//   // populate accordions
//   const populateLessons = (classroom: ClassroomOutputModel) =>
//     classroom.lessons.map((l) => (
//       <EditableAccordionItem
//         isSelectBox={true}
//         selectBoxList={genericLessonList.map((s) => ({
//           text: s.lessonName,
//           value: s.id.toString(),
//         }))}
//         onDelete={() => onLessonDeleteHandler(l.id)}
//         editableInputLabel="Ders Adı"
//         onUpdate={(name: string) => onLessonUpdateHandler(l.id, name)}
//         id={"lesson" + l.id}
//         parentId="Lessons"
//         title={l.lessonGenericId.toString()}
//       >
//         <Accordion
//           onInsert={(name: string) => onUnitInsertHandler(l.id, l.units, name)}
//           id="Units"
//           insertable={true}
//           insertTitle="Yeni Ünite Ekle"
//           insertInputLabel="Ünite Adı"
//           populate={() => populateUnits(classroom.id, l.id, l.units)}
//         ></Accordion>
//       </EditableAccordionItem>
//     ));

//   const populateUnits = (
//     classId: number,
//     lessonId: number,
//     units: Array<UnitOutputModel>
//   ) =>
//     (units || []).map((u) => (
//       <EditableAccordionItem
//         id={"unit" + u.id}
//         parentId="Units"
//         title={u.name}
//         onUpdate={(name: string) => onUnitEditHandler(u.id, name)}
//         onDelete={() => onUnitDeleteHandler(u.id)}
//         editableInputLabel="Ünite Adı"
//       >
//         <Accordion
//           onInsert={(name: string) =>
//             onTopicInsertHandler(lessonId, u.id, u.topics, name)
//           }
//           id="Topics"
//           insertable={true}
//           insertTitle="Yeni Konu Ekle"
//           insertInputLabel="Konu Adı"
//           populate={() => populateTopics(classId, lessonId, u.id, u.topics)}
//         ></Accordion>
//       </EditableAccordionItem>
//     ));

//   const populateTopics = (
//     classId: number,
//     lessonId: number,
//     unitId: number,
//     topics: Array<TopicOutputModel>
//   ) =>
//     topics.map((t) => {
//       let data: DatatableDataModel = {
//         header: ["Soru No", "Kazanım"],
//         list: t.testQuestions.map((q) => ({
//           id: q.id,
//           row: [q.id.toString(), q.learningOutcome],
//         })),
//       };
//       return (
//         <EditableAccordionItem
//           id={"topic" + t.id}
//           onUpdate={(name: string) => onTopicEditHandler(t.id, name)}
//           onDelete={() => onTopicDeleteHandler(t.id)}
//           parentId="Topics"
//           title={t.name}
//           editableInputLabel="Konu Adı"
//         >
//           <Datatable
//             viewable={true}
//             onViewClickCallback={(id: number) =>
//               showQuestionModal(classId, lessonId, unitId, t.id, id)
//             }
//             deletable={true}
//             insertTitle="Yeni Soru Ekle"
//             actionElements={[
//               <SelectFromList
//                 onSelectClick={(value) => selectBook(t.id, value)}
//                 options={classList}
//                 buttonTag="Kitaptan Soru Ekle"
//                 lessonId={lessonId}
//                 lessonBookList={lessonBookList}
//               />,
//             ]}
//             onDeleteClickCustomHandler={(itemId: number) =>
//               onQuestionDeleteHandler(itemId)
//             }
//             onEditClickCustomHandler={(id) =>
//               onQuestionEditHandler(classId, lessonId, unitId, t.id, id)
//             }
//             data={data}
//             onInsertClickCustomHandler={() =>
//               onQuestionInsertHandler(classId, lessonId, unitId, t.id)
//             }
//           />
//         </EditableAccordionItem>
//       );
//     });

//   const showQuestionModal = (
//     classId: number,
//     lessonId: number,
//     unitId: number,
//     topicId: number,
//     id: number
//   ) => {
//     const targetClass = classList.find((c) => c.id == classId);
//     if (!targetClass) return;
//     const targetLesson = targetClass.lessons.find((l) => l.id == lessonId);
//     if (!targetLesson) return;
//     const targetUnit = targetLesson.units.find((u) => u.id == unitId);
//     if (!targetUnit) return;
//     const targetTopic = targetUnit.topics.find((t) => t.id == topicId);
//     if (!targetTopic) return;
//     const selectedQuestion = targetTopic.testQuestions.find((q) => q.id == id);
//     setViewableQuestion(selectedQuestion);
//     setModalVisibility(true);
//   };

//   const selectBook = (topicId: number, value: string) => {
//     setSelectedTopicIdForQuestionInsert(topicId);
//     setSelectedBookIndex(Number(value));
//     setPdfListModalStatus(true);
//   };
//   // 1640
//   const getSelectedLessonBookList = (lessonId: number) => {
//     return (lessonBookList || []).map((b) => ({
//       value: b[0].split(";;;")[0],
//       text: b[0].split(";;;")[0],
//     }));
//   };

//   const getListOptions = (value) => {
//     return (lessonBookList || []).map((b, index) => ({
//       value: index.toString(),
//       text: b[0].split(";;;")[0],
//     }));
//   };

//   // inserts
//   const onLessonInsertHandler = async (
//     classId: number,
//     name: string
//   ): Promise<boolean> => {
//     dispatch(showLoader());
//     let result = await lessonService.InsertLesson({
//       classId: classId,
//       genericLessonId: Number(name),
//       name: genericLessonList.find((s) => s.id == Number(name)).lessonName,
//     });
//     dispatch(hideLoader());

//     if (result != null) {
//       const tempClassList = [...classList];
//       const targetClass = tempClassList.find((c) => c.id == classId);
//       targetClass.lessons = [
//         ...targetClass.lessons,
//         {
//           id: result.id,
//           name: result.name,
//           units: [],
//           lessonGenericId: result.lessonGenericId,
//         },
//       ];
//       setClassList(tempClassList);
//       return true;
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );

//     return false;
//   };

//   const onUnitInsertHandler = async (
//     lessonId: number,
//     units: Array<UnitOutputModel>,
//     name: string
//   ): Promise<boolean> => {
//     dispatch(showLoader());
//     let result = await lessonService.InsertUnit({
//       lessonId: lessonId,
//       name: name,
//     });
//     dispatch(hideLoader());

//     if (result != null) {
//       units.push({ id: result.id, name: result.name, topics: [] });
//       return true;
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );

//     return false;
//   };

//   const onTopicInsertHandler = async (
//     lessonId: number,
//     unitId: number,
//     topics: Array<TopicOutputModel>,
//     name: string
//   ): Promise<boolean> => {
//     dispatch(showLoader());
//     let result = await lessonService.InsertTopic({
//       unitId: unitId,
//       name: name,
//     });
//     dispatch(hideLoader());

//     if (result != null) {
//       topics.push({ id: result.id, name: result.name, testQuestions: [] });
//       return true;
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );

//     return false;
//   };

//   const onQuestionInsertHandler = (
//     classId: number,
//     lessonId: number,
//     unitId: number,
//     topicId: number
//   ) => {
//     seteditableQuestionIndentityData({ classId, lessonId, unitId, topicId });
//     dispatch(showQuestionCreateOrUpdateModal());
//   };

//   // edit
//   const onQuestionEditHandler = (
//     classId: number,
//     lessonId: number,
//     unitId: number,
//     topicId: number,
//     questionId: number
//   ) => {
//     let selectedClass = classList.find((c) => c.id == classId);
//     let lesson = selectedClass.lessons.find((l) => l.id == lessonId);
//     let unit = lesson.units.find((u) => u.id == unitId);
//     let topic = unit.topics.find((u) => u.id == topicId);
//     let question = topic.testQuestions.find((q) => q.id == questionId);
//     const learningOutCome = topic.testQuestions.find(
//       (q) => q.id == questionId
//     ).learningOutcome;
//     seteditableQuestionIndentityData({
//       classId,
//       lessonId,
//       unitId,
//       topicId,
//       question,
//     });
//     setFormLearningOutcome(learningOutCome);
//     setEditModalVis(true);

//     // dispatch(showQuestionCreateOrUpdateModal());
//   };

//   const onUnitEditHandler = async (unitId: number, name: string) => {
//     dispatch(showLoader());
//     let result = await lessonService.UpdateUnit({
//       id: unitId,
//       name: name,
//     });
//     dispatch(hideLoader());

//     if (result != null) {
//       return true;
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );
//     return false;
//   };

//   const onTopicEditHandler = async (topicId: number, name: string) => {
//     dispatch(showLoader());
//     let result = await lessonService.UpdateTopic({
//       id: topicId,
//       name: name,
//     });
//     dispatch(hideLoader());

//     if (result != null) {
//       return true;
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );
//     return false;
//   };

//   const onSuccessfulQuestionInsertOrUpdateHandler = (
//     question: QuestionOutputModel
//   ) => {
//     getLessonList();
//     // let newClassList = [...classList];
//     // let updatedClass = newClassList.find(c => c.id = editableQuestionIndentityData.classId);
//     // let updatedLesson = updatedClass.lessons.find(l => l.id == editableQuestionIndentityData.lessonId);
//     // let updatedUnit = updatedLesson.units.find(u => u.id == editableQuestionIndentityData.unitId);
//     // let updatedTopic = updatedUnit.topics.find(u => u.id == editableQuestionIndentityData.topicId);

//     // let editedQuestion = updatedTopic.testQuestions.find(q => q.id == question.id);
//     // if (editedQuestion) {
//     //     editedQuestion.question = question.question;
//     //     editedQuestion.difficultyLevel = question.difficultyLevel;
//     //     editedQuestion.testQuestionAnswers = question.testQuestionAnswers;
//     // }
//     // else updatedTopic.testQuestions.push(question);
//     // setClassList(newClassList);
//   };

//   // on delete
//   const onLessonDeleteHandler = async (lessonId: number) => {
//     dispatch(showLoader());
//     let result = await lessonService.DeleteLessonState(lessonId);

//     if (result !== false) {
//       await getLessonList();
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );

//     dispatch(hideLoader());
//   };

//   const onUnitDeleteHandler = async (unitId: number) => {
//     dispatch(showLoader());
//     let result = await lessonService.DeleteUnitState(unitId);

//     if (result !== false) {
//       await getLessonList();
//     } else {
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );
//     }

//     dispatch(hideLoader());
//   };

//   const onTopicDeleteHandler = async (topicId: number) => {
//     dispatch(showLoader());
//     let result = await lessonService.DeleteTopicState(topicId);

//     if (result !== false) {
//       await getLessonList();
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );

//     dispatch(hideLoader());
//   };

//   const onQuestionDeleteHandler = async (questionId: number) => {
//     dispatch(showLoader());
//     let result = await lessonService.DeleteQuestion(questionId);

//     dispatch(hideLoader());

//     if (result !== false) {
//       await getLessonList();
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );
//   };

//   // on update
//   const onLessonUpdateHandler = async (
//     lessonId: number,
//     name: string
//   ): Promise<boolean> => {
//     dispatch(showLoader());
//     let result = await lessonService.UpdateLesson({
//       id: lessonId,
//       genericLessonId: Number(name),
//       name: genericLessonList.find((s) => s.id == Number(name)).lessonName,
//     });
//     dispatch(hideLoader());

//     if (result != null) {
//       return true;
//     } else
//       dispatch(
//         sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
//       );
//     return false;
//   };

//   const [genericLessonList, setGenericLessonList] = useState<
//     Array<GenericLessonModel>
//   >([]);
//   const getGenericLessonList = async () => {
//     const result = await lessonService.GetGenericLessonList();
//     if (result) setGenericLessonList(result);
//   };

//   const submitQuestions = async (
//     selectedQuestionUrl: Array<string>,
//     selectedQuestionAnswers: {
//       id: number;
//       answer: string;
//       learningOutCome: string;
//     }[]
//   ) => {
//     dispatch(showLoader());
//     setPdfListModalStatus(false);

//     const formData = new FormData();
//     for (let index = 0; index < selectedQuestionUrl.length; index++) {
//       const data = await fetch(selectedQuestionUrl[index]);
//       const dataBlob = await data.blob();
//       formData.append(index.toString(), dataBlob);
//       formData.append(
//         `${index};;;correctAnswer`,
//         selectedQuestionAnswers[index].answer
//       );
//       formData.append(
//         `${index};;;learningOutcome`,
//         selectedQuestionAnswers[index].learningOutCome
//       );
//     }

//     formData.append("topicId", selectedTopicIdForQuestionInsert);

//     const result = await lessonService.InsertQuestions(formData);
//     if (result) getLessonList();
//     dispatch(hideLoader());
//   };
//   return (
//     <>
//       <div className="page-content">
//         <section className="row">
//           {classList.map((c) => (
//             <div className="card">
//               <div className="card-content">
//                 <div className="card-header">
//                   <h4>
//                     {c.name} ({c.lessons.length} Ders)
//                   </h4>
//                 </div>
//                 <div className="card-body">
//                   <Accordion
//                     isSelectBox={true}
//                     selectBoxList={genericLessonList.map((s) => ({
//                       text: s.lessonName,
//                       value: s.id.toString(),
//                     }))}
//                     onInsert={(name: string) =>
//                       onLessonInsertHandler(c.id, name)
//                     }
//                     id="Lessons"
//                     insertable={true}
//                     insertTitle="Yeni Ders Ekle"
//                     insertInputLabel="Ders Adı"
//                     populate={() => populateLessons(c)}
//                   ></Accordion>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </section>
//       </div>
//       <QuestionCreateOrUpdateModal
//         questionIdentityInfo={editableQuestionIndentityData}
//         callback={onSuccessfulQuestionInsertOrUpdateHandler}
//       />
//       <PdfViewer
//         viewerType={ViewerType.AddQuestion}
//         selectedPdfPageList={lessonBookList[selectedBookIndex]}
//         visible={pdfListModalStatus}
//         onCloseClick={() => setPdfListModalStatus(false)}
//         onSubmit={submitQuestions}
//       />
//       <Modal
//         showButtonRight={false}
//         title="Soru"
//         modalVisibility={modalVisibility}
//         onclose={() => setModalVisibility(false)}
//       >
//         {viewableQuestion ? (
//           viewableQuestion.questionUrl ? (
//             <img
//               src={
//                 baseService.endPoints().pdfUrl +
//                 "/testquestionbank/" +
//                 viewableQuestion.questionUrl
//               }
//             />
//           ) : (
//             <div
//               dangerouslySetInnerHTML={{ __html: viewableQuestion.question }}
//             />
//           )
//         ) : null}
//       </Modal>
//       {editModalVis && (
//         <div
//           className="modal fade text-left show"
//           tabIndex={-1}
//           aria-labelledby="myModalLabel33"
//           style={{
//             overflow: "auto",
//             display: editModalVis ? "block" : "none",
//             backgroundColor: "rgba(0,0,0,0.4)",
//           }}
//           aria-modal="true"
//           role="dialog"
//         >
//           <div
//             className="modal-dialog modal-dialog-centered modal-dialog"
//             role="document"
//           >
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h4 className="modal-title" id="myModalLabel33">
//                   Kazanım Güncelle/Ekle
//                 </h4>
//                 <button
//                   type="button"
//                   className="close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 >
//                   <i data-feather="x"></i>
//                 </button>
//               </div>
//               <div className="modal-body">
//                 <form action="#">
//                   <div className="row">
//                     <div className="form-group col-12">
//                       <label>Kazanım: </label>
//                       <input
//                         type="text"
//                         placeholder="Kazanım"
//                         value={formLearningOutcome}
//                         onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
//                           setFormLearningOutcome(e.target.value)
//                         }
//                         className={
//                           "form-control" +
//                           (formLearningOutcome ? " is-valid" : " is-invalid")
//                         }
//                         required
//                       />
//                     </div>
//                   </div>
//                 </form>
//               </div>

//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   onClick={() => setEditModalVis(false)}
//                   className="btn btn-default rounded pill"
//                   data-bs-dismiss="modal"
//                 >
//                   <i className="fas fa-times-circle"></i>
//                   <span className="d-inline p-2">Kapat</span>
//                 </button>
//                 <button
//                   disabled={!formLearningOutcome}
//                   type="button"
//                   onClick={async () => {
//                     let result = await lessonService.InsertOrUpdateQuestion({
//                       ...editableQuestionIndentityData.question,
//                       ...{ learningOutcome: formLearningOutcome, answers: [] },
//                     });
//                     console.warn(result);
//                   }}
//                   className="btn btn-primary ml-1"
//                   data-bs-dismiss="modal"
//                 >
//                   <i className="fas fa-check-circle"></i>
//                   <span className="d-inline p-2">Kaydet</span>
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// @ts-nocheck
import "../style/lessons.css";

import React, { useCallback, useEffect, useState } from "react";
import Accordion from "../sharedComponents/accordion";
import EditableAccordionItem, {
  AccordionItemType,
} from "../sharedComponents/editableAccordionItem";
import LessonService from "../../services/lessonService";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  showQuestionCreateOrUpdateModal,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import QuestionCreateOrUpdateModal from "./questionCreateOrUpdateModal";
import Datatable from "../sharedComponents/datatable";
import Draggable, { DraggableCore } from "react-draggable";
import TestService from "../../services/testService";
import SelectFromList from "./selectFormList";
import PdfViewer, { ViewerType } from "./pdfViewer";
import Modal from "../sharedComponents/modal";
import ConsentModal from "../consentModal";
import BaseService from "../../services/baseService";
import { useParams, useRouteMatch } from "react-router-dom";

declare global {
  interface QuestionIdentityInfo {
    classId?: number;
    lessonId?: number;
    unitId?: number;
    topicId?: number;
    question?: QuestionOutputModel;
  }
}

export default function SubLessonsPage() {
  const baseService = new BaseService();
  const { lessonId } = useParams();
  const [deleteModalState, setdeleteModalState] = useState({
    id: null,
    visibility: false,
  });
  const [formLearningOutcome, setFormLearningOutcome] = useState<string>("");
  const [classList, setClassList] = useState<Array<ClassroomOutputModel>>([]);
  const [editableQuestionIndentityData, seteditableQuestionIndentityData] =
    useState<QuestionIdentityInfo>({});
  const [lessonBookList, setLessonBookList] = useState<Array<Array<string>>>(
    []
  );
  const [pdfListModalStatus, setPdfListModalStatus] = useState(false);
  const [selectedBookIndex, setSelectedBookIndex] = useState<number>(null);
  const [
    selectedTopicIdForQuestionInsert,
    setSelectedTopicIdForQuestionInsert,
  ] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [viewableQuestion, setViewableQuestion] =
    useState<QuestionOutputModel>(null);
  const lessonService = new LessonService();
  const dispatch = useDispatch();
  useEffect(() => {
    getAllBooks();
    getGenericLessonList();
    getLessonList();
  }, []);
  const [editModalVis, setEditModalVis] = useState<boolean>(false);

  const testService = new TestService();
  const getAllBooks = async () => {
    const result = await testService.GetAllPdfList();
    if (result) {
      const data = result.reduce((acc, curr) => {
        acc.push(...curr.bookList);
        return acc;
      }, [] as Array<Array<string>>);
      setLessonBookList(data);
    }
  };

  const getBooksById = async (lessonId: number) => {
    const result = await testService.GetPdfList(lessonId);
    let data = [];
    if (result) {
      data = result.reduce((acc, curr) => {
        acc.push(...curr);
        return acc;
      }, [] as Array<Array<string>>);
    }
    return data;
  };

  // lessonId

  const getLessonList = async () => {
    dispatch(showLoader());
    var classList = await lessonService.GetClassroomWithLessons(lessonId);

    setClassList(classList);
    dispatch(hideLoader());
  };

  // populate accordions
  const populateLessons = (classroom: ClassroomOutputModel) =>
    classroom.lessons.map((l) => (
      <EditableAccordionItem
        isSelectBox={true}
        selectBoxList={genericLessonList.map((s) => ({
          text: s.lessonName,
          value: s.id.toString(),
        }))}
        onDelete={() => onLessonDeleteHandler(l.id)}
        editableInputLabel="Ders Adı"
        onUpdate={(name: string) => onLessonUpdateHandler(l.id, name)}
        id={"lesson" + l.id}
        parentId="Lessons"
        title={l.lessonGenericId.toString()}
      >
        <Accordion
          onInsert={(name: string) => onUnitInsertHandler(l.id, l.units, name)}
          id="Units"
          insertable={true}
          insertTitle="Yeni Ünite Ekle"
          insertInputLabel="Ünite Adı"
          populate={() => populateUnits(classroom.id, l.id, l.units)}
        ></Accordion>
      </EditableAccordionItem>
    ));

  const populateUnits = (
    classId: number,
    lessonId: number,
    units: Array<UnitOutputModel>
  ) =>
    (units || []).map((u) => (
      <EditableAccordionItem
        id={"unit" + u.id}
        parentId="Units"
        title={u.name}
        onUpdate={(name: string) => onUnitEditHandler(u.id, name)}
        onDelete={() => onUnitDeleteHandler(u.id)}
        editableInputLabel="Ünite Adı"
      >
        <Accordion
          onInsert={(name: string) =>
            onTopicInsertHandler(lessonId, u.id, u.topics, name)
          }
          id="Topics"
          insertable={true}
          insertTitle="Yeni Konu Ekle"
          insertInputLabel="Konu Adı"
          populate={() => populateTopics(classId, lessonId, u.id, u.topics)}
        ></Accordion>
      </EditableAccordionItem>
    ));

  const populateTopics = (
    classId: number,
    lessonId: number,
    unitId: number,
    topics: Array<TopicOutputModel>
  ) =>
    topics.map((t) => {
      let data: DatatableDataModel = {
        header: ["Soru No", "Kazanım"],
        list: t.testQuestions.map((q) => ({
          id: q.id,
          row: [q.id.toString(), q.learningOutcome],
        })),
      };
      return (
        <EditableAccordionItem
          id={"topic" + t.id}
          onUpdate={(name: string) => onTopicEditHandler(t.id, name)}
          onDelete={() => onTopicDeleteHandler(t.id)}
          parentId="Topics"
          title={t.name}
          editableInputLabel="Konu Adı"
        >
          <Datatable
            viewable={true}
            onViewClickCallback={(id: number) =>
              showQuestionModal(classId, lessonId, unitId, t.id, id)
            }
            deletable={true}
            insertTitle="Yeni Soru Ekle"
            actionElements={[
              <SelectFromList
                onSelectClick={(value) => selectBook(t.id, value)}
                options={classList}
                buttonTag="Kitaptan Soru Ekle"
                lessonId={lessonId}
                lessonBookList={lessonBookList}
              />,
            ]}
            onDeleteClickCustomHandler={(itemId: number) =>
              onQuestionDeleteHandler(itemId)
            }
            onEditClickCustomHandler={(id) =>
              onQuestionEditHandler(classId, lessonId, unitId, t.id, id)
            }
            data={data}
            onInsertClickCustomHandler={() =>
              onQuestionInsertHandler(classId, lessonId, unitId, t.id)
            }
          />
        </EditableAccordionItem>
      );
    });

  const showQuestionModal = (
    classId: number,
    lessonId: number,
    unitId: number,
    topicId: number,
    id: number
  ) => {
    const targetClass = classList.find((c) => c.id == classId);
    if (!targetClass) return;
    const targetLesson = targetClass.lessons.find((l) => l.id == lessonId);
    if (!targetLesson) return;
    const targetUnit = targetLesson.units.find((u) => u.id == unitId);
    if (!targetUnit) return;
    const targetTopic = targetUnit.topics.find((t) => t.id == topicId);
    if (!targetTopic) return;
    const selectedQuestion = targetTopic.testQuestions.find((q) => q.id == id);
    setViewableQuestion(selectedQuestion);
    setModalVisibility(true);
  };

  const selectBook = (topicId: number, value: string) => {
    setSelectedTopicIdForQuestionInsert(topicId);
    setSelectedBookIndex(Number(value));
    setPdfListModalStatus(true);
  };
  // 1640
  const getSelectedLessonBookList = (lessonId: number) => {
    return (lessonBookList || []).map((b) => ({
      value: b[0].split(";;;")[0],
      text: b[0].split(";;;")[0],
    }));
  };

  const getListOptions = (value) => {
    return (lessonBookList || []).map((b, index) => ({
      value: index.toString(),
      text: b[0].split(";;;")[0],
    }));
  };

  // inserts
  const onLessonInsertHandler = async (
    classId: number,
    name: string
  ): Promise<boolean> => {
    dispatch(showLoader());
    let result = await lessonService.InsertLesson({
      classId: classId,
      genericLessonId: Number(name),
      name: genericLessonList.find((s) => s.id == Number(name)).lessonName,
    });
    dispatch(hideLoader());

    if (result != null) {
      const tempClassList = [...classList];
      const targetClass = tempClassList.find((c) => c.id == classId);
      targetClass.lessons = [
        ...targetClass.lessons,
        {
          id: result.id,
          name: result.name,
          units: [],
          lessonGenericId: result.lessonGenericId,
        },
      ];
      setClassList(tempClassList);
      return true;
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );

    return false;
  };

  const onUnitInsertHandler = async (
    lessonId: number,
    units: Array<UnitOutputModel>,
    name: string
  ): Promise<boolean> => {
    dispatch(showLoader());
    let result = await lessonService.InsertUnit({
      lessonId: lessonId,
      name: name,
    });
    dispatch(hideLoader());

    if (result != null) {
      units.push({ id: result.id, name: result.name, topics: [] });
      return true;
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );

    return false;
  };

  const onTopicInsertHandler = async (
    lessonId: number,
    unitId: number,
    topics: Array<TopicOutputModel>,
    name: string
  ): Promise<boolean> => {
    dispatch(showLoader());
    let result = await lessonService.InsertTopic({
      unitId: unitId,
      name: name,
    });
    dispatch(hideLoader());

    if (result != null) {
      topics.push({ id: result.id, name: result.name, testQuestions: [] });
      return true;
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );

    return false;
  };

  const onQuestionInsertHandler = (
    classId: number,
    lessonId: number,
    unitId: number,
    topicId: number
  ) => {
    seteditableQuestionIndentityData({ classId, lessonId, unitId, topicId });
    dispatch(showQuestionCreateOrUpdateModal());
  };

  // edit
  const onQuestionEditHandler = (
    classId: number,
    lessonId: number,
    unitId: number,
    topicId: number,
    questionId: number
  ) => {
    let selectedClass = classList.find((c) => c.id == classId);
    let lesson = selectedClass.lessons.find((l) => l.id == lessonId);
    let unit = lesson.units.find((u) => u.id == unitId);
    let topic = unit.topics.find((u) => u.id == topicId);
    let question = topic.testQuestions.find((q) => q.id == questionId);
    const learningOutCome = topic.testQuestions.find(
      (q) => q.id == questionId
    ).learningOutcome;
    seteditableQuestionIndentityData({
      classId,
      lessonId,
      unitId,
      topicId,
      question,
    });
    setFormLearningOutcome(learningOutCome);
    setEditModalVis(true);

    // dispatch(showQuestionCreateOrUpdateModal());
  };

  const onUnitEditHandler = async (unitId: number, name: string) => {
    dispatch(showLoader());
    let result = await lessonService.UpdateUnit({
      id: unitId,
      name: name,
    });
    dispatch(hideLoader());

    if (result != null) {
      return true;
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    return false;
  };

  const onTopicEditHandler = async (topicId: number, name: string) => {
    dispatch(showLoader());
    let result = await lessonService.UpdateTopic({
      id: topicId,
      name: name,
    });
    dispatch(hideLoader());

    if (result != null) {
      return true;
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    return false;
  };

  const onSuccessfulQuestionInsertOrUpdateHandler = (
    question: QuestionOutputModel
  ) => {
    getLessonList();
    // let newClassList = [...classList];
    // let updatedClass = newClassList.find(c => c.id = editableQuestionIndentityData.classId);
    // let updatedLesson = updatedClass.lessons.find(l => l.id == editableQuestionIndentityData.lessonId);
    // let updatedUnit = updatedLesson.units.find(u => u.id == editableQuestionIndentityData.unitId);
    // let updatedTopic = updatedUnit.topics.find(u => u.id == editableQuestionIndentityData.topicId);

    // let editedQuestion = updatedTopic.testQuestions.find(q => q.id == question.id);
    // if (editedQuestion) {
    //     editedQuestion.question = question.question;
    //     editedQuestion.difficultyLevel = question.difficultyLevel;
    //     editedQuestion.testQuestionAnswers = question.testQuestionAnswers;
    // }
    // else updatedTopic.testQuestions.push(question);
    // setClassList(newClassList);
  };

  // on delete
  const onLessonDeleteHandler = async (lessonId: number) => {
    dispatch(showLoader());
    let result = await lessonService.DeleteLessonState(lessonId);

    if (result !== false) {
      await getLessonList();
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );

    dispatch(hideLoader());
  };

  const onUnitDeleteHandler = async (unitId: number) => {
    dispatch(showLoader());
    let result = await lessonService.DeleteUnitState(unitId);

    if (result !== false) {
      await getLessonList();
    } else {
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    }

    dispatch(hideLoader());
  };

  const onTopicDeleteHandler = async (topicId: number) => {
    dispatch(showLoader());
    let result = await lessonService.DeleteTopicState(topicId);

    if (result !== false) {
      await getLessonList();
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );

    dispatch(hideLoader());
  };

  const onQuestionDeleteHandler = async (questionId: number) => {
    dispatch(showLoader());
    let result = await lessonService.DeleteQuestion(questionId);

    dispatch(hideLoader());

    if (result !== false) {
      await getLessonList();
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
  };

  // on update
  const onLessonUpdateHandler = async (
    lessonId: number,
    name: string
  ): Promise<boolean> => {
    dispatch(showLoader());
    let result = await lessonService.UpdateLesson({
      id: lessonId,
      genericLessonId: Number(name),
      name: genericLessonList.find((s) => s.id == Number(name)).lessonName,
    });
    dispatch(hideLoader());

    if (result != null) {
      return true;
    } else
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    return false;
  };

  const [genericLessonList, setGenericLessonList] = useState<
    Array<GenericLessonModel>
  >([]);
  const getGenericLessonList = async () => {
    const result = await lessonService.GetGenericLessonList();
    if (result) setGenericLessonList(result);
  };

  const submitQuestions = async (
    selectedQuestionUrl: Array<string>,
    selectedQuestionAnswers: {
      id: number;
      answer: string;
      learningOutCome: string;
    }[]
  ) => {
    dispatch(showLoader());
    setPdfListModalStatus(false);

    const formData = new FormData();
    for (let index = 0; index < selectedQuestionUrl.length; index++) {
      const data = await fetch(selectedQuestionUrl[index]);
      const dataBlob = await data.blob();
      formData.append(index.toString(), dataBlob);
      formData.append(
        `${index};;;correctAnswer`,
        selectedQuestionAnswers[index].answer
      );
      formData.append(
        `${index};;;learningOutcome`,
        selectedQuestionAnswers[index].learningOutCome
      );
    }

    formData.append("topicId", selectedTopicIdForQuestionInsert);

    const result = await lessonService.InsertQuestions(formData);
    if (result) getLessonList();
    dispatch(hideLoader());
  };
  return (
    <>
      <div className="page-content">
        <section className="row">
          {classList.map((c) => (
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <h4>
                    {c.name} ({c.lessons.length} Ders)
                  </h4>
                </div>
                <div className="card-body">
                  <Accordion
                    isSelectBox={true}
                    selectBoxList={genericLessonList.map((s) => ({
                      text: s.lessonName,
                      value: s.id.toString(),
                    }))}
                    onInsert={(name: string) =>
                      onLessonInsertHandler(c.id, name)
                    }
                    id="Lessons"
                    insertable={true}
                    insertTitle="Yeni Ders Ekle"
                    insertInputLabel="Ders Adı"
                    populate={() => populateLessons(c)}
                  ></Accordion>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
      <QuestionCreateOrUpdateModal
        questionIdentityInfo={editableQuestionIndentityData}
        callback={onSuccessfulQuestionInsertOrUpdateHandler}
      />
      <PdfViewer
        viewerType={ViewerType.AddQuestion}
        selectedPdfPageList={lessonBookList[selectedBookIndex]}
        visible={pdfListModalStatus}
        onCloseClick={() => setPdfListModalStatus(false)}
        onSubmit={submitQuestions}
      />
      <Modal
        showButtonRight={false}
        title="Soru"
        modalVisibility={modalVisibility}
        onclose={() => setModalVisibility(false)}
      >
        {viewableQuestion ? (
          viewableQuestion.questionUrl ? (
            <img
              src={
                baseService.endPoints().pdfUrl +
                "/testquestionbank/" +
                viewableQuestion.questionUrl
              }
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: viewableQuestion.question }}
            />
          )
        ) : null}
      </Modal>
      {editModalVis && (
        <div
          className="modal fade text-left show"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            overflow: "auto",
            display: editModalVis ? "block" : "none",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Kazanım Güncelle/Ekle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x"></i>
                </button>
              </div>
              <div className="modal-body">
                <form action="#">
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Kazanım: </label>
                      <input
                        type="text"
                        placeholder="Kazanım"
                        value={formLearningOutcome}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFormLearningOutcome(e.target.value)
                        }
                        className={
                          "form-control" +
                          (formLearningOutcome ? " is-valid" : " is-invalid")
                        }
                        required
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setEditModalVis(false)}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  disabled={!formLearningOutcome}
                  type="button"
                  onClick={async () => {
                    let result = await lessonService.InsertOrUpdateQuestion({
                      ...editableQuestionIndentityData.question,
                      ...{ learningOutcome: formLearningOutcome, answers: [] },
                    });
                    setEditModalVis(false);
                    getLessonList();
                  }}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
