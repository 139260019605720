import BaseService from "./baseService";

declare global {
  interface MeetInputModel {
    studentId: string;
    note: string;
    interviewer: string;
  }

  interface MeetCreateResponseModel {
    id: number;
    student: StudentOutputModel;
    user: {
      id: string;
      name: string;
      surname: string;
    };
    interviewer: string;
    note: string;
    issueDate: string;
    isDeleted: boolean;
  }

  interface MeetOutputModel {
    student: StudentOutputModel;
    notes: {
      id: number;
      student: StudentOutputModel;
      user: {
        id: string;
        name: string;
        surname: string;
      };
      interviewer: string;
      note: string;
      issueDate: string;
      isDeleted: boolean;
    }[];
  }
}

export default class MeetService extends BaseService {
  constructor() {
    super("/StudentNotes");
  }

  CreateMeet(meet: MeetInputModel) {
    return this.Post<MeetInputModel, MeetCreateResponseModel>(meet);
  }

  async GetAllMeets(): Promise<Array<MeetOutputModel>> {
    return await this.Get<Array<MeetOutputModel>>();
  }

  GetStudentMeets(id: string) {
    return this.GetById<any, MeetOutputModel>(id, "/StudentNotes");
  }

  DeleteMeet(id: number) {
    return this.Delete<number>(id, "/StudentNotes");
  }
}
