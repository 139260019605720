import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  hideLoader,
  showLessonCreateOrUpdateModal,
  showLoader,
} from "../../reducers/commonSlice";
import LessonService from "../../services/lessonService";
import CreateTestPage from "./createTestPage";

declare global {
  interface UrlParam {
    lessonId: string;
  }
}

export default function LessonsPage() {
  let { path, url } = useRouteMatch();
  const lessonService = new LessonService();
  const dispatch = useDispatch();
  const [classLessonList, setclassLessonList] = useState<
    Array<ClassroomOutputModel>
  >([]);
  useEffect(() => {
    getClassLessonList();
  }, []);

  const getClassLessonList = async () => {
    dispatch(showLoader());
    let classLessonList = await lessonService.GetClassLessons();
    setclassLessonList(classLessonList);
    dispatch(hideLoader());
  };

  return (
    <>
      <div className="page-content">
        <section className="row">
          {classLessonList.map((cl) => (
            <div className="card-content">
              <div
                className="card-header"
                style={{ backgroundColor: "transparent" }}
              >
                <h4>{cl.name}</h4>
              </div>
              <div className="card-body">
                <div className="content-page">
                  <div className="row staff-grid-row">
                    {cl.lessons.map((l) => (
                      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                        <Link to={`${path}/lesson/${l.id}`}>
                          <div
                            className="profile-widget"
                            style={{ borderRadius: "100px" }}
                          >
                            <div className="profile-img">
                              <img
                                className="avatar"
                                src="/assets/img/test.png"
                                alt=""
                              />
                            </div>
                            <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                              <a>{l.name || "Default"}</a>
                            </h4>
                            <div className="small text-muted">Soru Bankası</div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </>
  );
}
