import BaseService from "./baseService";

declare global {
  interface LessonInputModel {
    id?: number;
    name: string;
    units?: Array<UnitInputModel>;
    classId?: number;
    genericLessonId?: number;
  }

  interface UnitInputModel {
    lessonId?: number;
    id?: number;
    name: string;
    topics?: Array<TopicIniputModel>;
  }

  interface TopicIniputModel {
    unitId?: number;
    id?: number;
    name: string;
    questions?: Array<any>;
  }

  interface QuestionInputModel {
    topicId: number;
    id?: number;
    questionText: string;
    learningOutcome?: string;
    difficultyLevel: number;
    answers?: Array<QuestionAnswerInputModel>;
  }

  interface QuestionAnswerInputModel {
    id?: number;
    key: string;
    value: string;
  }

  interface LessonOutputBaseModel {
    id: number;
    name: string;
    lessonGenericId: number;
    units: Array<UnitOutputModel>;
  }

  interface StudentLessonOutputModel extends LessonOutputBaseModel {
    teacher?: TeacherOutputModel;
  }

  // output
  interface LessonOutputModel extends LessonOutputBaseModel {
    teachers?: Array<TeacherOutputModel>;
    checked?: boolean;
    progressId?: number;
  }

  interface UnitOutputModel {
    id: number;
    name: string;
    topics: Array<TopicOutputModel>;
    checked?: boolean;
    progressId?: number;
  }

  interface TopicOutputModel {
    id: number;
    name: string;
    testQuestions: Array<QuestionOutputModel>;
    checked?: boolean;
    progressId?: number;
  }

  interface QuestionOutputModel {
    id: number;
    question: string;
    difficultyLevel: number;
    testQuestionAnswers: Array<QuestionAnswerOutputModel>;
    checked: boolean;
    correctAnswer?: string;
    questionUrl?: string;
    learningOutcome: string;
    unitId?: number;
    unitName?: string;
    order?: number;
  }

  interface QuestionAnswerOutputModel {
    id?: number;
    answerKey: string;
    answerValue: string;
  }

  // student progress models
  interface StudentSuccessModel {
    correctAnswerCount: number;
    questionCount: number;
  }

  interface LessonSuccessModel {
    lessonId: number;
    lessonName: string;
    successRate: StudentSuccessModel;
    lessonUnits: Array<LessonUnitSuccessModel>;
    studentName: string;
    studentClass: string;
  }

  interface LessonUnitSuccessModel {
    lessonUnitId: number;
    lessonUnitName: string;
    successRate: StudentSuccessModel;
  }

  interface GenericLessonModel {
    id?: number;
    lessonName: string;
    lessons?: {
      id: number;
      name: string;
      imageUrl: string;
      lessonGenericId: number;
      classroomId: number;
    }[];
  }

  interface TestAssigmentOutputModel {
    id: number;
    studentId: string;
    testId: number;
    startDate: string;
    endDate: string;
    isSolved: boolean;
    isDeleted: boolean;
    duration: number;
    test: {
      id: number;
      name: string;
      issueDate: string;
      lessonName: string;
      className: string;
      questionCount: number;
    };
  }

  interface TestStartTimeOutputModel {
    testStartTime: string;
    testEndDate: string;
    duration: number;
    testResult: any;
  }
}

export default class LessonService extends BaseService {
  constructor() {
    super("");
  }

  async GetLessonById(id: number) {
    return this.GetById<number, LessonOutputModel>(id, "/lesson");
  }

  async GetLessonList(): Promise<Array<LessonOutputModel>> {
    return await this.Get<Array<LessonOutputModel>>(null, "/lesson", false);
  }

  async GetLessonListWithDetails() {
    return await this.Get<Array<LessonOutputModel>>(null, "/lessondetails");
  }

  async InsertLesson(lesson: LessonInputModel): Promise<LessonOutputModel> {
    this.endPoint = "/lesson";
    return await this.Post(lesson);
  }

  async UpdateLesson(lesson: LessonInputModel): Promise<LessonOutputModel> {
    this.endPoint = "/lesson";
    return await this.Put(lesson.id, lesson);
  }

  async DeleteLesson(lessonId: number): Promise<boolean> {
    this.endPoint = "/lesson";
    return await this.Delete(lessonId);
  }

  async DeleteLessonState(lessonId: number): Promise<boolean> {
    this.endPoint = "/lesson/deleteState";
    return await this.Delete(lessonId);
  }

  // unit
  async InsertUnit(unit: UnitInputModel): Promise<UnitOutputModel> {
    this.endPoint = "/unit";
    return await this.Post(unit);
  }

  async UpdateUnit(unit: UnitInputModel): Promise<UnitOutputModel> {
    this.endPoint = "/unit";
    return await this.Put(unit.id, unit);
  }

  async DeleteUnit(unitId: number): Promise<boolean> {
    this.endPoint = "/unit";
    return await this.Delete(unitId);
  }

  async DeleteUnitState(unitId: number): Promise<boolean> {
    this.endPoint = "/unit/deleteState";
    return await this.Delete(unitId);
  }

  // topic

  async InsertTopic(topic: TopicIniputModel): Promise<TopicOutputModel> {
    this.endPoint = "/topic";
    return await this.Post(topic);
  }

  async UpdateTopic(topic: TopicIniputModel): Promise<TopicOutputModel> {
    this.endPoint = "/topic";
    return await this.Put(topic.id, topic);
  }

  async DeleteTopic(topicId: number): Promise<boolean> {
    this.endPoint = "/topic";
    return await this.Delete(topicId);
  }

  async DeleteTopicState(topicId: number): Promise<boolean> {
    this.endPoint = "/topic/deleteState";
    return await this.Delete(topicId);
  }

  // question

  async InsertOrUpdateQuestion(
    question: QuestionInputModel
  ): Promise<QuestionOutputModel> {
    this.endPoint = "/question";
    if (question.id) return await this.Put(question.id, question);
    return await this.Post(question);
  }

  async UpdateQuestion(
    question: QuestionInputModel
  ): Promise<QuestionOutputModel> {
    this.endPoint = "/question";
    return await this.Put(question.id, question);
  }

  async DeleteQuestion(questionId: number): Promise<boolean> {
    this.endPoint = "/question";
    return await this.Delete(questionId);
  }

  GetStudentProgress(studentId: string, lessonId: number) {
    return this.Get<LessonSuccessModel>(
      { studentId, lessonId: lessonId.toString() },
      "/studentprogress"
    );
  }

  GetStudentLessonProgress(lessonId: number) {
    return this.GetById<number, LessonSuccessModel>(
      lessonId,
      "/studentprogress"
    );
  }

  //
  GetClassroomWithLessons(id: number) {
    return this.Get<Array<ClassroomOutputModel>>(
      null,
      `/lessondetails/GetClassroomWithLessons/${id}`
    );
  }

  GetClassRoomWithLessonsByTeacherId() {
    return this.Get<Array<ClassroomOutputModel>>(
      null,
      "/lessondetails/GetClassroomWithLessonsByTeacherId"
    );
  }

  // generic lessons
  GetGenericLessonList() {
    return this.Get<Array<GenericLessonModel>>(null, "/lessongeneric", false);
  }

  CreateGenericLesson(model: GenericLessonModel) {
    return this.Post<GenericLessonModel, GenericLessonModel>(
      model,
      "/lessongeneric"
    );
  }

  UpdateGenericLesson(model: GenericLessonModel) {
    return this.Put<number, GenericLessonModel, GenericLessonModel>(
      model.id,
      model,
      "/lessongeneric"
    );
  }

  DeleteGenericLesson(id: number) {
    return this.Delete<number>(id, "/lessongeneric");
  }

  //
  GetClassLessons() {
    return this.Get<Array<ClassroomOutputModel>>(null, "/lesson/classlessons");
  }

  InsertQuestions(data: FormData) {
    return this.Post<FormData, boolean>(
      data,
      "/question/insertQuestions",
      true,
      true,
      {},
      false
    );
  }

  TestAssigment(data: TestAssignmentModel) {
    return this.Post<TestAssignmentModel, boolean>(data, "/TestAssignment");
  }

  GetTestAssigment() {
    return this.Get<Array<TestAssigmentOutputModel>>(
      null,
      "/TestAssignment",
      false
    );
  }

  DeleteTestAssigment(data: TestAssignmentModel) {
    return this.Post<TestAssignmentModel, boolean>(
      data,
      "/TestAssignment/delete"
    );
  }

  UpdateTestAssigment(data: TestAssignmentModel) {
    return this.Post<TestAssignmentModel, boolean>(
      data,
      "/TestAssignment/update"
    );
  }

  GetTestAssigmentById(id: number) {
    return this.GetById<number, any>(id, "/TestAssignment");
  }

  TestStartTime(id: number) {
    return this.Post<any, TestStartTimeOutputModel>(
      "",
      `/TestAssignment/TestStartTime/${id}`
    );
  }
}
