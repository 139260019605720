import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  showTeacherCreateOrUpdateModal,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import AccountService from "../../services/accountService";
import LessonService from "../../services/lessonService";
import TeacherService from "../../services/teacherService";
import Loader from "../loader";
import ProfileWidget from "../profileWidget";
import ComponentLoader from "../sharedComponents/componentLoader";
import Modal from "../sharedComponents/modal";
import { OgretmenRaporuOlustur } from "../sharedComponents/testpdf";
import { TeacherCreateOrEditModal } from "./teacherCreateOrEditModal";
import RoleService, { Roles } from "../../services/roleService";
import BranchesService from "../../services/branchesService";

declare global {
  interface Course {}

  interface TeacherPageModel {
    teachers: Array<TeacherOutputModel>;
    courses: Array<Course>;
  }
}

export default function TeachersPage() {
  //services
  var teacherService = new TeacherService();
  const lessonService = new LessonService();
  const accountService = new AccountService();
  const roleService = new RoleService();
  const branchesService = new BranchesService();
  const dispatch = useDispatch();
  //states
  let [teacherList, setTeacherList] = useState<Array<TeacherOutputModel>>([]);
  const [lessonList, setLessonList] = useState<Array<GenericLessonModel>>([]);
  const [filterBranche, setFilterBranche] = useState<number>(0);
  const [filterMajor, setFilterMajor] = useState<number>(NaN);
  const [filterName, setFilterName] = useState<string>("");

  let [editData, setEditData] = useState<TeacherOutputModel>({
    email: "",
    id: "",
    name: "",
    surname: "",
    telephone: "",
    majors: [],
  });
  let [deleteModelState, setdeleteModelState] = useState({
    id: null,
    visibility: false,
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchBarTeacherName, setSearchBarTeacherName] = useState("");
  const [searchBarTeacherMajorId, setSearchBarTeacherMajorId] =
    useState<number>(null);
  const [srcDoc, setSrcDoc] = useState(null);
  const [roles, setRoles] = useState<Array<string>>(null);
  const [brancheList, setBranchesList] = useState<Array<BranchesOutputModel>>();
  //definitions
  var teacherWidgetActions: (id: string) => Array<ProfileWidgetAction> = (
    id: string
  ) => [
    { tag: "Düzenle", callback: () => editCallback(id) },
    { tag: "Sil", callback: () => deleteCallback(id) },
    { tag: "Hesaba Geçiş Yap", callback: () => changeAccountCallback(id) },
    { tag: "Rapor Al", callback: () => OgretmenRaporuAl(id) },
  ];

  const getUserRoles = async () => {
    const result = await roleService.GetUserRoles();
    setRoles(result);
  };

  const getBranchesList = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranches();
    setBranchesList(response);
    dispatch(hideLoader());
  };

  const OgretmenRaporuAl = async (teacherId: string) => {
    dispatch(showLoader());
    const html = await OgretmenRaporuOlustur(teacherId);
    dispatch(hideLoader());
    setSrcDoc(html);
  };

  const changeAccountCallback = async (id: string) => {
    dispatch(showLoader());
    const result = await accountService.changeAccount(id);
    if (result === false) {
      dispatch(hideLoader());
      dispatch(
        sendMessage({
          title: "İşelm Başarısız",
          message: "Beklenmeyen Bir Hat Oluştu",
        })
      );
    }
  };

  const deleteCallback = (id: string) =>
    setdeleteModelState({ id: id, visibility: true });

  const editCallback = (id: string) => {
    var teacher = teacherList.find((t) => t.id == id);
    setEditData({
      email: teacher.email,
      id: teacher.id,
      name: teacher.name,
      surname: teacher.surname,
      telephone: teacher.telephone,
      majors: teacher.majors,
      profilePhoto: teacher.profilePhoto,
    });
    dispatch(showTeacherCreateOrUpdateModal());
  };
  const createHandler = () => {
    setEditData({
      email: "",
      id: "",
      name: "",
      surname: "",
      telephone: "",
      majors: [],
    });
    dispatch(showTeacherCreateOrUpdateModal());
  };

  const getTeacherList = async () => {
    dispatch(showLoader());
    var teacherList = await teacherService.GetTeacherList();
    setDataLoaded(true);
    // console.log(teacherList);
    setTeacherList(teacherList);
    dispatch(hideLoader());
  };

  const getLessonList = async () => {
    dispatch(showLoader());
    var lessonList = await lessonService.GetGenericLessonList();
    // console.log(lessonList);
    setLessonList(lessonList);
    dispatch(hideLoader());
  };

  // useEffect(() => console.log("rendering teacher page"));

  useEffect(() => {
    getLessonList();
    getTeacherList();
    getUserRoles();
  }, []);

  useEffect(() => {
    if ((roles || []).includes(Roles.Admin)) {
      getBranchesList();
    }
  }, [roles]);

  const deleteHandler = async () => {
    setdeleteModelState({ id: null, visibility: false });
    dispatch(showLoader());
    var result = await teacherService.DeleteTeacherById(deleteModelState.id);

    dispatch(hideLoader());
    if (result === true) {
      getTeacherList();
    } else {
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "Silme işlemi başarısız",
        })
      );
    }
  };

  const filterTeacherList = () => {
    setSearchBarTeacherName(
      (document.querySelector("#teacher-name-search") as HTMLInputElement).value
    );
    setSearchBarTeacherMajorId(
      Number(
        (document.querySelector("#teacher-major-search") as HTMLSelectElement)
          .value
      )
    );
  };

  const filteredTeacherList = (teacherList || []).filter((t) => {
    const teacherFullName = (t.name + " " + t.surname).toLocaleLowerCase();
    const teacherMajorIds = t.majors.map((m) => m.id);

    const mainFilterCondition =
      !searchBarTeacherName ||
      (teacherFullName.includes(searchBarTeacherName.toLocaleLowerCase()) &&
        (!searchBarTeacherMajorId ||
          teacherMajorIds.includes(searchBarTeacherMajorId)));

    const branchFilter =
      (roles || []).includes(Roles.Admin) && isNaN(filterBranche)
        ? t.branch === null
        : !filterBranche ||
          !Number.isInteger(filterBranche) ||
          t.branch?.id === filterBranche;

    const majorFilter = isNaN(filterMajor)
      ? true
      : !Number.isInteger(filterMajor) ||
        t.majors.find((item) => item.id === filterMajor);

    const nameFilter =
      filterName === ""
        ? true
        : t.name.toLocaleLowerCase().includes(filterName.toLocaleLowerCase()) ||
          t.surname
            .toLocaleLowerCase()
            .includes(filterName.toLocaleLowerCase());

    return nameFilter && branchFilter && majorFilter;
  });

  return (
    <>
      <div className="page-content">
        <section className="row">
          <div className="content-page">
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">Öğretmen Adı</label>
                  <i className="bar"></i>
                  <input
                    type="text"
                    id="teacher-name-search"
                    className="form-control"
                    onChange={(e) => setFilterName(e.target.value)}
                    placeholder="Öğretmen Adı Gir"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <label className="control-label">Branş</label>
                <i className="bar"></i>
                <div className="form-group custom-mt-form-group">
                  <select
                    id="teacher-major-search"
                    className="form-select"
                    onChange={(e) => setFilterMajor(Number(e.target.value))}
                    // onChange={(e) => console.warn(e.target.value)}
                  >
                    <option selected value={NaN}>
                      Seçiniz
                    </option>
                    {lessonList.map((l, index) => (
                      <option value={l.id}>{l.lessonName || index}</option>
                    ))}
                  </select>
                </div>
              </div>
              {(roles || []).includes(Roles.Admin) && (
                <div className="col-sm-6 col-md-3">
                  <label className="control-label">Kurum</label>
                  <i className="bar"></i>
                  <div className="form-group custom-mt-form-group">
                    <select
                      id="student-active-passive-search"
                      onChange={(e) => setFilterBranche(Number(e.target.value))}
                      className="form-select"
                    >
                      <option selected value={0}>
                        Hepsi
                      </option>
                      <option value={null}>Atanmamış</option>
                      {brancheList?.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">Arama Yap</label>
                  <i className="bar"></i>
                  <a
                    onClick={filterTeacherList}
                    className="btn btn-success btn-block form-control text-white"
                  >
                    Ara
                  </a>
                </div>
              </div> */}
              <div className="col-sm-6 col-md-3">
                <div className="form-group custom-mt-form-group">
                  <label className="control-label">&nbsp;</label>
                  <i className="bar"></i>
                  <button
                    onClick={createHandler}
                    className="btn btn-info btn-block form-control"
                  >
                    Yeni Öğretmen Kaydı
                  </button>
                </div>
              </div>
            </div>
            <div className="row staff-grid-row mt-3">
              {!dataLoaded ? (
                <ComponentLoader />
              ) : (
                filteredTeacherList.map((t) => (
                  <ProfileWidget
                    subtext={t.majors.map((m) => m.lessonName).join(", ")}
                    photoUrl={t.profilePhoto}
                    key={t.id}
                    actions={teacherWidgetActions(t.id)}
                    name={t.name}
                    surname={t.surname}
                  />
                ))
              )}
            </div>
          </div>
        </section>
      </div>
      <TeacherCreateOrEditModal
        listUpdated={getTeacherList}
        editData={editData}
        majorList={lessonList}
      />
      <Modal
        onclose={() => setdeleteModelState({ id: null, visibility: false })}
        onsubmit={deleteHandler}
        modalVisibility={deleteModelState.visibility}
        title="Öğretmen Kaydı Silme Onayı"
        buttonRight={"Evet"}
      >
        <div>Silme işlemini onaylıyor musunuz?</div>
      </Modal>
      <iframe style={{ visibility: "hidden" }} srcDoc={srcDoc}></iframe>
    </>
  );
}
