import React, { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import { useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import ClassroomService from "../../services/classroomService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { useEffect } from "react";

export default function StudentsWidget() {
  const teacherContext = useContext(TeacherIdentityContext);
  const classService = new ClassroomService();
  const dispatch = useDispatch();
  const history = useHistory();
  let { path, url } = useRouteMatch();
  const [hover, setHover] = useState(null);
  const [lessonsModalVis, setLessonsModalVis] = useState<boolean>(false);
  const [filterStudent, setFilterStudent] = useState<string>("");
  const [studentLesson, setStudentLesson] =
    useState<TeacherClassroomByStudentIdOutputModel>({
      id: null,
      lessons: [],
      name: "",
      students: [],
    });

  const getStudentById = async (studentId: string) => {
    dispatch(showLoader());
    const response = await classService.GetTeacherClassroomByStudentId(
      studentId
    );
    setStudentLesson(response);
    setLessonsModalVis(true);
    dispatch(hideLoader());
  };

  const filteredStudent = teacherContext.students.filter((item) =>
    item.name.toLocaleLowerCase().includes(filterStudent.toLocaleLowerCase())
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4>
            Birebir Özel Ders Öğrencileri ({teacherContext.students.length})
          </h4>
          {teacherContext.students.length > 0 && (
            <input
              type="text"
              id="teacher-name-search"
              className="form-control"
              placeholder="Öğrenci Ara"
              onChange={(e) => setFilterStudent(e.target.value)}
            />
          )}
        </div>
        <div
          className="card-body "
          style={{ maxHeight: "250px", overflowY: "scroll" }}
        >
          {filteredStudent.map((t) => (
            // <Link to={`/teacher/classroom/${2}/${3}/${t.id}`}>
            <div
              className={`rounded recent-message d-flex px-4 py-3 table-hover br-2 ${
                hover === t.id && "bg-primary text-white"
              }`}
              onMouseEnter={() => setHover(t.id)}
              onMouseLeave={() => setHover(null)}
              onClick={() => {
                getStudentById(t.id);
              }}
            >
              <div className="avatar avatar-lg " style={{ flexShrink: 0 }}>
                <img
                  src={
                    t.profilePhoto ||
                    "/assets/img/Profile_avatar_placeholder_large.png"
                  }
                />
              </div>
              <div className="name ms-4">
                <h5 className="mb-1">{t.name + " " + t.surname}</h5>
                <h6 className="text-muted mb-0">{""}</h6>
              </div>
            </div>
            // </Link>
          ))}
        </div>
      </div>
      {lessonsModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Dersler - ${studentLesson.students[0].name} `}
                </h4>
              </div>
              <div className="modal-body">
                {/* {studentLesson.students[0].lessons.map((item) => (
                  <button
                    type="button"
                    onClick={() => {
                      history.push(
                        `${path}/classroom/${studentLesson.id}/${studentLesson.students[0].id}/${item.lesson.id}`
                      );
                    }}
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    <span className="d-inline p-2">
                      {studentLesson.name} - {item.lesson.name}
                    </span>
                  </button>
                ))} */}
                <div className="row d-flex justify-content-between">
                  {studentLesson.students[0].lessons.map((item, index) => (
                    <div className="col-lg-4">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(
                            `${path}/classroom/${studentLesson.id}/${studentLesson.students[0].id}/${item.lesson.id}`
                          )
                        }
                        className="accordion-item card card-md card-body shadow "
                      >
                        <b>
                          {studentLesson.name} - {item.lesson.name}
                        </b>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setLessonsModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
