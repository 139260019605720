// @ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import LessonService from "../../services/lessonService";
import TeacherService from "../../services/teacherService";
import Accordion from "../sharedComponents/accordion";
import SelectableAccordionItem from "../sharedComponents/selectableAccordionItem";
import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import ComponentLoader from "../sharedComponents/componentLoader";

export default function SubClassroomProgress(props) {
  const dispatch = useDispatch();
  //   const { classroomId, studentId, lessonId } = useParams<any>();
  const teacherContext = useContext(TeacherIdentityContext);

  const studentId = "59d10fb6-9008-486d-91ca-6e6d126319f6";
  // const lessonId = teacherContext.majors[0].lessons[2].id;

  const [formEditable, setFormEditable] = useState(false);

  const teacherService = new TeacherService();
  const lessonService = new LessonService();

  const [lesson, setLesson] = useState<LessonOutputModel>(null);
  const [initialData, setInitialData] = useState<LessonOutputModel>(null);

  const [loader, setLoader] = useState<boolean>(false);

  const lessonId = (props?.major || []).find(
    (item) => item.classroomId == props.classRoomId
  )?.id;

  const cloneData = (data: LessonOutputModel) => {
    const cloneData: LessonOutputModel = {
      ...data,
      units: Array.from(
        data.units.map((u) => ({
          ...u,
          topics: Array.from(u.topics).map((t) => ({ ...t })),
        }))
      ),
    };
    return cloneData;
  };

  const getSubClassroomProgress = async () => {
    setLoader(true);
    const response = await teacherService.GetSubClassroomProgress(
      props.subClassRoomId,
      lessonId
    );
    if (response) {
      setInitialData(response);
      setLesson(cloneData(response));
    }
    setLoader(false);
  };

  useEffect(() => {
    // getLessonById();
    getSubClassroomProgress();
  }, [lessonId, props.major]);

  const populateUnits = () => {
    return lesson.units.map((u) => (
      <SelectableAccordionItem
        checkboxDisable={!formEditable}
        progressBar={{
          valueMax: 100,
          valueMin: 0,
          valueNow: Math.round(unitProgress(u) * 100),
        }}
        onCheckboxChangeHandler={(checked: boolean) =>
          onUnitCheckboxChangeCallbackHandler(u.id, checked)
        }
        id={"unit" + u.id}
        parentId="Units"
        title={u.name}
        checked={u.checked || false}
      >
        <Accordion
          id={"Topics"}
          insertable={false}
          populate={() => populateTopics(u.id, u.topics)}
        ></Accordion>
      </SelectableAccordionItem>
    ));
  };

  const populateTopics = (unitId: number, topics: Array<TopicOutputModel>) =>
    topics.map((t) => {
      let data: DatatableDataModel = {
        header: ["Soru No", "Zorluk Derecesi"],
        list: t.testQuestions.map((q) => ({
          id: q.id,
          checked: q.checked,
          row: [
            q.id.toString(),
            q.difficultyLevel ? q.difficultyLevel.toString() : "",
          ],
        })),
      };

      return (
        <SelectableAccordionItem
          checkboxDisable={!formEditable}
          collapsable={false}
          onCheckboxChangeHandler={(checked: boolean) =>
            onTopicCheckboxChangeCallbackHandler(unitId, t.id, checked)
          }
          id={"topic" + t.id}
          parentId="Topics"
          title={t.name}
          checked={t.checked || false}
        ></SelectableAccordionItem>
      );
    });

  const onUnitCheckboxChangeCallbackHandler = (
    unitId: number,
    checked: boolean
  ) => {
    const tempLesson = { ...lesson };
    const unit = tempLesson.units.find((u) => u.id == unitId);
    unit.topics.forEach((t) => (t.checked = checked));
    unit.checked = checked;
    setLesson(tempLesson);
  };

  const onTopicCheckboxChangeCallbackHandler = (
    unitId: number,
    topicId: number,
    checked: boolean
  ) => {
    const tempLesson = { ...lesson };
    const unit = tempLesson.units.find((u) => u.id == unitId);
    unit.topics.find((t) => t.id == topicId).checked = checked;
    if (unit.topics.some((t) => t.checked != true)) unit.checked = false;
    else unit.checked = true;
    setLesson(tempLesson);
  };

  const unitProgress = (u: UnitOutputModel) =>
    u.topics && u.topics.length > 0
      ? u.topics.reduce((acc, curr) => acc + (curr.checked ? 1 : 0), 0) /
        u.topics.length
      : u.checked
      ? 1
      : 0;
  const overallProgress: number = Math.round(
    lesson
      ? (lesson.units.reduce(
          (acc, curr) => acc + (curr.checked ? 1 : unitProgress(curr)),
          0
        ) *
          100) /
          lesson.units.length
      : 0
  );

  const exitEditMode = () => {
    setLesson(cloneData(initialData));
    setFormEditable(false);
  };

  const saveChanges = async () => {
    dispatch(showLoader());

    const progressList: Array<TeacherProgressInputModel> = [];

    if (lesson.progressId && !lesson.checked)
      progressList.push({ progressId: lesson.progressId, lessonId: lesson.id });
    else if (!lesson.progressId && lesson.checked)
      progressList.push({ lessonId: lesson.id });

    lesson.units.forEach((u) => {
      if (u.progressId && !u.checked)
        progressList.push({ progressId: u.progressId, unitId: u.id });
      else if (!u.progressId && u.checked) progressList.push({ unitId: u.id });

      u.topics.forEach((t) => {
        if (t.progressId && !t.checked)
          progressList.push({ progressId: t.progressId, topicId: t.id });
        else if (!t.progressId && t.checked)
          progressList.push({ topicId: t.id });
      });
    });

    const result = await teacherService.UpdateSubClassroomProgress({
      subClassRoomId: props.subClassRoomId,
      lessonId: lessonId,
      progressList: progressList,
    });

    setFormEditable(false);
    dispatch(hideLoader());

    if (!result)
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "İşlem Başarısız, tekrar deneyiniz",
        })
      );
    else getSubClassroomProgress();
  };

  return (
    <>
      {loader ? (
        <ComponentLoader />
      ) : (
        <div className="row">
          <div className="col-md-12 ">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Üniteler</h4>
                <div className="progress progress-info mt-4 mb-4">
                  <div
                    className="progress-bar progress-bar-striped progress-label"
                    role="progressbar"
                    style={{ width: overallProgress + "%", height: "100%" }}
                    aria-valuenow={overallProgress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <div className="buttons">
                  <button
                    className="btn btn-primary"
                    style={{ display: !formEditable ? "initial" : "none" }}
                    onClick={() => setFormEditable(true)}
                  >
                    Güncelle
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{ display: formEditable ? "initial" : "none" }}
                    onClick={exitEditMode}
                  >
                    İptal
                  </button>
                  <button
                    className="btn btn-success"
                    style={{ display: formEditable ? "initial" : "none" }}
                    onClick={saveChanges}
                  >
                    Kaydet
                  </button>
                </div>
              </div>
              <div className="card-body">
                {lesson ? (
                  <Accordion
                    id={"Units"}
                    insertable={false}
                    populate={populateUnits}
                  ></Accordion>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
