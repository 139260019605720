import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import LessonService from "../../services/lessonService";
import TeacherService from "../../services/teacherService";
import TestService from "../../services/testService";
import Accordion from "../sharedComponents/accordion";
import Modal from "../sharedComponents/modal";
import SelectableAccordionItem from "../sharedComponents/selectableAccordionItem";
import { CreatequestionPdf } from "../sharedComponents/testpdf";
import BaseService from "../../services/baseService";
import FailedQuestions from "../studentPanelComponents/FailedQuestions";
import FailedQuestionsDetail from "../studentPanelComponents/FailedQuestionsDetail";
import Header from "../header";

export default function StudentLessonProgress() {
  const dispatch = useDispatch();
  const { classroomId, studentId, lessonId } = useParams<any>();
  const [formEditable, setFormEditable] = useState(false);
  const [failedQuestionList, setFailedQuestionList] = useState<
    Array<TestQuestionWithStudentAnswerOutputModel>
  >([]);
  const teacherService = new TeacherService();
  const lessonService = new LessonService();
  const testService = new TestService();

  const [lesson, setLesson] = useState<LessonOutputModel>(null);
  const [initialData, setInitialData] = useState<LessonOutputModel>(null);
  const [studentSuccessRates, setStudentSuccessRates] =
    useState<LessonSuccessModel>(null);

  const [modalVisibility, setModalVisibility] = useState(false);
  const [studentResultsModalVisibility, setStudentResultsModalVisibility] =
    useState(false);
  const [viewableQuestion, setViewableQuestion] =
    useState<TestQuestionWithStudentAnswerOutputModel>(null);

  const [srcDoc, setSrcDoc] = useState(null);

  const baseService = new BaseService();

  const getLessonById = async () => {
    const result = await teacherService.GetTeacherStudentLessonProgress(
      studentId,
      lessonId
    );
    if (result) {
      setInitialData(result);
      setLesson(cloneData(result));
    }
  };

  const cloneData = (data: LessonOutputModel) => {
    const cloneData: LessonOutputModel = {
      ...data,
      units: Array.from(
        data.units.map((u) => ({
          ...u,
          topics: Array.from(u.topics).map((t) => ({ ...t })),
        }))
      ),
    };
    return cloneData;
  };

  const getStudentsFailedQuestionList = async () => {
    const result = await testService.GetStudentsFailedQuestionList(
      lessonId,
      studentId
    );
    // if (result) setFailedQuestionList(result);
  };

  const getStudentLessonProgress = async () => {
    const result = await lessonService.GetStudentProgress(studentId, lessonId);
    if (result) setStudentSuccessRates(result);
  };

  useEffect(() => {
    getLessonById();
    getStudentsFailedQuestionList();
    getStudentLessonProgress();
  }, []);

  const populateUnits = () => {
    return lesson.units.map((u) => (
      <SelectableAccordionItem
        checkboxDisable={!formEditable}
        progressBar={{
          valueMax: 100,
          valueMin: 0,
          valueNow: Math.round(unitProgress(u) * 100),
        }}
        onCheckboxChangeHandler={(checked: boolean) =>
          onUnitCheckboxChangeCallbackHandler(u.id, checked)
        }
        id={"unit" + u.id}
        parentId="Units"
        title={u.name}
        checked={u.checked || false}
      >
        <Accordion
          id={"Topics"}
          insertable={false}
          populate={() => populateTopics(u.id, u.topics)}
        ></Accordion>
      </SelectableAccordionItem>
    ));
  };

  const populateTopics = (unitId: number, topics: Array<TopicOutputModel>) =>
    topics.map((t) => {
      let data: DatatableDataModel = {
        header: ["Soru No", "Zorluk Derecesi"],
        list: t.testQuestions.map((q) => ({
          id: q.id,
          checked: q.checked,
          row: [
            q.id.toString(),
            q.difficultyLevel ? q.difficultyLevel.toString() : "",
          ],
        })),
      };

      return (
        <SelectableAccordionItem
          checkboxDisable={!formEditable}
          collapsable={false}
          onCheckboxChangeHandler={(checked: boolean) =>
            onTopicCheckboxChangeCallbackHandler(unitId, t.id, checked)
          }
          id={"topic" + t.id}
          parentId="Topics"
          title={t.name}
          checked={t.checked || false}
        ></SelectableAccordionItem>
      );
    });

  const onUnitCheckboxChangeCallbackHandler = (
    unitId: number,
    checked: boolean
  ) => {
    const tempLesson = { ...lesson };
    const unit = tempLesson.units.find((u) => u.id == unitId);
    unit.topics.forEach((t) => (t.checked = checked));
    unit.checked = checked;
    setLesson(tempLesson);
  };

  const onTopicCheckboxChangeCallbackHandler = (
    unitId: number,
    topicId: number,
    checked: boolean
  ) => {
    const tempLesson = { ...lesson };
    const unit = tempLesson.units.find((u) => u.id == unitId);
    unit.topics.find((t) => t.id == topicId).checked = checked;
    if (unit.topics.some((t) => t.checked != true)) unit.checked = false;
    else unit.checked = true;
    setLesson(tempLesson);
  };

  const unitProgress = (u: UnitOutputModel) =>
    u.topics && u.topics.length > 0
      ? u.topics.reduce((acc, curr) => acc + (curr.checked ? 1 : 0), 0) /
        u.topics.length
      : u.checked
      ? 1
      : 0;
  const overallProgress: number = Math.round(
    lesson
      ? (lesson.units.reduce(
          (acc, curr) => acc + (curr.checked ? 1 : unitProgress(curr)),
          0
        ) *
          100) /
          lesson.units.length
      : 0
  );

  const exitEditMode = () => {
    setLesson(cloneData(initialData));
    setFormEditable(false);
  };

  const saveChanges = async () => {
    dispatch(showLoader());

    const progressList: Array<TeacherProgressInputModel> = [];

    if (lesson.progressId && !lesson.checked)
      progressList.push({ progressId: lesson.progressId, lessonId: lesson.id });
    else if (!lesson.progressId && lesson.checked)
      progressList.push({ lessonId: lesson.id });

    lesson.units.forEach((u) => {
      if (u.progressId && !u.checked)
        progressList.push({ progressId: u.progressId, unitId: u.id });
      else if (!u.progressId && u.checked) progressList.push({ unitId: u.id });

      u.topics.forEach((t) => {
        if (t.progressId && !t.checked)
          progressList.push({ progressId: t.progressId, topicId: t.id });
        else if (!t.progressId && t.checked)
          progressList.push({ topicId: t.id });
      });
    });

    const result = await teacherService.UpdateTeacherStudentLessonProgress({
      lessonId: lessonId,
      studentId: studentId,
      progressList: progressList,
    });

    setFormEditable(false);
    dispatch(hideLoader());

    if (!result)
      dispatch(
        sendMessage({
          title: "İşlem Sonucu",
          message: "İşlem Başarısız, tekrar deneyiniz",
        })
      );
    else getLessonById();
  };

  return (
    <div className="row">
      <div className="col-md-6 ">
        <Header name={`Öğrenci İlerleme Durumu`} />
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Üniteler</h4>
            <div className="progress progress-info mt-4 mb-4">
              <div
                className="progress-bar progress-bar-striped progress-label"
                role="progressbar"
                style={{ width: overallProgress + "%", height: "100%" }}
                aria-valuenow={overallProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
            <div className="buttons">
              <button
                className="btn btn-primary"
                style={{ display: !formEditable ? "initial" : "none" }}
                onClick={() => setFormEditable(true)}
              >
                Güncelle
              </button>
              <button
                className="btn btn-danger"
                style={{ display: formEditable ? "initial" : "none" }}
                onClick={exitEditMode}
              >
                İptal
              </button>
              <button
                className="btn btn-success"
                style={{ display: formEditable ? "initial" : "none" }}
                onClick={saveChanges}
              >
                Kaydet
              </button>
            </div>
          </div>
          <div className="card-body">
            {lesson ? (
              <Accordion
                id={"Units"}
                insertable={false}
                populate={populateUnits}
              ></Accordion>
            ) : null}
          </div>
        </div>
      </div>

      <div className="col-md-6 ">
        <div>
          <FailedQuestionsDetail studentId={studentId} />
        </div>
      </div>

      {/* <div className="col-md-5">
        <div className="card" style={{ maxHeight: "100vh", overflow: "auto" }}>
          <div className="card-header">
            <h4 className="card-title">
              Çözülemeyen Sorular ({failedQuestionList.length})
            </h4>
            <div className="buttons">
              {failedQuestionList.length > 0 ? (
                <button className="btn btn-primary" onClick={createPdf}>
                  Pdf İndir
                </button>
              ) : null}
              <button
                onClick={() => setStudentResultsModalVisibility(true)}
                className="btn btn-success"
              >
                Öğrenci Başarı Durumu
              </button>
            </div>
          </div>
          <div className="card-body">
            {failedQuestionList.map((q, index) => (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => showQuestionModal(q.testQuestion.id)}
                className="accordion-item card card-md card-body shadow"
              >
                <b>Soru {index + 1}</b>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* {viewableQuestion ? (
        <Modal
          showButtonRight={false}
          title="Soru"
          modalVisibility={modalVisibility}
          onclose={() => setModalVisibility(false)}
        >
          {viewableQuestion.testQuestion.questionUrl ? (
            <img
              src={
                baseService.endPoints().pdfUrl +
                "/testquestionbank/" +
                viewableQuestion.testQuestion.questionUrl
              }
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: viewableQuestion.testQuestion.question,
              }}
            />
          )}
          <div>
            {(viewableQuestion.testQuestion.testQuestionAnswers || []).map(
              (a) => (
                <div>
                  <span
                    style={{ fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{ __html: a.answerKey }}
                  ></span>
                  <span style={{ fontWeight: "bold" }}>)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: a.answerValue }}
                  ></span>
                </div>
              )
            )}
          </div>
          <div>
            <i className="fas fa-check text-success"></i>{" "}
            <b className="text-success">
              Doğru Cevap: {viewableQuestion.testQuestion.correctAnswer}
            </b>
            <br />
            <i className="fas fa-times text-danger"></i>{" "}
            <b className="text-danger">
              Öğrencinin Cevabı: {viewableQuestion.studentAnswer}
            </b>
          </div>
        </Modal>
      ) : null} */}
      {/* {studentSuccessRates ? (
        <Modal
          showButtonRight={false}
          title="Öğrenci Başarı Durumu"
          modalVisibility={studentResultsModalVisibility}
          onclose={() => setStudentResultsModalVisibility(false)}
        >
          <b>Ders Değerlendirme Yüzdesi</b>
          <div className="progress progress-info mt-4 mb-4">
            <div
              className="progress-bar progress-bar-striped progress-label"
              role="progressbar"
              style={{ width: getStudentOverallScore() + "%", height: "100%" }}
              aria-valuenow={getStudentOverallScore()}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          {studentSuccessRates.lessonUnits.map((u, index) => {
            const correctCount = u.successRate.correctAnswerCount;
            const questionCount = u.successRate.questionCount;
            const unitSuccessRate =
              questionCount > 0
                ? Math.round((100 * correctCount) / questionCount)
                : 0;

            return (
              <div
                style={{ cursor: "pointer" }}
                className="card card-md card-body shadow"
              >
                <b>
                  {u.lessonUnitName} ({correctCount + " / " + questionCount})
                </b>
                <div className="progress progress-info mt-4 mb-4">
                  <div
                    className="progress-bar progress-bar-striped progress-label"
                    role="progressbar"
                    style={{ width: unitSuccessRate + "%", height: "100%" }}
                    aria-valuenow={unitSuccessRate}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            );
          })}
        </Modal>
      ) : null} */}
      <iframe style={{ visibility: "hidden" }} srcDoc={srcDoc}></iframe>
    </div>
  );
}
