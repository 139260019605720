import TestService from "../../services/testService";
import { v4 as uuidv4 } from "uuid";
import ReportService from "../../services/reportService";
import BaseService from "../../services/baseService";

const CM_IN_PIXEL = 37.795275591;

declare global {
  interface TestPdfProps {
    testName: string;
    questionList: Array<QuestionOutputModel>;
  }

  interface PrintPostMessage {
    print: boolean;
  }
}

declare interface IGrouping<T> {
  [key: string]: Array<T>;
}

export default async function TestPdf(testId: number, print?: boolean) {
  const testService = new TestService();
  const test = await testService.GetTestDetails(testId);
  const html = (await createTestPdf(test)).innerHTML;
  //   console.log(html);
  // return html;
  return printTemplate(html, print);
}

export async function CreatequestionPdf(
  lessonName: string,
  studentName: string,
  studentClass: string,
  questionList: any
  // questionList: Array<QuestionOutputModel>
) {
  // console.warn(questionList);
  const test = {
    name: lessonName + " Dersi Çözülemeyen Sorular",
    testQuestions: questionList,
  } as TestOutputModel;

  const unitGroup = test.testQuestions.reduce((acc, curr) => {
    if (acc[curr.unitId]) acc[curr.unitId].push(curr);
    else acc[curr.unitId] = [curr];

    return acc;
  }, {} as KeyValuePair<Array<QuestionOutputModel>>);

  let html: string = "";

  for (let key in unitGroup) {
    const unitTest = {
      name:
        lessonName +
        " Dersi Çözülemeyen Sorular (" +
        unitGroup[key][0].unitName +
        ")",
      testQuestions: unitGroup[key],
    } as TestOutputModel;
    html += (await createTestPdf(unitTest, studentName, studentClass))
      .innerHTML;
  }

  //   console.log(html);
  return printTemplate(html);
}

// export async function OgrenciRaporuOlustur(studentId: string) {
//   const reportService = new ReportService();
//   const report = await reportService.GetStudentReport(studentId);
//   if (!report) return;

//   let pageNumber: number = 1;
//   const container = document.createElement("div");

//   // create test frame
//   var testFrame = document.createElement("iframe");
//   testFrame.setAttribute("id", "my-test-frame");
//   testFrame.style.visibility = "hidden";
//   document.body.appendChild(testFrame);
//   const style = document.createElement("style");
//   style.innerHTML = pageStyle;
//   testFrame.contentDocument.head.append(style);
//   testFrame.contentDocument.body.append(container);

//   let page = createPageElement(PrintPageElementType.PAGE, 1); // create first page
//   container.appendChild(page);
//   let subpage = createPageElement(PrintPageElementType.SUBPAGE, 1);

//   page.appendChild(subpage);
//   let table = createStudentReportPage(
//     report.profile.name,
//     report.profile.surname,
//     report.profile.gumusEgitimClassroom
//   );
//   subpage.appendChild(table);
//   let tableBody = table.querySelector("tbody");

//   const examResultGroups = report.examResults.reduce((acc, curr) => {
//     if (!acc[curr.lessonId ? curr.lessonId + ":" + curr.lessonName : "-"])
//       acc[curr.lessonId ? curr.lessonId + ":" + curr.lessonName : "-"] = [];
//     acc[curr.lessonId ? curr.lessonId + ":" + curr.lessonName : "-"].push(curr);
//     return acc;
//   }, {} as IGrouping<TestResultModel>);

//   Object.keys(examResultGroups).forEach((k) => {
//     const testScores = {} as KeyValuePair<number>;
//     examResultGroups[k].forEach((r, index) => {
//       const testScore = Math.round(
//         (r.correctAnswerCount / r.questionCount) * 100
//       );

//       if (!testScores[r.testId] || testScores[r.testId] < testScore)
//         testScores[r.testId] = testScore;

//       const newRow = createStudentReportPageRow(r);
//       tableBody.appendChild(newRow);

//       if (subpage.clientHeight < subpage.scrollHeight) {
//         newRow.remove();
//         page = createPageElement(PrintPageElementType.PAGE, ++pageNumber); // create first page
//         container.appendChild(page);
//         subpage = createPageElement(PrintPageElementType.SUBPAGE, pageNumber);

//         page.appendChild(subpage);
//         table = createStudentReportPage(
//           report.profile.name,
//           report.profile.surname,
//           report.profile.gumusEgitimClassroom
//         );
//         subpage.appendChild(table);
//         tableBody = table.querySelector("tbody");

//         tableBody.appendChild(newRow);
//       }
//     });

//     const groupHeader = document.createElement("tr");
//     groupHeader.innerHTML = `<td colspan="9"><b>${
//       k.split(":")[1] || "&nbsp;"
//     }</b></td><td><b>${(
//       Object.values(testScores).reduce((acc, curr) => acc + curr, 0) /
//       Object.keys(testScores).length
//     ).toFixed(2)}</b></td>`;
//     tableBody.append(groupHeader);
//   });

//   testFrame.remove();

//   return printTemplate(container.innerHTML);
// }

export async function OgretmenRaporuOlustur(teacherId: string) {
  const reportService = new ReportService();
  const report = await reportService.GetTeacherReport(teacherId);
  if (!report) return;

  let pageNumber: number = 1;
  const container = document.createElement("div");

  // create test frame
  var testFrame = document.createElement("iframe");
  testFrame.setAttribute("id", "my-test-frame");
  testFrame.style.visibility = "hidden";
  document.body.appendChild(testFrame);
  const style = document.createElement("style");
  style.innerHTML = pageStyle;
  testFrame.contentDocument.head.append(style);
  testFrame.contentDocument.body.append(container);

  let page = createPageElement(PrintPageElementType.PAGE, 1); // create first page
  container.appendChild(page);
  let subpage = createPageElement(PrintPageElementType.SUBPAGE, 1);

  page.appendChild(subpage);
  let table = createTeacherReportPage(
    report.profile.name,
    report.profile.surname
  );
  subpage.appendChild(table);
  let tableBody = table.querySelector("tbody");

  const examResultGroups = report.lessonReportList.reduce((acc, curr) => {
    if (!acc[curr.lessonId ? curr.lessonId + ":" + curr.lessonName : "-"])
      acc[curr.lessonId ? curr.lessonId + ":" + curr.lessonName : "-"] = [];
    acc[curr.lessonId ? curr.lessonId + ":" + curr.lessonName : "-"].push(curr);
    return acc;
  }, {} as IGrouping<TeacherLessonReportModel>);

  Object.keys(examResultGroups).forEach((k) => {
    const testScores = {} as KeyValuePair<number>;
    examResultGroups[k].forEach((r, index) => {
      r.studentSuccessList.forEach((s) => {
        const newRow = createTeacherReportPageRow(r.lessonName, s);
        tableBody.appendChild(newRow);

        if (subpage.clientHeight < subpage.scrollHeight) {
          newRow.remove();
          page = createPageElement(PrintPageElementType.PAGE, ++pageNumber); // create first page
          container.appendChild(page);
          subpage = createPageElement(PrintPageElementType.SUBPAGE, pageNumber);

          page.appendChild(subpage);
          table = createTeacherReportPage(
            report.profile.name,
            report.profile.surname
          );
          subpage.appendChild(table);
          tableBody = table.querySelector("tbody");

          tableBody.appendChild(newRow);
        }
      });

      const groupHeader = document.createElement("tr");
      groupHeader.innerHTML = `<td colspan="9"><b>${
        k.split(":")[1] || "&nbsp;"
      }</b></td><td><b>${r.lessonSuccessRate}</b></td>`;
      tableBody.append(groupHeader);
    });
  });

  testFrame.remove();

  return printTemplate(container.innerHTML);
}

export const exportStudentList = async (data: any) => {
  const container = document.createElement("div");

  // create test frame
  var testFrame = document.createElement("iframe");
  testFrame.setAttribute("id", "my-test-frame");
  testFrame.style.visibility = "hidden";
  document.body.appendChild(testFrame);
  const style = document.createElement("style");
  style.innerHTML = pageStyle;
  testFrame.contentDocument.head.append(style);
  testFrame.contentDocument.body.append(container);

  let page = createPageElement(PrintPageElementType.PAGE, 1); // create first page
  container.appendChild(page);
  let subpage = createPageElement(PrintPageElementType.SUBPAGE, 1);

  page.appendChild(subpage);

  // const element = document.createElement("div");
  // element.className = "page-table-container";
  // element.innerHTML = `<table>
  //       <tr style="text-align:center">
  //           <td colspan="2"> <span style="font-weight:bold">Öğrenci İsim Soyisim</span></td>
  //           <td colspan="2"> <span style="font-weight:bold">Öğrenci Sınıfı</span></td>
  //           <td colspan="2"> <span style="font-weight:bold">Öğrenci Telefonu</span></td>
  //           <td colspan="2"> <span style="font-weight:bold">Veli İsim Soyisim</span></td>
  //           <td colspan="2"> <span style="font-weight:bold">Veli Telefonu</span></td>
  //       </tr>

  //       ${data.map((item, index) => {
  //         return ` <tr style="text-align:center">
  //             <td colspan="2">${item["Öğrenci İsim Soyisim"]}</td>
  //             <td colspan="2">${item["Öğrenci Sınıfı"]}</td>
  //             <td colspan="2">${item["Öğrenci Telefonu"]}</td>
  //             <td colspan="2">${item["Veli İsim Soyisim"]}</td>
  //             <td colspan="2">${item["Veli Telefonu"]}</td>
  //           </tr>`;
  //       })}
  //     </table>`;
  // subpage.appendChild(element);

  const filigran = document.createElement("div");
  filigran.className = `  pointer-events:none;
                          position:fixed;
                          left:0; 
                          top:0;
                          width:100%; 
                          height:100%; 
                          opacity:.1; 
                          z-index:9999;
                          background:url('http://localhost:3000/logo.png') left top repeat;`;
  subpage.appendChild(filigran);
  testFrame.remove();
  return printTemplate(container.innerHTML);
};

// student test
export const createTestPdf = async (
  test: TestOutputModel,
  studentName?: string,
  studentClass?: string
) => {
  let whichPane: string = "left";
  let pageNumber: number = 1;
  const container = document.createElement("div");

  // create test frame
  var testFrame = document.createElement("iframe");
  testFrame.setAttribute("id", "my-test-frame");
  testFrame.style.visibility = "hidden";
  document.body.appendChild(testFrame);
  const style = document.createElement("style");
  style.innerHTML = pageStyle;
  testFrame.contentDocument.head.append(style);
  testFrame.contentDocument.body.append(container);

  let page = createPageElement(PrintPageElementType.PAGE, 1); // create first page
  container.appendChild(page);
  const pageHeader = createPageElement(PrintPageElementType.SUBPAGE, 1, 4.2);
  pageHeader.appendChild(
    createTestPageHeader(test.name, studentName, studentClass)
  );
  page.appendChild(pageHeader);
  let subPage = createPageElement(PrintPageElementType.SUBPAGE, 1, 26.5, true);
  page.appendChild(subPage);

  let leftSubpageColumn = createPageElement(
    PrintPageElementType.SUBPAGE_COLUMN,
    1,
    26.3
  );
  leftSubpageColumn.classList.add("subpage-col-left");
  let rightSubpageColumn = createPageElement(
    PrintPageElementType.SUBPAGE_COLUMN,
    1,
    26.3
  );
  rightSubpageColumn.classList.add("subpage-col-right");
  subPage.appendChild(leftSubpageColumn);
  subPage.appendChild(rightSubpageColumn);

  for (let index = 0; index < test.testQuestions.length; index++) {
    const q = test.testQuestions[index];
    const imageAspects = { naturalWidth: null, renderImageHeight: null };
    const questionElement = await createQuestion(q, index + 1, imageAspects);
    const placeholderElement = document.createElement("div");
    placeholderElement.style.width = "100%";
    placeholderElement.style.height = imageAspects.renderImageHeight + "px";

    // background:url('http://localhost:3000/logo.png') left top repeat;

    if (imageAspects.naturalWidth > 1500) {
      whichPane = "left";
      // leftSubpageColumn.style.zIndex = "99";
      // leftSubpageColumn.style.border = "none";
      // rightSubpageColumn.style.border = "none";
    }

    if (whichPane == "left") {
      leftSubpageColumn.appendChild(questionElement);

      if (imageAspects.naturalWidth > 1500) {
        rightSubpageColumn.appendChild(placeholderElement);
      }
    } else if (whichPane == "right")
      rightSubpageColumn.appendChild(questionElement);

    let pageOverflow =
      whichPane == "left"
        ? leftSubpageColumn.clientHeight < leftSubpageColumn.scrollHeight
        : rightSubpageColumn.clientHeight < rightSubpageColumn.scrollHeight;

    if (whichPane == "left" && pageOverflow) {
      questionElement.remove();

      if (imageAspects.naturalWidth > 1500) {
        placeholderElement.remove();
        page = createPageElement(PrintPageElementType.PAGE, ++pageNumber);
        container.appendChild(page);
        subPage = createPageElement(
          PrintPageElementType.SUBPAGE,
          pageNumber,
          null,
          true
        );
        page.appendChild(subPage);

        leftSubpageColumn = createPageElement(
          PrintPageElementType.SUBPAGE_COLUMN,
          pageNumber
        );
        leftSubpageColumn.classList.add("subpage-col-left");
        rightSubpageColumn = createPageElement(
          PrintPageElementType.SUBPAGE_COLUMN,
          pageNumber
        );
        rightSubpageColumn.classList.add("subpage-col-right");
        subPage.appendChild(leftSubpageColumn);
        subPage.appendChild(rightSubpageColumn);
        leftSubpageColumn.appendChild(questionElement);
        rightSubpageColumn.appendChild(placeholderElement);
        whichPane = "left";
      } else {
        rightSubpageColumn.appendChild(questionElement);
        whichPane = "right";
      }
    }

    pageOverflow =
      whichPane == "left"
        ? leftSubpageColumn.clientHeight < leftSubpageColumn.scrollHeight
        : rightSubpageColumn.clientHeight < rightSubpageColumn.scrollHeight;

    if (whichPane == "right" && pageOverflow) {
      page = createPageElement(PrintPageElementType.PAGE, ++pageNumber);
      container.appendChild(page);
      subPage = createPageElement(
        PrintPageElementType.SUBPAGE,
        pageNumber,
        null,
        true
      );
      page.appendChild(subPage);

      leftSubpageColumn = createPageElement(
        PrintPageElementType.SUBPAGE_COLUMN,
        pageNumber
      );
      leftSubpageColumn.classList.add("subpage-col-left");
      rightSubpageColumn = createPageElement(
        PrintPageElementType.SUBPAGE_COLUMN,
        pageNumber
      );
      rightSubpageColumn.classList.add("subpage-col-right");
      subPage.appendChild(leftSubpageColumn);
      subPage.appendChild(rightSubpageColumn);

      questionElement.remove();
      leftSubpageColumn.appendChild(questionElement);
      whichPane = "left";
    }

    if (imageAspects.naturalWidth > 1500) {
      leftSubpageColumn.style.zIndex = "99";
      leftSubpageColumn.style.border = "none";
      rightSubpageColumn.style.border = "none";
    }
  }

  testFrame.remove();

  return container;
};

enum PrintPageElementType {
  PAGE = "page",
  SUBPAGE = "subpage",
  SUBPAGE_COLUMN = "subpage-col",
}

const pageStyle = `
        body {
            margin: 0;
            padding: 0;
            background-color: #FAFAFA;
            font: 12pt "Tahoma";
        }
        * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
        }
        .page {
            width: 22.5cm;
            height: 31.8cm;
            padding: 0.25cm;
            background: white;
            border: 0.1cm transparent solid;
        }
        .subpage {
            display: flex;
            border: 0.1cm transparent solid;
            /*height: 27.7cm;*/
        }

        .subpage-col {
            border:1px transparent solid;
            width:10.8cm;
            /*height:27.5cm;*/
        }

        .subpage-col-left {
            border-right:0.01px solid lightgray;
        }

        .subpage-col-right {
            /*padding-left: 0.1cm*/;
            border-left:0.01px solid gray;
        }

        @page {
            size: A4;
            margin: 0;
        }

        table {
            width: 100%;
            border: 1px solid #000;
            border-collapse: collapse;
            table-layout: fixed;
        }
        table td {
            border: 1px solid #000;
        }

        img {
            flex-grow:0;
            border: 1px transparent solid;
            object-fit: contain;
            /*width: 10.5cm !important;*/
            /*max-height:24.5cm !important;*/
        }

        #page-header {
            width: 100%;
        }

        #page-header .header {
            display: flex;
            border: 0.1px solid black;
            padding: 0.2cm;
            justify-content: space-between;
        }  
    `;

export const printTemplate = (htmlString: string, print?: boolean) => {
  let localPrint = print === undefined ? true : print;
  return `
    <style>
    ${pageStyle}
    </style>
    <div class="book" data-uid=${uuidv4()}>
        ${htmlString}
    </div>
   ${localPrint && "<script>window.print();</script>"}
`;
};

function createPageElement(
  type: PrintPageElementType,
  id: number,
  customHeight?: number,
  addPagination?: boolean
) {
  const element = document.createElement("div");
  element.style.position = "relative";
  // page num
  if (type == PrintPageElementType.SUBPAGE && addPagination) {
    const pageNumEl = document.createElement("div");
    pageNumEl.style.position = "absolute";
    pageNumEl.style.width = "100%";
    pageNumEl.style.bottom = "-0.6cm";
    pageNumEl.style.fontSize = "0.3cm";
    pageNumEl.style.border = "1px transparent blue";
    pageNumEl.style.textAlign = "center";
    pageNumEl.innerHTML = id.toString();
    //
    element.appendChild(pageNumEl);
  }

  element.classList.add(type);
  element.id = type + "-" + id;
  if (customHeight) element.style.height = customHeight + "cm";
  else if (type == PrintPageElementType.SUBPAGE)
    element.style.height = 30.7 + "cm";
  else if (type == PrintPageElementType.SUBPAGE_COLUMN)
    element.style.height = 30.5 + "cm";
  return element;
}

async function createQuestion(
  question: QuestionOutputModel,
  index: number,
  imageAspects: { naturalWidth: number; renderImageHeight: number }
): Promise<any> {
  if (question.questionUrl) {
    const baseService = new BaseService();

    const containerElement = document.createElement("div");
    containerElement.style.display = "flex";

    const element = document.createElement("img");
    element.src =
      baseService.endPoints().pdfUrl +
      "/testquestionbank/" +
      question.questionUrl;

    return new Promise((resolve) => {
      element.onload = () => {
        element.onload = null;
        const aspectRatio = element.width / element.height;
        imageAspects.naturalWidth = element.naturalWidth;
        const factor = element.naturalWidth > 1500 ? 2 : 1;

        const dimensions = {
          1: [15.118110236, 393.07086614],
          2: [30.236220472, 377.95275591],
          3: [45.354330709, 362.83464567],
        };
        const dimenson =
          index.toString().length > 3 ? 3 : index.toString().length;

        element.style.width = dimensions[dimenson][1] * factor + "px";
        element.style.height =
          (dimensions[dimenson][1] * factor) / aspectRatio + "px";

        // render question number
        const num = document.createElement("div");
        num.innerHTML =
          index +
          "." +
          (question.order
            ? "<br /><small style='color:red;'>" + question.order + "</small>"
            : "");
        num.style.display = "inline-block";
        num.style.width = dimensions[dimenson][0] + "px";
        // num.style.border = "1px solid red";
        num.style.top = "0";
        num.style.fontWeight = "bold";
        num.style.left = "0cm";
        element.prepend(num);

        containerElement.appendChild(num);
        containerElement.appendChild(element);

        imageAspects.renderImageHeight = (408.18897638 * factor) / aspectRatio;
        return resolve(containerElement);
      };
    });
  }

  const element = document.createElement("div");
  element.innerHTML = question.question;
  element.style.position = "relative";
  element.style.paddingBottom = "2.5cm";
  element.style.marginLeft = "1.0cm";

  return element;
}

function createTestPageHeader(
  testName: string,
  studentName?: string,
  studentClass?: string
) {
  const element = document.createElement("div");
  element.id = "page-header";
  element.innerHTML = `
        <div class="header">
            <div style="display:flex;flex-direction:column;justify-content:center;">
                <div style="padding-bottom:15px;">
                Ad, Soyad :
                <span>${studentName || "______________________"}</span>
                </div>
                <div>
                Sınıf:
                <span>${studentClass || "______________________"}</span>
                </div>
            </div>
            <div style="display:flex;justify-content:center;align-items:center;"> <img src="/logo192.png" width="180px" height="90px" /></div>
        </div>
        <div style="text-align:center;">
            <h4>${testName || "Test"}</h4>
        </div>
    `;

  return element;
}

function createStudentProfileInfoHeader(
  name: string,
  surname: string,
  classroom: string
) {
  const element = document.createElement("div");
  element.id = "page-header";
  element.innerHTML = `
        <div class="profile">
            <div>
               <p><b>Ad:</b> ${name}</p>
               <p><b>Soyad:</b> ${surname}</p>
               <p><b>Sınıf:</b> ${classroom}</p>
            </div>
        </div>
    `;

  return element;
}

function createTeacherProfileInfoHeader(name: string, surname: string) {
  const element = document.createElement("div");
  element.id = "page-header";
  element.innerHTML = `
        <div class="profile">
            <div>
               <p><b>Ad:</b> ${name}</p>
               <p><b>Soyad:</b> ${surname}</p>
            </div>
        </div>
    `;

  return element;
}

function createStudentReportPage(
  name: string,
  surname: string,
  gmclassroom: string
) {
  const element = document.createElement("div");
  element.className = "page-table-container";
  // element.style.height = "27.7cm";
  element.innerHTML = `
    <table>
        <tr>
            <td colspan="2">Ad</td>
            <td colspan="4">${name || "-"}</td>
            <td colspan="4" rowspan="3" style="text-align: right;"><img src="/logo192.png" width="130px" height="50px" /></td>
        </tr>
        <tr>
            <td colspan="2">Soyad</td>
            <td colspan="4">${surname || "-"}</td>
        </tr>
        <tr>
            <td colspan="2">Sınıf</td>
            <td colspan="4">${gmclassroom || "-"}</td>
        </tr>
        <tr> <td colspan="10" style="text-align: center;"><b>Öğrenci Sınav Değerlendirme Raporu</b></td></tr>
        <tr>
            <td colspan="1">Tarih</td>
            <td colspan="2">Ders</td>
            <td colspan="2">Sınav</td>
            <td colspan="1">Soru</td>
            <td colspan="1">Doğru</td>
            <td colspan="1">Yanlış</td>
            <td colspan="1">Boş</td>
            <td colspan="1">Başarı</td>
        </tr>
    </table>`;

  return element;
}

function createStudentReportPageRow(result: TestResultModel) {
  const element = document.createElement("tr");
  element.id = "report-row";
  element.innerHTML = `
            <td colspan="1">${result.fillDate || "-"}</td>
            <td colspan="2">${result.lessonName || "-"}</td>
            <td colspan="2">${result.testName || "-"}</td>
            <td colspan="1">${result.questionCount || "-"}</td>
            <td colspan="1">${result.correctAnswerCount}</td>
            <td colspan="1">${result.incorrectAnswerCount}</td>
            <td colspan="1">${result.unansweredCount}</td>
            <td colspan="1">% ${Math.round(
              (result.correctAnswerCount / result.questionCount) * 100
            )}</td>
    `;

  return element;
}

function createTeacherReportPage(name: string, surname: string) {
  const element = document.createElement("div");
  element.className = "page-table-container";
  // element.style.height = "27.7cm";
  element.innerHTML = `
    <table>
        <tr>
            <td colspan="2">Ad</td>
            <td colspan="4">${name || "-"}</td>
            <td colspan="4" rowspan="2" style="text-align: right;"><img src="/logo192.png" width="130px" height="50px" /></td>
        </tr>
        <tr>
            <td colspan="2">Soyad</td>
            <td colspan="4">${surname || "-"}</td>
        </tr>
        <tr> <td colspan="10" style="text-align: center;"><b>Öğretmen Değerlendirme Raporu</b></td></tr>
        <tr>
            <td colspan="3">Ders</td>
            <td colspan="2">Sınıfı</td>
            <td colspan="4">Öğrenci</td>
            <td colspan="1">Başarı</td>
        </tr>
    </table>`;

  return element;
}

function createTeacherReportPageRow(
  lessonName: string,
  result: LessonStudentSuccessModel
) {
  const element = document.createElement("tr");
  element.id = "report-row";
  element.innerHTML = `
            <td colspan="3">${lessonName || "-"}</td>
            <td colspan="2">${result.classRoom || "-"}</td>
            <td colspan="4">${result.studentName || "-"}</td>
            <td colspan="1">${result.lessonOverallSuccessRate}</td>
    `;

  return element;
}
