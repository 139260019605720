import React, { Children, useEffect, useState } from "react";
import SurveyService from "../../services/surveyService";
import Datatable from "../sharedComponents/datatable";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";

export default function SurveyResultPage() {
  const dispatch = useDispatch();
  const [surveyList, setSurveyList] = useState<Array<SurveyOutputModel>>([]);
  const [selectedSurveyResultId, setSelectedSurveyResultId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const surveyService = new SurveyService();

  const getSurveyList = async () => {
    dispatch(showLoader());
    const result = await surveyService.GetSurveyList();
    if (result != null) setSurveyList(result);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getSurveyList();
  }, []);

  const datatableModel: DatatableDataModel = {
    header: [
      "Id",
      "Oluşturma Tarihi",
      "Cevaplanmış Anket Sayısı",
      "Anket Sonuçları",
    ],
    list: surveyList.map((s) => ({
      id: s.id,
      row: [
        s.id.toString(),
        s.issueDate,
        s.surveyResults ? s.surveyResults.length.toString() : (0).toString(),
      ],
      extra: (s.surveyResults || []).map((sr) => ({
        id: s.id + ":" + sr.student.id + ":" + (sr.teacher || { id: "" }).id,
        value:
          "Öğrenci: " +
          ((sr.student || {}).name || "") +
          ", Öğretmen: " +
          ((sr.teacher || {}).name || ""),
      })),
    })),
  };

  useEffect(() => {
    selectedSurveyResultId && setShowModal(true);
  }, [selectedSurveyResultId]);

  const surveyResultsSelectBox = (props: { children: React.ReactNode }) => (
    <select
      className="form-select"
      value={selectedSurveyResultId}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        setSelectedSurveyResultId(e.target.value)
      }
    >
      <option disabled value="">
        Seçiniz
      </option>
      {props.children}
    </select>
  );

  const closeModal = () => {
    setShowModal(false);
    setSelectedSurveyResultId("");
  };

  let selectedSurveyId: number = null;
  let selectedStudentId: string = null;
  let selectedTeacherId: string = null;
  let selectedTeacherName = "";
  let studentParentName = "";
  const idTempArray = selectedSurveyResultId.split(":");
  if (idTempArray.length > 0) selectedSurveyId = Number(idTempArray[0]);
  if (idTempArray.length > 1) selectedStudentId = idTempArray[1];
  if (idTempArray.length > 2) selectedTeacherId = idTempArray[2];

  const selectedSurvey: SurveyOutputModel = showModal
    ? surveyList.find((s) => s.id == selectedSurveyId)
    : null;
  const selectedSurveyResult: SurveyResultOuputModel = selectedSurvey
    ? selectedSurvey.surveyResults.find(
        (sr) =>
          sr.student.id == selectedStudentId &&
          (sr.teacher || {}).id == selectedTeacherId
      )
    : null;
  let question1Answer = "";
  let question2Answer = "";
  let question3Answer = "";
  let question4Answer = "";
  if (selectedSurveyResult) {
    selectedTeacherName = selectedSurveyResult.teacher
      ? selectedSurveyResult.teacher.name +
        " " +
        selectedSurveyResult.teacher.surname
      : "";
    studentParentName = selectedSurveyResult.student
      ? selectedSurveyResult.student.name +
        " " +
        selectedSurveyResult.student.surname +
        " - " +
        selectedSurveyResult.student.parentName
      : "";
    selectedSurveyResult.surveyQuestionResults.forEach((sqr) => {
      if (sqr.questionId == selectedSurvey.surveyQuestions[0].id)
        question1Answer = sqr.value;
      if (sqr.questionId == selectedSurvey.surveyQuestions[1].id)
        question2Answer = sqr.value;
      if (sqr.questionId == selectedSurvey.surveyQuestions[2].id)
        question3Answer = sqr.value;
      if (sqr.questionId == selectedSurvey.surveyQuestions[3].id)
        question4Answer = sqr.value;
    });
  }

  return (
    <>
      <Datatable
        data={datatableModel}
        editable={false}
        insertable={false}
        customRowAction={surveyResultsSelectBox}
      />
      {showModal && (
        <div
          className="modal fade text-left show"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {"Anket Sonucu"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>
                        <b>Öğrenci - Veli</b>
                      </label>
                      <br />
                      {studentParentName || "-"}
                    </div>
                    <div className="form-group">
                      <label>
                        <b>Öğretmen</b>
                      </label>
                      <br />
                      {selectedTeacherName || "-"}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="first-name-vertical">
                        <b>
                          {selectedSurvey &&
                            (selectedSurvey.surveyQuestions || []).length > 0 &&
                            selectedSurvey.surveyQuestions[0].question}
                        </b>
                      </label>
                      <div className="stars">
                        <input
                          className="star star-5"
                          disabled={true}
                          checked={question1Answer == "5"}
                          id="star-15"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-5"
                          htmlFor="star-15"
                        ></label>
                        <input
                          className="star star-4"
                          disabled={true}
                          checked={question1Answer == "4"}
                          id="star-14"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-4"
                          htmlFor="star-14"
                        ></label>
                        <input
                          className="star star-3"
                          disabled={true}
                          checked={question1Answer == "3"}
                          id="star-13"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-3"
                          htmlFor="star-13"
                        ></label>
                        <input
                          className="star star-2"
                          disabled={true}
                          checked={question1Answer == "2"}
                          id="star-12"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-2"
                          htmlFor="star-12"
                        ></label>
                        <input
                          className="star star-1"
                          disabled={true}
                          checked={question1Answer == "1"}
                          id="star-11"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-1"
                          htmlFor="star-11"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="email-id-vertical">
                        <b>
                          {selectedSurvey &&
                            (selectedSurvey.surveyQuestions || []).length > 1 &&
                            selectedSurvey.surveyQuestions[1].question}
                        </b>
                      </label>
                      <div className="stars">
                        <form action="">
                          <input
                            className="star star-5"
                            disabled={true}
                            checked={question2Answer == "5"}
                            id="star-25"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-5"
                            htmlFor="star-25"
                          ></label>
                          <input
                            className="star star-4"
                            disabled={true}
                            checked={question2Answer == "4"}
                            id="star-24"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-4"
                            htmlFor="star-24"
                          ></label>
                          <input
                            className="star star-3"
                            disabled={true}
                            checked={question2Answer == "3"}
                            id="star-23"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-3"
                            htmlFor="star-23"
                          ></label>
                          <input
                            className="star star-2"
                            disabled={true}
                            checked={question2Answer == "2"}
                            id="star-22"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-2"
                            htmlFor="star-22"
                          ></label>
                          <input
                            className="star star-1"
                            disabled={true}
                            checked={question2Answer == "1"}
                            id="star-21"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-1"
                            htmlFor="star-21"
                          ></label>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="contact-info-vertical">
                        <b>
                          {selectedSurvey &&
                            (selectedSurvey.surveyQuestions || []).length > 2 &&
                            selectedSurvey.surveyQuestions[2].question}
                        </b>
                      </label>
                      <div className="stars">
                        <form action="">
                          <input
                            className="star star-5"
                            disabled={true}
                            checked={question3Answer == "5"}
                            id="star-35"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-5"
                            htmlFor="star-35"
                          ></label>
                          <input
                            className="star star-4"
                            disabled={true}
                            checked={question3Answer == "4"}
                            id="star-34"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-4"
                            htmlFor="star-34"
                          ></label>
                          <input
                            className="star star-3"
                            disabled={true}
                            checked={question3Answer == "3"}
                            id="star-33"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-3"
                            htmlFor="star-33"
                          ></label>
                          <input
                            className="star star-2"
                            disabled={true}
                            checked={question3Answer == "2"}
                            id="star-32"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-2"
                            htmlFor="star-32"
                          ></label>
                          <input
                            className="star star-1"
                            disabled={true}
                            checked={question3Answer == "1"}
                            id="star-31"
                            type="radio"
                            name="star"
                          />
                          <label
                            className="star star-1"
                            htmlFor="star-31"
                          ></label>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="#">
                        <b>
                          {selectedSurvey &&
                            (selectedSurvey.surveyQuestions || []).length > 3 &&
                            selectedSurvey.surveyQuestions[3].question}
                        </b>
                      </label>
                      <br />
                      {question4Answer || "-"}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
