import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import BranchesService from "../../services/branchesService";
import BrancheStudentAssignSelect from "../sharedComponents/BrancheStudentAssignSelect";
import StudentService from "../../services/studentService";
import TeacherService from "../../services/teacherService";
import ManagerService from "../../services/managerService";
import BrancheTeacherAssignSelect from "../sharedComponents/BrancheTeacherAssignSelect";
import BrancheManagerAssignSelect from "../sharedComponents/BrancheManagerAssignSelect";
import { useParams } from "react-router-dom";
import Header from "../header";
import Datatable from "../sharedComponents/datatable";

interface UserType {
  usertype: "students" | "teachers" | "managers";
}

export default function BranchePage() {
  const dispatch = useDispatch();
  const { brancheId } = useParams<any>();
  const branchesService = new BranchesService();
  const studentService = new StudentService();
  const teacherService = new TeacherService();
  const managerService = new ManagerService();
  const [trig, setTrig] = useState<boolean>(false);
  const [assignModalVis, setAssignModalVis] = useState<boolean>(false);
  const [assignType, setAssignType] = useState<string>("new");
  const [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);
  const [teacherList, setTeacherList] = useState<Array<TeacherOutputModel>>([]);
  const [managerList, setManagerList] = useState<any>([]);
  const [branche, setBranche] = useState<BrancheOutputModel>();
  const [formAssignStudent, setFormAssignStudent] = useState<
    Array<StudentOutputModel>
  >([]);
  const [formAssignTeacher, setFormAssignTeacher] = useState<
    Array<TeacherOutputModel>
  >([]);
  const [formAssignManager, setFormAssignManager] = useState<any>([]);

  const [formAssignUpdateStudent, setFormAssignUpdateStudent] = useState<
    Array<StudentOutputModel>
  >([]);
  const [formAssignUpdateTeacher, setFormAssignUpdateTeacher] = useState<
    Array<TeacherOutputModel>
  >([]);
  const [formAssignUpdateManager, setFormAssignUpdateManager] = useState<any>(
    []
  );

  const [searchText, setSearchText] = useState("");
  const [filteredManager, setFilteredManager] = useState([]);
  const [filteredTeacher, setFilteredTeacher] = useState([]);
  const [filteredStudent, setFilteredStudent] = useState([]);

  const getStudentList = async () => {
    dispatch(showLoader());
    const response = await studentService.GetStudentList();
    setStudentList(response);
    dispatch(hideLoader());
  };

  const getTeacherList = async () => {
    dispatch(showLoader());
    const response = await teacherService.GetTeacherList();
    setTeacherList(response);
    dispatch(hideLoader());
  };

  const getManagerList = async () => {
    dispatch(showLoader());
    const response = await managerService.GetManagerList();
    setManagerList(response);
    dispatch(hideLoader());
  };

  const getBranche = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranche(brancheId);
    setBranche(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getStudentList();
    getTeacherList();
    getManagerList();
    getBranche();
  }, [trig]);

  const handleAssign = async () => {
    dispatch(showLoader());
    let response: any = "";
    if (assignType === "new") {
      const data = {
        users: [
          ...formAssignManager,
          ...formAssignTeacher,
          ...formAssignStudent,
        ],
      };
      response = await branchesService.BrancheAddUser(brancheId, data);
    } else if (assignType === "update") {
      const data = {
        users: [
          ...formAssignUpdateManager,
          ...formAssignUpdateTeacher,
          ...formAssignUpdateStudent,
        ],
      };
      response = await branchesService.BrancheDeleteUser(brancheId, data);
    }

    if (response) {
      setTrig((trig) => !trig);
      setAssignModalVis(false);
    }
    dispatch(hideLoader());
  };

  // const filterFunc = (userType: "students" | "teachers" | "managers") => {
  //   return branche?.[userType].filter((e) => {
  //     const queryText = searchText.toLocaleLowerCase();
  //     const name = (e.name || "").toLocaleLowerCase();
  //     const surname = (e.surname || "").toLocaleLowerCase();
  //     return name.includes(queryText) || surname.includes(queryText);
  //   });
  // };

  const dataTableModel = (userType: "students" | "teachers" | "managers") => {
    let headers = [];
    let lists = [];
    switch (userType) {
      case "students":
        headers = ["Öğrenci Ad Soyad"];
        lists = (branche?.students || []).map((s, index) => ({
          id: index,
          row: [`${s?.name} ${s?.surname}`],
        }));
        break;
      case "teachers":
        headers = ["Öğretmen Ad Soyad", "Öğretmen Branş"];
        lists = (branche?.teachers || []).map((s, index) => ({
          id: index,
          row: [
            `${s?.name} ${s?.surname}`,
            `${
              s?.majors[0]?.lessonName === undefined
                ? "Branş Atanmamış"
                : s?.majors[0]?.lessonName
            }`,
          ],
        }));
        break;
      case "managers":
        headers = ["Yönetici Ad Soyad"];
        lists = (branche?.managers || []).map((s, index) => ({
          id: index,
          row: [`${s?.name} ${s?.surname}`],
        }));
        break;

      default:
        break;
    }

    let mappedData: DatatableDataModel = {
      header: headers,
      list: lists,
    };
    return mappedData;
  };

  useEffect(() => {
    const filteredStudentArr = studentList.filter((student) => {
      if (student.branch === null) {
        return !branche?.students.some((item) => {
          return student.id === item.id;
        });
      }
    });

    const filteredTeacherArr = teacherList.filter((teacher) => {
      if (teacher.branch === null) {
        return !branche?.teachers.some((item) => {
          return teacher.id === item.id;
        });
      }
    });

    const filteredManagerArr = managerList.filter((manager) => {
      return !branche?.managers.some((item) => {
        return manager.id === item.id;
      });
    });
    setFilteredManager(filteredManagerArr);
    setFilteredTeacher(filteredTeacherArr);
    setFilteredStudent(filteredStudentArr);
  }, [branche, studentList, teacherList, managerList]);

  // const customDataTableStudent = [
  //   {
  //     // accessorFn: (row) => `${row.note.slice(0, 20)} .......`,
  //     accessorKey: "task",
  //     header: "Görev Tanımı",
  //   },
  //   {
  //     accessorKey: "officer",
  //     header: "Yetkili",
  //   },
  //   {
  //     // accessorFn: (row) => `${dayjs(row.issueDate).format("DD.MM.YYYY")}`,
  //     accessorKey: "lastDate",
  //     header: "Bitiş Tarihi",
  //   },
  // ];

  // const data: typeof tasks = tasks;

  return (
    <>
      <Header name={`${branche?.name || ""} Şubesi`} />
      <div className="page-content">
        <div className="row">
          <div className="col-10 d-flex justify-content-center align-items-center ">
            <input
              type="text"
              placeholder="Kullanıcı Ara"
              className="form-control"
              value={searchText}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchText(e.target.value)
              }
              style={{ marginBottom: "25px" }}
            />
          </div>
          <div className="col-2 pt-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                setAssignType("new");
                setAssignModalVis(true);
              }}
            >
              Kullanıcı Atama
            </button>
          </div>
          <div className="col-lg-4">
            <Datatable
              editable={false}
              insertable={false}
              data={dataTableModel("managers")}
            />
          </div>
          <div className="col-lg-4">
            <Datatable
              editable={false}
              insertable={false}
              data={dataTableModel("teachers")}
            />
          </div>
          <div className="col-lg-4">
            <Datatable
              editable={false}
              insertable={false}
              data={dataTableModel("students")}
            />
          </div>
        </div>
      </div>
      {assignModalVis ? (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Şubeye Kullanıcı Ataması - ${branche?.name || ""} `}
                </h4>
              </div>
              <div className="modal-body">
                <>
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => {
                          setAssignType("new");
                        }}
                      >
                        Yeni Ata
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => {
                          setAssignType("update");
                        }}
                      >
                        Atamayı Kaldır
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    ></div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    ></div>
                    <div>
                      <div className="col-12 p-2">
                        <label>Yönetici: </label>
                        <BrancheManagerAssignSelect
                          setSelected={
                            assignType === "new"
                              ? setFormAssignManager
                              : setFormAssignUpdateManager
                          }
                          list={
                            assignType === "new"
                              ? filteredManager
                              : branche?.managers
                          }
                        />
                      </div>
                      <div className="col-12 p-2">
                        <label>Öğretmen: </label>
                        <BrancheTeacherAssignSelect
                          setSelected={
                            assignType === "new"
                              ? setFormAssignTeacher
                              : setFormAssignUpdateTeacher
                          }
                          list={
                            assignType === "new"
                              ? filteredTeacher
                              : branche?.teachers
                          }
                        />
                      </div>
                      <div className="col-12 p-2">
                        <label className="font-weight-bold">Öğrenci:</label>
                        <BrancheStudentAssignSelect
                          setSelected={
                            assignType === "new"
                              ? setFormAssignStudent
                              : setFormAssignUpdateStudent
                          }
                          list={
                            assignType === "new"
                              ? filteredStudent
                              : branche?.students
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAssignModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                {assignType === "update" ? (
                  <button
                    type="button"
                    onClick={handleAssign}
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    disabled={
                      !formAssignUpdateManager.length &&
                      !formAssignUpdateTeacher.length &&
                      !formAssignUpdateStudent.length
                    }
                  >
                    <i className="fas fa-trash"></i>
                    <span className="d-inline p-2">Atamayı Kaldır</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      handleAssign();
                    }}
                    className="btn btn-primary ml-1"
                    data-bs-dismiss="modal"
                    disabled={
                      !formAssignManager.length &&
                      !formAssignTeacher.length &&
                      !formAssignStudent.length
                    }
                  >
                    <i className="fas fa-check-circle"></i>
                    <span className="d-inline p-2">Kaydet</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
