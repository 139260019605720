//@ts-nocheck
import { useContext } from "react";
import Header from "../header";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import CheckBookService from "../../services/checkBookService";
import AdvancedTable from "../adminPanelComponents/AdvancedTable";
import { StudentIdentityContext } from "../panels/studentPanel";

export default function StudentCheckBookPage() {
  const dispatch = useDispatch();
  const checkBookService = new CheckBookService();
  const studentContext = useContext(StudentIdentityContext);

  const lessonName = `${studentContext?.subclassroom?.classroom?.name}/${studentContext?.subclassroom?.name}`;
  const [checkBookData, setCheckBookData] = useState<
    GetCheckBookForStudentOutputModel[]
  >([]);

  const getCheckBook = async () => {
    dispatch(showLoader());
    const response = await checkBookService.GetCheckBookForStudent();
    setCheckBookData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getCheckBook();
  }, []);

  const tableData = checkBookData?.map((item, index) => {
    const lesson = item.teacher?.majors
      .map((item) => item.lessonName)
      .join(", ");
    return {
      issueDate: dayjs(item.issueDate).format("DD-MM-YYYY"),
      check: item.check ? "Var" : "Yok",
      lesson,
      id: index,
    };
  });

  const customDataTable = [
    {
      accessorKey: "lesson",
      header: "Ders",
      maxSize: 100,
    },
    {
      accessorKey: "issueDate",
      header: "Tarih",
      maxSize: 100,
    },
    {
      accessorKey: "check",
      header: "Yoklama",
      maxSize: 100,
    },
  ];

  return (
    <div className="page-content">
      <Header name={`Yoklama Defteri - ${lessonName}`} />
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="row  d-flex justify-content-end align-items-center"></div>
            <AdvancedTable
              data={tableData}
              dataTable={customDataTable}
              filters={false}
              //   detailsButton={(row) => {
              //     setSelectedId(row.id);
              //   }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
