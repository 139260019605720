// @ts-nocheck
import React, { useEffect, useState } from "react";
import RoleService from "../../services/roleService";
import TestService from "../../services/testService";
import ExamResultUpload from "../adminPanelComponents/examResultUpload";
import ConsentModal from "../consentModal";
import LessonService from "../../services/lessonService";
import dayjs from "dayjs";
import TestPdf from "../sharedComponents/testpdf";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import PdfViewerStatic from "./PDFViewerStatic";
import AdvancedTable from "../adminPanelComponents/AdvancedTable";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconBookUpload, IconPdf } from "@tabler/icons-react";

export default function StudentTestResultUpload() {
  const dispatch = useDispatch();
  const testService = new TestService();
  const lessonService = new LessonService();
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [assigmentList, setAssignmentList] = useState<
    Array<TestAssigmentOutputModel>
  >([]);
  const [srcDoc, setSrcDoc] = useState(null);
  const [selectedTest, setSelectedTest] = useState<TestOutputModel>(null);
  const [answerModalVis, setAnswerModalVis] = useState<boolean>(false);
  const [pdf, setPdf] = useState(null);
  const [idAfterFirstUpload, setIdAfterFirstUpload] = useState<number>(null);
  const [testAssignmentId, setTestAssignmentId] = useState<number>(null);
  const [testTime, setTestTime] = useState<TestStartTimeOutputModel>({
    testStartTime: "",
    testEndDate: "",
    duration: null,
    testResult: null,
  });
  const [trig, setTrig] = useState<boolean>(false);
  const [timesUp, setTimesUp] = useState<boolean>(false);
  const getUserRoles = async () => {
    const roleService = new RoleService();
    const userRoles = await roleService.GetUserRoles();
    setUserRoles(userRoles);
  };

  const getTestAssigmentList = async () => {
    dispatch(showLoader());
    const response = await lessonService.GetTestAssigment();
    setAssignmentList(response);
    dispatch(hideLoader());
  };

  const createPdfOnClickHandler = async (id: number) => {
    dispatch(showLoader());
    const html = await TestPdf(id);
    setSrcDoc(html);
    dispatch(hideLoader());
  };

  const handleTestResult = async (id: number) => {
    dispatch(showLoader());
    const selected = assigmentList.find((item) => item.id === id);
    const response = await testService.GetTestDetails(selected.testId);
    const html = await TestPdf(selected.testId, false);
    setSelectedTest(response);
    setPdf(html);
    setTestAssignmentId(selected.id);
    const responseStartTime = await lessonService.TestStartTime(selected.id);
    setTestTime(responseStartTime);
    if (responseStartTime.testResult === null) {
      const data = response?.testQuestions.map((item) => ({
        value: "",
        questionId: item.id,
      }));
      const responseId = await testService.TestFirstUpload({
        questionResults: data,
        testId: response?.id,
        testAssignmentId: selected.id,
      });
      setIdAfterFirstUpload(responseId);
    } else {
      setIdAfterFirstUpload(responseStartTime.testResult.id);
    }

    setAnswerModalVis(true);
    dispatch(hideLoader());
  };

  const onSubmitAnswerUpload = async (answer) => {
    let questionResults = [];
    answer.map(
      (item, index) =>
        (questionResults = [
          ...questionResults,
          {
            value: item?.value === undefined ? "" : item.value,
            questionId: selectedTest.testQuestions[index].id,
          },
        ])
    );

    const response = await testService.UploadTestResult({
      questionResults,
      testId: selectedTest.id,
      id: idAfterFirstUpload,
      testAssignmentId,
    });
    if (response) {
      setTrig((trig) => !trig);
      setAnswerModalVis(false);
    }
  };

  useEffect(() => {
    getUserRoles();
    getTestAssigmentList();
  }, [trig]);

  // <td>{dayjs(l.startDate).format("DD/MM/YYYY")}</td>
  // <td>{dayjs(l.endDate).format("DD/MM/YYYY")}</td>

  const customDataTable = [
    {
      accessorKey: "test.className",
      header: "Sınıf",
      maxSize: 100,
    },
    {
      accessorKey: "test.lessonName",
      header: "Ders Adı",
      size: 40,
    },
    {
      accessorKey: "test.name",
      header: "Test Adı",
      maxSize: 150,
    },
    {
      accessorKey: "duration",
      header: "Test Süresi",
      size: 40,
    },
    {
      accessorKey: "test.questionCount",
      header: "Soru Sayısı",
      size: 40,
    },
    {
      accessorFn: (row) => `${dayjs(row.startDate).format("DD/MM/YYYY")}`,
      accessorKey: "startDate",
      header: "Başlangıç Tarihi",
      size: 40,
    },
    {
      accessorFn: (row) => `${dayjs(row.endDate).format("DD/MM/YYYY")}`,
      accessorKey: "endDate",
      header: "Bitiş Tarihi",
      size: 40,
    },
  ];

  const data: TestAssigmentOutputModel[] = assigmentList;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <AdvancedTable
            data={data}
            dataTable={customDataTable}
            filters={false}
            extraButtons={(row) => {
              return (
                <>
                  <Tooltip label="İndir">
                    <ActionIcon
                      color="green"
                      onClick={() => createPdfOnClickHandler(row.testId)}
                    >
                      <IconPdf />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Sonuç Yükle">
                    <ActionIcon
                      color="indigo"
                      onClick={() => {
                        if (dayjs().isAfter(dayjs(row.endDate))) {
                          setTimesUp(true);
                        } else {
                          setTimesUp(false);
                          handleTestResult(row.id);
                        }
                      }}
                    >
                      <IconBookUpload />
                    </ActionIcon>
                  </Tooltip>
                </>
              );
            }}
          />
        </div>
      </div>

      <iframe style={{ visibility: "hidden" }} srcDoc={srcDoc}></iframe>

      {timesUp && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Uyarı`}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    // onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 p-2">
                    <label className="font-weight-bold">
                      Test Süresi Geçmiş !
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setTimesUp(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedTest && !timesUp && (
        <PdfViewerStatic
          visible={answerModalVis}
          onCloseClick={() => setAnswerModalVis(false)}
          onSubmit={onSubmitAnswerUpload}
          pdf={pdf}
          questionCount={selectedTest.testQuestions}
          // lastResult={selectedTest.testResults}
          time={testTime}
          testId={selectedTest.id}
        />
      )}
    </>
  );
}
