import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useScript from "../../hooks/useScript";
import {
  hideLoader,
  hideTeacherCreateOrUpdateModal,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import TeacherService from "../../services/teacherService";
import { RootState } from "../../store";
import Modal from "../sharedComponents/modal";
import Choices from "choices.js";
import {
  filterNonNumerics,
  validateEmail,
} from "../../extensions/commonFunctions";

var choices: Choices;

export function TeacherCreateOrEditModal(props: {
  listUpdated: () => void;
  editData?: TeacherOutputModel;
  majorList: Array<GenericLessonModel>;
}) {
  const showTeacherCreateOrUpdateModal = useSelector(
    (state: RootState) => state.commons.showTeacherCreateOrUpdateModal
  );
  const ref = useRef(null);
  const [createMode, setCreateMode] = useState(false);

  const dispatch = useDispatch();

  const teacherService = new TeacherService();
  const formSubmitHandler = async () => {
    dispatch(hideTeacherCreateOrUpdateModal());
    dispatch(showLoader());

    var result = await teacherService.AddOrUpdateTeacher({
      id: teacherId,
      name: formTeacherName,
      surname: formTeacherSurname,
      telephone: formTeacherTel,
      email: formTeacherEmail,
      majors: formTeacherMajors,
      profilePhoto: teacherProfilePhoto,
      password: formTeacherPassword,
    });

    dispatch(hideLoader());

    if (result != null) {
      props.listUpdated();
    } else {
      let message = teacherId
        ? "Güncelleme işlemi başarısız"
        : "Kayıt işlemi başarısız";
      // dispatch(sendMessage({ title: "İşlem Sonucu", message: message }));
    }
  };

  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormTeacherMajors(
      Array.from(event.target.selectedOptions, (option) => Number(option.value))
    );
  };

  // form data
  let [teacherId, setTeacherId] = useState("");
  let [formTeacherName, setFormTeacherName] = useState("");
  let [formTeacherSurname, setFormTeacherSurname] = useState("");
  let [formTeacherTel, setFormTeacherTel] = useState("");
  let [formTeacherEmail, setFormTeacherEmail] = useState("");
  const [formTeacherPassword, setFormTeacherPassword] = useState("");
  let [formTeacherMajors, setFormTeacherMajors] = useState<Array<number>>([]);
  const [teacherProfilePhoto, setTeacherProfilePhoto] = useState("");
  useEffect(() => {
    if (!props.editData) return;

    var teacherMajors = props.editData.majors.map((m) => m.id);
    setTeacherId(props.editData.id);
    setFormTeacherName(props.editData.name);
    setFormTeacherSurname(props.editData.surname);
    setFormTeacherTel(props.editData.telephone);
    setFormTeacherEmail(props.editData.email || "");
    setFormTeacherMajors(teacherMajors);
    setTeacherProfilePhoto(props.editData.profilePhoto);
    setFormTeacherPassword("");
    setCreateMode(!props.editData.id);
    choices && choices.destroy();
    choices = new Choices(ref.current, {
      itemSelectText: "Seçmek için tıklayınız",
      removeItemButton: true,
      choices: props.majorList.map((m) => ({
        value: m.id.toString(),
        label: m.lessonName,
        selected: teacherMajors.includes(m.id),
      })),
    });
  }, [props.editData]);

  const openFileUpload = () => {
    (document.querySelector("#profile-photo-input") as HTMLInputElement).value =
      "";
    (
      document.querySelector("#profile-photo-input") as HTMLInputElement
    ).click();
  };

  const profileImageOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setTeacherProfilePhoto(reader.result as string);
      //(document.querySelector("#preview-profile-img") as HTMLImageElement).src = reader.result as string;
    };

    reader.readAsDataURL(selectedFile);
  };

  const isEmailValid = validateEmail(formTeacherEmail);
  const isPasswordLengthValid = formTeacherPassword.length >= 5;
  const isPasswordFormatValid = !!formTeacherPassword.match(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/
  );

  const filterPhoneInput = (value: string) => {
    const matches = (value || "").match(/^[0-9]*$/);
    if (matches) setFormTeacherTel(matches[0]);
  };

  const checPasswordValidity = () => {
    if (createMode) {
      return isPasswordFormatValid && isPasswordLengthValid;
    } else
      return (
        !formTeacherPassword || (isPasswordFormatValid && isPasswordLengthValid)
      );
  };

  return (
    <div
      className="modal fade text-left show"
      id="yeniOgrenciEkleModal"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: showTeacherCreateOrUpdateModal ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {"Öğretmen Formu"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                data-feather="x"
                onClick={() => dispatch(hideTeacherCreateOrUpdateModal())}
              ></i>
            </button>
          </div>
          <div className="modal-body">
            <form action="#">
              <div className="row justify-content-center">
                <div className="col-6">
                  <div className="card-profile-image">
                    <div
                      className="pic-holder"
                      onClick={openFileUpload}
                      style={{ width: "97%", height: "215px" }}
                    >
                      <a href="#">
                        <img
                          id="preview-profile-img"
                          src={
                            teacherProfilePhoto ||
                            "/assets/img/Profile_avatar_placeholder_large.png"
                          }
                          height="225"
                          style={{ width: "100%", objectFit: "contain" }}
                          className="profile-teacher-photo rounded-circle"
                        />
                      </a>
                      <input
                        id="profile-photo-input"
                        type="file"
                        onChange={profileImageOnChangeHandler}
                        hidden
                      />
                      <label className="upload-file-block">
                        <div className="text-center">
                          <div className="mb-2">
                            <i className="fa fa-camera fa-2x"></i>
                          </div>
                          <div className="text-uppercase">
                            Fotoğraf <br /> Ekle
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label>Öğretment Adı: </label>
                  <input
                    type="text"
                    placeholder="Ad"
                    value={formTeacherName}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormTeacherName(e.target.value)
                    }
                    className={
                      "form-control" + (formTeacherName ? "" : " is-invalid")
                    }
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Öğretmen Soyadı: </label>
                  <input
                    type="text"
                    placeholder="Soyad"
                    value={formTeacherSurname}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormTeacherSurname(e.target.value)
                    }
                    className={
                      "form-control" + (formTeacherSurname ? "" : " is-invalid")
                    }
                    required
                  />
                </div>

                <div className="form-group col-12">
                  <label>Telefon: </label>
                  <input
                    type="text"
                    placeholder="Telefon giriniz"
                    value={formTeacherTel}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      filterPhoneInput(e.target.value)
                    }
                    className={
                      "form-control" + (formTeacherTel ? "" : " is-invalid")
                    }
                    required
                  />
                </div>
                <div className="form-group col-12">
                  <label>Email: </label>
                  <input
                    type="text"
                    placeholder="Email giriniz"
                    value={formTeacherEmail}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormTeacherEmail(e.target.value)
                    }
                    className={
                      "form-control" +
                      (formTeacherEmail && isEmailValid ? "" : " is-invalid")
                    }
                    required
                  />
                  <div className="invalid-feedback">
                    <i className="bx bx-radio-circle"></i>
                    {!isEmailValid ? "Email formatında giriş yapınız" : ""}
                  </div>
                </div>
                <div className="form-group col-12">
                  <label>Öğretmen Giriş Şifresi: </label>
                  <input
                    type="text"
                    placeholder="Öğretmen Giriş Şifresi giriniz"
                    value={formTeacherPassword}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormTeacherPassword(e.target.value)
                    }
                    className={
                      "form-control" +
                      (checPasswordValidity() ? "" : " is-invalid")
                    }
                  />
                  <div className="invalid-feedback">
                    <i className="bx bx-radio-circle"></i>
                    {!isPasswordLengthValid
                      ? "Şifre en az 5 karakter uzunluğunda olmalıdır"
                      : !isPasswordFormatValid
                      ? "Şifre küçük, büyük harf ve rakam içermelidir"
                      : ""}
                  </div>
                </div>
                <div className="form-group col-12">
                  <label>Branş: </label>
                  <select
                    className="choices form-select multiple-remove"
                    ref={ref}
                    multiple
                    onChange={selectChangeHandler}
                  ></select>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => dispatch(hideTeacherCreateOrUpdateModal())}
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              type="button"
              disabled={
                !(
                  isEmailValid &&
                  formTeacherName &&
                  formTeacherSurname &&
                  formTeacherTel &&
                  formTeacherEmail &&
                  (createMode
                    ? isPasswordLengthValid && isPasswordFormatValid
                    : true)
                )
              }
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2" onClick={formSubmitHandler}>
                Kaydet
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
