// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import {
  hideLoader,
  showClassroomCreateOrUpdateModal,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import ClassroomService from "../../services/classroomService";
import ConsentModal from "../consentModal";
import Header from "../header";
import ClassroomCreateOrUpdateModal from "./classroomCreateOrUpdateModal";
import ClassStudentsPage from "./classStudentsPage";
import SubClassroomCreateOrUpdateModal from "./SubClassroomCreateOrUpdateModal";
import SubClassroomService from "../../services/subClassroomService";
import RoleService, { Roles } from "../../services/roleService";
import TestService from "../../services/testService";
import ComponentLoader from "../sharedComponents/componentLoader";
import CheckboxTree from "react-checkbox-tree";
import { SubClassroomTestReportPDF } from "../../utils/Pdf";

export default function SubClassroomListPage(props: {
  classroomName: string;
  subClassroomList?: Array<SubClassroomOutputModel>;
  classId: number;
}) {
  const { path, url } = useRouteMatch();
  const subClassrromService = new SubClassroomService();
  const testService = new TestService();
  const roleService = new RoleService();
  const [classroomList, setClassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);
  const dispatch = useDispatch();
  const [createOrUpdateModalVis, setCreateOrUpdateModalVis] =
    useState<boolean>(false);
  const [modalMode, setModalMode] = useState("update");
  const [constModalVisibility, setConstModalVisibility] = useState(false);
  const [selectedClassroom, setSelectedClassroom] =
    useState<ClassroomOutputModel>(null);
  const [trig, setTrig] = useState<boolean>(false);
  const [subClassroomList, setSubClassroomList] = useState<
    SubClassroomOutputModel[]
  >([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedId, setSelectedId] = useState<number>(null);
  const [roles, setRoles] = useState<Array<string>>(null);
  const subClassroomService = new SubClassroomService();
  const classroomService = new ClassroomService();

  const [branchesList, setBranchesList] = useState<Array<BranchesOutputModel>>(
    []
  );
  const [userRoles, setUserRoles] = useState<Array<string>>([]);

  const [selectedSubClassroom, setSelectedSubClassroom] =
    useState<number>(null);
  const [subClassroomTestList, setSubClassroomTestList] = useState([]);
  const [selectedTestExamValues, setSelectedTestExamValues] = useState([]);
  const [expandedTestExam, setExpandedTestExam] = useState([]);
  const [subClassroomTestReportModalVis, setSubClassroomTestReportModalVis] =
    useState<boolean>(false);
  const [reportLoader, setReportLoader] = useState<boolean>(false);

  const getUserRoles = async () => {
    dispatch(showLoader());
    const result = await roleService.GetUserRoles();
    setRoles(result);
    dispatch(hideLoader());
  };

  const getClassroomById = async () => {
    dispatch(showLoader());
    const response = await subClassrromService.GetSubClassroomById(
      props.classId
    );
    setSubClassroomList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getClassroomById();
    getUserRoles();
  }, [trig]);

  const selectClassroomForEdit = (name: string, subClassId: number) => {
    setSelectedId(subClassId);
    setSelectedName(name);
    setCreateOrUpdateModalVis(true);
    setModalMode("update");
  };

  const deleteClassroom = async () => {
    dispatch(showLoader());
    const response = await subClassrromService.DeleteSubClassroom(selectedId);
    response && setTrig((trig) => !trig);
    setConstModalVisibility(false);
    dispatch(hideLoader());
  };

  const showDeleteConsentMessage = (id: number) => {
    setSelectedId(id);
    setConstModalVisibility(true);
  };

  const getSubClassroomTestExamList = async (id) => {
    setReportLoader(true);
    const response = await testService.GetTestListBySubClassroom(id);
    const transformedData = response.map((lessonTests, lessonIndex) => {
      const lessonName = lessonTests.lessonName;
      setExpandedTestExam((expandedTestExam) => [
        ...expandedTestExam,
        lessonName,
      ]);
      const transformedLesson = {
        label: lessonName,
        value: lessonName,
        children: lessonTests.tests.map((test, index) => {
          setSelectedTestExamValues((selectedTestExamValues) => [
            ...selectedTestExamValues,
            `${test.id}`,
          ]);
          return {
            value: `${test.id}`,
            label: `${test.name} - ${test.issueDate}`,
          };
        }),
      };

      return transformedLesson;
    });
    setSubClassroomTestList(transformedData);
    setReportLoader(false);
  };

  const getSubClassroomTestReport = async () => {
    setReportLoader(true);
    let mappedData = [];
    selectedTestExamValues.map((item) => {
      mappedData = [...mappedData, Number(item)];
    });

    const response = await testService.GetTestReportForSubClassroom(
      selectedSubClassroom,
      mappedData
    );
    SubClassroomTestReportPDF(response);
    setReportLoader(false);
  };

  return (
    <Switch>
      <Route path={`${path}/:classId`}>
        <ClassStudentsPage classroomList={classroomList} />
      </Route>
      <Route path={path}>
        {!path.includes("report") && (
          <Header name={`${props.classroomName} Şubeler`} />
        )}
        <>
          <div className="page-content">
            <section className="row">
              <div className="content-page">
                <div className="row staff-grid-row">
                  {(subClassroomList || []).map((l) => (
                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                      <div
                        className={`profile-widget ${
                          path.includes("report") && "pt-5"
                        }`}
                        style={{ borderRadius: "100px" }}
                      >
                        {!path.includes("report") && (
                          <div className="buttons">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                selectClassroomForEdit(l.name, l.id)
                              }
                            >
                              Düzenle
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => showDeleteConsentMessage(l.id)}
                            >
                              Sil
                            </button>
                          </div>
                        )}

                        <div className="profile-img">
                          {path.includes("report") ? (
                            <img
                              onClick={() => {
                                getSubClassroomTestExamList(l.id);
                                setSelectedSubClassroom(l.id);
                                setSubClassroomTestReportModalVis(true);
                              }}
                              className="avatar"
                              src="/assets/img/class.png"
                              alt=""
                            />
                          ) : (
                            <Link
                              to={`/admin/classroom/${props.classId}/subClassroom/${l.id}`}
                            >
                              <img
                                className="avatar"
                                src="/assets/img/class.png"
                                alt=""
                              />
                            </Link>
                          )}
                        </div>
                        <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                          <a>{l.name || "Default"}</a>
                        </h4>
                      </div>
                    </div>
                  ))}
                  {!(roles || []).includes(Roles.Admin) &&
                    !path.includes("report") && (
                      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                        <div
                          className="profile-widget"
                          style={{ borderRadius: "100px" }}
                        >
                          <div
                            className="profile-img"
                            onClick={() => {
                              setSelectedClassroom(null);
                              setCreateOrUpdateModalVis(true);
                              setModalMode("create");
                            }}
                          >
                            <i
                              className="fas fa-plus fa-4x"
                              style={{ opacity: "0.5" }}
                            ></i>
                          </div>
                          <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                            <a data-bs-toggle="modal" data-bs-target="#small">
                              Yeni Şube Ekle
                            </a>
                          </h4>
                          <div className="small text-muted"></div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </section>
          </div>
          {subClassroomTestReportModalVis && (
            <div
              className="modal text-left show"
              id="uploadModal"
              tabIndex={-1}
              aria-labelledby="myModalLabel33"
              style={{
                // zIndex: 1,
                overflow: "auto",
                display: "block",
                backgroundColor: "rgba(0,0,0,0.4)",
              }}
              aria-modal="true"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-dialog"
                role="document"
                style={{
                  maxWidth: "800px",
                  height: "800px",
                }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="myModalLabel33">
                      Sınav Rapor
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i data-feather="x" onClick={() => {}}></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    {reportLoader ? (
                      <ComponentLoader />
                    ) : (
                      <CheckboxTree
                        nativeCheckboxes
                        nodes={subClassroomTestList}
                        checked={selectedTestExamValues}
                        expanded={expandedTestExam}
                        onCheck={setSelectedTestExamValues}
                        onExpand={setExpandedTestExam}
                        showNodeIcon={false}
                      />
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={() => {
                        setSubClassroomTestReportModalVis(false);
                        //   setSelectedExamForReport([]);
                        //   setStudentPracticeExams([]);
                        //   setSelectedTestExamValues([]);
                        setSelectedTestExamValues([]);
                      }}
                      className="btn btn-default rounded pill"
                      data-bs-dismiss="modal"
                    >
                      <i className="fas fa-times-circle"></i>
                      <span className="d-inline p-2">Kapat</span>
                    </button>

                    <button
                      type="button"
                      onClick={(e) => {
                        getSubClassroomTestReport();
                      }}
                      className="btn btn-primary ml-1"
                      data-bs-dismiss="modal"
                      disabled={selectedTestExamValues.length == 0}
                    >
                      <i className="fas fa-check-circle"></i>
                      <span className="d-inline p-2">Rapor Oluştur</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <SubClassroomCreateOrUpdateModal
            trig={trig}
            setTrig={setTrig}
            data={classroomList}
            vis={createOrUpdateModalVis}
            onClose={(vis) => {
              setCreateOrUpdateModalVis(vis);
            }}
            mode={modalMode}
            classId={props.classId}
            name={selectedName}
            subClassId={selectedId}
          />
          <ConsentModal
            visible={constModalVisibility}
            message="Silme işlemini onaylıyor musunuz?"
            title="Uyarı"
            cancelCallback={() => setConstModalVisibility(false)}
            approveCallback={deleteClassroom}
          />
        </>
      </Route>
    </Switch>
  );
}
