// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import ClassroomService from "../../services/classroomService";
import Header from "../header";
import Datatable from "../sharedComponents/datatable";
import SubClassroomListPage from "./SubClassroomListPage";
import SubClassroomService from "../../services/subClassroomService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import TestAssignModal from "../sharedComponents/TestAssignModal";
import StudentService from "../../services/studentService";
import ComponentLoader from "../sharedComponents/componentLoader";
import TeacherService from "../../services/teacherService";
import Select from "react-select";

export default function SubClassRoomPage() {
  const { subClassId, classId } = useParams<any>();
  const dispatch = useDispatch();
  const subClassroomService = new SubClassroomService();
  const studentService = new StudentService();
  const classService = new ClassroomService();
  const teacherService = new TeacherService();
  const [userList, setUserList] =
    useState<StudentSubClassroomOutputModel>(null);
  const [searchText, setSearchText] = useState("");
  const [studentAddModalVis, setStudentAddModalVis] = useState<boolean>(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [allStudentList, setAllStudentList] = useState<
    Array<StudentOutputModel>
  >([]);
  const [teacherList, setTeacherList] = useState<Array<TeacherOutputModel>>([]);
  const [assignType, setAssignType] = useState<"new" | "update">("new");
  const [isAddStudentsTriggered, setIsAddStudentsTriggered] = useState(false);
  const [classroom, setClassroom] = useState<ClassroomOutputModel>({
    students: [],
    name: "",
    lessons: [],
    id: 0,
  });
  const { path } = useRouteMatch();

  const getSubClassroom = async () => {
    dispatch(showLoader());
    const response = await subClassroomService.GetStudentSubClassroomList(
      subClassId
    );
    setUserList(response);

    dispatch(hideLoader());
  };

  const getClassroomById = async () => {
    dispatch(showLoader());
    const response = await classService.GetClassroomById(classId);
    await setClassroom(response[0]);
    dispatch(hideLoader());
  };

  const getAllStudent = async () => {
    dispatch(showLoader());
    const response = await studentService.GetStudentList();
    setAllStudentList(response);
    dispatch(hideLoader());
  };

  const getTecaherList = async () => {
    const response = await teacherService.GetTeacherList();
    setTeacherList(response);
  };

  const handleAddNewStudents = async () => {
    dispatch(showLoader());
    if (assignType === "new") {
      const response = await subClassroomService.SubClassRoomAddUser(
        [
          ...selectedStudents.map((item) => item.value),
          ...selectedTeachers.map((item) => item.value),
        ],
        userList.id
      );
    } else if (assignType === "update") {
      const response = await subClassroomService.DeleteSubClassroomUser(
        [
          ...selectedStudents.map((item) => item.value),
          ...selectedTeachers.map((item) => item.value),
        ],
        userList.id
      );
    }
    setStudentAddModalVis(false);
    setIsAddStudentsTriggered(
      (isAddStudentsTriggered) => !isAddStudentsTriggered
    );
    setSelectedStudents([]);
    setSelectedTeachers([]);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getSubClassroom();
    getAllStudent();
    getClassroomById();
    getTecaherList();
  }, [isAddStudentsTriggered]);

  const filteredStudentList = userList?.students.filter((e) => {
    const queryText = searchText.toLocaleLowerCase();

    const name = (e.name || "").toLocaleLowerCase();
    const surname = (e.surname || "").toLocaleLowerCase();
    // const testName = (e.name || "").toLocaleLowerCase();
    const isPassive = e.isPassive === false;

    return (
      name.includes(queryText) || (surname.includes(queryText) && isPassive)
    );
  });

  const filteredTeacherList = userList?.teachers.filter((e) => {
    const queryText = searchText.toLocaleLowerCase();

    const name = (e.teacher.name || "").toLocaleLowerCase();
    const surname = (e.teacher.surname || "").toLocaleLowerCase();

    return name.includes(queryText) || surname.includes(queryText);
  });

  //   const testId = e?.id.toString();
  //   const name = (e?.name || "").toLocaleLowerCase();
  //   const surname = (e?.surname || "").toLocaleLowerCase();
  //   const parentName = (e?.parentName || "").toLocaleLowerCase();
  //   // const testName = (e.name || "").toLocaleLowerCase();
  //   const isPassive = e?.isPassive === false;

  //   return (
  //     (testId.includes(queryText) ||
  //       name.includes(queryText) ||
  //       surname.includes(queryText) ||
  //       parentName.includes(queryText)) &&
  //     isPassive
  //     // testName.includes(queryText)
  //   );
  // });

  const studentDatatableModel: DatatableDataModel = {
    header: ["Öğrenci Adı Soyadı"],
    list: (filteredStudentList || []).map((s, index) => ({
      id: index,
      row: [s?.name],
    })),
  };

  const tecaherDatatableModel: DatatableDataModel = {
    header: ["Öğretmen Adı Soyadı", "Branş"],
    list: (filteredTeacherList || []).map((s, index) => ({
      id: index,
      row: [
        `${s?.teacher?.name} ${s?.teacher?.surname}`,
        `${s?.teacher.majors[0].lessonName}`,
      ],
    })),
  };

  const studentSelectOptions = (classroom.students || [])
    .filter((student) => !student.isPassive)
    .map((student) => ({
      value: student.id,
      label: student.name,
    }));

  const studentUpdateSelectOptions = (userList?.students || [])
    .filter((student) => !student.isPassive)
    .map((student) => ({
      value: student.id,
      label: student.name,
    }));

  const teacherSelectOptions = (
    teacherList.filter(
      (item) =>
        !userList?.teachers?.some((teacher) => teacher.teacher.id === item.id)
    ) || []
  ).map((teacher) => ({
    value: teacher.id,
    label: `${teacher.name} ${teacher.surname}`,
  }));

  const teacherUpdateSelectOptions = (userList?.teachers || []).map(
    (teacher) => ({
      value: teacher.teacher.id,
      label: `${teacher.teacher.name} ${teacher.teacher.surname}`,
    })
  );

  return (
    <>
      <Header
        name={`${userList?.name} Şubesi (${userList?.students.length} Öğrenci)`}
      />
      <div className="form-group row">
        <div
          className={`${
            path.includes("branchesClassrooms") ? "col-12" : "col-9"
          } d-flex justify-content-center align-items-center`}
        >
          <input
            type="text"
            placeholder="Öğrenci Ara"
            className="form-control"
            value={searchText}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
            style={{ marginBottom: "25px" }}
          />
        </div>
        {!path.includes("branchesClassrooms") && (
          <div className="col-3 d-flex justify-content-center align-items-start pt-1 ">
            <button
              onClick={() => setStudentAddModalVis(true)}
              type="button"
              className="btn btn-primary ml-1 btn-l"
            >
              Öğrenci / Öğretmen Atama
            </button>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-6">
          <Datatable
            editable={false}
            insertable={false}
            data={studentDatatableModel}
          />
        </div>
        <div className="col-6">
          <Datatable
            editable={false}
            insertable={false}
            data={tecaherDatatableModel}
          />
        </div>
      </div>
      {studentAddModalVis ? (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  {`Öğrenci Ekle - ${userList?.name} Şubesi `}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    // onClick={() => setUploadModalVis(false)}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="row p-2">
                    <div className="col-12">
                      <>
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                              onClick={() => {
                                setAssignType("new");
                                setSelectedStudents([]);
                                setSelectedTeachers([]);
                              }}
                            >
                              Yeni Atama
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                              onClick={() => {
                                setAssignType("update");
                                setSelectedStudents([]);
                                setSelectedTeachers([]);
                              }}
                            >
                              Atama Kaldır
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <label className="font-weight-bold">
                            Öğrenciler:
                          </label>
                          <Select
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                              }),
                            }}
                            aria-label="Default select example"
                            options={
                              assignType === "new"
                                ? studentSelectOptions
                                : studentUpdateSelectOptions
                            }
                            value={selectedStudents}
                            onChange={(e) => setSelectedStudents(e)}
                            isMulti
                            closeMenuOnSelect={false}
                          />
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                          <label className="font-weight-bold">
                            Öğretmenler:
                          </label>
                          <Select
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                              }),
                            }}
                            aria-label="Default select example"
                            options={
                              assignType === "new"
                                ? teacherSelectOptions
                                : teacherUpdateSelectOptions
                            }
                            value={selectedTeachers}
                            onChange={(e) => setSelectedTeachers(e)}
                            isMulti
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setStudentAddModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>

                <button
                  type="button"
                  onClick={handleAddNewStudents}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={
                    !selectedTeachers.length && !selectedStudents.length
                  }
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
