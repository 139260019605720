import React, { useContext, useEffect } from "react";
import { StudentIdentityContext } from "../panels/studentPanel";
import SyllabusService from "../../services/syllabusService";
import { useState } from "react";
import dayjs from "dayjs";
import { Button } from "@mantine/core";

export default function SyllabusWidget() {
  const studentContext = useContext(StudentIdentityContext);
  const syllabusService = new SyllabusService();
  const [syllabusData, setSyllabusData] = useState<Array<SyllabusOutputModel>>(
    []
  );
  const [printModalVis, setPrintModalVis] = useState<boolean>(false);

  const getSyllabus = async () => {
    const response = await syllabusService.GetSyllabusProgressForStudentId();
    setSyllabusData(response);
  };

  useEffect(() => {
    getSyllabus();
  }, []);
  const days = [
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
    "Pazar",
  ];
  return (
    <div className="card">
      <div className="card-body px-3 py-4-5">
        <div className="row">
          <h4>Ders Programım</h4>
          {!!syllabusData.length ? (
            <div className="col-md-12">
              <h5 className="font-bold border-bottom ">{`${
                syllabusData[syllabusData.length - 1]?.name
              } - ${dayjs(
                syllabusData[syllabusData.length - 1]?.endDate
              ).format("DD.MM.YYYY")}`}</h5>
              <div className="row">
                <div className="row">
                  {days.map((day) => (
                    <div className="border col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                      <h6 className="border-bottom">{day}</h6>
                      {syllabusData[
                        syllabusData.length - 1
                      ]?.syllabusProgressesGroupedDay.map((schedule) => {
                        return (
                          schedule.day === day &&
                          schedule?.syllabusProgress.map((item) => {
                            return (
                              <>
                                <h6 className="text-muted mb-0">
                                  -{"> "}
                                  {`${item.lesson.lessonName} - ${item.learningOutcome.learningOutcome} (${item.questionCount} Soru)`}
                                </h6>
                              </>
                            );
                          })
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <h5 className="font-bold border-bottom ">
              {"->"} Program Henüz Hazırlanmadı
            </h5>
          )}
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          // window.print();
          setPrintModalVis(true);
        }}
        className="btn btn-default rounded pill"
        data-bs-dismiss="modal"
      >
        <i className="fas fa-check"></i>
        <span className="d-inline p-2">Yazdır</span>
      </button>
      {printModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="card-body px-3 py-4">
                <div className="row">
                  <h4>Ders Programım</h4>
                  {!!syllabusData.length ? (
                    <div className="col-md-12">
                      <h5 className="font-bold border-bottom ">{`${
                        syllabusData[syllabusData.length - 1]?.name
                      } - ${dayjs(
                        syllabusData[syllabusData.length - 1]?.endDate
                      ).format("DD.MM.YYYY")}`}</h5>
                      <div className="row">
                        <div className="row">
                          {days.map((day) => (
                            <div className="border col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 p-3">
                              <h6 className="border-bottom">{day}</h6>
                              {syllabusData[
                                syllabusData.length - 1
                              ]?.syllabusProgressesGroupedDay.map(
                                (schedule) => {
                                  return (
                                    schedule.day === day &&
                                    schedule?.syllabusProgress.map((item) => {
                                      return (
                                        <>
                                          <h6 className="text-muted mb-0">
                                            -{"> "}
                                            {`${item.lesson.lessonName} - ${item.learningOutcome.learningOutcome} (${item.questionCount} Soru)`}
                                          </h6>
                                        </>
                                      );
                                    })
                                  );
                                }
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h5 className="font-bold border-bottom ">
                      {"->"} Program Henüz Hazırlanmadı
                    </h5>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setPrintModalVis(false);
                    window.print();
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-check"></i>
                  <span className="d-inline p-2">Yazdır</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
