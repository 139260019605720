import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideQuestionCreateOrUpdateModal } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import LessonService from "../../services/lessonService";
import { RootState } from "../../store";
import RichTextEditor from "../sharedComponents/richTextEditor";

export default function QuestionCreateOrUpdateModal(props: {
  questionIdentityInfo: QuestionIdentityInfo;
  callback: (insertedQuestion: QuestionOutputModel) => void;
}) {
  const lessonService = new LessonService();
  const dispatch = useDispatch();
  const showQuestionCreateOrUpdateModal = useSelector(
    (state: RootState) => state.commons.showQuestionCreateOrUpdateModal
  );
  const optionKeys = ["A", "B", "C", "D", "E"];
  const [question, setQuestion] = useState<QuestionInputModel>({
    id: 0,
    questionText: "",
    answers: [{ id: 0, key: "A", value: "" }],
    difficultyLevel: 0,
    topicId: 0,
  });
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const onOptionRemoveHandler = () => {
    let newQuestion = { ...question };
    let newAnswers = newQuestion.answers.slice(0, question.answers.length - 1);
    newQuestion.answers = newAnswers;
    setQuestion(newQuestion);
  };
  const onOptionAddHandler = () => {
    if (question.answers.length == 5) {
      //dispatch(sendMessage({ title: "Uyarı", message: "Maksimum 5 şık ekleyebilirsiniz" }));
      return;
    }
    let newQuestion = { ...question };
    let newOptionKey = optionKeys[newQuestion.answers.length];
    let newOptionId =
      (newQuestion.answers[newQuestion.answers.length - 1] || { id: -1 }).id +
      1;
    newQuestion.answers.push({ value: "", id: newOptionId, key: newOptionKey });
    setQuestion(newQuestion);
  };
  const onQuestionInsertHandler = async () => {
    let result = await lessonService.InsertOrUpdateQuestion(question);
    dispatch(hideQuestionCreateOrUpdateModal());

    if (result != null) props.callback(result);
    else
      dispatch(
        sendMessage({ title: "Uyarı", message: "Soru ekleme işlemi başarısız" })
      );
  };
  const onQuestionChangeHandler = (text: string) => {
    question.questionText = text;
    //setSubmitButtonDisabled(!text);
  };
  const onAnswerChangeHandler = (text: string, asnwerId: number) => {
    question.answers.find((a) => a.id == asnwerId).value = text;
  };

  const setQuestionDifficultLevel = (value: number) => {
    const tempQuestion = { ...question };
    tempQuestion.difficultyLevel = value;
    setQuestion(tempQuestion);
  };

  useEffect(() => {
    setQuestion({
      difficultyLevel:
        (props.questionIdentityInfo.question || {}).difficultyLevel || 0,
      questionText: (props.questionIdentityInfo.question || {}).question || "",
      topicId: props.questionIdentityInfo.topicId || 0,
      answers: props.questionIdentityInfo.question
        ? (props.questionIdentityInfo.question.testQuestionAnswers || []).map(
            (a) => ({ id: a.id, key: a.answerKey, value: a.answerValue })
          ) || []
        : [],
      id: (props.questionIdentityInfo.question || {}).id || 0,
    });
  }, [props.questionIdentityInfo]);

  return (
    <div
      className="modal fade text-left show"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        overflow: "auto",
        paddingRight: "16px",
        display: showQuestionCreateOrUpdateModal ? "block" : "none",
      }}
      id="soruEkleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myModalLabel33"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              Yeni Soru Ekle{" "}
            </h4>
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="form-group">
                <label htmlFor="first-name-vertical">Zorluk Seviyesi</label>
                <div className="stars">
                  <input
                    className="star star-5"
                    checked={question.difficultyLevel == 5}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      e.target.checked && setQuestionDifficultLevel(5)
                    }
                    id="star-15"
                    type="radio"
                    name="star"
                  />
                  <label className="star star-5" htmlFor="star-15"></label>
                  <input
                    className="star star-4"
                    checked={question.difficultyLevel == 4}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      e.target.checked && setQuestionDifficultLevel(4)
                    }
                    id="star-14"
                    type="radio"
                    name="star"
                  />
                  <label className="star star-4" htmlFor="star-14"></label>
                  <input
                    className="star star-3"
                    checked={question.difficultyLevel == 3}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      e.target.checked && setQuestionDifficultLevel(3)
                    }
                    id="star-13"
                    type="radio"
                    name="star"
                  />
                  <label className="star star-3" htmlFor="star-13"></label>
                  <input
                    className="star star-2"
                    checked={question.difficultyLevel == 2}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      e.target.checked && setQuestionDifficultLevel(2)
                    }
                    id="star-12"
                    type="radio"
                    name="star"
                  />
                  <label className="star star-2" htmlFor="star-12"></label>
                  <input
                    className="star star-1"
                    checked={question.difficultyLevel == 1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      e.target.checked && setQuestionDifficultLevel(1)
                    }
                    id="star-11"
                    type="radio"
                    name="star"
                  />
                  <label className="star star-1" htmlFor="star-11"></label>
                </div>
              </div>
              <RichTextEditor
                textEditorId="summernote"
                innerHtml={question.questionText}
                onChangeCallback={onQuestionChangeHandler}
              />
              {/* <b>Cevap Şıkları:</b>
                            <div>
                                {question.answers.map(o => (<><b>{o.key})</b><RichTextEditor innerHtml={o.value} onChangeCallback={(text: string) => onAnswerChangeHandler(text, o.id)} key={o.id} placeholder="Bu alana cevap şıkkını ekleyebilirsiniz" textEditorId={`summernote${o.id}`} editorHeight={80} /></>))}
                            </div>
                            <div className="buttons mt-2">
                                <button onClick={onOptionAddHandler} className="btn btn-success rounded pill">Şık Ekle</button>
                                <button onClick={onOptionRemoveHandler} className="btn btn-danger rounded pill">Şık Sil</button>
                            </div> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-default rounded pill"
              onClick={() => dispatch(hideQuestionCreateOrUpdateModal())}
            >
              Kapat
            </button>
            <button
              disabled={false}
              className="btn btn-info rounded pill"
              onClick={onQuestionInsertHandler}
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
