import "../style/examresultupload.css";

import React, { useEffect, useState } from "react";
import TestService from "../../services/testService";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../reducers/messengerSlice";
import { hideLoader, showLoader } from "../../reducers/commonSlice";

enum InputType {
  radioButtonList = "R",
  textInput = "T",
}

export default function ExamAnswerKeyUpload(props: {
  examId: number;
  onClose: () => void;
  show: boolean;
  data: TestOutputModel;
  callback: () => Promise<void>;
}) {
  const [questionList, setQuestionList] = useState<
    Array<ExamResultQuestionModel>
  >([]);
  const dispatch = useDispatch();
  const testService = new TestService();
  const radioOptions = ["A", "B", "C", "D", "E"];

  useEffect(() => {
    setQuestionList(
      props.data.testQuestions.map((v) => ({
        ...v,
        inputType:
          radioOptions.includes(v.correctAnswer) || !v.correctAnswer
            ? InputType.radioButtonList
            : InputType.textInput,
      }))
    );
  }, [props]);

  const swapInput = (index: number) => {
    const tempQuestionList = [...questionList];
    const q = tempQuestionList[index];
    if (q.inputType == InputType.radioButtonList)
      q.inputType = InputType.textInput;
    else q.inputType = InputType.radioButtonList;

    setQuestionList(tempQuestionList);
  };

  const selectQuestionAnswer = (questionId: number, answer: string) => {
    const tempQuestionList = [...questionList];
    const questionToUpdate = tempQuestionList.find((q) => q.id == questionId);
    questionToUpdate.correctAnswer = answer;
    setQuestionList(tempQuestionList);
  };

  const uploadAnswerKey = async () => {
    dispatch(showLoader());
    const result = await testService.UploadAnswerKey(
      questionList.map((q) => ({ correctAnswer: q.correctAnswer, id: q.id }))
    );
    props.onClose();
    if (result) {
      await props.callback();
    } else
      dispatch(
        sendMessage({ title: "İşlem Sonucu", message: "İşlem Başarısız" })
      );
    dispatch(hideLoader());
  };

  return (
    <div
      className="modal fade text-left show"
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        overflow: "auto",
        paddingRight: "16px",
        display: "block",
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel17">
              Sonuç Yükle
            </h4>
            <button
              onClick={props.onClose}
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              {questionList.map((item, index) => (
                <div className="col-md-6 pb-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{index + 1}.</span>
                    </div>
                    <div
                      style={{
                        padding: "0 10px 0 10px",
                      }}
                    >
                      <div
                        style={{
                          display:
                            item.inputType == InputType.radioButtonList
                              ? "flex"
                              : "none",
                          justifyContent: "space-between",
                        }}
                        className="resultRadio"
                      >
                        <input
                          type="radio"
                          id={`A-${index}`}
                          name={`skills-${index}`}
                          value="A"
                          checked={item.correctAnswer == "A"}
                          onChange={() => selectQuestionAnswer(item.id, "A")}
                        />
                        <label htmlFor={`A-${index}`}>A</label>
                        <input
                          type="radio"
                          id={`B-${index}`}
                          name={`skills-${index}`}
                          value="B"
                          checked={item.correctAnswer == "B"}
                          onChange={() => selectQuestionAnswer(item.id, "B")}
                        />
                        <label htmlFor={`B-${index}`}>B</label>
                        <input
                          type="radio"
                          id={`C-${index}`}
                          name={`skills-${index}`}
                          value="C"
                          checked={item.correctAnswer == "C"}
                          onChange={() => selectQuestionAnswer(item.id, "C")}
                        />
                        <label htmlFor={`C-${index}`}>C</label>
                        <input
                          type="radio"
                          id={`D-${index}`}
                          name={`skills-${index}`}
                          value="D"
                          checked={item.correctAnswer == "D"}
                          onChange={() => selectQuestionAnswer(item.id, "D")}
                        />
                        <label htmlFor={`D-${index}`}>D</label>
                        <input
                          type="radio"
                          id={`E-${index}`}
                          name={`skills-${index}`}
                          value="E"
                          checked={item.correctAnswer == "E"}
                          onChange={() => selectQuestionAnswer(item.id, "E")}
                        />
                        <label htmlFor={`E-${index}`}>E</label>
                      </div>
                      <div
                        style={{
                          display:
                            item.inputType == InputType.textInput
                              ? "block"
                              : "none",
                        }}
                      >
                        <input
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            selectQuestionAnswer(item.id, e.target.value)
                          }
                          value={
                            radioOptions.includes(item.correctAnswer)
                              ? ""
                              : item.correctAnswer
                          }
                          className="form-control"
                          name={`skills-${index}`}
                        />
                      </div>
                    </div>

                    <div className="input-group-append">
                      <i
                        onClick={() => swapInput(index)}
                        style={{ cursor: "pointer" }}
                        className="fas fa-sync-alt"
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={props.onClose}
              type="button"
              className="btn btn-default rounded pill"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              type="button"
              className="btn btn-primary ml-1"
              onClick={uploadAnswerKey}
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Yükle</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
