import React, { useContext, useEffect } from "react";
import { StudentIdentityContext } from "../panels/studentPanel";
import ProfileWidget from "../profileWidget";
import StudentBasicInfo from "./studentBasicInfo";
import StudentService from "../../services/studentService";
import { Link } from "react-router-dom";

export default function FailedQuestions() {
  const studentContext = useContext(StudentIdentityContext);
  const privateLessons = studentContext?.subclassroom?.teachers.map(
    (item) => item.teacher.majors
  );

  const subClassLessons = studentContext?.teachers?.map((item) =>
    item.majors.map((major) => {
      return major;
    })
  );

  const mergedLessons = [
    ...(subClassLessons || []).flat(),
    ...(privateLessons || []).flat(),
  ];

  const uniqueKeys = new Set(
    mergedLessons.map((item) => `${item.id}_${item.lessonName}`)
  );

  const uniqueLessons = Array.from(uniqueKeys).map((key) => {
    const [id, lessonName] = key.split("_");
    return { id: parseInt(id), lessonName, lessons: [] };
  });

  return (
    <div className="page-content">
      <div className="row staff-grid-row">
        {uniqueLessons.map((l) => (
          <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
            <div
              className="profile-widget pt-4"
              style={{ borderRadius: "100px" }}
            >
              <div className="profile-img">
                <Link to={`/student/failedQuestions/${l.id}`}>
                  <img className="avatar" src="/assets/img/class.png" alt="" />
                </Link>
              </div>
              <h4 className="user-name m-t-10 m-b-0 text-ellipsis pt-3">
                <a>{l.lessonName || "Default"}</a>
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
