// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import useAuthenticate from "../../hooks/useAuthenticate";
import AccountService from "../../services/accountService";
import { RootState } from "../../store";
import Header from "../header";
import Loader from "../loader";
import Preloader from "../sharedComponents/preloader";
import UserPanelFooter from "../sharedComponents/userPanelFooter";
import StudentSideBar from "../sideBars/studentSideBar";
import ParentSurvey from "../studentPanelComponents/parentSurvey";
import StudentLesson from "../studentPanelComponents/studentLesson";
import StudentMainPage from "../studentPanelComponents/studentMainPage";
import StudentTeachers from "../studentPanelComponents/studentTeachers";
import StudentTestResults from "../studentPanelComponents/studentTestResults";
import StudentTests from "../studentPanelComponents/studentTests";
import StudentTestResultUpload from "../studentPanelComponents/studentTestResultUpload";
import FailedQuestions from "../studentPanelComponents/FailedQuestions";
import FailedQuestionsDetail from "../studentPanelComponents/FailedQuestionsDetail";
import StudentPanelReportPage from "../studentPanelComponents/StudentPanelReportPage";
import SubjectBookDetailsPage from "../adminPanelComponents/SubjectBookDetailsPage";
import CheckBookDetailsPage from "../adminPanelComponents/CheckBookDetailsPage";
import SubjectBook from "../adminPanelComponents/SubjectBook";
import CheckBook from "../adminPanelComponents/CheckBook";
import StudentSubjectBookPage from "../studentPanelComponents/StudentSubjectBook";
import StudentCheckBookPage from "../studentPanelComponents/StudentCheckBook";

export default function StudentPanel() {
  const accountService = new AccountService();

  const [accountDetails, setAccountDetails] =
    useState<StudentAccountModel>(studentIdentity);
  let { path, url } = useRouteMatch();
  const isAuthenticated = useAuthenticate("student");

  const getStudentAccountInfo = async () => {
    const result = await accountService.getAccountInfo<StudentAccountModel>();
    if (result) setAccountDetails(result);
  };

  useEffect(() => {
    getStudentAccountInfo();
  }, []);

  return isAuthenticated === null ? null : isAuthenticated === false ? (
    <Redirect to="/login" />
  ) : (
    <StudentIdentityContext.Provider value={accountDetails}>
      <StudentSideBar />
      <Switch>
        <Route path={`${path}/exam`}>
          <Header name="Sınavlar" />
          <StudentTests />
        </Route>
        <Route path={`${path}/examresults`}>
          <Header name="Sınav Sonuçları" />
          <StudentTestResults />
        </Route>
        <Route path={`${path}/uploadExamresults`}>
          <Header name="Sınav Sonuç Yükle" />
          <StudentTestResultUpload />
        </Route>
        <Route path={`${path}/failedQuestions/lessons`}>
          <Header name="Yapılamayan Sorular" />
          <FailedQuestions />
        </Route>
        <Route path={`${path}/failedQuestions/:lessonId`}>
          <FailedQuestionsDetail />
        </Route>
        <Route path={`${path}/lesson/:lessonId`}>
          <StudentLesson />
        </Route>
        <Route path={`${path}/teachers`}>
          <Header name="Öğretmenlerim" />
          <StudentTeachers />
        </Route>
        <Route path={`${path}/survey`}>
          <Header name="Anket" />
          <ParentSurvey />
        </Route>
        <Route exact path={path}>
          <Header name="Öğrenci Gösterge Paneli" />
          <StudentMainPage />
        </Route>

        <Route path={`${path}/classBook/subjectBook`}>
          <StudentSubjectBookPage />
        </Route>
        <Route path={`${path}/classBook/checkBook`}>
          <StudentCheckBookPage />
        </Route>

        <Route path={`${path}/report`}>
          <Header name="Raporlarım" />
          {/* <StudentTestResults /> */}
          <StudentPanelReportPage />
        </Route>
      </Switch>
      <UserPanelFooter />
    </StudentIdentityContext.Provider>
  );
}

const studentIdentity: StudentAccountModel = {
  gumusEgitimClassroom: "",
  lessons: [],
  name: "",
  surname: "",
  teachers: [],
  profilePhoto: "",
};

export const StudentIdentityContext = React.createContext(studentIdentity);
