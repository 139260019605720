// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showQuestionCreateOrUpdateModal } from "../../reducers/commonSlice";

declare global {
  interface ExtraDataModel {
    value: string;
    id: any;
  }

  interface DatatableRowDataModel<T> {
    id: T;
    row: Array<string>;
    checked?: boolean;
    extra?: Array<ExtraDataModel>;
  }

  interface DatatableDataModel {
    header: Array<string>;
    list: Array<DatatableRowDataModel<number>>;
  }

  interface ActionButton {
    title: string;
    callback: () => void;
  }
}

export default function Datatable(props: {
  insertable?: boolean;
  editable?: boolean;
  selectable?: boolean;
  viewable?: boolean;
  deletable?: boolean;
  insertTitle?: string;
  onViewClickCallback?: (id: number) => void;
  onCheckboxChangeCallback?: (checked: boolean, id: number) => void;
  onInsertClickCustomHandler?: () => void;
  data: DatatableDataModel;
  onEditClickCustomHandler?: (id: number) => void;
  onDeleteClickCustomHandler?: (id: number) => void;
  actionButtons?: Array<ActionButton>;
  actionElements?: Array<React.ReactNode>;
  customRowAction?: React.ComponentType<{ children: React.ReactNode }>;
}) {
  const [data, setData] = useState<DatatableDataModel>({
    header: [],
    list: [],
  });

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const deleteSubmitHandler = async (itemId: number) => {
    const result = await props.onDeleteClickCustomHandler(itemId);
    // if (!result) return;

    // const deletedItemIndex = props.data.list.findIndex(s => s.id == itemId);
    // const tempData = { ...data };
    // data.list.splice(deletedItemIndex, 1);
    // setData(tempData);
  };
  return (
    <div className="card">
      <div className="card-header">
        {props.insertable !== false ? (
          <button
            className="btn btn-info rounded-pill"
            onClick={props.onInsertClickCustomHandler}
          >
            {props.insertTitle || "Yeni Ekle"}
          </button>
        ) : null}
        {(props.actionButtons || []).map((s) => (
          <button
            className="ml-2 btn btn-info rounded-pill"
            onClick={s.callback}
          >
            {s.title || "No name"}
          </button>
        ))}
      </div>
      {props.actionElements || [].map((s) => s)}
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-striped" id="table1">
            <thead>
              <tr>
                {props.selectable === true ? <th>Seç</th> : null}
                {props.data.header.map((h) => (
                  <th>{h}</th>
                ))}
                {props.editable !== false ? <th></th> : null}
              </tr>
            </thead>
            <tbody>
              {data.list.map((l) => (
                <tr>
                  {props.selectable === true ? (
                    <th>
                      <input
                        style={{ marginLeft: "0" }}
                        className="form-check-input"
                        type="checkbox"
                        checked={l.checked}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          props.onCheckboxChangeCallback(
                            event.target.checked,
                            l.id
                          );
                        }}
                      />
                    </th>
                  ) : null}
                  {l.row.map((d) => (
                    <td>{d}</td>
                  ))}
                  {props.editable !== false ? (
                    <td>
                      <i
                        onClick={() => props.onEditClickCustomHandler(l.id)}
                        className="fas fa-edit"
                        style={{ cursor: "pointer" }}
                      ></i>
                      {props.deletable !== false ? (
                        <i
                          onClick={() => deleteSubmitHandler(l.id)}
                          style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                          className="fas fa-trash"
                        ></i>
                      ) : null}
                    </td>
                  ) : null}
                  {props.viewable === true ? (
                    <td>
                      {" "}
                      <i
                        onClick={() => props.onViewClickCallback(l.id)}
                        className="fas fa-eye"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </td>
                  ) : null}
                  {props.customRowAction ? (
                    <td>
                      {
                        <props.customRowAction>
                          {(l.extra as Array<ExtraDataModel>).map((s) => (
                            <option value={s.id}>{s.value}</option>
                          ))}
                        </props.customRowAction>
                      }
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
