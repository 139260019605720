import BaseService from "./baseService";

export enum Roles {
  Admin = "admin",
  Student = "student",
  Teacher = "teacher",
  Manager = "manager",
}

export default class RoleService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/userrole");
  }

  GetUserRoles() {
    // console.log("getting user roles")
    return this.Get<Array<string>>(null, "/userrole", true, false);
  }

  IsUserInRole(role: string) {
    return this.Post<string, boolean>(role);
  }
}
