// @ts-nocheck
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ConsentModal from "../consentModal";
import BranchesService from "../../services/branchesService";
import BrancheCreateOrUpdateModal from "./BrancheCreateOrUpdateModal";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import BranchePage from "./BranchePage";
import Header from "../header";
import ClassroomPage from "./classroomsPage";
import RoleService from "../../services/roleService";
import SubClassroomService from "../../services/subClassroomService";
import Select from "react-select";
import TestService from "../../services/testService";
import ComponentLoader from "../sharedComponents/componentLoader";
import CheckboxTree from "react-checkbox-tree";
import { SubClassroomTestReportPDF } from "../../utils/Pdf";
import SubClassRoomPage from "./SubClassroomPage";
import AdminClassStudentsPage from "./AdminClassStudentsPage";
import ClassroomService from "../../services/classroomService";

const ReportClassroomPage = () => {};

export default function SubClassroomReportPage() {
  const dispatch = useDispatch();
  const roleService = new RoleService();
  const subClassroomService = new SubClassroomService();
  const testService = new TestService();
  const classroomService = new ClassroomService();

  const { path, url } = useRouteMatch();
  const branchesService = new BranchesService();
  const [branchesList, setBranchesList] = useState<Array<BranchesOutputModel>>(
    []
  );
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [subClassroomList, setSubClassroomList] = useState<
    Array<SubClassroomOutputModel>
  >([]);
  const [selectedSubClassroom, setSelectedSubClassroom] =
    useState<number>(null);
  const [subClassroomTestList, setSubClassroomTestList] = useState([]);
  const [selectedTestExamValues, setSelectedTestExamValues] = useState([]);
  const [expandedTestExam, setExpandedTestExam] = useState([]);
  const [subClassroomTestReportModalVis, setSubClassroomTestReportModalVis] =
    useState<boolean>(false);
  const [reportLoader, setReportLoader] = useState<boolean>(false);
  const [classroomList, setClassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);

  const getClassroomList = async () => {
    dispatch(showLoader());
    let result = await classroomService.GetClassroomList();
    dispatch(hideLoader());

    if (result != null) {
      setClassroomList(result);
    } else {
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    }
  };
  useEffect(() => {
    getClassroomList();
  }, []);

  const getSubClassroom = async () => {
    dispatch(showLoader());
    const response = await subClassroomService.GetSubClassroomByTeacherId();
    setSubClassroomList(response);
    dispatch(hideLoader());
  };

  const getUserRole = async () => {
    dispatch(showLoader());
    const response = await roleService.GetUserRoles();
    setUserRoles(response);
    dispatch(hideLoader());
  };

  const getBranchesList = async () => {
    dispatch(showLoader());
    const response = await branchesService.GetBranches();
    setBranchesList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getBranchesList();
    getUserRole();
    getSubClassroom();
  }, []);

  const selectSubClassroomOpt = subClassroomList.map((item) => ({
    value: item.id,
    label: `${item.classroom.name} / ${item.name} Şubesi`,
  }));

  const getSubClassroomTestExamList = async () => {
    setReportLoader(true);
    const response = await testService.GetTestListBySubClassroom(
      selectedSubClassroom
    );
    const transformedData = response.map((lessonTests, lessonIndex) => {
      const lessonName = lessonTests.lessonName;
      setExpandedTestExam((expandedTestExam) => [
        ...expandedTestExam,
        lessonName,
      ]);
      const transformedLesson = {
        label: lessonName,
        value: lessonName,
        children: lessonTests.tests.map((test, index) => {
          setSelectedTestExamValues((selectedTestExamValues) => [
            ...selectedTestExamValues,
            `${test.id}`,
          ]);
          return {
            value: `${test.id}`,
            label: `${test.name} - ${test.issueDate}`,
          };
        }),
      };

      return transformedLesson;
    });
    setSubClassroomTestList(transformedData);
    setReportLoader(false);
  };

  const getSubClassroomTestReport = async () => {
    setReportLoader(true);
    let mappedData = [];
    selectedTestExamValues.map((item) => {
      mappedData = [...mappedData, Number(item)];
    });

    const response = await testService.GetTestReportForSubClassroom(
      selectedSubClassroom,
      mappedData
    );
    SubClassroomTestReportPDF(response);
    setReportLoader(false);
  };

  return (
    <Switch>
      <Route path={`report/branchesClassrooms/:brancheId`}>
        <Header name="Şubeler" />
        <AdminClassStudentsPage classroomList={classroomList} />
      </Route>
      <Route path={path}>
        {userRoles.includes("teacher") ? (
          <>
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-xl-9 col-lg-7 col-md-8 col-sm-12 col-12 d-flex justify-content-start align-items-center pt-2">
                    <Select
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        container: (base) => ({
                          ...base,
                          width: "100%",
                        }),
                      }}
                      menuPosition={"fixed"}
                      aria-label="Default select example"
                      options={selectSubClassroomOpt}
                      onChange={(selectedOption) => {
                        setSelectedSubClassroom(selectedOption.value);
                      }}
                      placeholder="Lütfen Şube Seçiniz"
                    />
                  </div>
                  <div className="col-xl-3 col-lg-5 col-md-4 col-sm-12 col-12 d-flex justify-content-center align-items-center pt-2">
                    <button
                      type="button"
                      onClick={(e) => {
                        // getStudentExamsReport();
                        //   getStudentTestList();
                        //   setStudentTestReportModalVis(true);
                        //   setSelectedExamForReport([]);
                        getSubClassroomTestExamList();
                        setSubClassroomTestReportModalVis(true);
                      }}
                      className="btn btn-success ml-1"
                      data-bs-dismiss="modal"
                      disabled={selectedSubClassroom === null}
                    >
                      <span className="d-inline p-2">Sınav Rapor</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {subClassroomTestReportModalVis && (
              <div
                className="modal text-left show"
                id="uploadModal"
                tabIndex={-1}
                aria-labelledby="myModalLabel33"
                style={{
                  // zIndex: 1,
                  overflow: "auto",
                  display: "block",
                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
                aria-modal="true"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-dialog"
                  role="document"
                  style={{
                    maxWidth: "800px",
                    height: "800px",
                  }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myModalLabel33">
                        Sınav Rapor
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i data-feather="x" onClick={() => {}}></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      {reportLoader ? (
                        <ComponentLoader />
                      ) : (
                        <CheckboxTree
                          nativeCheckboxes
                          nodes={subClassroomTestList}
                          checked={selectedTestExamValues}
                          expanded={expandedTestExam}
                          onCheck={setSelectedTestExamValues}
                          onExpand={setExpandedTestExam}
                          showNodeIcon={false}
                        />
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => {
                          setSubClassroomTestReportModalVis(false);
                          //   setSelectedExamForReport([]);
                          //   setStudentPracticeExams([]);
                          //   setSelectedTestExamValues([]);
                          setSelectedTestExamValues([]);
                        }}
                        className="btn btn-default rounded pill"
                        data-bs-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        <span className="d-inline p-2">Kapat</span>
                      </button>

                      <button
                        type="button"
                        onClick={(e) => {
                          getSubClassroomTestReport();
                        }}
                        className="btn btn-primary ml-1"
                        data-bs-dismiss="modal"
                        disabled={selectedTestExamValues.length == 0}
                      >
                        <i className="fas fa-check-circle"></i>
                        <span className="d-inline p-2">Rapor Oluştur</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : userRoles.includes("admin") ? (
          <>
            <Header name="Şubeler" />
            <div className="page-content">
              <section className="row">
                <div className="content-page">
                  <div className="row staff-grid-row">
                    {branchesList.map((l) => (
                      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 ">
                        <div
                          className="profile-widget d-flex justify-content-center align-items-center"
                          style={{ borderRadius: "100px" }}
                        >
                          <div className="profile-img">
                            <Link to={`${path}/${l.id}`}>
                              <img
                                className="avatar"
                                src="/assets/img/school.png"
                                alt=""
                              />
                            </Link>
                          </div>
                          <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                            <a>{l.name || "Default"}</a>
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
          <ClassroomPage />
        )}
      </Route>
    </Switch>
  );
}
