import "../style/examresultupload.css";

import React, { useEffect, useRef, useState } from "react";
import StudentService from "../../services/studentService";
import TestService from "../../services/testService";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../reducers/messengerSlice";
import Choices from "choices.js";
import { hideLoader, showLoader } from "../../reducers/commonSlice";

var choices: Choices;

export default function UpdateTestAssignSubClassModal(props: {
  list: Array<SubClassroomOutputModel>;
  setSelected: (key) => void;
  disabled?: boolean;
}) {
  const ref = useRef(null);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    choices && choices.destroy();
    const choicesList = [];
    props.list.forEach((l) =>
      choicesList.push({
        value: l?.id,
        label: l?.classroom?.name + " / " + l?.name + " " + "Şubesi",
      })
    );
    choices = new Choices(ref.current, {
      itemSelectText: "Seçmek için tıklayınız",
      removeItemButton: true,
      choices: choicesList.map((m) => ({
        value: Number(m.value),
        label: m.label,
        selected: selected.includes(m.value),
      })),
    });
  }, [props.list]);

  useEffect(() => {
    if (choices) {
      if (props.disabled) {
        choices.disable();
      } else {
        choices.enable();
      }
    }
  }, [props.disabled]);

  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(
      Array.from(event.target.selectedOptions, (option) => Number(option.value))
    );

    props.setSelected(
      Array.from(event.target.selectedOptions, (option) => Number(option.value))
    );
  };

  return (
    <>
      <select
        className="choices form-select multiple-remove"
        ref={ref}
        multiple
        onChange={selectChangeHandler}
      ></select>
    </>
  );
}
