import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import RoleService from "../../services/roleService";
import TeacherService from "../../services/teacherService";

declare interface TeacherPageParam {
  teacherId: string;
}

export default function OgretmenProfil() {
  const { teacherId } = useParams<TeacherPageParam>();
  const teacherService = new TeacherService();
  const roleService = new RoleService();
  const dispatch = useDispatch();

  const [reviewList, setReviewList] = useState<
    Array<TeacherReviewInputOutputModel>
  >([]);
  const [teacher, setTeacher] = useState<TeacherProfileOutputModel>({
    aboutMe: "",
    city: "",
    commentCount: 0,
    name: "",
    profilePhoto: "",
    reviewScore: 0,
    specialty: "",
    surname: "",
    university: "",
  });

  // comment
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewScore, setReviewScore] = useState(5);
  const [reviewText, setReviewText] = useState("");
  const [reviewee, setReviewee] = useState("Anonim");

  const [isAuthorizedToComment, setIsAuthorizedToComment] = useState(false);

  const checkCommentAuthorization = async () => {
    const roles = await roleService.GetUserRoles();
    if (roles != null && roles.includes("student"))
      setIsAuthorizedToComment(true);
  };

  const getTeacherProfile = async () => {
    dispatch(showLoader());

    let result = await teacherService.GetTeacherProfileById(teacherId);
    dispatch(hideLoader());
    if (result == null) {
      dispatch(
        sendMessage({ title: "Bilgilendirme", message: "Bir hata oluştu" })
      );
      return;
    }

    setTeacher(result);
  };

  const getReviewList = async () => {
    dispatch(showLoader());
    const result = await teacherService.ListTeacherReviews(teacherId);
    dispatch(hideLoader());

    if (result) setReviewList(result);
  };

  const sendNewReview = async () => {
    const result = await teacherService.AddTeacherReview({
      evaluationScore: reviewScore,
      reviewOwner: reviewee,
      reviewText: reviewText,
      teacherId: teacherId,
      title: reviewTitle,
    });

    if (result) {
      clearForm();
      getReviewList();
      getTeacherProfile();
    }
  };

  useEffect(() => {
    getTeacherProfile();
    getReviewList();
    checkCommentAuthorization();
  }, []);

  const clearForm = () => {
    setReviewTitle("");
    setReviewScore(5);
    setReviewText("");
    setReviewee("");
  };

  return (
    <>
      <section className="section bg-secondary pt-9 pt-lg-13">
        <div className="container">
          <div className="card card-profile shadow mt--150">
            <div className="px-4">
              <div className="row justify-content-center">
                <div className="col-lg-2 order-lg-2 mt-3">
                  <div className="card-profile-image">
                    <a href="javascript:;">
                      <img
                        src={
                          teacher.profilePhoto ||
                          "/assets/img/Profile_avatar_placeholder_large.png"
                        }
                        className="rounded-circle"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                  <div className="card-profile-actions py-4 mt-lg-0">
                    {/* <a href="#" className="btn btn-sm btn-info mr-4">Connect</a>
                                        <a href="#" className="btn btn-sm btn-default float-right">Message</a> */}
                  </div>
                </div>
                <div className="col-lg-4 order-lg-1 text-lg-right align-self-lg-center">
                  <div className="card-profile-stats d-flex justify-content-center">
                    <div className="stats">
                      <div className="stars star-color">
                        <i
                          className={`${
                            teacher.reviewScore > 0 ? "fas" : "far"
                          } fa-star`}
                        ></i>
                        <i
                          className={`${
                            teacher.reviewScore > 1 ? "fas" : "far"
                          } fa-star`}
                        ></i>
                        <i
                          className={`${
                            teacher.reviewScore > 2 ? "fas" : "far"
                          } fa-star`}
                        ></i>
                        <i
                          className={`${
                            teacher.reviewScore > 3 ? "fas" : "far"
                          } fa-star`}
                        ></i>
                        <i
                          className={`${
                            teacher.reviewScore > 4 ? "fas" : "far"
                          } fa-star`}
                        ></i>
                        <span className="star-color-bol pr-2 pl-2 ">|</span>
                        <a href="#yorumlar">
                          <p className="d-inline text-default">Yorumları Gör</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <h3>
                  {teacher.name + " " + teacher.surname}
                  <span className="font-weight-light"></span>
                </h3>
                <div className="h6 font-weight-300">
                  <i className="ni location_pin mr-2"></i>
                  {teacher.city}
                </div>
                <div className="h6 mt-4">
                  <i className="ni business_briefcase-24 mr-2"></i>
                  {teacher.specialty}
                </div>
                <div>
                  <i className="ni education_hat mr-2"></i>
                  {teacher.university}
                </div>
              </div>
              <div className="mt-5 py-5 border-top text-center">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <h4>HAKKIMDA</h4>
                    <p>{teacher.aboutMe}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row justify-content-center bg-secondary text-white">
          <div className="col-12 col-md-8 text-center">
            <h1 className="display-2 mt-3">Yorumlar</h1>
          </div>
        </div>
      </div>

      <section className="section bg-secondary" id="yorumlar">
        <div className="container">
          {isAuthorizedToComment ? (
            <div className="card bg-gradient-white mb-3">
              <form action="">
                <div className="card-body">
                  <h3 className="card-title text-black">
                    <label>Başlık</label>
                    <input
                      type="text"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setReviewTitle(e.target.value)
                      }
                      className={`form-control ${
                        reviewTitle ? "" : "is-invalid"
                      }`}
                      value={reviewTitle}
                      id="name"
                      placeholder="Başlık Giriniz"
                    />
                  </h3>
                  <blockquote className="blockquote text-black mb-0">
                    <div className="form-group">
                      <label>Açıklama</label>
                      <textarea
                        className={`form-control ${
                          reviewText ? "" : "is-invalid"
                        }`}
                        onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setReviewText(e.target.value)
                        }
                        value={reviewText}
                        id="aciklama"
                        placeholder="Açıklama Giriniz"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="first-name-vertical">Puanınız</label>
                      <div className="stars">
                        <input
                          className="star star-5"
                          checked={reviewScore == 5}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.target.checked && setReviewScore(5)
                          }
                          id="star-15"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-5"
                          htmlFor="star-15"
                        ></label>
                        <input
                          className="star star-4"
                          checked={reviewScore == 4}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.target.checked && setReviewScore(4)
                          }
                          id="star-14"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-4"
                          htmlFor="star-14"
                        ></label>
                        <input
                          className="star star-3"
                          checked={reviewScore == 3}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.target.checked && setReviewScore(3)
                          }
                          id="star-13"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-3"
                          htmlFor="star-13"
                        ></label>
                        <input
                          className="star star-2"
                          checked={reviewScore == 2}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.target.checked && setReviewScore(2)
                          }
                          id="star-12"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-2"
                          htmlFor="star-12"
                        ></label>
                        <input
                          className="star star-1"
                          checked={reviewScore == 1}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            e.target.checked && setReviewScore(1)
                          }
                          id="star-11"
                          type="radio"
                          name="star"
                        />
                        <label
                          className="star star-1"
                          htmlFor="star-11"
                        ></label>
                      </div>
                    </div>
                    <footer className="blockquote-footer text-danger pt-3">
                      <label>
                        İsim <small>(Zorunlu Değil)</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setReviewee(e.target.value)
                        }
                        value={reviewee}
                        id="name"
                        placeholder="İsim Giriniz"
                      />
                    </footer>
                  </blockquote>
                </div>
                <div className="col text-right">
                  <input
                    type="button"
                    onClick={sendNewReview}
                    className="btn btn-primary mb-3 mr-2"
                    value="Gönder"
                    disabled={!reviewTitle || !reviewText}
                  />
                </div>
              </form>
            </div>
          ) : null}

          {reviewList.map((r) => (
            <div className="card bg-gradient-white mb-3">
              <div className="card-body">
                <div className="stats">
                  <div className="stars star-color">
                    <i
                      className={`${
                        r.evaluationScore > 0 ? "fas" : "far"
                      } fa-star`}
                    ></i>
                    <i
                      className={`${
                        r.evaluationScore > 1 ? "fas" : "far"
                      } fa-star`}
                    ></i>
                    <i
                      className={`${
                        r.evaluationScore > 2 ? "fas" : "far"
                      } fa-star`}
                    ></i>
                    <i
                      className={`${
                        r.evaluationScore > 3 ? "fas" : "far"
                      } fa-star`}
                    ></i>
                    <i
                      className={`${
                        r.evaluationScore > 4 ? "fas" : "far"
                      } fa-star`}
                    ></i>
                  </div>
                </div>
                <h3 className="card-title text-black font-weight-bolder">
                  {r.title}
                </h3>
                <blockquote className="blockquote text-black mb-0">
                  <p>{r.reviewText}</p>
                  <footer className="blockquote-footer text-danger font-weight-bold">
                    <cite title="Commenter Name">{r.reviewOwner}</cite>
                  </footer>
                </blockquote>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
