import React, { useContext, useEffect } from "react";
import { StudentIdentityContext } from "../panels/studentPanel";
import ProfileWidget from "../profileWidget";
import StudentBasicInfo from "./studentBasicInfo";
import Header from "../header";

export default function StudentTeachers() {
  const studentContext = useContext(StudentIdentityContext);
  return (
    <div className="page-content">
      <section className="row">
        <StudentBasicInfo />
      </section>
      <Header name="Sınıf Öğretmenlerim" />
      <div className="row staff-grid-row mt-3">
        {studentContext?.subclassroom?.teachers.map((t) => (
          <ProfileWidget
            photoUrl={t.teacher.profilePhoto}
            subtext={t.teacher.majors.map((m) => m.lessonName).join(", ")}
            key={t.teacher.id}
            actions={null}
            name={t.teacher.name}
            surname={t.teacher.surname}
          />
        ))}
      </div>
      <Header name="Özel Ders Öğretmenlerim" />
      <div className="row staff-grid-row mt-3">
        {studentContext.teachers.map((t) => (
          <ProfileWidget
            photoUrl={t.profilePhoto}
            subtext={t.majors.map((m) => m.lessonName).join(", ")}
            key={t.id}
            actions={null}
            name={t.name}
            surname={t.surname}
          />
        ))}
      </div>
    </div>
  );
}
