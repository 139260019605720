// @ts-nocheck

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  BlobProvider,
  Image,
  Font,
  pdf,
  Svg,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import BaseService from "../services/baseService";
import { getImageSize } from "react-image-size";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../reducers/commonSlice";
// SuccessRate'ler x<24 kırmızı, 25<x<49 Turuncu, 50<x<79 Sarı, 80<x<100 Yeşil olarak renklensin
const successRateBgColor = (successRate) => {
  // const value =
  //   successRate === "" ? 0 : parseFloat(successRate.replaceAll(",", "."));
  // const lastValue = Math.round(Number(value));
  const lastValue = Math.round(Number(successRate));

  if (lastValue < 25) {
    return "#FF5733";
  } else if (25 <= lastValue && lastValue < 50) {
    return "#FFC300";
  } else if (50 <= lastValue && lastValue < 80) {
    return "#FFFE00";
  } else if (80 <= lastValue && lastValue <= 100) {
    return "#87FF26";
  }
};

Font.register({
  family: "RobotoBold",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
});
Font.register({
  family: "RobotoRegular",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});
Font.register({
  family: "NotoSansTurkish",
  src: "https://fonts.googleapis.com/css2?family=Open+Sans:ital@1&display=swap", // Uygun bir font URL'si ekleyin
});

const styles = StyleSheet.create({
  body: {
    // paddingTop: "3%",
    // paddingBottom: "3%",
    padding: "3%",
    // margin: "3%",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    fontFamily: "RobotoBold",
    margin: "auto",
    paddingTop: "2%",
    paddingBottom: "2%",
    fontSize: 10,
  },
  tableCell: {
    fontFamily: "RobotoRegular",
    margin: "auto",
    paddingTop: "2%",
    paddingBottom: "2%",
    fontSize: 9,
  },
  watermarkContainer: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    opacity: 0.05, // Filigran opaklığı
    width: "100%",
    height: "100%",
  },
  image: {
    transform: "rotate(-45deg)",
    // opacity: 0.1,
  },
});

const Filigran = () => {
  return (
    <View style={styles.watermarkContainer} fixed>
      <Image src="http://gumusegitim.online/logo.png" style={styles.image} />
    </View>
  );
};

export const StudentList = (props) => {
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <Filigran />
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "30%" }]}>
              <Text style={styles.tableCellHeader}>Öğrenci İsim Soyisim</Text>
            </View>
            <View style={[styles.tableCol, { width: "10%" }]}>
              <Text style={styles.tableCellHeader}>Öğrenci Sınıfı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Öğrenci Telefonu</Text>
            </View>
            <View style={[styles.tableCol, { width: "30%" }]}>
              <Text style={styles.tableCellHeader}>Veli İsim Soyisim</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>Veli Telefonu</Text>
            </View>
          </View>
          {props.data.map((item, index) => {
            return (
              <View style={styles.tableRow} wrap={false}>
                <View style={[styles.tableCol, { width: "30%" }]}>
                  <Text style={[styles.tableCell]}>
                    {item["Öğrenci İsim Soyisim"]}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "10%" }]}>
                  <Text style={styles.tableCell}>{item["Öğrenci Sınıfı"]}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {item["Öğrenci Telefonu"]}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: "30%" }]}>
                  <Text style={styles.tableCell}>
                    {item["Veli İsim Soyisim"]}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item["Veli Telefonu"]}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

const RowContent = (props) => {
  return (
    <View
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
      }}
    >
      <View
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        {/* <Image src={props.left} /> */}
        {props.left}
      </View>
      <View
        style={{
          alignItems: "center",
          width: 1,
          backgroundColor: "red",
        }}
      ></View>
      {/* <View
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        {props.right}
        <Image src={props.right} />
      </View> */}
    </View>
  );
};

export const PDFF = (props) => {
  const baseService = new BaseService();
  const fetchImageSize = async (src) => {
    try {
      const dimensions = await getImageSize(src);
      return dimensions;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Filigran />
        {props.items.map((item, index) => {
          return (
            <RowContent
              left={
                <View style={{ backgroundColor: "red" }}>
                  <Image
                    style={{ opacity: 0.5 }}
                    src={
                      baseService.endPoints().pdfUrl +
                      "/testquestionbank/" +
                      item.questionUrl
                    }
                  />
                </View>
              }
            />
          );
        })}
      </Page>
    </Document>
  );
};

const PDF = async (data) => {
  // const blob = await pdf(<MyPDFDocument data={data} />).toBlob();
  // saveAs(blob, "Öğrenci Listesi.pdf");
  const blob = await pdf(<StudentList data={data} />).toBlob();
  const pdfUrl = URL.createObjectURL(blob);
  const printWindow = window.open(pdfUrl, "_blank");
  // printWindow.print();
};

export const StudentPracticeExamReportPDF = async (data) => {
  // const blob = await pdf(<MyPDFDocument data={data} />).toBlob();
  // saveAs(blob, "Öğrenci Listesi.pdf");
  const blob = await pdf(<StudentReport2 data={data} />).toBlob();
  const pdfUrl = await URL.createObjectURL(blob);
  const printWindow = await window.open(pdfUrl, "_blank");
  // printWindow.print();
};

export const StudentExamReportPDF = async (data) => {
  // const blob = await pdf(<MyPDFDocument data={data} />).toBlob();
  // saveAs(blob, "Öğrenci Listesi.pdf");
  const blob = await pdf(<StudentExamReport data={data} />).toBlob();
  const pdfUrl = await URL.createObjectURL(blob);
  const printWindow = await window.open(pdfUrl, "_blank");
  // printWindow.print();
};

export const SubClassroomTestReportPDF = async (data) => {
  const blob = await pdf(<StudentExamCompareReport data={data} />).toBlob();
  const pdfUrl = await URL.createObjectURL(blob);
  const printWindow = await window.open(pdfUrl, "_blank");
};

export const SubClassroomCompareTestReportPDF = async (data) => {
  const blob = await pdf(
    <SubClassroomExamCompareReport data={data} />
  ).toBlob();
  const pdfUrl = await URL.createObjectURL(blob);
  const printWindow = await window.open(pdfUrl, "_blank");
};

const styles2 = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  imageContainer: {
    width: "50%",
    padding: 10,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "red",
  },
});

export const MyPDF = (props) => {
  const baseService = new BaseService();

  return (
    <Document>
      <Page size="A4" style={styles2.page}>
        {props.images.map((item, index) => (
          <View style={styles2.imageContainer}>
            <Image
              key={index}
              src={
                baseService.endPoints().pdfUrl +
                "/testquestionbank/" +
                item.questionUrl
              }
              style={{
                width: "100%",
                aspectRatio: 0.75,
                opacity: 0.5,
              }}
            />
          </View>
        ))}
      </Page>
    </Document>
  );
};

const StudentReportTable = ({
  text,
  height,
  width,
  fontWeight,
  fontSize,
  align,
  background,
  textColor,
}: {
  text: string;
  height: number;
  width?: string | number;
  fontWeight?: "bold" | "regular";
  align?: undefined | string;
  background?: undefined | string;
  fontSize?: undefined | number;
  textColor?: undefined | string;
}) => {
  return (
    <View
      style={{
        backgroundColor: background === undefined ? "transparent" : background,
        borderWidth: 0.5,
        borderColor: "#DDDDDD",
        width: "100%",
        fontFamily: fontWeight === "bold" ? "RobotoBold" : "RobotoRegular",
        fontSize: fontSize === undefined ? 10 : fontSize,
        height: height,
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          textAlign: align === undefined ? "flex-start" : align,
          paddingLeft: align === "center" ? 0 : 5,
          color: textColor === undefined ? "black" : textColor,
        }}
      >
        {text}
      </Text>
    </View>
  );
};

export const PracticeExamReport = (props) => {
  const practiceExamName: string = "TEST OKUL MELTEM TYT-1";
  const practiceExamDate: string = "10.11.2023";
  const logoSrc: string = "http://gumusegitim.online/logo.png";

  const head1: { text: string; width: string }[] = [
    { text: "TYT-TÜRKÇE", width: "10%" },
    { text: "TYT-SOSYAL BİLİMLER", width: "40%" },
    { text: "TYT-MATEMATİK", width: "15%" },
    { text: "TYT-FEN BİLİMLERİ", width: "25%" },
    { text: "PUAN", width: "10%" },
  ];

  const head1Params: { text: string[] }[] = [
    { text: ["TÜRKÇE"] },
    { text: ["TARİH", "COĞRAFYA", "FELSEFE", "DİN KÜLTÜRÜ", "SEÇ. FELSEFE"] },
    { text: ["MATEMATİK"] },
    { text: ["FİZİK", "KİMYA", "BİYOLOJİ"] },
    { text: ["349,15"] },
  ];

  const head1Sub: { text: string }[] = [
    { text: "D" },
    { text: "Y" },
    { text: "N" },
  ];

  const head1SubParams: { text: string }[] = [
    { text: "TÜRKÇE", D: "10,55", Y: "10,55", N: "5,55" },
    { text: "TARİH", D: "10", Y: "10", N: "5" },
    { text: "COĞRAFYA", D: "10", Y: "10", N: "5" },
    { text: "FELSEFE", D: "10", Y: "10", N: "5" },
    { text: "DİN KÜLTÜRÜ", D: "10", Y: "10", N: "5" },
    { text: "SEÇ. FELSEFE", D: "10", Y: "10", N: "5" },
    { text: "MATEMATİK", D: "10", Y: "10", N: "5" },
    { text: "FİZİK", D: "10", Y: "10", N: "5" },
    { text: "KİMYA", D: "10", Y: "10", N: "5" },
    { text: "BİYOLOJİ", D: "10", Y: "10", N: "5" },
  ];

  return (
    <Document>
      <Page
        size="A4"
        orientation={"landscape"}
        style={stylesStudentReport.body}
        wrap
      >
        <Filigran />
        <View style={{ paddingTop: 10 }}>
          {/* Öğrenci Bilgileri*/}
          <View
            style={[stylesStudentReport.square, { height: 80, fontSize: 13 }]}
          >
            <View style={stylesStudentReport.header}>
              <View>
                <Text style={stylesStudentReport.headerText}>
                  GÜMÜŞ KİŞİSEL GELİŞİM KURSU
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "25%",
                  height: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={logoSrc} />
              </View>

              <View
                style={{
                  width: "75%",
                  height: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={[stylesStudentReport.headerText, { color: "black" }]}
                >
                  {practiceExamName}
                </Text>
                <Text
                  style={[stylesStudentReport.headerText, { color: "black" }]}
                >
                  {practiceExamDate}
                </Text>
              </View>
            </View>
          </View>
          {/* Ortalama Netler */}
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                stylesStudentReport.square,
                { height: 130, fontSize: 10 },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>
                    Ortalama Netler
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                {head1.map((item, index) => (
                  <View
                    style={{
                      width: item.width,
                    }}
                  >
                    <StudentReportTable
                      text={item.text}
                      height={30}
                      fontWeight={"bold"}
                      align="center"
                      // background={item.background}
                    />
                  </View>
                ))}
              </View>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                {head1Params.map((item, index) => (
                  <View
                    style={{
                      width: head1[index].width,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      {item.text.map((itemText, itemIndex) => (
                        <View
                          style={{
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                            }}
                          >
                            <StudentReportTable
                              text={itemText}
                              fontWeight={"bold"}
                              height={25}
                              align="center"
                              fontSize={7}
                            />
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            {index !== head1.length - 1 &&
                              head1Sub.map((subItem) => (
                                <View
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <StudentReportTable
                                    text={subItem.text}
                                    fontWeight={"bold"}
                                    height={25}
                                    align="center"
                                    fontSize={8}
                                  />
                                </View>
                              ))}
                          </View>
                          {index !== head1.length - 1 && (
                            <View
                              style={{ flexDirection: "row", width: "100%" }}
                            >
                              <StudentReportTable
                                text={head1SubParams[itemIndex].D}
                                fontWeight={"regular"}
                                height={25}
                                align="center"
                                fontSize={6}
                              />
                              <StudentReportTable
                                text={head1SubParams[itemIndex].Y}
                                fontWeight={"regular"}
                                height={25}
                                align="center"
                                fontSize={6}
                              />
                              <StudentReportTable
                                text={head1SubParams[itemIndex].N}
                                fontWeight={"regular"}
                                height={25}
                                align="center"
                                fontSize={6}
                              />
                            </View>
                          )}
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const stylesStudentReport = StyleSheet.create({
  body: {
    padding: "3%",
  },
  watermarkContainer: {
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    opacity: 0.15, // Filigran opaklığı
    width: "100%",
    height: "100%",
  },
  image: {
    transform: "rotate(-45deg)",
  },
  header: {
    backgroundColor: "#164C8C",
    width: "100%",
    height: 30,
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    fontFamily: "RobotoBold", // Font'u stil içinde belirtin
  },
  headerText: {
    color: "white",
    textAlign: "center",
  },
  square: {
    width: "100%",
    height: 50,
    borderWidth: 1,
    borderColor: "#DDDDDD",
    alignItems: "center",
    alignSelf: "center",
  },
});

export const StudentReport2 = (props) => {
  const logoSrc: string = "http://gumusegitim.online/logo.png";

  const head1: { text: string }[] = [
    { text: "Ad Soyad" },
    { text: "Sınıf" },
    { text: "Sınav Türü" },
    { text: "Tarih" },
  ];

  const learningOutcomesHeader: {
    text: string;
    width: string;
    value: string;
  }[] = [
    { value: "lessonName", text: "Ders", width: "10%" },
    { value: "learningOutcome", text: "Kazanım", width: "40%" },
    { value: "questionCount", text: "Soru Sayısı", width: "10%" },
    { value: "correctAnswerCount", text: "Dooğru Sayısı", width: "10%" },
    { value: "inCorrectAnswerCount", text: "Yanlış Sayısı", width: "10%" },
    { value: "unAnsweredCount", text: "Boş Sayısı", width: "10%" },
    { value: "successRate", text: "Başarı Oranı", width: "10%" },
  ];
  const mergedData: {
    student: {
      name: string;
      classroom: string;
      date: string;
      examType: string;
    };
    headers: string[];
    exams: {
      examName: string;
      examDate: string;
      results: {
        lessonName: string;
        questionCount: string;
        totalNet: string;
      }[];
    }[];
    average: {
      lessonName: string;
      averageCount: string;
      questionCount: string;
    }[];
    learningOutcomes: {
      lessonName: string;
      learningOutcome: string;
      questionCount: string;
      correctAnswerCount: string;
      inCorrectAnswerCount: string;
      unAnsweredCount: string;
      successRate: string;
    }[];
  } = props.data;

  let lessonHeadCnt = 0;

  mergedData.learningOutcomes.map((item, index) => {
    if (mergedData.headers.includes(item.lessonName)) {
      lessonHeadCnt++;
    }
  });

  return (
    <Document>
      <Page size="A4" style={stylesStudentReport.body} wrap>
        <Filigran />
        <View style={{ paddingTop: 10 }}>
          {/* Öğrenci Bilgileri*/}
          <View
            style={[stylesStudentReport.square, { height: 100, fontSize: 13 }]}
          >
            <View style={stylesStudentReport.header}>
              <View>
                <Text style={stylesStudentReport.headerText}>
                  GÜMÜŞ KİŞİSEL GELİŞİM KURSU
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "30%",
                  height: 68,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={logoSrc} />
              </View>
              <View
                style={{
                  width: "15%",
                  height: 69,
                }}
              >
                {head1.map((item) => (
                  <StudentReportTable
                    text={item.text}
                    fontWeight={"bold"}
                    height={23}
                    fontSize={8}
                  />
                ))}
              </View>
              <View
                style={{
                  width: "55%",
                  height: 69,
                }}
              >
                <StudentReportTable
                  text={mergedData.student.name}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={mergedData.student.classroom}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={mergedData.student.examType}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={dayjs(mergedData.student.date).format("DD.MM.YYYY")}
                  height={23}
                  fontSize={8}
                />
              </View>
            </View>
          </View>

          {/* Sınav Bazlı Netler */}
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height: 75 + mergedData.exams.length * 25,
                  fontSize: 10,
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>
                    Sınav Bazlı Netler
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: `25%`,
                  }}
                >
                  <StudentReportTable
                    text={"SINAV"}
                    height={20}
                    fontWeight={"bold"}
                    align="center"
                    background={"transparent"}
                    fontSize={7}
                  />
                </View>
                {mergedData.headers.map((header, headerIndex) => (
                  <View
                    style={{
                      width: `${100 / mergedData.headers.length} %`,
                    }}
                  >
                    <StudentReportTable
                      text={header}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {mergedData.exams.slice(0, 1).map((exam, examIndex) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          width: `25%`,
                        }}
                      >
                        <StudentReportTable
                          text={""}
                          height={20}
                          fontWeight={"bold"}
                          align="center"
                          background={"transparent"}
                          fontSize={6}
                        />
                      </View>

                      {mergedData.headers.map((header, headerIndex) => {
                        return exam.results.map((result, resultIndex) => {
                          return (
                            result.lessonName === header && (
                              <View
                                style={{
                                  width: `${100 / mergedData.headers.length} %`,
                                  flexDirection: "row",
                                }}
                              >
                                <View
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  <StudentReportTable
                                    text={"SS"}
                                    height={20}
                                    fontWeight={"bold"}
                                    align="center"
                                    background={"transparent"}
                                    fontSize={6}
                                  />
                                </View>
                                <View
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  <StudentReportTable
                                    text={"NET"}
                                    height={20}
                                    fontWeight={"bold"}
                                    align="center"
                                    background={"transparent"}
                                    fontSize={6}
                                  />
                                </View>
                              </View>
                            )
                          );
                        });
                      })}
                    </View>
                  );
                })}
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {mergedData.exams.map((exam, examIndex) => {
                  return (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <View
                        style={{
                          width: `25%`,
                        }}
                      >
                        <StudentReportTable
                          text={exam.examName}
                          height={20}
                          fontWeight={"bold"}
                          align="center"
                          background={
                            examIndex % 2 ? "#164c8b0d" : "transparent"
                          }
                          fontSize={6}
                        />
                      </View>

                      {mergedData.headers.map((header, headerIndex) => {
                        return exam.results.map((result, resultIndex) => {
                          return (
                            result.lessonName === header && (
                              <View
                                style={{
                                  width: `${100 / mergedData.headers.length} %`,
                                  flexDirection: "row",
                                }}
                              >
                                <View
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  <StudentReportTable
                                    text={result.questionCount}
                                    height={20}
                                    fontWeight={"bold"}
                                    align="center"
                                    background={
                                      examIndex % 2
                                        ? "#164c8b0d"
                                        : "transparent"
                                    }
                                    fontSize={6}
                                  />
                                </View>
                                <View
                                  style={{
                                    width: "50%",
                                  }}
                                >
                                  <StudentReportTable
                                    text={result.totalNet}
                                    height={20}
                                    fontWeight={"bold"}
                                    align="center"
                                    background={
                                      examIndex % 2
                                        ? "#164c8b0d"
                                        : "transparent"
                                    }
                                    fontSize={6}
                                  />
                                </View>
                              </View>
                            )
                          );
                        });
                      })}
                    </View>
                  );
                })}
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      width: `25%`,
                    }}
                  >
                    <StudentReportTable
                      text={"ORTALAMA"}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={"#e6e8eb"}
                      fontSize={7}
                    />
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {mergedData.headers.map((header, headerIndex) => {
                      return mergedData.average.map((result, resultIndex) => {
                        return (
                          result.lessonName === header && (
                            <View
                              style={{
                                width: `100%`,
                                flexDirection: "row",
                              }}
                            >
                              <View
                                style={{
                                  width: "100%",
                                }}
                              >
                                <StudentReportTable
                                  text={result.questionCount}
                                  height={20}
                                  fontWeight={"bold"}
                                  align="center"
                                  background={"#e6e8eb"}
                                  fontSize={6}
                                />
                              </View>
                              <View
                                style={{
                                  width: "100%",
                                }}
                              >
                                <StudentReportTable
                                  text={result.averageCount}
                                  height={20}
                                  fontWeight={"bold"}
                                  align="center"
                                  background={"#e6e8eb"}
                                  fontSize={6}
                                />
                              </View>
                            </View>
                          )
                        );
                      });
                    })}
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Kazanım Listesi*/}
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height:
                    mergedData.headers.length * 15 + lessonHeadCnt * 15 + 45,
                  fontSize: 10,
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>
                    Kazanım Listesi
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {/* {mergedData.learningOutcomes.map((outcome, outcomeIndex) => {
                  return (
                    <>
                      {outcomeIndex === 0 ? (
                        <View
                          style={[
                            stylesStudentReport.header,
                            { height: 15, backgroundColor: "#8aa5c5" },
                          ]}
                        >
                          <View>
                            <Text style={stylesStudentReport.headerText}>
                              {outcome.lessonName}
                            </Text>
                          </View>
                        </View>
                      ) : (
                        mergedData.learningOutcomes[outcomeIndex - 1]
                          .lessonName !== outcome.lessonName && (
                          <View
                            style={[
                              stylesStudentReport.header,
                              { height: 15, backgroundColor: "#8aa5c5" },
                            ]}
                          >
                            <View>
                              <Text style={stylesStudentReport.headerText}>
                                {outcome.lessonName}
                              </Text>
                            </View>
                          </View>
                        )
                      )}
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                        }}
                        wrap={false}
                      >
                        {learningOutcomesHeader.map((header, headerIndex) => (
                          <>
                            <View style={{ width: header.width }} wrap={false}>
                              <StudentReportTable
                                text={
                                  header.value === "successRate"
                                    ? `% ${
                                        outcome[header.value] === ""
                                          ? "0"
                                          : outcome[header.value]
                                      }`
                                    : outcome[header.value]
                                }
                                height={15}
                                // fontWeight={"bold"}
                                background={
                                  header.value === "successRate"
                                    ? successRateBgColor(outcome[header.value])
                                    : outcomeIndex % 2
                                    ? "#164c8b0d"
                                    : "transparent"
                                }
                                align="center"
                                fontSize={7}
                              />
                            </View>
                          </>
                        ))}
                      </View>
                    </>
                  );
                })} */}
                {mergedData.headers
                  .slice(0, mergedData.headers.length - 1)
                  .map((header, outcomeIndex) => {
                    return (
                      <>
                        <View
                          style={{
                            fontSize: 10,
                          }}
                        >
                          <View
                            style={[
                              stylesStudentReport.header,
                              { height: 15, backgroundColor: "#8aa5c5" },
                            ]}
                          >
                            <Text style={stylesStudentReport.headerText}>
                              {header}
                            </Text>
                          </View>
                          {mergedData.learningOutcomes.map(
                            (outcome, outcomeIndex) => {
                              return (
                                outcome.lessonName === header && (
                                  <>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                      }}
                                      wrap={false}
                                    >
                                      {learningOutcomesHeader.map(
                                        (header, headerIndex) => (
                                          <>
                                            <View
                                              style={{ width: header.width }}
                                              wrap={false}
                                            >
                                              <StudentReportTable
                                                text={
                                                  header.value === "successRate"
                                                    ? `% ${
                                                        outcome[
                                                          header.value
                                                        ] === ""
                                                          ? "0"
                                                          : outcome[
                                                              header.value
                                                            ]
                                                      }`
                                                    : outcome[header.value]
                                                }
                                                height={15}
                                                // fontWeight={"bold"}
                                                background={
                                                  header.value === "successRate"
                                                    ? successRateBgColor(
                                                        outcome[header.value]
                                                      )
                                                    : outcomeIndex % 2
                                                    ? "#164c8b0d"
                                                    : "transparent"
                                                }
                                                align="center"
                                                fontSize={7}
                                              />
                                            </View>
                                          </>
                                        )
                                      )}
                                    </View>
                                  </>
                                )
                              );
                            }
                          )}
                        </View>
                      </>
                    );
                  })}
              </View>
            </View>
          </View>

          {/* Acil Çalışılması Gereken Konular*/}
          <View style={{ paddingTop: 10 }} break>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height:
                    mergedData.headers.length * 15 + lessonHeadCnt * 15 + 45,
                  fontSize: 10,
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>
                    Acil Çalışılması Gereken Konular
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {mergedData.headers
                  .slice(0, mergedData.headers.length - 1)
                  .map((header, outcomeIndex) => {
                    return (
                      <>
                        <View
                          style={{
                            fontSize: 10,
                          }}
                        >
                          <View
                            style={[
                              stylesStudentReport.header,
                              { height: 15, backgroundColor: "#8aa5c5" },
                            ]}
                          >
                            <Text style={stylesStudentReport.headerText}>
                              {header}
                            </Text>
                          </View>
                          {mergedData.orderByDescLearningOutcomes.map(
                            (outcome, outcomeIndex) => {
                              return (
                                outcome.lessonName === header && (
                                  <>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        width: "100%",
                                      }}
                                      wrap={false}
                                    >
                                      {learningOutcomesHeader.map(
                                        (header, headerIndex) => (
                                          <>
                                            <View
                                              style={{ width: header.width }}
                                              wrap={false}
                                            >
                                              <StudentReportTable
                                                text={
                                                  header.value === "successRate"
                                                    ? `% ${
                                                        outcome[
                                                          header.value
                                                        ] === ""
                                                          ? "0"
                                                          : outcome[
                                                              header.value
                                                            ]
                                                      }`
                                                    : outcome[header.value]
                                                }
                                                height={15}
                                                // fontWeight={"bold"}
                                                background={
                                                  header.value === "successRate"
                                                    ? successRateBgColor(
                                                        outcome[header.value]
                                                      )
                                                    : outcomeIndex % 2
                                                    ? "#164c8b0d"
                                                    : "transparent"
                                                }
                                                align="center"
                                                fontSize={7}
                                              />
                                            </View>
                                          </>
                                        )
                                      )}
                                    </View>
                                  </>
                                )
                              );
                            }
                          )}
                        </View>
                      </>
                    );
                  })}
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: 10,
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const StudentExamReport = (props) => {
  const mergedData: ReportForStudentOutputModel = props.data;

  // µ

  const logoSrc: string = "http://gumusegitim.online/logo.png";

  const head1: { text: string }[] = [
    { text: "Ad Soyad" },
    { text: "Sınıf" },
    { text: "Tarih" },
  ];

  const learningOutcomesHeader: {
    text: string;
    width: string;
    value: string;
  }[] = [
    { value: "fillDate", text: "Tarih", width: "15%" },
    { value: "testName", text: "Test Adı", width: "37%" },
    { value: "questionCount", text: "Soru Sayısı", width: "8%" },
    { value: "correctAnswerCount", text: "Doğru Sayısı", width: "8%" },
    { value: "incorrectAnswerCount", text: "Yanlış Sayısı", width: "8%" },
    { value: "unansweredCount", text: "Boş Sayısı", width: "8%" },
    { value: "net", text: "Net", width: "8%" },
    { value: "successRate", text: "Başarı Oranı", width: "8%" },
  ];

  const failedQuestionsHeader: {
    text: string;
    width: string;
    value: string;
  }[] = [
    { value: "questionIndex", text: "Soru Numarası", width: "33%" },
    { value: "correctAnswer", text: "Doğru Cevap", width: "33%" },
    { value: "studentAnswer", text: "Öğrenci Cevabı", width: "34%" },
  ];

  // mergedData.examResults.map((item, index) => {
  //   if (index > 0) {
  //     if (item.lessonName !== mergedData.examResults[index - 1].lessonName)
  //       lessonHeadCnt++;
  //   } else {
  //     lessonHeadCnt++;
  //   }
  // });
  let headerLength = 0;
  let headerLength2 = 0;
  let testCount = 0;
  mergedData.exams.map((exam) => {
    headerLength2++;
    testCount = testCount + exam.testResults.length;
    exam.testResults.map((item) => headerLength++);
  });
  // mergedData.exams.map((exam, examIndex) => {
  //   exam.failedQuestions.map((item) => console.warn(item));
  //   console.warn(exam);
  // });

  let examCount = 0;
  let headerCount = 0;
  let lessonCount = 0;
  mergedData.exams.map((exam) => {
    lessonCount++;
    exam.testResults.map((item) => {
      examCount = examCount + item.failedQuestions.length;
    });
  });
  const splitFailedQuestionsArray = (arr, index) => {
    const oldArr = arr;
    const calculatedArr = Math.round(arr.length / 3);

    switch (index) {
      case 1:
        return oldArr.slice(0, calculatedArr * index);
      case 2:
        return oldArr.slice(calculatedArr, calculatedArr * index);
      case 3:
        return oldArr.slice(calculatedArr * 2, arr.length);

      default:
        break;
    }
  };

  return (
    <Document>
      <Page size="A4" style={stylesStudentReport.body} wrap>
        <Filigran />
        <View style={{ paddingTop: 10 }}>
          {/* Öğrenci Bilgileri*/}
          <View
            style={[stylesStudentReport.square, { height: 100, fontSize: 13 }]}
          >
            <View style={stylesStudentReport.header}>
              <View>
                <Text style={stylesStudentReport.headerText}>
                  GÜMÜŞ KİŞİSEL GELİŞİM KURSU
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "30%",
                  height: 68,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={logoSrc} />
              </View>
              <View
                style={{
                  width: "15%",
                  height: 69,
                }}
              >
                {head1.map((item) => (
                  <StudentReportTable
                    text={item.text}
                    fontWeight={"bold"}
                    height={23}
                    fontSize={8}
                  />
                ))}
              </View>
              <View
                style={{
                  width: "55%",
                  height: 69,
                }}
              >
                <StudentReportTable
                  text={mergedData.student.name}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={mergedData.student.classRoom?.name}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={dayjs().format("DD.MM.YYYY")}
                  height={23}
                  fontSize={8}
                />
              </View>
            </View>
          </View>

          <View style={{ paddingTop: 10 }} wrap>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height:
                    lessonCount * 20 + testCount * 15 + examCount * 15 + 45,
                  // height: "auto",
                  fontSize: 10,
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>Rapor</Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
                wrap={false}
              >
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
              </View>

              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
                // wrap={false}
              >
                {mergedData.exams.map((exam, examIndex) => {
                  return (
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <View
                        style={[
                          stylesStudentReport.header,
                          {
                            height: 15,
                            backgroundColor: "#8aa5c5",
                            width: "100%",
                          },
                        ]}
                        wrap={false}
                      >
                        <View>
                          <Text style={stylesStudentReport.headerText}>
                            {exam.lessonName}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          {exam.testResults.map((result, resultIndex) => {
                            return (
                              <View
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingBottom: "1%",
                                }}
                              >
                                <View
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {learningOutcomesHeader.map((header) => {
                                    return (
                                      <View
                                        style={{
                                          width: header.width,
                                          display: "flex",
                                        }}
                                      >
                                        <StudentReportTable
                                          text={
                                            header.value === "successRate"
                                              ? `% ${result[header.value]}`
                                              : result[header.value]
                                          }
                                          height={15}
                                          fontWeight={"bold"}
                                          background={
                                            header.value === "successRate"
                                              ? successRateBgColor(
                                                  result[header.value]
                                                )
                                              : "#bccbde"
                                          }
                                          align="center"
                                          fontSize={7}
                                        />
                                      </View>
                                    );
                                  })}
                                </View>

                                {/* FailedQuestions */}
                                <View
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                    borderLeft: 1,
                                    borderRight: 1,
                                    borderBottom: 1,
                                    borderColor: "black",
                                  }}
                                >
                                  {!!splitFailedQuestionsArray(
                                    result.failedQuestions,
                                    1
                                  ).length && (
                                    <View
                                      style={{
                                        width: "100%",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <>
                                        <View
                                          style={{
                                            width: "100%",
                                            flexDirection: "row",
                                          }}
                                        >
                                          {failedQuestionsHeader.map(
                                            (fHeader, headerIndex) => (
                                              <>
                                                <View
                                                  style={{
                                                    width: fHeader.width,
                                                  }}
                                                  wrap={false}
                                                >
                                                  <StudentReportTable
                                                    text={fHeader.text}
                                                    height={20}
                                                    fontWeight={"bold"}
                                                    align="center"
                                                    background={
                                                      fHeader.background
                                                    }
                                                    fontSize={7}
                                                  />
                                                </View>
                                              </>
                                            )
                                          )}
                                        </View>

                                        <View
                                          style={{
                                            width: "100%",
                                            flexDirection: "row",
                                          }}
                                          wrap={false}
                                        >
                                          {failedQuestionsHeader.map(
                                            (fHeader, headerIndex) => {
                                              return (
                                                <View
                                                  style={{
                                                    width: fHeader.width,
                                                    display: "flex",
                                                  }}
                                                >
                                                  {splitFailedQuestionsArray(
                                                    result.failedQuestions,
                                                    1
                                                  ).map(
                                                    (outcome, outcomeIndex) => {
                                                      return (
                                                        <View
                                                          style={{
                                                            width: "100%",
                                                            display: "flex",
                                                          }}
                                                          wrap={false}
                                                        >
                                                          <StudentReportTable
                                                            text={
                                                              fHeader.value ===
                                                              "successRate"
                                                                ? `% ${
                                                                    outcome[
                                                                      fHeader
                                                                        .value
                                                                    ]
                                                                  }`
                                                                : outcome[
                                                                    fHeader
                                                                      .value
                                                                  ]
                                                            }
                                                            height={15}
                                                            background={
                                                              "transparent"
                                                            }
                                                            align="center"
                                                            fontSize={7}
                                                          />
                                                        </View>
                                                      );
                                                    }
                                                  )}
                                                </View>
                                              );
                                            }
                                          )}
                                        </View>
                                      </>
                                    </View>
                                  )}
                                  {/* failed-2 */}

                                  {!!splitFailedQuestionsArray(
                                    result.failedQuestions,
                                    2
                                  ).length && (
                                    <View
                                      style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        borderLeft: 1,
                                        borderRight: 1,
                                        borderBottom: 1,
                                        borderColor: "black",
                                      }}
                                    >
                                      <>
                                        <View
                                          style={{
                                            width: "100%",
                                            flexDirection: "row",
                                          }}
                                        >
                                          {failedQuestionsHeader.map(
                                            (fHeader, headerIndex) => (
                                              <>
                                                <View
                                                  style={{
                                                    width: fHeader.width,
                                                    backgroundColor:
                                                      "#164c8b0d",
                                                  }}
                                                >
                                                  <StudentReportTable
                                                    text={fHeader.text}
                                                    height={20}
                                                    fontWeight={"bold"}
                                                    align="center"
                                                    background={
                                                      fHeader.background
                                                    }
                                                    fontSize={7}
                                                  />
                                                </View>
                                              </>
                                            )
                                          )}
                                        </View>
                                        <View
                                          style={{
                                            width: "100%",
                                            flexDirection: "row",
                                            backgroundColor: "#164c8b0d",
                                          }}
                                          wrap={false}
                                        >
                                          {failedQuestionsHeader.map(
                                            (fHeader, headerIndex) => {
                                              return (
                                                <View
                                                  style={{
                                                    width: fHeader.width,
                                                    display: "flex",
                                                  }}
                                                >
                                                  {splitFailedQuestionsArray(
                                                    result.failedQuestions,
                                                    2
                                                  ).map(
                                                    (outcome, outcomeIndex) => {
                                                      return (
                                                        <View
                                                          style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            backgroundColor:
                                                              "#164c8b0d",
                                                          }}
                                                        >
                                                          <StudentReportTable
                                                            text={
                                                              fHeader.value ===
                                                              "successRate"
                                                                ? `% ${
                                                                    outcome[
                                                                      fHeader
                                                                        .value
                                                                    ]
                                                                  }`
                                                                : outcome[
                                                                    fHeader
                                                                      .value
                                                                  ]
                                                            }
                                                            height={15}
                                                            // fontWeight={"bold"}
                                                            background={
                                                              fHeader.value ===
                                                              "successRate"
                                                                ? successRateBgColor(
                                                                    outcome[
                                                                      fHeader
                                                                        .value
                                                                    ]
                                                                  )
                                                                : outcomeIndex %
                                                                  2
                                                                ? "#164c8b0d"
                                                                : "transparent"
                                                            }
                                                            align="center"
                                                            fontSize={7}
                                                          />
                                                        </View>
                                                      );
                                                    }
                                                  )}
                                                </View>
                                              );
                                            }
                                          )}
                                        </View>
                                      </>
                                    </View>
                                  )}

                                  {/* failed-3 */}

                                  {!!splitFailedQuestionsArray(
                                    result.failedQuestions,
                                    3
                                  ).length && (
                                    <View
                                      style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        borderLeft: 1,
                                        borderRight: 1,
                                        borderBottom: 1,
                                        borderColor: "black",
                                      }}
                                    >
                                      <>
                                        <View
                                          style={{
                                            width: "100%",
                                            flexDirection: "row",
                                          }}
                                        >
                                          {failedQuestionsHeader.map(
                                            (fHeader, headerIndex) => (
                                              <>
                                                <View
                                                  style={{
                                                    width: fHeader.width,
                                                  }}
                                                >
                                                  <StudentReportTable
                                                    text={fHeader.text}
                                                    height={20}
                                                    fontWeight={"bold"}
                                                    align="center"
                                                    background={
                                                      fHeader.background
                                                    }
                                                    fontSize={7}
                                                  />
                                                </View>
                                              </>
                                            )
                                          )}
                                        </View>

                                        <View
                                          style={{
                                            width: "100%",
                                            flexDirection: "row",
                                          }}
                                          wrap={false}
                                        >
                                          {failedQuestionsHeader.map(
                                            (fHeader, headerIndex) => {
                                              return (
                                                <View
                                                  style={{
                                                    width: fHeader.width,
                                                    display: "flex",
                                                  }}
                                                  wrap={false}
                                                >
                                                  {splitFailedQuestionsArray(
                                                    result.failedQuestions,
                                                    3
                                                  ).map(
                                                    (outcome, outcomeIndex) => {
                                                      return (
                                                        <View
                                                          style={{
                                                            width: "100%",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <StudentReportTable
                                                            text={
                                                              fHeader.value ===
                                                              "successRate"
                                                                ? `% ${
                                                                    outcome[
                                                                      fHeader
                                                                        .value
                                                                    ]
                                                                  }`
                                                                : outcome[
                                                                    fHeader
                                                                      .value
                                                                  ]
                                                            }
                                                            height={15}
                                                            // fontWeight={"bold"}
                                                            background={
                                                              "transparent"
                                                            }
                                                            align="center"
                                                            fontSize={7}
                                                          />
                                                        </View>
                                                      );
                                                    }
                                                  )}
                                                </View>
                                              );
                                            }
                                          )}
                                        </View>
                                      </>
                                    </View>
                                  )}
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: 10,
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const StudentExamCompareReport = (props) => {
  const mergedData: TestReportForSubClassroomOutputModel = props.data;

  const logoSrc: string = "http://gumusegitim.online/logo.png";

  const head1: { text: string }[] = [
    { text: "Sınıf" },
    { text: "Şube" },
    { text: "Tarih" },
  ];

  const learningOutcomesHeader: {
    text: string;
    width: string;
    value: string;
  }[] = [
    { value: "rating", text: "Derece", width: "10%" },
    { value: "studentName", text: "Öğrenci Adı", width: "30%" },
    { value: "questionCount", text: "Soru Sayısı", width: "10%" },
    { value: "correctAnswerCount", text: "Doğru Sayısı", width: "10%" },
    { value: "incorrectAnswerCount", text: "Yanlış Sayısı", width: "10%" },
    { value: "unansweredCount", text: "Boş Sayısı", width: "10%" },
    { value: "net", text: "Net", width: "10%" },
    { value: "successRate", text: "Başarı Oranı", width: "10%" },
  ];

  let headerLength = 0;
  let headerLength2 = 0;
  mergedData.exams.map((exam) => {
    headerLength2++;
    exam.testResults.map((item) => headerLength++);
  });

  return (
    <Document>
      <Page size="A4" style={stylesStudentReport.body} wrap>
        <Filigran />
        <View style={{ paddingTop: 10 }}>
          {/* Öğrenci Bilgileri*/}
          <View
            style={[stylesStudentReport.square, { height: 100, fontSize: 13 }]}
          >
            <View style={stylesStudentReport.header}>
              <View>
                <Text style={stylesStudentReport.headerText}>
                  GÜMÜŞ KİŞİSEL GELİŞİM KURSU
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "30%",
                  height: 68,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={logoSrc} />
              </View>
              <View
                style={{
                  width: "15%",
                  height: 69,
                }}
              >
                {head1.map((item) => (
                  <StudentReportTable
                    text={item.text}
                    fontWeight={"bold"}
                    height={23}
                    fontSize={8}
                  />
                ))}
              </View>
              <View
                style={{
                  width: "55%",
                  height: 69,
                }}
              >
                <StudentReportTable
                  text={mergedData.info.classroomInfo.classroom.name}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={`${mergedData.info.classroomInfo.name} Şubesi`}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={dayjs(mergedData.info.date).format("DD.MM.YYYY")}
                  height={23}
                  fontSize={8}
                />
              </View>
            </View>
          </View>

          {/* Kazanım Listesi*/}
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height: 680,
                  fontSize: 10,
                  alignItems: "flex-start",
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>Rapor</Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
              </View>

              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {mergedData.exams.map((exam, examIndex) => (
                  <View
                    style={{
                      width: "50%",
                      flexDirection: "column",
                      // marginBottom: 10, // İsterseniz aralara boşluk ekleyebilirsiniz
                    }}
                    key={examIndex}
                  >
                    <View
                      style={[
                        stylesStudentReport.header,
                        {
                          height: 15,
                          backgroundColor: "#8aa5c5",
                          width: "100%",
                        },
                      ]}
                    >
                      <View
                        style={{
                          width: "100%",
                        }}
                        wrap={false}
                      >
                        <StudentReportTable
                          text={exam?.testName}
                          height={20}
                          fontWeight={"bold"}
                          textColor={"white"}
                          align="center"
                          // background={header.background}
                          fontSize={8}
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      {learningOutcomesHeader.map((header, headerIndex) => (
                        <View
                          style={{
                            width: header.width,
                            display: "flex",
                          }}
                          key={headerIndex}
                        >
                          {exam.testResults.map((outcome, outcomeIndex) => (
                            <View
                              style={{
                                width: "100%",
                                display: "flex",
                              }}
                              key={outcomeIndex}
                            >
                              <StudentReportTable
                                text={
                                  header.value === "successRate"
                                    ? `% ${outcome[header.value]}`
                                    : header.value === "rating"
                                    ? outcomeIndex + 1
                                    : header.value === "studentName"
                                    ? outcome.student.name
                                    : outcome[header.value]
                                }
                                height={15}
                                background={
                                  header.value === "successRate"
                                    ? successRateBgColor(outcome[header.value])
                                    : outcomeIndex % 2
                                    ? "#164c8b0d"
                                    : "transparent"
                                }
                                align="center"
                                fontSize={7}
                              />
                            </View>
                          ))}
                        </View>
                      ))}
                      <View
                        style={{
                          width: "50%",
                          display: "flex",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <StudentReportTable
                            text={"Ortalama Başarı Oranı"}
                            height={15}
                            background={"#D1DDE8"}
                            align="center"
                            fontWeight="bold"
                            fontSize={7}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          width: "50%",
                          display: "flex",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <StudentReportTable
                            text={`% ${exam.average}`}
                            height={15}
                            background={successRateBgColor(exam.average)}
                            fontWeight="bold"
                            align="center"
                            fontSize={7}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: 10,
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const SubClassroomExamCompareReport = (props) => {
  const mergedData: TestReportForSubClassroomOutputModel = props.data;

  const logoSrc: string = "http://gumusegitim.online/logo.png";

  const head1: { text: string }[] = [{ text: "Sınıf" }, { text: "Tarih" }];

  const learningOutcomesHeader: {
    text: string;
    width: string;
    value: string;
  }[] = [
    { value: "rating", text: "Derece", width: "10%" },
    { value: "studentName", text: "Şube Adı", width: "30%" },
    { value: "questionCount", text: "Soru Sayısı", width: "10%" },
    { value: "correctAnswerCount", text: "Doğru Sayısı", width: "10%" },
    { value: "inCorrectAnswerCount", text: "Yanlış Sayısı", width: "10%" },
    { value: "unasweredCount", text: "Boş Sayısı", width: "10%" },
    { value: "net", text: "Net", width: "10%" },
    { value: "successRate", text: "Başarı Oranı", width: "10%" },
  ];

  let headerLength = 0;
  let headerLength2 = 0;
  mergedData.exams.map((exam) => {
    headerLength2++;
    exam.testResults.map((item) => headerLength++);
  });

  return (
    <Document>
      <Page size="A4" style={stylesStudentReport.body} wrap>
        <Filigran />
        <View style={{ paddingTop: 10 }}>
          {/* Öğrenci Bilgileri*/}
          <View
            style={[stylesStudentReport.square, { height: 100, fontSize: 13 }]}
          >
            <View style={stylesStudentReport.header}>
              <View>
                <Text style={stylesStudentReport.headerText}>
                  GÜMÜŞ KİŞİSEL GELİŞİM KURSU
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "30%",
                  height: 68,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={logoSrc} />
              </View>
              <View
                style={{
                  width: "15%",
                  height: 69,
                }}
              >
                {head1.map((item) => (
                  <StudentReportTable
                    text={item.text}
                    fontWeight={"bold"}
                    height={23}
                    fontSize={8}
                  />
                ))}
              </View>
              <View
                style={{
                  width: "55%",
                  height: 69,
                }}
              >
                <StudentReportTable
                  text={mergedData.info.classroomInfo?.name}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={dayjs(mergedData.info.date).format("DD.MM.YYYY")}
                  height={23}
                  fontSize={8}
                />
              </View>
            </View>
          </View>

          {/* Kazanım Listesi*/}
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height: 680,
                  fontSize: 10,
                  alignItems: "flex-start",
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>Rapor</Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
                {learningOutcomesHeader.map((header, headerIndex) => (
                  <View
                    style={{
                      width: header.width,
                    }}
                    wrap={false}
                  >
                    <StudentReportTable
                      text={header.text}
                      height={20}
                      fontWeight={"bold"}
                      align="center"
                      background={header.background}
                      fontSize={7}
                    />
                  </View>
                ))}
              </View>

              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {mergedData.exams.map((exam, examIndex) => (
                  <View
                    style={{
                      width: "50%",
                      flexDirection: "column",
                      // marginBottom: 10, // İsterseniz aralara boşluk ekleyebilirsiniz
                    }}
                    key={examIndex}
                  >
                    <View
                      style={[
                        stylesStudentReport.header,
                        {
                          height: 15,
                          backgroundColor: "#8aa5c5",
                          width: "100%",
                        },
                      ]}
                    >
                      <View
                        style={{
                          width: "100%",
                        }}
                        wrap={false}
                      >
                        <StudentReportTable
                          text={exam?.testName}
                          height={20}
                          fontWeight={"bold"}
                          textColor={"white"}
                          align="center"
                          // background={header.background}
                          fontSize={8}
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      {learningOutcomesHeader.map((header, headerIndex) => (
                        <View
                          style={{
                            width: header.width,
                            display: "flex",
                          }}
                          key={headerIndex}
                        >
                          {exam.testResults.map((outcome, outcomeIndex) => (
                            <View
                              style={{
                                width: "100%",
                                display: "flex",
                              }}
                              key={outcomeIndex}
                            >
                              <StudentReportTable
                                text={
                                  header.value === "successRate"
                                    ? `% ${outcome[header.value]}`
                                    : header.value === "rating"
                                    ? outcomeIndex + 1
                                    : header.value === "studentName"
                                    ? outcome?.subclassroom?.name
                                    : outcome[header.value]
                                }
                                height={15}
                                background={
                                  header.value === "successRate"
                                    ? successRateBgColor(outcome[header.value])
                                    : outcomeIndex % 2
                                    ? "#164c8b0d"
                                    : "transparent"
                                }
                                align="center"
                                fontSize={7}
                              />
                            </View>
                          ))}
                        </View>
                      ))}
                      <View
                        style={{
                          width: "50%",
                          display: "flex",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <StudentReportTable
                            text={"Ortalama Başarı Oranı"}
                            height={15}
                            background={"#D1DDE8"}
                            align="center"
                            fontWeight="bold"
                            fontSize={7}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          width: "50%",
                          display: "flex",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <StudentReportTable
                            text={`% ${exam.average}`}
                            height={15}
                            background={successRateBgColor(exam.average)}
                            fontWeight="bold"
                            align="center"
                            fontSize={7}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: 10,
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const PracticeExamCompareReport = (props) => {
  const logoSrc: string = "http://gumusegitim.online/logo.png";

  const head1: { text: string }[] = [
    { text: "Sınav Adı" },
    { text: "Sınav Türü" },
    { text: "Tarih" },
  ];

  const learningOutcomesHeader: {
    text: string;
    width: string;
    value: string;
  }[] = [
    { value: "rating", text: "Sıralama", width: "10%" },
    { value: "studentName", text: "Öğrenci", width: "30%" },
    { value: "questionCount", text: "Soru Sayısı", width: "10%" },
    { value: "correctAnswerCount", text: "Dooğru Sayısı", width: "10%" },
    { value: "incorrectAnswerCount", text: "Yanlış Sayısı", width: "10%" },
    { value: "unansweredCount", text: "Boş Sayısı", width: "10%" },
    { value: "net", text: "Net", width: "10%" },
    { value: "successRate", text: "Başarı Oranı", width: "10%" },
  ];

  const mergedData: {
    info: {
      examName: string;
      examType: string;
      date: string;
    };
    headers: string[];
    sort: {
      lessonName: string;
      studentName: string;
      questionCount: number;
      correctAnswerCount: number;
      incorrectAnswerCount: number;
      unansweredCount: number;
      net: number;
      successRate: number | string;
      rating: number;
    }[];
  } = {
    info: {
      examName: "Deneme",
      examType: "TYT",
      date: "06.01.2024",
    },
    headers: ["TURK", "MAT"],
    sort: [
      {
        lessonName: "TURK",
        studentName: "BERK DUMAN",
        questionCount: 10,
        correctAnswerCount: 10,
        incorrectAnswerCount: 0,
        unansweredCount: 0,
        net: 10,
        successRate: "100",
        rating: 1,
      },
      {
        lessonName: "TURK",
        studentName: "EMRECC",
        questionCount: 10,
        correctAnswerCount: 10,
        incorrectAnswerCount: 0,
        unansweredCount: 0,
        net: 10,
        successRate: "100",
        rating: 2,
      },
    ],
  };

  // let lessonHeadCnt = 0;

  // mergedData.learningOutcomes.map((item, index) => {
  //   if (mergedData.headers.includes(item.lessonName)) {
  //     lessonHeadCnt++;
  //   }
  // });

  return (
    <Document>
      <Page size="A4" style={stylesStudentReport.body} wrap>
        <Filigran />
        <View style={{ paddingTop: 10 }}>
          {/* Öğrenci Bilgileri*/}
          <View
            style={[stylesStudentReport.square, { height: 100, fontSize: 13 }]}
          >
            <View style={stylesStudentReport.header}>
              <View>
                <Text style={stylesStudentReport.headerText}>
                  GÜMÜŞ KİŞİSEL GELİŞİM KURSU
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "30%",
                  height: 68,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={logoSrc} />
              </View>
              <View
                style={{
                  width: "15%",
                  height: 69,
                }}
              >
                {head1.map((item) => (
                  <StudentReportTable
                    text={item.text}
                    fontWeight={"bold"}
                    height={23}
                    fontSize={8}
                  />
                ))}
              </View>
              <View
                style={{
                  width: "55%",
                  height: 69,
                }}
              >
                <StudentReportTable
                  text={mergedData.info.examName}
                  height={23}
                  fontSize={8}
                />

                <StudentReportTable
                  text={mergedData.info.examType}
                  height={23}
                  fontSize={8}
                />
                <StudentReportTable
                  text={dayjs(mergedData.info.date).format("DD.MM.YYYY")}
                  height={23}
                  fontSize={8}
                />
              </View>
            </View>
          </View>
          {/* Sınav Bazlı Netler */}
          <View style={{ paddingTop: 10 }}>
            <View
              style={[
                stylesStudentReport.square,
                {
                  height: 680,
                  fontSize: 10,
                },
              ]}
            >
              <View style={[stylesStudentReport.header, { height: 25 }]}>
                <View>
                  <Text style={stylesStudentReport.headerText}>
                    Ders Bazlı Sıralama
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {mergedData.headers.map((header, headerIndex) => {
                  return (
                    <>
                      <StudentReportTable
                        text={header}
                        height={20}
                        fontWeight={"bold"}
                        align="center"
                        textColor="white"
                        background={"#8aa5c5"}
                        fontSize={7}
                      />

                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                        }}
                      >
                        {learningOutcomesHeader.map((outcome, outcomeIndex) => {
                          return (
                            <View
                              style={{
                                width: outcome.width,
                                flexDirection: "row",
                              }}
                            >
                              <StudentReportTable
                                text={outcome.text}
                                height={20}
                                fontWeight={"bold"}
                                align="center"
                                background={"#b8c9dc"}
                                fontSize={7}
                              />
                            </View>
                          );
                        })}
                      </View>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                        }}
                      >
                        {learningOutcomesHeader.map((outcome, outcomeIndex) => {
                          return (
                            <View
                              style={{
                                width: outcome.width,
                                flexDirection: "column",
                              }}
                            >
                              {mergedData.sort.map((sort, sortIndex) => (
                                <View
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                >
                                  <StudentReportTable
                                    text={
                                      outcome.value === "successRate"
                                        ? `% ${
                                            sort[outcome.value] === ""
                                              ? "0"
                                              : sort[outcome.value]
                                          }`
                                        : sort[outcome.value]
                                    }
                                    height={20}
                                    fontWeight={"bold"}
                                    align="center"
                                    background={
                                      outcome.value === "successRate"
                                        ? successRateBgColor(
                                            sort[outcome.value]
                                          )
                                        : sortIndex % 2
                                        ? "#164c8b0d"
                                        : "transparent"
                                    }
                                    fontSize={7}
                                  />
                                </View>
                              ))}
                            </View>
                          );
                        })}
                      </View>
                    </>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
        <Text
          style={{
            fontSize: 10,
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDF;
