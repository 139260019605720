// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ClassroomService from "../../services/classroomService";
import ProfileWidget from "../profileWidget";
import StudentLessonProgress from "./studentLessonProgress";
import Header from "../header";

export default function TeacherClassroom() {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const { classroomId } = useParams<any>();
  const classroomService = new ClassroomService();
  const [classStudentList, setClassStudentList] = useState<
    Array<StudentOutputModel>
  >([]);
  const [className, setClassName] = useState();
  const getClasroom = async () => {
    dispatch(showLoader());
    const result = await classroomService.GetTeacherClassroomList(classroomId);
    if (result) {
      setClassStudentList(result.students);
      setClassName(result.name);
    }
    dispatch(hideLoader());
  };

  useEffect(() => {
    getClasroom();
  }, [classroomId]);

  return (
    <Switch>
      <Route path={`${path}/:studentId/:lessonId`}>
        <StudentLessonProgress />
      </Route>
      <Route path={path}>
        <Header name={`${className} Sınıfı`} />
        <div className="row">
          {classStudentList.map((s) => (
            <ProfileWidget
              name={s.name}
              surname={s.surname}
              photoUrl={s.profilePhoto}
              links={s.lessons.map((l) => ({
                tag: l.lesson.name,
                url: `/teacher/classroom/${classroomId}/${s.id}/${l.lesson.id}`,
              }))}
            />
          ))}
        </div>
      </Route>
    </Switch>
  );
}
