import { useContext, useEffect } from "react";
import { StudentIdentityContext } from "../panels/studentPanel";
import StudentService from "../../services/studentService";
import { useParams } from "react-router-dom";
import Header from "../header";
import { useState } from "react";
import ExamListSelect from "./ExamListSelect";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import Modal from "../sharedComponents/modal";
import BaseService from "../../services/baseService";
import RoleService, { Roles } from "../../services/roleService";
import TestService from "../../services/testService";
import { CreatequestionPdf, createTestPdf } from "../sharedComponents/testpdf";

export default function FailedQuestionsDetail(props: { studentId?: string }) {
  const dispatch = useDispatch();
  const roleService = new RoleService();
  const studentContext = useContext(StudentIdentityContext);
  const studentService = new StudentService();
  const testService = new TestService();
  const baseService = new BaseService();
  const { lessonId } = useParams<any>();
  const lessonList = studentContext.lessons;
  const [failedQuestions, setFailedQuestions] =
    useState<Array<StudentFailedQuestionOutputModel>>();
  const [selectedExams, setSelectedExams] = useState<any>([]);
  const [selectedFailedQuestions, setSelectedFailedQuestions] = useState<any>(
    []
  );
  const [failedQuestionCnt, setFailedQuestionCnt] = useState<number>(0);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [viewableQuestion, setViewableQuestion] = useState<any>(null);
  const [cntType, setCntType] = useState<"Toplam" | "Seçilen">("Toplam");
  const [roles, setRoles] = useState<Array<string>>(null);
  const [srcDoc, setSrcDoc] = useState(null);
  const getUserRoles = async () => {
    const response = await roleService.GetUserRoles();
    setRoles(response);
  };
  const getFailedQuestions = async () => {
    dispatch(showLoader());
    if ((roles || []).includes(Roles.Teacher)) {
      getStudentsFailedQuestionList();
    } else if ((roles || []).includes(Roles.Student)) {
      // const response = await studentService.GetStudentFailedQuestion(lessonId);
      const response =
        await studentService.GetStudentFailedQuestionByLessonGenericId(
          lessonId
        );

      setFailedQuestions(response);
    }
    dispatch(hideLoader());
  };

  const getStudentsFailedQuestionList = async () => {
    const result = await testService.GetStudentsFailedQuestionList(
      lessonId,
      props.studentId
    );
    if (result) setFailedQuestions(result);
  };

  const getAllFailedQuestionCnt = () => {
    const cnt = (failedQuestions || []).map((item) => {
      setFailedQuestionCnt(
        (failedQuestionCnt) =>
          failedQuestionCnt +
          item.testQuestionWithStudentAnswerOutputModel.length
      );
    });
    setCntType("Toplam");
  };

  const getSelectedQuestionList = () => {
    const q = (failedQuestions || []).filter((item, index) => {
      if (selectedExams.includes(item.test.id.toString())) {
        item.testQuestionWithStudentAnswerOutputModel.map((question) =>
          setSelectedFailedQuestions((selectedFailedQuestions) => [
            ...selectedFailedQuestions,
            {
              ...question.testQuestion,
              studentAnswer: question.studentAnswer,
              order: question.order,
              index: index,
            },
          ])
        );
      }
    });
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  useEffect(() => {
    getFailedQuestions();
  }, [lessonId, roles]);

  useEffect(() => {
    getAllFailedQuestionCnt();
  }, [failedQuestions]);

  useEffect(() => {
    setFailedQuestionCnt(selectedFailedQuestions.length);
    setCntType("Seçilen");
    if (!selectedExams.length) {
      getAllFailedQuestionCnt();
    }
  }, [selectedFailedQuestions]);

  useEffect(() => {
    setSelectedFailedQuestions([]);
    getSelectedQuestionList();
  }, [selectedExams]);

  const createPdf = async () => {
    dispatch(showLoader());
    const data = await CreatequestionPdf(
      (roles || []).includes(Roles.Teacher)
        ? failedQuestions?.[0]?.test.lessonName
        : lessonList.find((item) => item.id === Number(lessonId))?.name,
      "",
      "",
      selectedFailedQuestions.map((item, index) => ({
        ...item,
      }))
    );

    setSrcDoc(data);
    dispatch(hideLoader());
  };

  const showQuestionModal = (questionId: number, index: number) => {
    setViewableQuestion(
      selectedFailedQuestions.find(
        (fq) => fq.id == questionId && fq.index == index
      )
    );
    setModalVisibility(true);
  };
  return (
    <>
      <Header
        name={`${
          failedQuestions?.[0]?.test.lessonName
          // (roles || []).includes(Roles.Teacher)
          //   ? failedQuestions?.[0]?.test.lessonName
          //   : lessonList.find((item) => item.id === Number(lessonId))?.name
        } Yapılamayan Sorular`}
      />

      <div className="col-md-12 h-75 d-inline-block">
        <div className="card">
          <div className="card-header">
            <h2 className="card-title">
              Çözülemeyen Sorular ({cntType} {failedQuestionCnt})
            </h2>
            <div className="buttons">
              {
                <button
                  className="btn btn-primary"
                  onClick={createPdf}
                  disabled={failedQuestionCnt <= 0 || cntType === "Toplam"}
                >
                  Pdf İndir
                </button>
              }
              {/* <button
                // onClick={() => setStudentResultsModalVisibility(true)}
                className="btn btn-success"
              >
                Başarı Durumu
              </button> */}
            </div>
            {failedQuestionCnt > 0 && (
              <div className="col-12 ">
                <b>Sınav Seç : </b>
                <ExamListSelect
                  setSelected={setSelectedExams}
                  list={failedQuestions}
                />
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="row d-flex justify-content-between">
              {selectedFailedQuestions?.map((q, index) => (
                <div className="col-lg-4">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => showQuestionModal(q.id, q.index)}
                    className="accordion-item card card-md card-body shadow"
                  >
                    <i
                      onClick={() => showQuestionModal(q.id, q.index)}
                      className="fas fa-eye"
                    ></i>
                    <b>Soru {index + 1}</b>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {viewableQuestion ? (
        <Modal
          showButtonRight={false}
          title="Soru"
          modalVisibility={modalVisibility}
          onclose={() => setModalVisibility(false)}
        >
          {viewableQuestion.questionUrl ? (
            <img
              src={
                baseService.endPoints().pdfUrl +
                "/testquestionbank/" +
                viewableQuestion.questionUrl
              }
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: viewableQuestion.question,
              }}
            />
          )}
          <div>
            {(viewableQuestion.testQuestionAnswers || []).map((a) => (
              <div>
                <span
                  style={{ fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{ __html: a.answerKey }}
                ></span>
                <span style={{ fontWeight: "bold" }}>)</span>
                <span
                  dangerouslySetInnerHTML={{ __html: a.answerValue }}
                ></span>
              </div>
            ))}
          </div>
          <div>
            <i className="fas fa-check text-success"></i>{" "}
            <b className="text-success">
              Doğru Cevap: {viewableQuestion.correctAnswer}
            </b>
            <br />
            <i className="fas fa-times text-danger"></i>{" "}
            <b className="text-danger">
              Öğrencinin Cevabı: {viewableQuestion.studentAnswer}
            </b>
          </div>
        </Modal>
      ) : null}
      <iframe style={{ visibility: "hidden" }} srcDoc={srcDoc}></iframe>
    </>
  );
}
