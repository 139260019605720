import "../style/examresultupload.css";

import React, { useEffect, useRef, useState } from "react";
import Choices from "choices.js";

var choices: Choices;

export default function ExamListSelect(props: {
  list: Array<StudentFailedQuestionOutputModel>;
  setSelected: (key) => void;
  disabled?: boolean;
}) {
  const ref = useRef(null);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    choices && choices.destroy();
    const choicesList = [];
    props.list.map((l, index) =>
      choicesList.push({
        value: l.test.id,
        label: l.test.name,
      })
    );
    choices = new Choices(ref.current, {
      itemSelectText: "Seçmek için tıklayınız",
      removeItemButton: true,
      choices: choicesList.map((m, index) => ({
        // value: `${m.value}-${index}`.toString(),
        value: m.value.toString(),
        label: m.label,
        selected: selected.includes(m.value),
      })),
    });
  }, [props.list]);

  useEffect(() => {
    if (choices) {
      if (props.disabled) {
        choices.disable();
      } else {
        choices.enable();
      }
    }
  }, [props.disabled]);

  const selectChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(
      Array.from(event.target.selectedOptions, (option) => option.value)
    );
    props.setSelected(
      Array.from(event.target.selectedOptions, (option) => option.value)
    );
  };

  return (
    <>
      <select
        className="choices form-select multiple-remove"
        ref={ref}
        multiple
        onChange={selectChangeHandler}
      ></select>
    </>
  );
}
