import React, { useEffect } from 'react'

export default function UserPanelFooter() {
    return (
        <footer>
            <div className="footer clearfix mb-0 text-muted">
                <div className="float-start">
                    <p>2021 &copy; <a href="https://www.gumusegitim.com">gumusegitim.com</a> - </p>
                </div>
                <div className="float-end">
                    <p>Tüm hakları saklıdır </p>
                </div>
            </div>
        </footer>
    );
}