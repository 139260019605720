// @ts-nocheck
import { useMemo, useState, useEffect } from "react";
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { ReactNode } from "react";
import { Box, ActionIcon, Tooltip, Text } from "@mantine/core";
import {
  IconEdit,
  IconListDetails,
  IconSend,
  IconTrash,
} from "@tabler/icons-react";

const AdvancedTable = ({
  dataTable,
  data,
  sendButton,
  deleteButton,
  detailsButton,
  editButton,
  detailsButtonTooltip,
  extraButtons,
  rowSelect,
  filters,
}: {
  dataTable: {
    accessorKey: string;
    header: string;
    accessorFn?: (row) => Void;
    minSize?: number;
    maxSize?: number;
    size?: number;
  }[];
  data: any;
  sendButton?: (row: any) => Void;
  deleteButton?: (row: any) => Void;
  detailsButton?: (row: any) => Void;
  editButton?: (row: any) => Void;
  extraButtons?: (row: any) => ReactNode;
  detailsButtonTooltip?: string;
  rowSelect?: (rows: any) => Void;
  filters?: boolean;
}) => {
  const columns = useMemo<MRT_ColumnDef<typeof data>[]>(() => dataTable, []);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    rowSelect && rowSelect(rowSelection);
  }, [rowSelection]);

  const table = useMantineReactTable({
    localization: {
      actions: "İşlem",
      and: "ve",
      cancel: "İptal",
      showAll: "Hepsini Göster",
      hideAll: "Hepsini Gizle",
      showHideFilters: "Filtre Gizle/Göster",
      showHideSearch: "Arama",
      changeFilterMode: "Filtre Modunu Değiştir",
      changeSearchMode: "Arama Modunu Değiştir",
      clearFilter: "Filtreyi Temizle",
      clearSearch: "Aramayı Temizle",
      clearSort: "Sıralamayı Temizle",
      clickToCopy: "Kopyala",
      toggleFullScreen: "Tam Ekran",
      toggleDensity: "Çözünürlük Değiştir",
      showHideColumns: "Kolon Gizle/Göster",
      hideColumn: "Kolonu Gizle",
      filterByColumn: "Kolona Göre Filtrele",
      sortByColumnDesc: "Kolona Göre Azalan Sırala",
      sortByColumnAsc: "Kolona Göre Artan Sırala",
      showAllColumns: "Bütün Kolonları Göster",
      sortedByColumnAsc: "Kolona Göre Artan Sıralandı",
      sortedByColumnDesc: "Kolona Göre Azalan Sıralandı",
      columnActions: "Kolon İşlemleri",
      search: "Arama",
      noResultsFound: "Bulunamadı :(",
      noRecordsToDisplay: "Veri Yok :(",
      rowsPerPage: "Sayfa Başı Satır Sayısı",
    },
    columns,
    data,
    paginationDisplayMode: "pages",
    enableRowActions:
      extraButtons || sendButton || editButton || detailsButton || deleteButton
        ? true
        : false,
    positionActionsColumn: "last",

    enableRowSelection: rowSelect ? true : false,
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableColumnActions: filters,
    enableSorting: filters,
    mantinePaperProps: {
      shadow: "none",
      withBorder: false,
      sx: {
        borderRadius: "0",
        border: "0px solid #e1e4e8",
        borderTopWidth: 1,
      },
    },

    renderRowActions: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            gap: "8px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {sendButton && (
              <Tooltip label="Gönder">
                <ActionIcon
                  color="blue"
                  onClick={() => sendButton(row.original)}
                >
                  <IconSend />
                </ActionIcon>
              </Tooltip>
            )}
            {editButton && (
              <Tooltip label="Düzenle">
                <ActionIcon
                  color="orange"
                  onClick={() => editButton(row.original)}
                >
                  <IconEdit />
                </ActionIcon>
              </Tooltip>
            )}
            {detailsButton && (
              <Tooltip
                label={detailsButtonTooltip ? detailsButtonTooltip : "Detay"}
              >
                <ActionIcon
                  color="gray"
                  onClick={() => detailsButton(row.original)}
                >
                  <IconListDetails />
                </ActionIcon>
              </Tooltip>
            )}
            {deleteButton && (
              <Tooltip label="Sil">
                <ActionIcon
                  color="red"
                  onClick={() => deleteButton(row.original)}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {extraButtons && extraButtons(row.original)}
          </div>
        </Box>
      );
    },
    initialState: {
      expanded: false,
      density: "xs",
    },

    mantineTableProps: {
      striped: true,
    },

    // renderDetailPanel: ({ row }) => {
    //   return (
    //     <Box
    //       sx={{
    //         display: "grid",
    //         margin: "auto",
    //         gridTemplateColumns: "1fr 1fr",
    //         width: "100%",
    //       }}
    //     >
    //       <Text>Address: {row.original.address}</Text>
    //     </Box>
    //   );
    // },
  });

  return <MantineReactTable table={table} />;
};

export default AdvancedTable;
