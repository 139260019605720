// @ts-nocheck
import React, { useEffect, useState } from "react";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import "react-datepicker/dist/react-datepicker.css";
import OpticsService from "../../services/opticsService";
import "react-datepicker/dist/react-datepicker.css";
import AdvancedTable from "./AdvancedTable";
import Select from "react-select";
import dayjs from "dayjs";
import MeetService from "../../services/meetService";
import ComponentLoader from "../sharedComponents/componentLoader";

enum VisibleModalType {
  answerKey = "A",
  examResult = "B",
}

export default function MeetsPage() {
  const opticService = new OpticsService();
  const studentService = new StudentService();
  const meetService = new MeetService();

  const dispatch = useDispatch();

  const [consentModalVisibility, setConsentModalVisibility] = useState(false);
  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);

  const [studentList, setStudentList] = useState<Array<StudentOutputModel>>([]);

  const [detailsModalVis, setDetailsModalVis] = useState<boolean>(false);

  const [selectedStudent, setSelectedStudent] = useState<string>("");
  const [selectedId, setSelectedId] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [formText, setFormText] = useState<string>("");
  const [formInterviewer, setFormInterviewer] = useState<string>("");
  const [meetData, setMeetData] = useState<MeetOutputModel>({
    student: {},
    notes: [],
  });
  const [trig, setTrig] = useState<boolean>(false);

  const getStudentList = async () => {
    dispatch(showLoader());
    const response = await studentService.GetStudentList();
    setStudentList(response);
    dispatch(hideLoader());
  };

  const getMeetsNotes = async () => {
    if (selectedStudent !== "") {
      setLoader(true);
      const response = await meetService.GetStudentMeets(selectedStudent.value);
      if (Array.isArray(response)) {
        setMeetData({
          student: {},
          notes: [],
        });
      } else {
        setMeetData(response);
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    getMeetsNotes();
  }, [selectedStudent, trig]);

  useEffect(() => {
    getStudentList();
  }, []);

  const handleSave = async () => {
    dispatch(showLoader());
    const meetObj: MeetInputModel = {
      studentId: selectedStudent.value,
      note: formText,
      interviewer: formInterviewer,
    };
    const response = await meetService.CreateMeet(meetObj);
    setAddNewModalVis(false);
    setFormText("");
    setFormInterviewer("");
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const handleDelete = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    // const response = await opticService.DeleteOptic(selectedId);
    const response = await meetService.DeleteMeet(selectedId);
    setTrig((trig) => !trig);
    dispatch(hideLoader());
  };

  const selectStudentOptions = studentList.map((item) => ({
    value: item.id,
    label: item.name + " " + item.surname,
  }));

  const customDataTable = [
    {
      accessorFn: (row) => {
        return row.note.length > 80
          ? `${row.note.slice(0, 80)} ...`
          : `${row.note}`;
      },
      accessorKey: "note",
      header: "Görüşme Notu",
      maxSize: 100,
    },
    {
      accessorFn: (row) => `${row.user.name} ${row.user.surname}`,
      header: "Yetkili",
      maxSize: 100,
    },
    {
      accessorKey: "interviewer",
      header: "Görüşülen Kişi",
      maxSize: 100,
    },
    {
      accessorFn: (row) => `${dayjs(row.issueDate).format("DD.MM.YYYY")}`,
      accessorKey: "date",
      header: "Tarih",
      maxSize: 100,
    },
  ];

  const data: {
    id: number;
    student: StudentOutputModel;
    user: {
      id: string;
      name: string;
      surname: string;
    };
    interviewer: string;
    note: string;
    issueDate: string;
    isDeleted: boolean;
  }[] = meetData?.notes;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div
              className={
                "col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12 d-flex justify-content-start align-items-center pt-2"
              }
            >
              <Select
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  container: (base) => ({
                    ...base,
                    width: "100%",
                  }),
                }}
                menuPosition={"fixed"}
                aria-label="Default select example"
                options={selectStudentOptions}
                onChange={(selectedOption) => {
                  setSelectedStudent(selectedOption);
                }}
                placeholder="Lütfen Öğrenci Seçiniz"
              />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12 pt-2  d-flex justify-content-center align-items-center">
              <button
                onClick={() => {
                  setAddNewModalVis(true);
                  //   setFormOpticName("");
                  //   setFormPracticeExamPublisher("");
                  //   dispatch(setSidebarStatusModal(false));
                  //   setMatchedParameters([]);
                }}
                type="button"
                className="btn btn-primary ml-1 btn-l"
                disabled={selectedStudent === ""}
              >
                Görüşme Ekle
              </button>
            </div>
          </div>
          {loader ? (
            <div className="pt-5">
              <ComponentLoader />
            </div>
          ) : (
            <AdvancedTable
              data={data}
              dataTable={customDataTable}
              filters={false}
              deleteButton={(row) => {
                setSelectedId(row.id);
                setConsentModalVisibility(true);
              }}
              detailsButton={(row) => {
                setSelectedId(row.id);
                setDetailsModalVis(true);
              }}
            />
          )}
        </div>
      </div>

      {detailsModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Görüşme Detayı
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="form-group col-4">
                    <label className="font-weight-bold">Görüşme Tarihi:</label>
                    <h6>
                      {dayjs(
                        meetData.notes.find((note) => note.id === selectedId)
                          .issueDate
                      ).format("DD.MM.YYYY")}
                    </h6>
                  </div>

                  <div className="form-group col-4">
                    <label className="font-weight-bold">Yetkili:</label>
                    <h6>
                      {`${
                        meetData.notes.find((note) => note.id === selectedId)
                          .user.name
                      } ${
                        meetData.notes.find((note) => note.id === selectedId)
                          .user.surname
                      }`}
                    </h6>
                  </div>

                  <div className="form-group col-4">
                    <label className="font-weight-bold">Görüşülen Kişi:</label>
                    <h6>
                      {
                        meetData.notes.find((note) => note.id === selectedId)
                          .interviewer
                      }
                    </h6>
                  </div>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görüşme Notu:</label>
                    <h6>
                      {
                        meetData.notes.find((note) => note.id === selectedId)
                          .note
                      }
                    </h6>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setDetailsModalVis(false);
                    setFormText("");
                    setFormInterviewer("");
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Görüşme Ekle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görüşme Notu:</label>
                    <textarea
                      class="form-control"
                      aria-label="With textarea"
                      value={formText}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormText(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formText ? " is-valid" : " is-invalid")
                      }
                    />
                  </div>
                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görüşülen Kişi:</label>
                    <input
                      type="text"
                      placeholder="Görüşülen Kişi"
                      value={formInterviewer}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormInterviewer(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formInterviewer ? " is-valid" : " is-invalid")
                      }
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                    setFormText("");
                    setFormInterviewer("");
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={formText === "" || formInterviewer === ""}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={handleDelete}
        cancelCallback={() => {
          setConsentModalVisibility(false);
        }}
      />
    </>
  );
}
