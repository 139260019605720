// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import {
  hideLoader,
  showClassroomCreateOrUpdateModal,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import ClassroomService from "../../services/classroomService";
import ConsentModal from "../consentModal";
import Header from "../header";
import ClassroomCreateOrUpdateModal from "./classroomCreateOrUpdateModal";
import ClassStudentsPage from "./classStudentsPage";
import AdminClassStudentsPage from "./AdminClassStudentsPage";

export default function ClassroomPage() {
  const { path, url } = useRouteMatch();
  const { brancheId } = useParams();
  const [classroomList, setClassroomList] = useState<
    Array<ClassroomOutputModel>
  >([]);
  const classroomService = new ClassroomService();
  const dispatch = useDispatch();
  const getClassroomList = async () => {
    dispatch(showLoader());
    let result = await classroomService.GetClassroomList();
    dispatch(hideLoader());

    if (result != null) {
      setClassroomList(result);
    } else {
      dispatch(
        sendMessage({ title: "İşlem sonucu", message: "İşlem başarısız" })
      );
    }
  };

  useEffect(() => {
    getClassroomList();
  }, []);

  const [selectedClassroom, setSelectedClassroom] =
    useState<ClassroomOutputModel>(null);
  const selectClassroomForEdit = (classId: number) => {
    const el = classroomList.find((c) => c.id == classId);
    setSelectedClassroom(el);
    dispatch(showClassroomCreateOrUpdateModal());
  };

  const [constModalVisibility, setConstModalVisibility] = useState(false);

  const deleteClassroom = async () => {
    dispatch(showLoader());
    setConstModalVisibility(false);
    const result = await classroomService.DeleteClassroom(selectedClassroom.id);

    if (result) {
      await getClassroomList();
    } else {
      dispatch(
        sendMessage({
          title: "Hata",
          message: "Sınıf silinemedi. Sınfta öğrenci olmadığından emin olunuz",
        })
      );
    }

    dispatch(hideLoader());
  };

  const showDeleteConsentMessage = (classId: number) => {
    const el = classroomList.find((c) => c.id == classId);
    setSelectedClassroom(el);
    setConstModalVisibility(true);
  };
  return (
    <Switch>
      {path.includes("brancheId") ? (
        path.includes("report") ? (
          <Route
            path={`/admin/report/branchesClassrooms/:brancheId/classroom/:classId`}
          >
            <AdminClassStudentsPage classroomList={classroomList} />
          </Route>
        ) : (
          <Route
            path={`/admin/branchesClassrooms/:brancheId/classroom/:classId`}
          >
            <AdminClassStudentsPage classroomList={classroomList} />
          </Route>
        )
      ) : (
        <Route path={`${path}/:classId`}>
          <ClassStudentsPage classroomList={classroomList} />
        </Route>
      )}
      <Route path={path}>
        {!path.includes("report") && <Header name="Sınıflar" />}
        <>
          <div className="page-content">
            <section className="row">
              <div className="content-page">
                <div className="row staff-grid-row">
                  {classroomList.map((l) => (
                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                      <div
                        className={`profile-widget ${
                          path.includes("report") && "pt-5"
                        }`}
                        style={{ borderRadius: "100px" }}
                      >
                        {!path.includes("report") && (
                          <div className="buttons">
                            <button
                              className="btn btn-primary"
                              onClick={() => selectClassroomForEdit(l.id)}
                            >
                              Düzenle
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => showDeleteConsentMessage(l.id)}
                            >
                              Sil
                            </button>
                          </div>
                        )}
                        <div className="profile-img">
                          <Link
                            to={`${
                              path.includes("brancheId")
                                ? path.includes("report")
                                  ? path.includes("outbranchesClassrooms")
                                    ? "/admin/report/outbranchesClassrooms/" +
                                      brancheId +
                                      "/classroom"
                                    : "/admin/report/branchesClassrooms/" +
                                      brancheId +
                                      "/classroom"
                                  : "/admin/branchesClassrooms/" +
                                    brancheId +
                                    "/classroom"
                                : path
                            }/${l.id}`}
                          >
                            <img
                              className="avatar"
                              src="/assets/img/class.png"
                              alt=""
                            />
                          </Link>
                        </div>
                        <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                          <a>{l.name || "Default"}</a>
                        </h4>
                      </div>
                    </div>
                  ))}
                  {!path.includes("report") && (
                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                      <div
                        className="profile-widget"
                        style={{ borderRadius: "100px" }}
                      >
                        <div
                          className="profile-img"
                          onClick={() => {
                            setSelectedClassroom(null);
                            dispatch(showClassroomCreateOrUpdateModal());
                          }}
                        >
                          <i
                            className="fas fa-plus fa-4x"
                            style={{ opacity: "0.5" }}
                          ></i>
                        </div>
                        <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                          <a data-bs-toggle="modal" data-bs-target="#small">
                            Yeni Sınıf Ekle
                          </a>
                        </h4>
                        <div className="small text-muted"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          <ClassroomCreateOrUpdateModal
            callback={getClassroomList}
            data={selectedClassroom}
          />
          <ConsentModal
            visible={constModalVisibility}
            message="Silme işlemini onaylıyor musunuz?"
            title="Uyarı"
            cancelCallback={() => setConstModalVisibility(false)}
            approveCallback={deleteClassroom}
          />
        </>
      </Route>
    </Switch>
  );
}
