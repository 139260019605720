// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import BaseService from "../../services/baseService";
import ResultQuestions from "../sharedComponents/ResultQuestions";

export enum ViewerType {
  CreateTest = 1,
  AddQuestion = 2,
  Static = 3,
}

export default function PdfViewer(props: {
  viewerType: ViewerType;
  visible: boolean;
  selectedPdfPageList: Array<string>;
  onCloseClick: () => void;
  onSubmit: (
    selectedQuestionUrl?: Array<string>,
    selectedQuestionAnswers?: {
      id: number;
      answer: string;
      learningOutCome: string;
    }[],
    testCreateName?: string
  ) => void;
}) {
  const [docZoomLevel, setDocZoomLevel] = useState(90);
  const [cropList, setCropList] = useState<Array<Crop>>([]);
  const [completedCropList, setCompletedCropList] = useState<Array<Crop>>([]);
  const [selectedQuestionList, setSelectedQuestionList] = useState<
    Array<string>
  >([]);
  const [testCreateName, setTestCreateName] = useState("");
  const [closeTrig, setCloseTrig] = useState(false);
  const previewCanvasRef = useRef(null);
  // image crop
  const [imageRefs, setImageRefs] = useState([]);

  const [answer, setAnswer] = useState<
    {
      id: number;
      answer: string;
      learningOutCome: string;
    }[]
  >([{ id: null, answer: "", learningOutCome: "" }]);

  const onCropChangeHandler = (crop: Crop, index: number) => {
    const copyCropList = [...cropList];
    copyCropList[index] = { ...crop };
    setCropList(copyCropList);
  };

  useEffect(() => {
    setAnswer([{ id: null, answer: "", learningOutCome: "" }]);
    setSelectedQuestionList([]);
    setCropList([]);
  }, [closeTrig]);

  const onCompleteCropHandler = (crop: Crop, index: number) => {
    const copyCompleteCropList = [...completedCropList];
    copyCompleteCropList[index] = { ...crop };
    setCompletedCropList(copyCompleteCropList);

    cropImage(crop, index);
  };

  const removeFromQuestionList = (index: number) => {
    const copyQuestionList = [...selectedQuestionList];
    copyQuestionList.splice(index, 1);
    setSelectedQuestionList(copyQuestionList);
  };

  const onLoad = (img, index) => {
    const copyImageRefs = [...imageRefs];

    copyImageRefs[index] = img;
    setImageRefs(copyImageRefs);
  };

  const cropImage = (completedCrop: Crop, index: number) => {
    if (
      completedCrop.height <= 0 ||
      completedCrop.height <= 0 ||
      !imageRefs ||
      !imageRefs.length
    )
      return;

    const image = imageRefs[index];
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const questionUrl = canvas.toDataURL("image/png", 1.0);
    setSelectedQuestionList([...selectedQuestionList, questionUrl]);
  };

  const baseService = new BaseService();

  return (
    <>
      {props.visible ? (
        <div
          className="doc-container container-fluid"
          style={{
            height: "100vh",
            overflowY: "scroll",
            position: "fixed",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.7)",
            left: 0,
            top: 0,
            zIndex: 9999999,
          }}
        >
          <div className="row">
            <div
              className="col-12"
              style={{ height: "10%", textAlign: "right" }}
            >
              <i
                onClick={() => {
                  props.onCloseClick();
                  setCloseTrig((closeTrig) => !closeTrig);
                }}
                className="fas fa-times"
                style={{
                  color: "white",
                  fontSize: "2rem",
                  padding: "0.5rem",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          </div>
          <div className="row">
            <div
              className={
                props.viewerType === ViewerType.Static ? `col-12` : `col-9`
              }
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h4 style={{ color: "white" }}>
                Test
                <i
                  onClick={() =>
                    setDocZoomLevel(docZoomLevel > 50 ? docZoomLevel - 10 : 50)
                  }
                  style={{ cursor: "pointer" }}
                  className="pl-2 fas fa-search-minus"
                ></i>
                <i
                  onClick={() =>
                    setDocZoomLevel(
                      docZoomLevel < 100 ? docZoomLevel + 10 : 100
                    )
                  }
                  style={{ cursor: "pointer" }}
                  className="pl-2 fas fa-search-plus"
                ></i>
              </h4>
              {props.selectedPdfPageList.map((p, index) => (
                <ReactCrop
                  crossorigin="anonymous"
                  style={{
                    width: `${docZoomLevel}%`,
                    justifyContent: "center",
                    display: "flex",
                    marginBottom: "2%",
                  }}
                  src={baseService.endPoints().pdfUrl + "/testbank/" + p}
                  onImageLoaded={(img) => onLoad(img, index)}
                  crop={cropList[index]}
                  // zoom={2}
                  // scale={0.5}
                  onChange={(c) => onCropChangeHandler(c, index)}
                  onComplete={(c) => onCompleteCropHandler(c, index)}
                ></ReactCrop>
              ))}
            </div>

            {props.viewerType !== ViewerType.Static && (
              <div className="col-3">
                <h4 style={{ color: "white" }}>
                  Seçilen Sorular ({selectedQuestionList.length})
                </h4>
                {selectedQuestionList.length > 0 ? (
                  props.viewerType == ViewerType.CreateTest ? (
                    <div className="card">
                      <div className="card-body">
                        <div className="form-group">
                          <label></label>
                          <input
                            className={
                              "form-control" +
                              (testCreateName ? " is-valid" : " is-invalid")
                            }
                            placeholder="Test Adı Giriniz"
                            value={testCreateName}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setTestCreateName(e.target.value)
                            }
                          />
                        </div>
                        <button
                          className="col-12 btn btn-primary"
                          disabled={!testCreateName}
                          onClick={() =>
                            props.onSubmit(
                              selectedQuestionList,
                              answer,
                              testCreateName
                            )
                          }
                        >
                          Oluştur
                        </button>
                      </div>
                    </div>
                  ) : props.viewerType == ViewerType.Static ? null : (
                    <div className="form-group">
                      <button
                        className="col-12 btn btn-primary"
                        onClick={() =>
                          props.onSubmit(selectedQuestionList, answer, "")
                        }
                      >
                        Soruları Ekle
                      </button>
                    </div>
                  )
                ) : null}
                {selectedQuestionList.map((q, index) => (
                  <div className="b-2 t-2" style={{ paddingBottom: 15 }}>
                    <div className="card" style={{ borderRadius: 0 }}>
                      <div className="row">
                        <div className="col-12 text-right">
                          <i
                            onClick={() => removeFromQuestionList(index)}
                            className="fas fa-times"
                            style={{
                              color: "black",
                              fontSize: "2rem",
                              // padding: "0.5rem",
                              cursor: "pointer",
                            }}
                          ></i>
                        </div>
                      </div>
                      <img style={{ width: "100%" }} src={q} />
                    </div>
                    <div className="col-12 d-flex justify-content-center align-self-center bg-light p-3 rounded">
                      <ResultQuestions
                        answer={answer}
                        setAnswer={setAnswer}
                        index={index}
                        learningOutcome={true}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : null}
      <canvas
        ref={previewCanvasRef}
        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
        style={{
          display: "none",
          width: Math.round(200),
          height: Math.round(200),
        }}
      />
    </>
  );
}
