import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideClassroomCreateOrUpdateModal,
  hideLoader,
  showLoader,
} from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import ClassroomService from "../../services/classroomService";
import TeacherService from "../../services/teacherService";
import { RootState } from "../../store";
import SubClassroomService from "../../services/subClassroomService";
import BranchesService from "../../services/branchesService";

export default function BrancheCreateOrUpdateModal(props: {
  trig?: boolean;
  setTrig?: (trig: boolean) => void;
  data?: Array<BranchesOutputModel>;
  vis?: boolean;
  onClose?: (vis: boolean) => void;
  mode?: "update" | "create";
  classId?: number;
  name?: string;
  subClassId?: number;
  id: number;
}) {
  const dispatch = useDispatch();
  const [formBrancheName, setFormBranchName] = useState<string>("");
  const branchesService = new BranchesService();

  useEffect(() => {
    setFormBranchName(
      props.mode === "update"
        ? props.data.find((item) => item.id === props.id)?.name
        : ""
    );
  }, [props]);

  // const classroomService = new ClassroomService();
  // const subClassroomService = new SubClassroomService();

  // const [classroomId, setClassroomId] = useState<any>(null);
  // const [subClassroomName, setSubClassroomName] = useState<string>(props.name);

  // useEffect(() => {
  //   setSubClassroomName(props.name);
  // }, [props]);

  const formSubmitHandler = async () => {
    dispatch(showLoader());
    let response: any = "";
    if (props.mode === "create") {
      response = await branchesService.CreateBranche({ name: formBrancheName });
    } else {
      response = await branchesService.UpdateBranche(props.id, {
        name: formBrancheName,
      });
      // await subClassroomService.updateSubClassroom(
      //   {
      //     name: subClassroomName,
      //   },
      //   props.subClassId
      // );
    }
    props.onClose(false);
    props.setTrig(!props.trig);
    dispatch(hideLoader());
  };

  //form data
  return (
    <div
      className="modal fade text-left show"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: props.vis ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {props.mode === "update" ? "Şube Güncelle" : "Yeni Şube Ekle"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">
            <form action="#">
              <div className="row">
                <div className="form-group col-12">
                  <label>Şube Adı: </label>
                  <input
                    type="text"
                    placeholder="Şube Adı"
                    value={formBrancheName}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFormBranchName(e.target.value)
                    }
                    className={
                      "form-control" +
                      (formBrancheName ? " is-valid" : " is-invalid")
                    }
                    required
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => props.onClose(false)}
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              disabled={formBrancheName === ""}
              type="button"
              onClick={formSubmitHandler}
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
