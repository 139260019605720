import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { useContext } from "react";
import { TeacherIdentityContext } from "../panels/teacherPanel";
import ClassroomService from "../../services/classroomService";

declare global {
  interface UrlParam {
    lessonId: string;
  }
}

export default function TeacherQuestionsBank() {
  let { path, url } = useRouteMatch();
  const teacherContext = useContext(TeacherIdentityContext);
  const classroomService = new ClassroomService();
  const dispatch = useDispatch();
  const [classLessonList, setclassLessonList] = useState<
    Array<ClassroomOutputModel>
  >([]);

  const getClassroomList = async () => {
    dispatch(showLoader());
    const response = await classroomService.GetClassroomList();
    setclassLessonList(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getClassroomList();
  }, []);

  const teacherLessonsList = () => {
    let arr: {
      lessonName: string;
      lessonId: number;
      classroomId: number;
    }[] = [];
    (classLessonList || []).forEach((classroom) => {
      teacherContext.majors.forEach((major) => {
        major.lessons.forEach((lesson) => {
          if (lesson.classroomId === classroom.id) {
            arr.push({
              lessonName: lesson.name,
              lessonId: lesson.id,
              classroomId: classroom.id,
            });
          }
        });
      });
    });
    return arr;
  };

  return (
    <>
      <div className="page-content">
        <section className="row">
          {(classLessonList || []).map((cl) => (
            <div className="card-content">
              <div
                className="card-header"
                style={{ backgroundColor: "transparent" }}
              >
                <h4>{cl.name}</h4>
              </div>
              <div className="card-body">
                <div className="content-page">
                  <div className="row staff-grid-row">
                    {(teacherLessonsList() || []).map((major) => {
                      if (major.classroomId === cl.id)
                        return (
                          <div
                            key={major.lessonId}
                            className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                          >
                            <Link
                              key={major.lessonId}
                              to={`${path}/lesson/${major.lessonId}`}
                            >
                              <div
                                className="profile-widget"
                                style={{ borderRadius: "100px" }}
                              >
                                <div className="profile-img">
                                  <img
                                    className="avatar"
                                    src="/assets/img/test.png"
                                    alt=""
                                  />
                                </div>
                                <h4 className="user-name m-t-10 m-b-0 text-ellipsis">
                                  <a>{major.lessonName || "Default"}</a>
                                </h4>
                                <div className="small text-muted">
                                  Soru Bankası
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </>
  );
}
