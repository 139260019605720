import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../reducers/commonSlice";
import RoleService from "../services/roleService";

const useAuthenticate = (role: string) => {
  const [authenticated, setAuthenticated] = useState<boolean>(null);
  const dispatch = useDispatch();
  const authenticateUser = async () => {
    const roleService = new RoleService();
    dispatch(showLoader());
    let result = await roleService.IsUserInRole(role);
    dispatch(hideLoader());
    setAuthenticated(result);
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  return authenticated;
};

export default useAuthenticate;
