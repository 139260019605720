import BaseService from "./baseService";

declare global {
  interface StudentReportModel {
    questionCount: number;
    correctAnswerCount: number;
    incorrectAnswerCount: number;
    unansweredCount: number;
    questionResults: {
      id: number;
      value: string;
      questionId: number;
    }[];
    studentId: string;
    testId: number;
    testName: string;
    fillDate: string;
    lessonName: string;
    lessonId: number;
    className: string;
    testAssignmentId: any;
    id: number;
    net: number;
    successRate: number;
  }

  interface TeacherReportModel {
    profile: TeacherAccountModel;
    lessonReportList: Array<TeacherLessonReportModel>;
  }

  interface TeacherLessonReportModel {
    lessonId: number;
    lessonName: string;
    studentSuccessList: Array<LessonStudentSuccessModel>;
    lessonSuccessRate: string;
  }

  interface LessonStudentSuccessModel {
    studentName: string;
    classRoom: string;
    lessonOverallSuccessRate: string;
  }
}

export default class ReportService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/report");
  }

  GetTeacherReport(teacherId: string) {
    return this.Get<TeacherReportModel>(
      { teacherId: teacherId, studentId: "" },
      "/report",
      true,
      true,
      true
    );
  }

  GetStudentReport(studentId: string) {
    return this.Get<StudentReportModel>(
      { studentId: studentId, teacherId: "" },
      "/report",
      true,
      true,
      true
    );
  }
}
