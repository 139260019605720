import React, { useEffect } from "react";

export default function ConsentModal(props: {
  title: string;
  message: string;
  visible: boolean;
  approveCallback: () => void;
  cancelCallback: () => void;
}) {
  return (
    <div
      className="modal fade text-left show"
      tabIndex={-1}
      aria-labelledby="myModalLabel33"
      style={{
        overflow: "auto",
        display: props.visible ? "block" : "none",
        backgroundColor: "rgba(0,0,0,0.4)",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel33">
              {props.title || "Yeni Sınıf Ekle"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i data-feather="x"></i>
            </button>
          </div>
          <div className="modal-body">
            {props.message || "İşlemi onaylıyor musunuz?"}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={props.cancelCallback}
              className="btn btn-default rounded pill"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              <span className="d-inline p-2">Kapat</span>
            </button>
            <button
              type="button"
              onClick={props.approveCallback}
              className="btn btn-primary ml-1"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-check-circle"></i>
              <span className="d-inline p-2">Onayla</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
