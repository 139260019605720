// @ts-nocheck
import React, { useEffect, useState } from "react";
import TestService from "../../services/testService";
import ConsentModal from "../consentModal";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  setSidebarStatusModal,
  showLoader,
} from "../../reducers/commonSlice";
import StudentService from "../../services/studentService";
import "react-datepicker/dist/react-datepicker.css";
import { sendMessage } from "../../reducers/messengerSlice";
import OpticsService from "../../services/opticsService";
import StudentAnswerTable from "./StudentAnswerTable";
import { lineDetection } from "../../utils/FmtParser";
import Select from "react-select";
import LessonService from "../../services/lessonService";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TeacherService from "../../services/teacherService";
import ManagerService from "../../services/managerService";
import AdvancedTable from "./AdvancedTable";
import { ActionIcon, Tooltip } from "@mantine/core";
import {
  IconCheck,
  IconListDetails,
  IconPdf,
  IconTrash,
} from "@tabler/icons-react";
import { IconCheckbox } from "@tabler/icons-react";
import TaskService from "../../services/taskService";

enum VisibleModalType {
  answerKey = "A",
  examResult = "B",
}

export default function TasksPage() {
  const opticService = new OpticsService();
  const teacherService = new TeacherService();
  const managerService = new ManagerService();
  const taskService = new TaskService();

  const dispatch = useDispatch();

  const [addNewModalVis, setAddNewModalVis] = useState<boolean>(false);

  const [trig, setTrig] = useState<boolean>(false);

  const [consentModalVisibility, setConsentModalVisibility] = useState(false);

  const [selectedId, setSelectedId] = useState<number>(null);

  const [formSelectedDate, setFromSelectedDate] = useState<any>(new Date());
  const [formTaskDesc, setFormTaskDesc] = useState<string>("");
  const [formSelectedOfficers, setFormSelectedOfficers] = useState([]);

  const [managerList, setManagerList] = useState<Array<TeacherOutputModel>>([]);
  const [teacherList, setTeacherList] = useState<Array<ManagerModel>>([]);
  const [assignType, setAssignType] = useState<"partner" | "standalone">(
    "partner"
  );
  const [taskData, setTaskData] = useState<Array<TaskOutputModel>>([]);
  const [detailsModalVis, setDetailsModalVis] = useState<boolean>(false);
  const [approveModalVis, setApproveModalVis] = useState<boolean>(false);

  const handleSave = async () => {
    dispatch(showLoader());
    let taskObject;
    if (assignType === "partner") {
      let AttendentIds = "";
      formSelectedOfficers.map((item, index) => {
        if (index === 0) {
          AttendentIds = AttendentIds.concat("", item.value);
        } else {
          AttendentIds = AttendentIds.concat(",", item.value);
        }
      });
      taskObject = [
        {
          AttendentIds,
          LastDate: formSelectedDate,
          Task: formTaskDesc,
        },
      ];
    } else {
      taskObject = [];
      formSelectedOfficers.map((item, index) => {
        taskObject = [
          ...taskObject,
          {
            AttendentIds: item.value,
            LastDate: formSelectedDate,
            Task: formTaskDesc,
          },
        ];
      });
    }
    const response = await taskService.CreateTask(taskObject);
    setTrig((trig) => !trig);
    setAssignType("partner");
    setAddNewModalVis(false);
    setFormTaskDesc("");
    setFromSelectedDate(new Date());
    dispatch(hideLoader());
  };

  const getTeacherList = async () => {
    const response = await teacherService.GetTeacherList();
    setTeacherList(response);
  };

  const getManagerList = async () => {
    const response = await managerService.GetManagerList();

    setManagerList(response);
  };

  const getAllTasks = async () => {
    dispatch(showLoader());
    const response = await taskService.GetAllTasks();
    setTaskData(response);
    dispatch(hideLoader());
  };

  useEffect(() => {
    getAllTasks();
  }, [trig]);

  useEffect(() => {
    getTeacherList();
    getManagerList();
  }, []);

  const handleDelete = async () => {
    setConsentModalVisibility(false);
    dispatch(showLoader());
    const response = await taskService.DeleteTask(selectedId);
    if (response) {
      setTrig((trig) => !trig);
    }
    dispatch(hideLoader());
  };

  const handleApprove = async () => {
    const response = await taskService.TaskAdminChecked(selectedId);
    if (response) {
      setTrig((trig) => !trig);
      setApproveModalVis(false);
      setFormTaskDesc("");
      setFromSelectedDate(new Date());
    }
  };

  const selectOfficerOptions = [...(managerList || []), ...teacherList].map(
    (item) => ({
      value: item.id,
      label: item.name + " " + item.surname,
    })
  );

  const customDataTable = [
    {
      accessorFn: (row) => {
        return row.task.length > 30 ? `${row.task.slice(0, 30)} ...` : row.task;
      },
      accessorKey: "task",
      header: "Görev Tanımı",
    },
    {
      accessorFn: (row) => {
        return row.attendent.length > 1
          ? `${row.attendent[0].name} ${row.attendent[0].surname} ...`
          : `${row.attendent[0].name} ${row.attendent[0].surname}`;
      },
      accessorKey: "attendent",
      header: "Yetkili",
    },
    {
      accessorFn: (row) =>
        `${
          row.checked
            ? row.adminChecked
              ? "Onaylandı"
              : "Tamamlandı"
            : "Beklemde"
        }`,
      accessorKey: "status",
      header: "Durum",
    },
    {
      accessorFn: (row) => {
        return row.checked
          ? `${row.completer.name} ${row.completer.surname}`
          : "-";
      },
      accessorKey: "completer",
      header: "Tamamlayan",
    },
    {
      accessorFn: (row) => `${dayjs(row.lastDate).format("DD.MM.YYYY")}`,
      accessorKey: "lastDate",
      header: "Bitiş Tarihi",
    },
  ];

  const data: TaskOutputModel = taskData;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-12 d-flex justify-content-start align-items-start ">
              <button
                onClick={() => {
                  setAddNewModalVis(true);
                }}
                type="button"
                className="btn btn-primary ml-1 btn-l"
              >
                Görev Ekle
              </button>
            </div>
          </div>
          <AdvancedTable
            data={data}
            dataTable={customDataTable}
            extraButtons={(row) => {
              return (
                <>
                  {row.checked && !row.adminChecked && (
                    <Tooltip label="Onayla">
                      <ActionIcon
                        color="green"
                        onClick={() => {
                          setSelectedId(row.id);
                          setApproveModalVis(true);
                        }}
                      >
                        <IconCheckbox />
                      </ActionIcon>
                    </Tooltip>
                  )}

                  <Tooltip label={"Detay"}>
                    <ActionIcon
                      color="gray"
                      onClick={() => {
                        setSelectedId(row.id);
                        setDetailsModalVis(true);
                      }}
                    >
                      <IconListDetails />
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip label="Sil">
                    <ActionIcon
                      color="red"
                      onClick={() => {
                        setSelectedId(row.id);
                        setConsentModalVisibility(true);
                      }}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </Tooltip>
                </>
              );
            }}
          />
        </div>
      </div>

      {addNewModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
            // style={{
            //   maxWidth: "1250px",
            // }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Görev Ekle
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i
                    data-feather="x"
                    onClick={() => {
                      setMode("create");
                      setAddNewModalVis(false);
                      setParsedData([]);
                    }}
                  ></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={() => {
                            setAssignType("partner");
                          }}
                        >
                          Ortak Görev Olarak
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                          onClick={() => {
                            setAssignType("standalone");
                          }}
                        >
                          Ayrı Görev Olarak
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent"></div>
                  </>
                  <div className="form-group col-12">
                    <label className="font-weight-bold">Görev Tanımı:</label>

                    <input
                      type="text"
                      placeholder="Görev Tanımı"
                      value={formTaskDesc}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormTaskDesc(e.target.value)
                      }
                      className={
                        "form-control" +
                        (formTaskDesc ? " is-valid" : " is-invalid")
                      }
                      required
                    />
                  </div>

                  <div className="col-12 p-2">
                    <label>Yetkili: </label>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                        }),
                      }}
                      aria-label="Default select example"
                      options={selectOfficerOptions}
                      onChange={(selectedOption) => {
                        setFormSelectedOfficers(selectedOption);
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      placeholder="Lütfen Yetkili Seçin"
                    />
                  </div>

                  <div className="col-12 p-2">
                    <label className="font-weight-bold">Bitiş Tarihi:</label>

                    <div className="col-4">
                      <DatePicker
                        selected={formSelectedDate}
                        onChange={(date) => setFromSelectedDate(date)}
                        selectsEnd
                        minDate={new Date()}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setAddNewModalVis(false);
                    setAssignType("partner");
                    setFormTaskDesc("");
                    setFromSelectedDate(new Date());
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="btn btn-primary ml-1"
                  data-bs-dismiss="modal"
                  disabled={formTaskDesc === "" || !formSelectedOfficers.length}
                >
                  <i className="fas fa-check-circle"></i>
                  <span className="d-inline p-2">Kaydet</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {detailsModalVis && (
        <div
          className="modal text-left show"
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="myModalLabel33"
          style={{
            // zIndex: 1,
            overflow: "auto",
            display: "block",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel33">
                  Görev Detay
                </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i data-feather="x" onClick={() => {}}></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="form-group col-6">
                    <label className="font-weight-bold">
                      Görev Veriliş Tarihi:
                    </label>
                    <h6>
                      {dayjs(
                        taskData.find((item) => item.id === selectedId)
                          .issueDate
                      ).format("DD.MM.YYYY")}
                    </h6>
                  </div>

                  <div className="form-group col-6">
                    <label className="font-weight-bold">
                      Görev Bitiş Tarihi:
                    </label>
                    <h6>
                      {dayjs(
                        taskData.find((item) => item.id === selectedId).lastDate
                      ).format("DD.MM.YYYY")}
                    </h6>
                  </div>

                  <div className="form-group col-6">
                    <label className="font-weight-bold">Durum:</label>
                    <h6>
                      {taskData.find((item) => item.id === selectedId).checked
                        ? `" ${
                            taskData.find((item) => item.id === selectedId)
                              .completer.name
                          } ${
                            taskData.find((item) => item.id === selectedId)
                              .completer.surname
                          } " Tarafından Tamamlandı`
                        : "Beklemede"}
                    </h6>
                  </div>

                  <div className="form-group col-6">
                    <label className="font-weight-bold">Yönetici Onayı:</label>
                    <h6>
                      {taskData.find((item) => item.id === selectedId)
                        .adminChecked
                        ? "Onaylandı"
                        : "Beklemede"}
                    </h6>
                  </div>

                  <div className="form-group col-12">
                    <label className="font-weight-bold">Yetkililer:</label>
                    <h6>
                      {taskData
                        .find((item) => item.id === selectedId)
                        .attendent.map((obj, index) =>
                          index === 0
                            ? `"${obj.name} ${obj.surname}" `
                            : `, "${obj.name} ${obj.surname}" `
                        )}
                    </h6>
                  </div>

                  <div className="form-group col-12 ">
                    <label className="font-weight-bold">Görev Tanımı:</label>
                    <h6>
                      {taskData.find((item) => item.id === selectedId).task}
                    </h6>
                  </div>
                  <div className="form-group col-12 ">
                    <label className="font-weight-bold">Görev Notu:</label>
                    <h6>
                      {taskData.find((item) => item.id === selectedId)
                        .completerNote === "" ||
                      taskData.find((item) => item.id === selectedId)
                        .completerNote === null
                        ? "-"
                        : taskData.find((item) => item.id === selectedId)
                            .completerNote}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setDetailsModalVis(false);
                  }}
                  className="btn btn-default rounded pill"
                  data-bs-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i>
                  <span className="d-inline p-2">Kapat</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConsentModal
        title="Uyarı"
        visible={consentModalVisibility}
        message={"Silme işlemini onaylıyor musunuz?"}
        approveCallback={handleDelete}
        cancelCallback={() => {
          setConsentModalVisibility(false);
        }}
      />

      <ConsentModal
        title="Uyarı"
        visible={approveModalVis}
        message={"Görevi onaylıyor musunuz?"}
        approveCallback={handleApprove}
        cancelCallback={() => {
          setApproveModalVis(false);
        }}
      />
    </>
  );
}
