import React, { useEffect } from "react";
import AccordionItem from "./accordionItem";

export default function SelectableAccordionItem(props: {
  children?: React.ReactNode;
  id?: string;
  parentId?: string;
  //
  title?: string;
  checked?: boolean;
  onCheckboxChangeHandler: (checked: boolean) => void;
  collapsable?: boolean;
  progressBar?: ProgressBarData;
  checkboxDisable?: boolean;
  //
}) {
  const changeHandler = (event) => {
    props.onCheckboxChangeHandler(!props.checked);
  };

  return (
    <AccordionItem
      collapsable={props.collapsable}
      progressBar={props.progressBar}
      id={props.id}
      parentId={props.parentId}
      title={props.title}
      headerExtra={
        <input
          className="form-check-input"
          style={{
            position: "unset",
            width: "2rem",
            height: "2rem",
            marginRight: "1%",
            marginLeft: "1%",
          }}
          type="checkbox"
          disabled={props.checkboxDisable || false}
          onChange={changeHandler}
          checked={props.checked}
        ></input>
      }
    >
      {props.children}
    </AccordionItem>
  );
}
