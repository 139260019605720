import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import { sendMessage } from "../../reducers/messengerSlice";
import TeacherService from "../../services/teacherService";
import { RootState } from "../../store";
import "../style/profile.css";

export default function ProfilePage() {
  const teacherService = new TeacherService();
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();

  const getUserDetails = async () => {
    dispatch(showLoader());

    const result = await teacherService.GetTeacherProfile();
    setDataLoaded(true);
    dispatch(hideLoader());
    if (result != null) {
      setName(result.name);
      setSurname(result.surname);
      setCity(result.city);
      setUniversity(result.university);
      setAboutMe(result.aboutMe);
      setSpecialty(result.specialty);
      setProfilePhoto(result.profilePhoto);
      setFacebookUrl(result.facebookUrl);
      setTwitterUrl(result.twitterUrl);
      setInstagramUrl(result.instagramUrl);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [city, setCity] = useState("");
  const [university, setUniversity] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");

  const onSubmitHandler = async () => {
    dispatch(showLoader());
    let result = await teacherService.UpdateTeacherProfile({
      aboutMe: aboutMe,
      city: city,
      name: name,
      profilePhoto: profilePhoto,
      specialty: specialty,
      surname: surname,
      university: university,
      facebookUrl: facebookUrl,
      twitterUrl: twitterUrl,
      instagramUrl: instagramUrl,
    });

    dispatch(hideLoader());
    if (result != null) {
      setEditable(false);
    } else
      dispatch(
        sendMessage({ title: "İşlem Sonucu", message: "İşlem Başarısız" })
      );
  };

  const openFileUpload = () => {
    (document.querySelector("#profile-photo-input") as any).click();
  };

  const profileImageOnChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setProfilePhoto(reader.result as string);
      //(document.querySelector("#preview-profile-img") as HTMLImageElement).src = reader.result as string;
    };

    reader.readAsDataURL(selectedFile);
  };

  const isUrlValid = (url: string) =>
    !!url.match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    );

  return !dataLoaded ? null : (
    <div className="page-content">
      <div
        className="card card-profile shadow"
        style={{ display: editable ? "none" : "flex" }}
      >
        <div className="card-header">
          <div className="buttons" style={{ float: "right" }}>
            <button className="btn btn-info" onClick={() => setEditable(true)}>
              Güncelle
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="px-4">
            <div className="row justify-content-center">
              <div
                className="col-lg-3 order-lg-2"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="card-profile-image">
                  <a href="javascript:;">
                    <img
                      src={
                        profilePhoto ||
                        "/assets/img/Profile_avatar_placeholder_large.png"
                      }
                      height="225"
                      width="225"
                      style={{ objectFit: "fill" }}
                      className="rounded-circle"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                <div className="card-profile-actions py-4 mt-lg-0 d-flex justify-content-center">
                  <a href="#yorumlar">
                    {/* <p className="d-inline text-default">Yorumları Gör</p> */}
                  </a>
                </div>
              </div>
              <div className="col-lg-4 order-lg-1 text-lg-right align-self-lg-center">
                {/* <div className="card-profile-stats d-flex justify-content-center">
                                    <div className="stats">
                                        <div className="stars star-color">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <h3>
                {name + " " + surname}
                <span className="font-weight-light"></span>
              </h3>
              <div className="h6 font-weight-300">{city}</div>
              <div className="h6 mt-4">{specialty}</div>
              <div>{university}</div>
            </div>

            <ul
              className="social-buttons py-3 text-center"
              style={{ padding: 0 }}
            >
              <li style={{ display: "inline-block" }}>
                <a
                  href={facebookUrl}
                  target="_blank"
                  className="btn btn-link btn-facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li style={{ display: "inline-block" }}>
                <a
                  href={twitterUrl}
                  target="_blank"
                  className="btn btn-link btn-twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li style={{ display: "inline-block" }}>
                <a
                  href={instagramUrl}
                  target="_blank"
                  className="btn btn-link btn-instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>

            <div className="mt-5 py-5 border-top text-center">
              <div className="row justify-content-center">
                <div className="col-lg-9">
                  <h4>Hakkımda</h4>
                  <p>{aboutMe}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* editable */}
      <div
        className="card card-profile shadow"
        style={{ display: editable ? "flex" : "none" }}
      >
        <div className="card-header"></div>
        <div className="card-body">
          <div className="px-4">
            <div className="row justify-content-center">
              <div className="col-lg-3 order-lg-2">
                <div
                  className="card-profile-image"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="pic-holder"
                    onClick={openFileUpload}
                    style={{ width: "225px", height: "225px" }}
                  >
                    <a href="#">
                      <img
                        id="preview-profile-img"
                        src={
                          profilePhoto ||
                          "/assets/img/Profile_avatar_placeholder_large.png"
                        }
                        height="225"
                        style={{ width: "100%", objectFit: "fill" }}
                        className="profile-teacher-photo rounded-circle"
                      />
                    </a>
                    <input
                      id="profile-photo-input"
                      type="file"
                      onChange={profileImageOnChangeHandler}
                      hidden
                    />
                    <label className="upload-file-block">
                      <div className="text-center">
                        <div className="mb-2">
                          <i className="fa fa-camera fa-2x"></i>
                        </div>
                        <div className="text-uppercase">
                          Profil Fotoğrafını <br /> Güncelle
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                <div className="card-profile-actions py-4 mt-lg-0 d-flex justify-content-center"></div>
              </div>
              <div className="col-lg-4 order-lg-1 text-lg-right align-self-lg-center">
                {/* <div className="card-profile-stats d-flex justify-content-center">
                                    <div className="stats">
                                        <div className="stars star-color">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="form-group">
                    <h5>Ad</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setName(e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <h5>Soyad</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={surname}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSurname(e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <h5>Branş</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={specialty}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSpecialty(e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <h5>Şehir</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={city}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCity(e.target.value)
                      }
                    />
                  </div>

                  <div className="form-group">
                    <h5>Üniversite</h5>
                    <input
                      type="text"
                      className="form-control"
                      value={university}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUniversity(e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <h5>Sosyal Ağlar</h5>
                    <input
                      type="text"
                      className={
                        "form-control mb-1" +
                        (facebookUrl
                          ? isUrlValid(facebookUrl)
                            ? " is-valid"
                            : " is-invalid"
                          : "")
                      }
                      value={facebookUrl}
                      placeholder="Facebook URL"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFacebookUrl(e.target.value)
                      }
                    />
                    <input
                      type="text"
                      className={
                        "form-control mb-1" +
                        (twitterUrl
                          ? isUrlValid(twitterUrl)
                            ? " is-valid"
                            : " is-invalid"
                          : "")
                      }
                      value={twitterUrl}
                      placeholder="Twitter URL"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setTwitterUrl(e.target.value)
                      }
                    />
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (instagramUrl
                          ? isUrlValid(instagramUrl)
                            ? " is-valid"
                            : " is-invalid"
                          : "")
                      }
                      value={instagramUrl}
                      placeholder="Instagram URL"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setInstagramUrl(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 py-5 border-top text-center">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <h4>Hakkımda</h4>
                  <textarea
                    className="form-control"
                    rows={5}
                    value={aboutMe}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setAboutMe(e.target.value)
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="buttons" style={{ float: "right" }}>
            <button
              className="btn btn-default"
              onClick={() => setEditable(false)}
            >
              İptal
            </button>
            <button className="btn btn-info" onClick={onSubmitHandler}>
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
