import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export default function Preloader() {
    const showLoader = useSelector((state: RootState) => state.commons.showLoader);

    useEffect(() => {
        setLoaderVisibility(showLoader);
    }, [showLoader]);

    const [loaderVisibility, setLoaderVisibility] = useState(false);

    return (
        <div style={loaderVisibility ? visibleStyle : hiddenStyle} className="preloader bg-soft flex-column justify-content-center align-items-center">
            <div className="loader-element">
                <div className="container canvas">
                    <div className="pencil-row">
                        <div className="pencil-col-6 jar">
                            <div className="top"></div>
                        </div>
                        <div className="pencil one">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

const visibleStyle: React.CSSProperties = {
    transition: "opacity .5s",
    opacity: 1,
    zIndex: 999
};

const hiddenStyle: React.CSSProperties = {
    transition: "opacity .5s",
    opacity: 0,
    zIndex: -1
};