import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { decrement, increment } from "./reducers/counterSlice";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  RouteComponentProps,
} from "react-router-dom";
import Header from "./components/header";
import AdminPanel from "./components/panels/adminPanel";
import TeacherPanel from "./components/panels/teacherPanel";
import StudentPanel from "./components/panels/studentPanel";
import WebPanel from "./components/panels/webPanel";
import UserLayout from "./components/layouts/userLayout";
import WebLayout from "./components/layouts/webLayout";
import MessageModal from "./components/sharedComponents/messageModal";
import Loader from "./components/loader";
import ScrollToTop from "./components/sharedComponents/scrollToTop";
// import TestPdf from './components/sharedComponents/testpdf';
import Preloader from "./components/sharedComponents/preloader";

function App() {
  const count = useSelector((state: RootState) => state.counter.value);
  const dispatch = useDispatch();

  return (
    <div id="app">
      <div id="main">
        <MessageModal />
        <Preloader />
        <Router>
          <ScrollToTop />
          <Switch>
            {/* Admin panels */}
            <Route path="/admin">
              <UserLayout>
                <AdminPanel />
              </UserLayout>
            </Route>
            {/* Student panels */}
            <Route path="/student">
              <UserLayout>
                <StudentPanel />
              </UserLayout>
            </Route>
            {/* Teacher Panels */}
            <Route path="/teacher">
              <UserLayout>
                <TeacherPanel />
              </UserLayout>
            </Route>
            {/* Gumus Egitim Tanıtım Panels */}
            {/* <Route path="/test/:testId" render={(props) => <WebLayout><TestPdf/></WebLayout>} /> */}
            <Route path="/">
              <WebLayout>
                <WebPanel />
              </WebLayout>
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
