import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import ClassroomService from "../../services/classroomService";
import Header from "../header";
import Datatable from "../sharedComponents/datatable";
import SubClassroomListPage from "./SubClassroomListPage";
import SubClassroomService from "../../services/subClassroomService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/commonSlice";
import ComponentLoader from "../sharedComponents/componentLoader";

export default function ClassStudentsPage(props: {
  classroomList: Array<ClassroomOutputModel>;
}) {
  const { path } = useRouteMatch();
  const { classId, brancheId } = useParams<any>();
  const classService = new ClassroomService();
  const subClassroomService = new SubClassroomService();
  const dispatch = useDispatch();
  const [classroom, setClassroom] = useState<ClassroomOutputModel>({
    students: [],
    name: "",
    lessons: [],
    id: 0,
  });
  const [subClassroomList, setSubClassroomList] = useState<
    SubClassroomOutputModel[]
  >([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState<boolean>(true);
  const getSubClassroomById = async () => {
    const response = await subClassroomService.GetSubClassroomById(classId);
    await setSubClassroomList(response);
  };

  const getClassroomById = async () => {
    setLoader(true);

    const response = await classService.GetClassroomById(classId);
    await setClassroom(response?.[0]);

    setLoader(false);
  };

  const getClassroomsForAdmin = async () => {
    // const response = await subClassroomService.GetSubClassroomForAdmin();
    // console.warn(response);
  };

  useEffect(() => {
    dispatch(showLoader());
    if (path.includes("branchesClassrooms")) {
      getClassroomsForAdmin();
    } else {
      getSubClassroomById();
      getClassroomById();
    }
    dispatch(hideLoader());
  }, [classId, props]);

  const filteredStudentList = classroom?.students.filter((e) => {
    const queryText = searchText.toLocaleLowerCase();

    const testId = e.id.toString();
    const name = (e.name || "").toLocaleLowerCase();
    const surname = (e.surname || "").toLocaleLowerCase();
    const parentName = (e.parentName || "").toLocaleLowerCase();
    // const testName = (e.name || "").toLocaleLowerCase();
    const isPassive = e.isPassive === false;
    return (
      (testId.includes(queryText) ||
        name.includes(queryText) ||
        surname.includes(queryText) ||
        parentName.includes(queryText)) &&
      isPassive
    );
  });

  const datatableModel: DatatableDataModel = {
    header: [
      "Öğrenci Adı Soyadı",
      "Veli Adı Soyadı",
      "Öğrenci Tel",
      "Veli Tel",
    ],
    list: (filteredStudentList || []).map((s, index) => ({
      id: index,
      row: [s?.name, s?.parentName, s?.telephone, s?.parentTel],
    })),
  };

  useEffect(() => {
    datatableModel.list.length && dispatch(hideLoader());
  }, [datatableModel]);

  return (
    <>
      <SubClassroomListPage
        classroomName={classroom?.name}
        subClassroomList={subClassroomList}
        classId={classId}
      />
      {!path.includes("report") && (
        <>
          <div className="page-heading">
            <h3>{`Şubesi Belirlenmemiş Öğrenciler (${classroom?.students?.length} Öğrenci)`}</h3>
          </div>
          <div className="form-group row">
            <div className="col-12 d-flex justify-content-center align-items-center ">
              <input
                type="text"
                placeholder="Öğrenci Ara"
                className="form-control"
                value={searchText}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchText(e.target.value)
                }
                style={{ marginBottom: "25px" }}
              />
            </div>
          </div>
          {loader ? (
            <ComponentLoader />
          ) : (
            <Datatable
              editable={false}
              insertable={false}
              data={datatableModel}
            />
          )}
        </>
      )}
    </>
  );
}
