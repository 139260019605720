import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LessonService from "../../services/lessonService";
import Header from "../header";
import StudentBasicInfo from "./studentBasicInfo";
import TeacherService from "../../services/teacherService";
import Accordion from "../sharedComponents/accordion";
import SelectableAccordionItem from "../sharedComponents/selectableAccordionItem";

export default function StudentLesson() {
  const { lessonId } = useParams<any>();
  const lessonService = new LessonService();
  const teacherService = new TeacherService();
  const [studentLessonProgress, setStudentLessonProgress] =
    useState<LessonSuccessModel>(null);
  const [studentTopicPorgress, setStudentTopicProgress] =
    useState<LessonOutputModel>();
  const getStudentLessonProgress = async () => {
    const result = await lessonService.GetStudentLessonProgress(lessonId);
    if (result) setStudentLessonProgress(result);
  };

  const getStudentProgress = async () => {
    const response = await teacherService.StudentProgress(lessonId);
    setStudentTopicProgress(response);
  };

  useEffect(() => {
    getStudentLessonProgress();
    getStudentProgress();
  }, [lessonId]);

  const getStudentSuccessRate = (model: StudentSuccessModel) => {
    const correctCount = model.correctAnswerCount;
    const questionCount = model.questionCount;
    return questionCount > 0
      ? Math.round((100 * correctCount) / questionCount)
      : 0;
  };

  const unitProgress = (u: UnitOutputModel) =>
    u.topics && u.topics.length > 0
      ? u.topics.reduce((acc, curr) => acc + (curr.checked ? 1 : 0), 0) /
        u.topics.length
      : u.checked
      ? 1
      : 0;
  const overallProgress: number = Math.round(
    studentTopicPorgress
      ? (studentTopicPorgress.units.reduce(
          (acc, curr) => acc + (curr.checked ? 1 : unitProgress(curr)),
          0
        ) *
          100) /
          studentTopicPorgress.units.length
      : 0
  );
  const populateTopics = (unitId: number, topics: Array<TopicOutputModel>) =>
    topics.map((t) => {
      let data: DatatableDataModel = {
        header: ["Soru No", "Zorluk Derecesi"],
        list: t.testQuestions.map((q) => ({
          id: q.id,
          checked: q.checked,
          row: [
            q.id.toString(),
            q.difficultyLevel ? q.difficultyLevel.toString() : "",
          ],
        })),
      };

      return (
        <SelectableAccordionItem
          // checkboxDisable={!formEditable}
          collapsable={false}
          onCheckboxChangeHandler={
            (checked: boolean) => {}
            // onTopicCheckboxChangeCallbackHandler(unitId, t.id, checked)
          }
          id={"topic" + t.id}
          parentId="Topics"
          title={t.name}
          checked={t.checked || false}
        ></SelectableAccordionItem>
      );
    });
  const populateUnits = () => {
    return studentTopicPorgress.units.map((u) => (
      <SelectableAccordionItem
        // checkboxDisable={!formEditable}
        progressBar={{
          valueMax: 100,
          valueMin: 0,
          valueNow: Math.round(unitProgress(u) * 100),
        }}
        onCheckboxChangeHandler={
          (checked: boolean) => {}
          // onUnitCheckboxChangeCallbackHandler(u.id, checked)
        }
        id={"unit" + u.id}
        parentId="Units"
        title={u.name}
        checked={u.checked || false}
      >
        <Accordion
          id={"Topics"}
          insertable={false}
          populate={() => populateTopics(u.id, u.topics)}
        ></Accordion>
      </SelectableAccordionItem>
    ));
  };

  return (
    <>
      <Header
        name={
          studentLessonProgress && studentLessonProgress.lessonName
            ? studentLessonProgress.lessonName + " Dersi"
            : ""
        }
      />
      <div className="page-content">
        <section className="row">
          <StudentBasicInfo />
        </section>
        {studentLessonProgress ? (
          <>
            <div className="card">
              <div className="card-header">
                <h4>Üniteler</h4>
              </div>
              <div className="card-body">
                <div className="row" id="table-hover-row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-content">
                        <div className="table-responsive">
                          <table className="table table-hover mb-0">
                            <thead>
                              <tr>
                                <th>Ünite</th>
                                <th>Başarı Durumu</th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentLessonProgress.lessonUnits.map((u) => (
                                <tr>
                                  <td className="text-bold-500">
                                    <a>{u.lessonUnitName}</a>
                                  </td>
                                  <td className="pt-4">
                                    <div className="progress progress-info">
                                      <div
                                        className="progress-bar progress-bar-striped progress-label"
                                        role="progressbar"
                                        style={{
                                          width:
                                            getStudentSuccessRate(
                                              u.successRate
                                            ) + "%",
                                          height: "100%",
                                        }}
                                        aria-valuenow={getStudentSuccessRate(
                                          u.successRate
                                        )}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      ></div>
                                    </div>
                                    <div style={{ float: "right" }}>
                                      {u.successRate.correctAnswerCount +
                                        " / " +
                                        u.successRate.questionCount}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4>Genel Değerlendirme</h4>
              </div>
              <div className="card-body">
                <b>
                  {studentLessonProgress.lessonName} Dersi Değerlendirme Yüzdesi
                </b>
                <div className="progress progress-info mt-4 mb-4">
                  <div
                    className="progress-bar progress-bar-striped progress-label"
                    role="progressbar"
                    id="uniteDegerlendirmeYuzdesi"
                    style={{
                      width:
                        getStudentSuccessRate(
                          studentLessonProgress.successRate
                        ) + "%",
                      height: "100%",
                    }}
                    aria-valuenow={getStudentSuccessRate(
                      studentLessonProgress.successRate
                    )}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <div style={{ float: "right" }}>
                  {studentLessonProgress.successRate.correctAnswerCount +
                    " / " +
                    studentLessonProgress.successRate.questionCount}
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Öğrenci İlerleme Durumu</h4>
            <div className="progress progress-info mt-4 mb-4">
              <div
                className="progress-bar progress-bar-striped progress-label"
                role="progressbar"
                style={{ width: overallProgress + "%", height: "100%" }}
                aria-valuenow={overallProgress}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
          <div className="card-body">
            {studentTopicPorgress ? (
              <Accordion
                id={"Units"}
                insertable={false}
                populate={populateUnits}
              ></Accordion>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
