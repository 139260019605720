import BaseService from "./baseService";

declare global {
  interface AddCheckBookInputModel {
    IssueDate: string;
    SubclassroomId: number;
    StudentId: string[];
  }

  interface GetCheckBookOutputModel {
    issueDate: string;
    teacher: null | string;
    subClassRoom: {
      id: number;
      name: string;
      classroom: {
        id: number;
        name: string;
      };
    };
    students: {
      student: {
        name: string;
        surname: string;
        id: string;
      };
      check: true;
    }[];
  }

  interface GetCheckBookForStudentOutputModel {
    id: number;
    check: boolean;
    issueDate: string;
    teacher: string;
    student: {
      id: string;
      name: string;
      surname: string;
    };
    subClassRoom: {
      id: number;
      name: string;
      classroom: {
        id: number;
        name: string;
      };
    };
  }
}

export default class CheckBookService extends BaseService {
  /**
   *
   */
  constructor() {
    super("/checkbook");
  }

  AddCheckBook(check: AddCheckBookInputModel) {
    return this.Post<AddCheckBookInputModel, SurveyOutputModel>(check);
  }

  GetCheckBook(id: number) {
    return this.GetById<number, GetCheckBookOutputModel[]>(
      id,
      "/checkbook/BySubclassroomId"
    );
  }

  GetCheckBookForStudent() {
    return this.Get<Array<GetCheckBookForStudentOutputModel>>(
      null,
      "/checkbook/ForStudent"
    );
  }
}
